import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue } from "bootstrap-vue";
import "./custom.scss";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons/faPlusSquare.js";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons/faMinusSquare.js";
import { faDrawPolygon } from "@fortawesome/free-solid-svg-icons/faDrawPolygon.js";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash.js";
import { faCompressAlt } from "@fortawesome/free-solid-svg-icons/faCompressAlt.js";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons/faExpandAlt.js";
import { faMousePointer } from "@fortawesome/free-solid-svg-icons/faMousePointer.js";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueGtag from "vue-gtag";

library.add(faPlusSquare);
library.add(faMinusSquare);
library.add(faDrawPolygon);
library.add(faExpandAlt);
library.add(faCompressAlt);
library.add(faMousePointer);
library.add(faTrash);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);

Vue.use(VueGtag, {
  config: {
    id: "G-TFZ72DGC80",
    params: {
      send_page_view: false
    }
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
