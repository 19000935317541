const cols =
  "the_geom_webmercator, oa11_cd, la17_cd, la17_nm, toa_01, toa_02, toa_03, toa_04, " +
  "toa_05, toa_06, toa_07, toa_08, toa_09, toa_10, toa_11";

const groups = [
  {
    id: "1",
    name: "Suburban Diversity",
    colour: "#e41a1c",
    visible: true,
    description:
      "These areas are typically suburban in location, with very high ethnic diversity. Populations are typically young, and many families have young children. There are above average numbers of residents from newer EU countries and crowded, privately rented terraced housing is common. Perhaps given lower rent values within these areas, they are attractive to students. Although unemployment is high, those who are in work tend to be employed in manual occupations such as warehousing, transport, accommodation and food services."
  },
  {
    id: "2",
    name: "Ethnicity Central",
    colour: "#377eb8",
    visible: true,
    description:
      "These are areas of very high ethnic diversity, with especially high prevalence of Black and Bangladeshi residents. Many households have young children and rates of divorce are higher than the national average. There are also high numbers of students living within these areas. The dominant housing stock is flats, with many overcrowded and rented within the public sector. Unemployment within these areas is high, and as might be expected given their central locations, public transport is heavily used."
  },
  {
    id: "3",
    name: "Intermediate Areas",
    colour: "#4daf4a",
    visible: true,
    description:
      "These areas have few distinctive features, apart from higher than average numbers of very elderly people living in communal establishments."
  },
  {
    id: "4",
    name: "Students & Aspiring Professionals",
    colour: "#984ea3",
    visible: true,
    description:
      "Both undergraduate and postgraduate students, and those who are starting their careers are over represented in these areas. Residents are ethnically diverse, with higher than average numbers of people identifying their origins as Chinese, Indian or being born in countries that acceded to the EU prior to 2001. The dominant housing stock is flats, which are typically rented within the private sector, and there is some overcrowding."
  },
  {
    id: "5",
    name: "Country Living & Retirement",
    colour: "#ff7f00",
    visible: true,
    description:
      "These rural areas are overwhelmingly White and house large numbers of people who work in agriculture, forestry and fishing. Of those not working, there are higher numbers of people who are past retirement age. Many people live in uncrowded detached houses, perhaps because children have aged and left the family home."
  },
  {
    id: "6",
    name: "Blue-collar Suburbanites",
    colour: "#ffff33",
    visible: true,
    description:
      "These suburban areas are dominated by terraced or semi detached housing, with a higher than average number being socially rented. Employment is most typically in manufacturing, although many other blue-collar occupations are prevalent, such as construction."
  },
  {
    id: "7",
    name: "Professional Prosperity",
    colour: "#a65628",
    visible: true,
    description:
      "The populations of these areas are most typically White and towards the latter stages of successful careers in a range of white-collar professional occupations. Most are married, and if they have had children, these are of an age where they are no longer dependent. Housing within these areas is typically privately owned and detached; and higher incomes enable many households to sustain multiple car ownership."
  },
  {
    id: "8",
    name: "Hard-up Households",
    colour: "#f781bf",
    visible: true,
    description:
      "These deprived and predominantly white areas feature households from a full range of age groups. Those of working age experience higher than average rates of unemployment. Employed residents work in service or manual occupations. Housing within these areas is typically terraced or flats, with some overcrowding and very high rates of renting within the social housing sector."
  }
];

export const TOAC = {
  title: "Temporal OAC 2001-11",
  description: "Geodemographic change in England, 2001-2011.",
  downloadLink: "https://data.cdrc.ac.uk/dataset/temporal-oac",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM oa11_uk",
  buildingSql: "SELECT " + cols + " FROM oa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM oa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM oa11_uk_urban",
  defaultLayers: "1111000",
  extent: 3,
  popup: true,
  popupType: "classification",
  idField: "oa11_cd",
  geogNameField: "la17_nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la17_cd",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.toa_01 = '1' then 1 else 0 end) as count_1_2001,
      sum(case when d.toa_01 = '2' then 1 else 0 end) as count_2_2001,
      sum(case when d.toa_01 = '3' then 1 else 0 end) as count_3_2001,
      sum(case when d.toa_01 = '4' then 1 else 0 end) as count_4_2001,
      sum(case when d.toa_01 = '5' then 1 else 0 end) as count_5_2001,
      sum(case when d.toa_01 = '6' then 1 else 0 end) as count_6_2001,
      sum(case when d.toa_01 = '7' then 1 else 0 end) as count_7_2001,
      sum(case when d.toa_01 = '8' then 1 else 0 end) as count_8_2001,
      sum(case when d.toa_02 = '1' then 1 else 0 end) as count_1_2002,
      sum(case when d.toa_02 = '2' then 1 else 0 end) as count_2_2002,
      sum(case when d.toa_02 = '3' then 1 else 0 end) as count_3_2002,
      sum(case when d.toa_02 = '4' then 1 else 0 end) as count_4_2002,
      sum(case when d.toa_02 = '5' then 1 else 0 end) as count_5_2002,
      sum(case when d.toa_02 = '6' then 1 else 0 end) as count_6_2002,
      sum(case when d.toa_02 = '7' then 1 else 0 end) as count_7_2002,
      sum(case when d.toa_02 = '8' then 1 else 0 end) as count_8_2002,
      sum(case when d.toa_02 = '1' then 1 else 0 end) as count_1_2002,
      sum(case when d.toa_02 = '2' then 1 else 0 end) as count_2_2002,
      sum(case when d.toa_02 = '3' then 1 else 0 end) as count_3_2002,
      sum(case when d.toa_02 = '4' then 1 else 0 end) as count_4_2002,
      sum(case when d.toa_02 = '5' then 1 else 0 end) as count_5_2002,
      sum(case when d.toa_02 = '6' then 1 else 0 end) as count_6_2002,
      sum(case when d.toa_02 = '7' then 1 else 0 end) as count_7_2002,
      sum(case when d.toa_02 = '8' then 1 else 0 end) as count_8_2002,
      sum(case when d.toa_03 = '1' then 1 else 0 end) as count_1_2003,
      sum(case when d.toa_03 = '2' then 1 else 0 end) as count_2_2003,
      sum(case when d.toa_03 = '3' then 1 else 0 end) as count_3_2003,
      sum(case when d.toa_03 = '4' then 1 else 0 end) as count_4_2003,
      sum(case when d.toa_03 = '5' then 1 else 0 end) as count_5_2003,
      sum(case when d.toa_03 = '6' then 1 else 0 end) as count_6_2003,
      sum(case when d.toa_03 = '7' then 1 else 0 end) as count_7_2003,
      sum(case when d.toa_03 = '8' then 1 else 0 end) as count_8_2003,
      sum(case when d.toa_04 = '1' then 1 else 0 end) as count_1_2004,
      sum(case when d.toa_04 = '2' then 1 else 0 end) as count_2_2004,
      sum(case when d.toa_04 = '3' then 1 else 0 end) as count_3_2004,
      sum(case when d.toa_04 = '4' then 1 else 0 end) as count_4_2004,
      sum(case when d.toa_04 = '5' then 1 else 0 end) as count_5_2004,
      sum(case when d.toa_04 = '6' then 1 else 0 end) as count_6_2004,
      sum(case when d.toa_04 = '7' then 1 else 0 end) as count_7_2004,
      sum(case when d.toa_04 = '8' then 1 else 0 end) as count_8_2004,
      sum(case when d.toa_05 = '1' then 1 else 0 end) as count_1_2005,
      sum(case when d.toa_05 = '2' then 1 else 0 end) as count_2_2005,
      sum(case when d.toa_05 = '3' then 1 else 0 end) as count_3_2005,
      sum(case when d.toa_05 = '4' then 1 else 0 end) as count_4_2005,
      sum(case when d.toa_05 = '5' then 1 else 0 end) as count_5_2005,
      sum(case when d.toa_05 = '6' then 1 else 0 end) as count_6_2005,
      sum(case when d.toa_05 = '7' then 1 else 0 end) as count_7_2005,
      sum(case when d.toa_05 = '8' then 1 else 0 end) as count_8_2005,
      sum(case when d.toa_06 = '1' then 1 else 0 end) as count_1_2006,
      sum(case when d.toa_06 = '2' then 1 else 0 end) as count_2_2006,
      sum(case when d.toa_06 = '3' then 1 else 0 end) as count_3_2006,
      sum(case when d.toa_06 = '4' then 1 else 0 end) as count_4_2006,
      sum(case when d.toa_06 = '5' then 1 else 0 end) as count_5_2006,
      sum(case when d.toa_06 = '6' then 1 else 0 end) as count_6_2006,
      sum(case when d.toa_06 = '7' then 1 else 0 end) as count_7_2006,
      sum(case when d.toa_06 = '8' then 1 else 0 end) as count_8_2006,
      sum(case when d.toa_07 = '1' then 1 else 0 end) as count_1_2007,
      sum(case when d.toa_07 = '2' then 1 else 0 end) as count_2_2007,
      sum(case when d.toa_07 = '3' then 1 else 0 end) as count_3_2007,
      sum(case when d.toa_07 = '4' then 1 else 0 end) as count_4_2007,
      sum(case when d.toa_07 = '5' then 1 else 0 end) as count_5_2007,
      sum(case when d.toa_07 = '6' then 1 else 0 end) as count_6_2007,
      sum(case when d.toa_07 = '7' then 1 else 0 end) as count_7_2007,
      sum(case when d.toa_07 = '8' then 1 else 0 end) as count_8_2007,
      sum(case when d.toa_08 = '1' then 1 else 0 end) as count_1_2008,
      sum(case when d.toa_08 = '2' then 1 else 0 end) as count_2_2008,
      sum(case when d.toa_08 = '3' then 1 else 0 end) as count_3_2008,
      sum(case when d.toa_08 = '4' then 1 else 0 end) as count_4_2008,
      sum(case when d.toa_08 = '5' then 1 else 0 end) as count_5_2008,
      sum(case when d.toa_08 = '6' then 1 else 0 end) as count_6_2008,
      sum(case when d.toa_08 = '7' then 1 else 0 end) as count_7_2008,
      sum(case when d.toa_08 = '8' then 1 else 0 end) as count_8_2008,
      sum(case when d.toa_09 = '1' then 1 else 0 end) as count_1_2009,
      sum(case when d.toa_09 = '2' then 1 else 0 end) as count_2_2009,
      sum(case when d.toa_09 = '3' then 1 else 0 end) as count_3_2009,
      sum(case when d.toa_09 = '4' then 1 else 0 end) as count_4_2009,
      sum(case when d.toa_09 = '5' then 1 else 0 end) as count_5_2009,
      sum(case when d.toa_09 = '6' then 1 else 0 end) as count_6_2009,
      sum(case when d.toa_09 = '7' then 1 else 0 end) as count_7_2009,
      sum(case when d.toa_09 = '8' then 1 else 0 end) as count_8_2009,
      sum(case when d.toa_10 = '1' then 1 else 0 end) as count_1_2010,
      sum(case when d.toa_10 = '2' then 1 else 0 end) as count_2_2010,
      sum(case when d.toa_10 = '3' then 1 else 0 end) as count_3_2010,
      sum(case when d.toa_10 = '4' then 1 else 0 end) as count_4_2010,
      sum(case when d.toa_10 = '5' then 1 else 0 end) as count_5_2010,
      sum(case when d.toa_10 = '6' then 1 else 0 end) as count_6_2010,
      sum(case when d.toa_10 = '7' then 1 else 0 end) as count_7_2010,
      sum(case when d.toa_10 = '8' then 1 else 0 end) as count_8_2010,
      sum(case when d.toa_11 = '1' then 1 else 0 end) as count_1_2011,
      sum(case when d.toa_11 = '2' then 1 else 0 end) as count_2_2011,
      sum(case when d.toa_11 = '3' then 1 else 0 end) as count_3_2011,
      sum(case when d.toa_11 = '4' then 1 else 0 end) as count_4_2011,
      sum(case when d.toa_11 = '5' then 1 else 0 end) as count_5_2011,
      sum(case when d.toa_11 = '6' then 1 else 0 end) as count_6_2011,
      sum(case when d.toa_11 = '7' then 1 else 0 end) as count_7_2011,
      sum(case when d.toa_11 = '8' then 1 else 0 end) as count_8_2011
  FROM oliverobrien.oa11_uk d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "2001",
      field: "toa_01",
      lookup: "id",
      groups: groups,
      hideExpand: true
    },
    {
      id: 1,
      name: "2002",
      field: "toa_02",
      lookup: "id",
      groups: groups
    },
    {
      id: 2,
      name: "2003",
      field: "toa_03",
      lookup: "id",
      groups: groups
    },
    {
      id: 3,
      name: "2004",
      field: "toa_04",
      lookup: "id",
      groups: groups
    },
    {
      id: 4,
      name: "2005",
      field: "toa_05",
      lookup: "id",
      groups: groups
    },
    {
      id: 5,
      name: "2006",
      field: "toa_06",
      lookup: "id",
      groups: groups
    },
    {
      id: 6,
      name: "2007",
      field: "toa_07",
      lookup: "id",
      groups: groups
    },
    {
      id: 7,
      name: "2008",
      field: "toa_08",
      lookup: "id",
      groups: groups
    },
    {
      id: 8,
      name: "2009",
      field: "toa_09",
      lookup: "id",
      groups: groups
    },
    {
      id: 9,
      name: "2010",
      field: "toa_10",
      lookup: "id",
      groups: groups
    },
    {
      id: 10,
      name: "2011",
      field: "toa_11",
      lookup: "id",
      groups: groups
    }
  ]
};
