export const MAPBOXSTYLE = {
  version: 8,
  name: "Empty",
  sources: {},
  glyphs: "https://tiles.basemaps.cartocdn.com/fonts/{fontstack}/{range}.pbf",
  layers: [
    {
      id: "background",
      type: "background",
      paint: {
        "background-color": "rgba(30,50,70,1.0)"
      }
    }
  ],
  id: "empty"
};
