<template>
  <div class="legend mt-2">
    <div class="my-2">
      <strong>{{ metrictitle }} </strong>
      <small
        ><div v-if="metricdescription !== undefined">
          {{ metricdescription }}
        </div></small
      >
    </div>
    <div
      v-for="stop in stops"
      :key="stop.name"
      class="legend-item"
      style="text-indent: -35px; margin-left: 35px;"
    >
      <span class="legend-key" v-bind:style="{ backgroundColor: stop.colour }">
      </span>
      {{ stop.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MapMetricLegend",
  props: { stops: Array, metrictitle: String, metricdescription: String },
  components: {},
  methods: {}
};
</script>

<style scoped>
.legend-key {
  display: inline-block;
  border-radius: 20%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
</style>
