import colorbrewer from "colorbrewer";

const colsPrefix = "the_geom_webmercator, oa11_cd, la17_cd, la17_nm, ";

const ramp = colorbrewer.RdPu[9];

const legendStops = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp[0], name: "Under 5 min" },
      { value: 5, colour: ramp[1], name: "5-10 min" },
      { value: 10, colour: ramp[2], name: "10-20 min" },
      { value: 20, colour: ramp[3], name: "20-30 min" },
      { value: 30, colour: ramp[4], name: "30-45 min" },
      { value: 45, colour: ramp[5], name: "45-60 min" },
      { value: 60, colour: ramp[6], name: "60-90 min" },
      { value: 90, colour: ramp[7], name: "90-120 min" },
      { value: 120, colour: ramp[8], name: "Over 120 min" }
    ]
  }
];

const rampChg = colorbrewer.RdBu[11];

const legendStopsChg = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampChg[10], name: "20%+ shorter" },
      { value: -0.2, colour: rampChg[9], name: "15-20% shorter" },
      { value: -0.15, colour: rampChg[8], name: "10-15% shorter" },
      { value: -0.1, colour: rampChg[7], name: "5-10% shorter" },
      { value: -0.05, colour: rampChg[6], name: "2-5% shorter" },
      { value: -0.02, colour: rampChg[5], name: "<2% change" },
      { value: 0.02, colour: rampChg[4], name: "2-5% longer" },
      { value: 0.05, colour: rampChg[3], name: "5-10% longer" },
      { value: 0.1, colour: rampChg[2], name: "10-15% longer" },
      { value: 0.15, colour: rampChg[1], name: "15-20% longer" },
      { value: 0.2, colour: rampChg[0], name: "20%+ longer" }
    ]
  }
];

function getMetrics() {
  // prettier-ignore
  return [
    { id: 0, name: "All Oct-10", field: "acall_oc10", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 1, name: "All Oct-11", field: "acall_oc11", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 2, name: "All Oct-13", field: "acall_oc13", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 3, name: "All Jan-14", field: "acall_ja14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 4, name: "All Apr-14", field: "acall_ap14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 5, name: "All Jul-14", field: "acall_jl14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 6, name: "All Oct-14", field: "acall_oc14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 7, name: "All Jan-15", field: "acall_ja15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 8, name: "All Apr-15", field: "acall_ap15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 9, name: "All Jul-15", field: "acall_jl15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 10, name: "All Oct-15", field: "acall_oc15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 11, name: "All Jan-16", field: "acall_ja16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 12, name: "All Apr-16", field: "acall_ap16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 13, name: "All Jul-16", field: "acall_jl16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 14, name: "All Oct-16", field: "acall_oc16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 15, name: "All Chg 1YE Oct-11", field: "acall_oc11.chg", compField:"acall_oc10", denomField:"acall_oc10", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 16, name: "All Chg 1YE Oct-14", field: "acall_oc14.chg", compField:"acall_oc13", denomField:"acall_oc13", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 17, name: "All Chg 1YE Jan-15", field: "acall_ja15.chg", compField:"acall_ja14", denomField:"acall_ja14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 18, name: "All Chg 1YE Apr-15", field: "acall_ap15.chg", compField:"acall_ap14", denomField:"acall_ap14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 19, name: "All Chg 1YE Jul-15", field: "acall_jl15.chg", compField:"acall_jl14", denomField:"acall_jl14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 20, name: "All Chg 1YE Oct-15", field: "acall_oc15.chg", compField:"acall_oc14", denomField:"acall_oc14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 21, name: "All Chg 1YE Jan-16", field: "acall_ja16.chg", compField:"acall_ja15", denomField:"acall_ja15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 22, name: "All Chg 1YE Apr-16", field: "acall_ap16.chg", compField:"acall_ap15", denomField:"acall_ap15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 23, name: "All Chg 1YE Jul-16", field: "acall_jl16.chg", compField:"acall_jl15", denomField:"acall_jl15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 24, name: "All Chg 1YE Oct-16", field: "acall_oc16.chg", compField:"acall_oc15", denomField:"acall_oc15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 25, name: "Clinic Oct-10", field: "accli_oc10", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 26, name: "Clinic Oct-11", field: "accli_oc11", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 27, name: "Clinic Oct-13", field: "accli_oc13", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 28, name: "Clinic Jan-14", field: "accli_ja14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 29, name: "Clinic Apr-14", field: "accli_ap14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 30, name: "Clinic Jul-14", field: "accli_jl14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 31, name: "Clinic Oct-14", field: "accli_oc14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 32, name: "Clinic Jan-15", field: "accli_ja15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 33, name: "Clinic Apr-15", field: "accli_ap15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 34, name: "Clinic Jul-15", field: "accli_jl15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 35, name: "Clinic Oct-15", field: "accli_oc15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 36, name: "Clinic Jan-16", field: "accli_ja16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 37, name: "Clinic Apr-16", field: "accli_ap16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 38, name: "Clinic Jul-16", field: "accli_jl16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 39, name: "Clinic Oct-16", field: "accli_oc16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 40, name: "Clinic Chg 1YE Oct-11", field: "accli_oc11.chg", compField:"accli_oc10", denomField:"accli_oc10", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 41, name: "Clinic Chg 1YE Oct-14", field: "accli_oc14.chg", compField:"accli_oc13", denomField:"accli_oc13", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 42, name: "Clinic Chg 1YE Jan-15", field: "accli_ja15.chg", compField:"accli_ja14", denomField:"accli_ja14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 43, name: "Clinic Chg 1YE Apr-15", field: "accli_ap15.chg", compField:"accli_ap14", denomField:"accli_ap14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 44, name: "Clinic Chg 1YE Jul-15", field: "accli_jl15.chg", compField:"accli_jl14", denomField:"accli_jl14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 45, name: "Clinic Chg 1YE Oct-15", field: "accli_oc15.chg", compField:"accli_oc14", denomField:"accli_oc14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 46, name: "Clinic Chg 1YE Jan-16", field: "accli_ja16.chg", compField:"accli_ja15", denomField:"accli_ja15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 47, name: "Clinic Chg 1YE Apr-16", field: "accli_ap16.chg", compField:"accli_ap15", denomField:"accli_ap15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 48, name: "Clinic Chg 1YE Jul-16", field: "accli_jl16.chg", compField:"accli_jl15", denomField:"accli_jl15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 49, name: "Clinic Chg 1YE Oct-16", field: "accli_oc16.chg", compField:"accli_oc15", denomField:"accli_oc15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 50, name: "GP Oct-10", field: "acgp_oc10", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 51, name: "GP Oct-11", field: "acgp_oc11", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 52, name: "GP Oct-13", field: "acgp_oc13", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 53, name: "GP Jan-14", field: "acgp_ja14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 54, name: "GP Apr-14", field: "acgp_ap14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 55, name: "GP Jul-14", field: "acgp_jl14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 56, name: "GP Oct-14", field: "acgp_oc14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 57, name: "GP Jan-15", field: "acgp_ja15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 58, name: "GP Apr-15", field: "acgp_ap15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 59, name: "GP Jul-15", field: "acgp_jl15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 60, name: "GP Oct-15", field: "acgp_oc15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 61, name: "GP Jan-16", field: "acgp_ja16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 62, name: "GP Apr-16", field: "acgp_ap16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 63, name: "GP Jul-16", field: "acgp_jl16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 64, name: "GP Oct-16", field: "acgp_oc16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 65, name: "GP Chg 1YE Oct-11", field: "acgp_oc11.chg", compField:"acgp_oc10", denomField:"acgp_oc10", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 66, name: "GP Chg 1YE Oct-14", field: "acgp_oc14.chg", compField:"acgp_oc13", denomField:"acgp_oc13", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 67, name: "GP Chg 1YE Jan-15", field: "acgp_ja15.chg", compField:"acgp_ja14", denomField:"acgp_ja14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 68, name: "GP Chg 1YE Apr-15", field: "acgp_ap15.chg", compField:"acgp_ap14", denomField:"acgp_ap14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 69, name: "GP Chg 1YE Jul-15", field: "acgp_jl15.chg", compField:"acgp_jl14", denomField:"acgp_jl14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 70, name: "GP Chg 1YE Oct-15", field: "acgp_oc15.chg", compField:"acgp_oc14", denomField:"acgp_oc14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 71, name: "GP Chg 1YE Jan-16", field: "acgp_ja16.chg", compField:"acgp_ja15", denomField:"acgp_ja15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 72, name: "GP Chg 1YE Apr-16", field: "acgp_ap16.chg", compField:"acgp_ap15", denomField:"acgp_ap15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 73, name: "GP Chg 1YE Jul-16", field: "acgp_jl16.chg", compField:"acgp_jl15", denomField:"acgp_jl15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 74, name: "GP Chg 1YE Oct-16", field: "acgp_oc16.chg", compField:"acgp_oc15", denomField:"acgp_oc15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 75, name: "Hospital Oct-10", field: "achsp_oc10", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 76, name: "Hospital Oct-11", field: "achsp_oc11", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 77, name: "Hospital Oct-13", field: "achsp_oc13", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 78, name: "Hospital Jan-14", field: "achsp_ja14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 79, name: "Hospital Apr-14", field: "achsp_ap14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 80, name: "Hospital Jul-14", field: "achsp_jl14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 81, name: "Hospital Oct-14", field: "achsp_oc14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 82, name: "Hospital Jan-15", field: "achsp_ja15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 83, name: "Hospital Apr-15", field: "achsp_ap15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 84, name: "Hospital Jul-15", field: "achsp_jl15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 85, name: "Hospital Oct-15", field: "achsp_oc15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 86, name: "Hospital Jan-16", field: "achsp_ja16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 87, name: "Hospital Apr-16", field: "achsp_ap16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 88, name: "Hospital Jul-16", field: "achsp_jl16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 89, name: "Hospital Oct-16", field: "achsp_oc16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 90, name: "Hospital Chg 1YE Oct-11", field: "achsp_oc11.chg", compField:"achsp_oc10", denomField:"achsp_oc10", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 91, name: "Hospital Chg 1YE Oct-14", field: "achsp_oc14.chg", compField:"achsp_oc13", denomField:"achsp_oc13", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 92, name: "Hospital Chg 1YE Jan-15", field: "achsp_ja15.chg", compField:"achsp_ja14", denomField:"achsp_ja14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 93, name: "Hospital Chg 1YE Apr-15", field: "achsp_ap15.chg", compField:"achsp_ap14", denomField:"achsp_ap14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 94, name: "Hospital Chg 1YE Jul-15", field: "achsp_jl15.chg", compField:"achsp_jl14", denomField:"achsp_jl14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 95, name: "Hospital Chg 1YE Oct-15", field: "achsp_oc15.chg", compField:"achsp_oc14", denomField:"achsp_oc14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 96, name: "Hospital Chg 1YE Jan-16", field: "achsp_ja16.chg", compField:"achsp_ja15", denomField:"achsp_ja15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 97, name: "Hospital Chg 1YE Apr-16", field: "achsp_ap16.chg", compField:"achsp_ap15", denomField:"achsp_ap15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 98, name: "Hospital Chg 1YE Jul-16", field: "achsp_jl16.chg", compField:"achsp_jl15", denomField:"achsp_jl15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 99, name: "Hospital Chg 1YE Oct-16", field: "achsp_oc16.chg", compField:"achsp_oc15", denomField:"achsp_oc15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 100, name: "Rail Oct-10", field: "acsta_oc10", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 101, name: "Rail Oct-11", field: "acsta_oc11", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 102, name: "Rail Oct-13", field: "acsta_oc13", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 103, name: "Rail Jan-14", field: "acsta_ja14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 104, name: "Rail Apr-14", field: "acsta_ap14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 105, name: "Rail Jul-14", field: "acsta_jl14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 106, name: "Rail Oct-14", field: "acsta_oc14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 107, name: "Rail Jan-15", field: "acsta_ja15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 108, name: "Rail Apr-15", field: "acsta_ap15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 109, name: "Rail Jul-15", field: "acsta_jl15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 110, name: "Rail Oct-15", field: "acsta_oc15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 111, name: "Rail Jan-16", field: "acsta_ja16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 112, name: "Rail Apr-16", field: "acsta_ap16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 113, name: "Rail Jul-16", field: "acsta_jl16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 114, name: "Rail Oct-16", field: "acsta_oc16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 115, name: "Rail Chg 1YE Oct-11", field: "acsta_oc11.chg", compField:"acsta_oc10", denomField:"acsta_oc10", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 116, name: "Rail Chg 1YE Oct-14", field: "acsta_oc14.chg", compField:"acsta_oc13", denomField:"acsta_oc13", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 117, name: "Rail Chg 1YE Jan-15", field: "acsta_ja15.chg", compField:"acsta_ja14", denomField:"acsta_ja14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 118, name: "Rail Chg 1YE Apr-15", field: "acsta_ap15.chg", compField:"acsta_ap14", denomField:"acsta_ap14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 119, name: "Rail Chg 1YE Jul-15", field: "acsta_jl15.chg", compField:"acsta_jl14", denomField:"acsta_jl14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 120, name: "Rail Chg 1YE Oct-15", field: "acsta_oc15.chg", compField:"acsta_oc14", denomField:"acsta_oc14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 121, name: "Rail Chg 1YE Jan-16", field: "acsta_ja16.chg", compField:"acsta_ja15", denomField:"acsta_ja15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 122, name: "Rail Chg 1YE Apr-16", field: "acsta_ap16.chg", compField:"acsta_ap15", denomField:"acsta_ap15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 123, name: "Rail Chg 1YE Jul-16", field: "acsta_jl16.chg", compField:"acsta_jl15", denomField:"acsta_jl15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 124, name: "Rail Chg 1YE Oct-16", field: "acsta_oc16.chg", compField:"acsta_oc15", denomField:"acsta_oc15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 125, name: "Retail Oct-10", field: "acret_oc10", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 126, name: "Retail Oct-11", field: "acret_oc11", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 127, name: "Retail Oct-13", field: "acret_oc13", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 128, name: "Retail Jan-14", field: "acret_ja14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 129, name: "Retail Apr-14", field: "acret_ap14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 130, name: "Retail Jul-14", field: "acret_jl14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 131, name: "Retail Oct-14", field: "acret_oc14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 132, name: "Retail Jan-15", field: "acret_ja15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 133, name: "Retail Apr-15", field: "acret_ap15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 134, name: "Retail Jul-15", field: "acret_jl15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 135, name: "Retail Oct-15", field: "acret_oc15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 136, name: "Retail Jan-16", field: "acret_ja16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 137, name: "Retail Apr-16", field: "acret_ap16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 138, name: "Retail Jul-16", field: "acret_jl16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 139, name: "Retail Oct-16", field: "acret_oc16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 140, name: "Retail Chg 1YE Oct-11", field: "acret_oc11.chg", compField:"acret_oc10", denomField:"acret_oc10", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 141, name: "Retail Chg 1YE Oct-14", field: "acret_oc14.chg", compField:"acret_oc13", denomField:"acret_oc13", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 142, name: "Retail Chg 1YE Jan-15", field: "acret_ja15.chg", compField:"acret_ja14", denomField:"acret_ja14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 143, name: "Retail Chg 1YE Apr-15", field: "acret_ap15.chg", compField:"acret_ap14", denomField:"acret_ap14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 144, name: "Retail Chg 1YE Jul-15", field: "acret_jl15.chg", compField:"acret_jl14", denomField:"acret_jl14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 145, name: "Retail Chg 1YE Oct-15", field: "acret_oc15.chg", compField:"acret_oc14", denomField:"acret_oc14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 146, name: "Retail Chg 1YE Jan-16", field: "acret_ja16.chg", compField:"acret_ja15", denomField:"acret_ja15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 147, name: "Retail Chg 1YE Apr-16", field: "acret_ap16.chg", compField:"acret_ap15", denomField:"acret_ap15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 148, name: "Retail Chg 1YE Jul-16", field: "acret_jl16.chg", compField:"acret_jl15", denomField:"acret_jl15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 149, name: "Retail Chg 1YE Oct-16", field: "acret_oc16.chg", compField:"acret_oc15", denomField:"acret_oc15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 150, name: "S/market Small Oct-10", field: "acsms_oc10", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 151, name: "S/market Small Oct-11", field: "acsms_oc11", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 152, name: "S/market Small Oct-13", field: "acsms_oc13", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 153, name: "S/market Small Jan-14", field: "acsms_ja14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 154, name: "S/market Small Apr-14", field: "acsms_ap14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 155, name: "S/market Small Jul-14", field: "acsms_jl14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 156, name: "S/market Small Oct-14", field: "acsms_oc14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 157, name: "S/market Small Jan-15", field: "acsms_ja15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 158, name: "S/market Small Apr-15", field: "acsms_ap15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 159, name: "S/market Small Jul-15", field: "acsms_jl15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 160, name: "S/market Small Oct-15", field: "acsms_oc15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 161, name: "S/market Small Jan-16", field: "acsms_ja16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 162, name: "S/market Small Apr-16", field: "acsms_ap16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 163, name: "S/market Small Jul-16", field: "acsms_jl16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 164, name: "S/market Small Oct-16", field: "acsms_oc16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 165, name: "S/market Small Chg 1YE Oct-11", field: "acsms_oc11.chg", compField:"acsms_oc10", denomField:"acsms_oc10", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 166, name: "S/market Small Chg 1YE Oct-14", field: "acsms_oc14.chg", compField:"acsms_oc13", denomField:"acsms_oc13", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 167, name: "S/market Small Chg 1YE Jan-15", field: "acsms_ja15.chg", compField:"acsms_ja14", denomField:"acsms_ja14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 168, name: "S/market Small Chg 1YE Apr-15", field: "acsms_ap15.chg", compField:"acsms_ap14", denomField:"acsms_ap14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 169, name: "S/market Small Chg 1YE Jul-15", field: "acsms_jl15.chg", compField:"acsms_jl14", denomField:"acsms_jl14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 170, name: "S/market Small Chg 1YE Oct-15", field: "acsms_oc15.chg", compField:"acsms_oc14", denomField:"acsms_oc14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 171, name: "S/market Small Chg 1YE Jan-16", field: "acsms_ja16.chg", compField:"acsms_ja15", denomField:"acsms_ja15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 172, name: "S/market Small Chg 1YE Apr-16", field: "acsms_ap16.chg", compField:"acsms_ap15", denomField:"acsms_ap15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 173, name: "S/market Small Chg 1YE Jul-16", field: "acsms_jl16.chg", compField:"acsms_jl15", denomField:"acsms_jl15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 174, name: "S/market Small Chg 1YE Oct-16", field: "acsms_oc16.chg", compField:"acsms_oc15", denomField:"acsms_oc15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 175, name: "S/market Medium Oct-10", field: "acsmm_oc10", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 176, name: "S/market Medium Oct-11", field: "acsmm_oc11", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 177, name: "S/market Medium Oct-13", field: "acsmm_oc13", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 178, name: "S/market Medium Jan-14", field: "acsmm_ja14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 179, name: "S/market Medium Apr-14", field: "acsmm_ap14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 180, name: "S/market Medium Jul-14", field: "acsmm_jl14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 181, name: "S/market Medium Oct-14", field: "acsmm_oc14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 182, name: "S/market Medium Jan-15", field: "acsmm_ja15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 183, name: "S/market Medium Apr-15", field: "acsmm_ap15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 184, name: "S/market Medium Jul-15", field: "acsmm_jl15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 185, name: "S/market Medium Oct-15", field: "acsmm_oc15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 186, name: "S/market Medium Jan-16", field: "acsmm_ja16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 187, name: "S/market Medium Apr-16", field: "acsmm_ap16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 188, name: "S/market Medium Jul-16", field: "acsmm_jl16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 189, name: "S/market Medium Oct-16", field: "acsmm_oc16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 190, name: "S/market Medium Chg 1YE Oct-11", field: "acsmm_oc11.chg", compField:"acsmm_oc10", denomField:"acsmm_oc10", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 191, name: "S/market Medium Chg 1YE Oct-14", field: "acsmm_oc14.chg", compField:"acsmm_oc13", denomField:"acsmm_oc13", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 192, name: "S/market Medium Chg 1YE Jan-15", field: "acsmm_ja15.chg", compField:"acsmm_ja14", denomField:"acsmm_ja14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 193, name: "S/market Medium Chg 1YE Apr-15", field: "acsmm_ap15.chg", compField:"acsmm_ap14", denomField:"acsmm_ap14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 194, name: "S/market Medium Chg 1YE Jul-15", field: "acsmm_jl15.chg", compField:"acsmm_jl14", denomField:"acsmm_jl14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 195, name: "S/market Medium Chg 1YE Oct-15", field: "acsmm_oc15.chg", compField:"acsmm_oc14", denomField:"acsmm_oc14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 196, name: "S/market Medium Chg 1YE Jan-16", field: "acsmm_ja16.chg", compField:"acsmm_ja15", denomField:"acsmm_ja15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 197, name: "S/market Medium Chg 1YE Apr-16", field: "acsmm_ap16.chg", compField:"acsmm_ap15", denomField:"acsmm_ap15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 198, name: "S/market Medium Chg 1YE Jul-16", field: "acsmm_jl16.chg", compField:"acsmm_jl15", denomField:"acsmm_jl15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 199, name: "S/market Medium Chg 1YE Oct-16", field: "acsmm_oc16.chg", compField:"acsmm_oc15", denomField:"acsmm_oc15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 200, name: "S/market Large Oct-10", field: "acsml_oc10", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 201, name: "S/market Large Oct-11", field: "acsml_oc11", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 202, name: "S/market Large Oct-13", field: "acsml_oc13", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 203, name: "S/market Large Jan-14", field: "acsml_ja14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 204, name: "S/market Large Apr-14", field: "acsml_ap14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 205, name: "S/market Large Jul-14", field: "acsml_jl14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 206, name: "S/market Large Oct-14", field: "acsml_oc14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 207, name: "S/market Large Jan-15", field: "acsml_ja15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 208, name: "S/market Large Apr-15", field: "acsml_ap15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 209, name: "S/market Large Jul-15", field: "acsml_jl15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 210, name: "S/market Large Oct-15", field: "acsml_oc15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 211, name: "S/market Large Jan-16", field: "acsml_ja16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 212, name: "S/market Large Apr-16", field: "acsml_ap16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 213, name: "S/market Large Jul-16", field: "acsml_jl16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 214, name: "S/market Large Oct-16", field: "acsml_oc16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 215, name: "S/market Large Chg 1YE Oct-11", field: "acsml_oc11.chg", compField:"acsml_oc10", denomField:"acsml_oc10", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 216, name: "S/market Large Chg 1YE Oct-14", field: "acsml_oc14.chg", compField:"acsml_oc13", denomField:"acsml_oc13", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 217, name: "S/market Large Chg 1YE Jan-15", field: "acsml_ja15.chg", compField:"acsml_ja14", denomField:"acsml_ja14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 218, name: "S/market Large Chg 1YE Apr-15", field: "acsml_ap15.chg", compField:"acsml_ap14", denomField:"acsml_ap14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 219, name: "S/market Large Chg 1YE Jul-15", field: "acsml_jl15.chg", compField:"acsml_jl14", denomField:"acsml_jl14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 220, name: "S/market Large Chg 1YE Oct-15", field: "acsml_oc15.chg", compField:"acsml_oc14", denomField:"acsml_oc14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 221, name: "S/market Large Chg 1YE Jan-16", field: "acsml_ja16.chg", compField:"acsml_ja15", denomField:"acsml_ja15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 222, name: "S/market Large Chg 1YE Apr-16", field: "acsml_ap16.chg", compField:"acsml_ap15", denomField:"acsml_ap15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 223, name: "S/market Large Chg 1YE Jul-16", field: "acsml_jl16.chg", compField:"acsml_jl15", denomField:"acsml_jl15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 224, name: "S/market Large Chg 1YE Oct-16", field: "acsml_oc16.chg", compField:"acsml_oc15", denomField:"acsml_oc15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 225, name: "S/market V.Large Oct-10", field: "acsmv_oc10", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 226, name: "S/market V.Large Oct-11", field: "acsmv_oc11", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 227, name: "S/market V.Large Oct-13", field: "acsmv_oc13", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 228, name: "S/market V.Large Jan-14", field: "acsmv_ja14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 229, name: "S/market V.Large Apr-14", field: "acsmv_ap14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 230, name: "S/market V.Large Jul-14", field: "acsmv_jl14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 231, name: "S/market V.Large Oct-14", field: "acsmv_oc14", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 232, name: "S/market V.Large Jan-15", field: "acsmv_ja15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 233, name: "S/market V.Large Apr-15", field: "acsmv_ap15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 234, name: "S/market V.Large Jul-15", field: "acsmv_jl15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 235, name: "S/market V.Large Oct-15", field: "acsmv_oc15", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 236, name: "S/market V.Large Jan-16", field: "acsmv_ja16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 237, name: "S/market V.Large Apr-16", field: "acsmv_ap16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 238, name: "S/market V.Large Jul-16", field: "acsmv_jl16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 239, name: "S/market V.Large Oct-16", field: "acsmv_oc16", display: "min", decimals: 0, legendStops: legendStops, legendOptions: [], additionalMetrics: [] },
    { id: 240, name: "S/market V.Large Chg 1YE Oct-11", field: "acsmv_oc11.chg", compField:"acsmv_oc10", denomField:"acsmv_oc10", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 241, name: "S/market V.Large Chg 1YE Oct-14", field: "acsmv_oc14.chg", compField:"acsmv_oc13", denomField:"acsmv_oc13", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 242, name: "S/market V.Large Chg 1YE Jan-15", field: "acsmv_ja15.chg", compField:"acsmv_ja14", denomField:"acsmv_ja14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 243, name: "S/market V.Large Chg 1YE Apr-15", field: "acsmv_ap15.chg", compField:"acsmv_ap14", denomField:"acsmv_ap14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 244, name: "S/market V.Large Chg 1YE Jul-15", field: "acsmv_jl15.chg", compField:"acsmv_jl14", denomField:"acsmv_jl14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 245, name: "S/market V.Large Chg 1YE Oct-15", field: "acsmv_oc15.chg", compField:"acsmv_oc14", denomField:"acsmv_oc14", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 246, name: "S/market V.Large Chg 1YE Jan-16", field: "acsmv_ja16.chg", compField:"acsmv_ja15", denomField:"acsmv_ja15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 247, name: "S/market V.Large Chg 1YE Apr-16", field: "acsmv_ap16.chg", compField:"acsmv_ap15", denomField:"acsmv_ap15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 248, name: "S/market V.Large Chg 1YE Jul-16", field: "acsmv_jl16.chg", compField:"acsmv_jl15", denomField:"acsmv_jl15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
    { id: 249, name: "S/market V.Large Chg 1YE Oct-16", field: "acsmv_oc16.chg", compField:"acsmv_oc15", denomField:"acsmv_oc15", display: "Percentage", decimals: 0, legendStops: legendStopsChg, legendOptions: [] },
  ];
}

export const WMACCESSIBILITY = {
  title: "West Midlands Bus-Based Accessibility",
  description:
    "Accessibility estimates to selected POIs based on bus timetables and the ITN road network at OA level 2010-2016.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/west-midlands-accessibility-and-travel-passes",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + getCols() + " FROM oa11_wm",
  buildingSql: "SELECT " + getCols() + " FROM oa11_wm_buildings",
  detailUrbanSql: "SELECT " + getCols() + " FROM oa11_wm_detail_urban",
  urbanSql: "SELECT " + getCols() + " FROM oa11_wm_urban",
  defaultLayers: "1111000",
  extent: 5,
  popup: false,
  idField: "oa11_cd",
  geogNameField: "la17_nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la17_cd",
  hoverFieldMetricMode: "MetricOnly",
  metrics: getMetrics()
};

function getCols() {
  return (
    colsPrefix +
    getMetrics()
      .filter(function(a) {
        if (a.field.substring(a.field.length - 4) == ".chg") {
          return false;
        }
        return true;
      })
      .map(function(b) {
        return b.field;
      })
      .join(", ")
  );
}
