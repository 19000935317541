import colorbrewer from "colorbrewer";

const cols =
  "the_geom_webmercator, oa21cd, la23cd, la23nm, " +
  "case when slprop < 0.1 then -1 else slcat::INTEGER end as slcat, " +
  "case when slprop < 0.1 then 0 else slprop end as slprop, " +
  "case when tiprop < 0.2 then -1 else ticat end as ticat ";

const legendStops = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#807870", name: "No data/low result" },
      { value: 1, colour: "#00ff00", name: "English (+Welsh in Wales)" },
      { value: 2, colour: "#008800", name: "Other UK (e.g. Gaelic)" },
      { value: 3, colour: "#eeeeff", name: "French" },
      { value: 4, colour: "#ccccff", name: "Portuguese" },
      { value: 5, colour: "#aaaaff", name: "Spanish" },
      { value: 6, colour: "#8888ff", name: "Polish" },
      { value: 7, colour: "#6666aa", name: "Other EU (e.g. Romanian)" },
      { value: 8, colour: "#0000ff", name: "Other Euro ex-EU (e.g. Yiddish)" },
      { value: 9, colour: "#ffffbb", name: "Russian" },
      { value: 10, colour: "#ffff66", name: "Turkish" },
      { value: 11, colour: "#cccc00", name: "Arabic" },
      { value: 12, colour: "#ff6600", name: "W./C. Asian (e.g. Farsi)" },
      { value: 13, colour: "#ffcccc", name: "Panjabi" },
      { value: 14, colour: "#ff8888", name: "Urdu" },
      { value: 15, colour: "#ff4444", name: "Bengali, Sylheti, Chatgaya" },
      { value: 16, colour: "#ff0000", name: "Gujarati" },
      { value: 17, colour: "#bb0000", name: "Tamil" },
      { value: 18, colour: "#770000", name: "Other S. Asian (e.g. Nepalese)" },
      { value: 19, colour: "#ff66ff", name: "All Chinese languages" },
      { value: 20, colour: "#880088", name: "Other E. Asian (e.g. Japanese)" },
      { value: 21, colour: "#00ffff", name: "All African languages" },
      { value: 22, colour: "#000000", name: "Other" }
    ]
  }
];

const ramp2 = colorbrewer.Purples[6];

const legendPerc = [
  {
    id: 0,
    stops: [
      { value: 0, colour: "#807870", name: "No data/low result" },
      { value: 0.1, colour: ramp2[0], name: "10-12%" },
      { value: 0.12, colour: ramp2[1], name: "12-15%" },
      { value: 0.15, colour: ramp2[2], name: "15-20%" },
      { value: 0.2, colour: ramp2[3], name: "20-25%" },
      { value: 0.25, colour: ramp2[4], name: "25-30%" },
      { value: 0.3, colour: ramp2[5], name: "30%+" }
    ]
  }
];

const legendIndustry = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#908880", name: "No data/low result" },
      {
        value: 1,
        colour: "#006600",
        name: "Agriculture, energy, water (ABDE)"
      },
      { value: 2, colour: "#0088ff", name: "Manufacturing (C)" },
      { value: 3, colour: "#995500", name: "Construction (F)" },
      { value: 4, colour: "#ddddcc", name: "Retail, wholesale, repair (G)" },
      { value: 5, colour: "#00ffff", name: "Transport, storage (H)" },
      { value: 6, colour: "#00ff00", name: "Accommodation, food service (I)" },
      { value: 7, colour: "#8800ff", name: "Information, communication (J)" },
      { value: 8, colour: "#ff8800", name: "Financial, insurance (K)" },
      { value: 9, colour: "#888888", name: "Real estate (L)" },
      { value: 10, colour: "#ffff00", name: "Professional, scientific (M)" },
      { value: 11, colour: "#0022aa", name: "Administrative, support (N)" },
      { value: 12, colour: "#0000ff", name: "Public admin, defence (O)" },
      { value: 13, colour: "#ff0000", name: "Education (P)" },
      { value: 14, colour: "#ffddbb", name: "Health, social work (Q)" },
      { value: 15, colour: "#ff88ff", name: "Arts, Ents, Rec, Other (RSTU)" }
    ]
  }
];

/*
const legendIndustry = [
    {
      id: 0,
      stops: [
        { value: -999999, colour: "#807870", name: "No data/low result" },
        { value: 1, colour: "#991155", name: "Corp managers/directors (11)" },
        { value: 2, colour: "#995511", name: "Oth managers/directors (12)" },
        { value: 3, colour: "#eeeeff", name: "Science, research, tech prof (21)" },
        { value: 4, colour: "#ccccff", name: "Health professionals (22)" },
        { value: 5, colour: "#aaaaff", name: "Education professionals (23)" },
        { value: 6, colour: "#8888ff", name: "Business, media, public prof (24)" },
        { value: 7, colour: "#6666aa", name: "Science, eng, tech as.prof (31)" },
        { value: 8, colour: "#0000ff", name: "Health & social care as.prof (32)" },
        { value: 9, colour: "#ffffbb", name: "Protective service (33)" },
        { value: 10, colour: "#ffff00", name: "Culture, media, sports (34)" },
        { value: 11, colour: "#bbbb00", name: "Business, public as.prof (35)" },
        { value: 12, colour: "#ff6600", name: "Administrative (41)" },
        { value: 13, colour: "#ffcccc", name: "Secretarial (42)" },
        { value: 14, colour: "#ff8888", name: "Agricultural trades (51)" },
        { value: 15, colour: "#ff4444", name: "Metal, electrical trades (52)" },
        { value: 16, colour: "#ff0000", name: "Construction trades (53)" },
        { value: 17, colour: "#bb0000", name: "Textiles, printing trades (54)" },
        { value: 18, colour: "#770000", name: "Caring personal service (61)" },
        { value: 19, colour: "#ff66ff", name: "Leisure, travel personal sv (62)" },
        { value: 20, colour: "#880088", name: "Community, civil enforcement (63)" },
        { value: 21, colour: "#00ffff", name: "Sales (71)" },
        { value: 22, colour: "#000000", name: "Customer service (72)" },
        { value: 23, colour: "#115599", name: "Process, plant, machine oper (81)" },
        { value: 24, colour: "#551199", name: "Transport, machine driver op (82)" },
        { value: 25, colour: "#00ff00", name: "Elementary trades (91)" },
        { value: 26, colour: "#008800", name: "Elementary administration/sv (92)" }
       ]
    }
  ]; */

export const SECONDLANGUAGES = {
  title: "Second Languages",
  description:
    "A map of the second most common main spoken language (or language grouping) by small area (Census 2021) where this exceeds 10% of the population.",
  downloadLink: "",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM oa21_ew",
  buildingSql: "SELECT " + cols + " FROM oa21_ew_vmd23",
  detailUrbanSql: "SELECT " + cols + " FROM oa21_ew_bua22",
  urbanSql: "SELECT " + cols + " FROM oa21_ew_bua22",
  defaultLayers: "1111000",
  extent: 7,
  popup: false,
  idField: "oa21cd",
  geogNameField: "la23nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la23cd",
  metrics: [
    {
      id: 0,
      name: "Second Most Common Main Language (>10%)",
      field: "slcat",
      display: "Stop Name",
      decimals: 0,
      extent: 7,
      legendOptions: [],
      legendStops: legendStops,
      additionalMetrics: [0, 1]
    },
    {
      id: 1,
      name: "Percentage using as Main Language",
      field: "slprop",
      display: "Percentage",
      decimals: 0,
      extent: 7,
      legendOptions: [],
      legendStops: legendPerc,
      additionalMetrics: [0, 1]
    },
    {
      id: 2,
      name: "Top Industry (by Home Location) (>20%)",
      field: "ticat",
      display: "Stop Name",
      decimals: 0,
      extent: 7,
      legendOptions: [],
      legendStops: legendIndustry
    }
  ]
};
