import colorbrewer from "colorbrewer";

const cols =
  "the_geom_webmercator, lsoa11_cd, lsoa11_nm, lad_code, efd_ew_dec, " +
  "efd_sc_dec, efd_ew_scr, efd_ew_rnk, efd_sc_scr, efd_sc_rnk";

const ramp = colorbrewer.RdYlBu[10];

const legendStops = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 1, colour: ramp[0], name: "Highest scoring decile" },
      { value: 2, colour: ramp[1], name: "2nd" },
      { value: 3, colour: ramp[2], name: "3rd" },
      { value: 4, colour: ramp[3], name: "4th" },
      { value: 5, colour: ramp[4], name: "5th" },
      { value: 6, colour: ramp[5], name: "6th" },
      { value: 7, colour: ramp[6], name: "7th" },
      { value: 8, colour: ramp[7], name: "8th" },
      { value: 9, colour: ramp[8], name: "9th" },
      { value: 10, colour: ramp[9], name: "Lowest scoring decile" }
    ]
  }
];

export const EFDI = {
  title: "E-Food Desert Index",
  description:
    "Measures the extent to which neighbourhoods exhibit characterstics associated with food deserts across four key drivers of groceries accessibility",
  downloadLink: "https://data.cdrc.ac.uk/dataset/e-food-desert-index",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM lsoa11_uk",
  buildingSql: "SELECT " + cols + " FROM lsoa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM lsoa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM lsoa11_uk_urban",
  defaultLayers: "1111000",
  extent: 0,
  popup: false,
  idField: "lsoa11_cd",
  geogNameField: "lsoa11_nm",
  ladCodeField: "lad_code",
  hoverFieldMetricMode: "MetricOnly",
  metrics: [
    {
      id: 0,
      name: "Decile (England & Wales)",
      field: "efd_ew_dec",
      decimals: 0,
      extent: 3,
      legendOptions: [{ id: 0, name: "Decile" }],
      legendStops: legendStops,
      additionalMetrics: [2, 3]
    },
    {
      id: 1,
      name: "Decile (Scotland)",
      field: "efd_sc_dec",
      decimals: 0,
      extent: 4,
      legendOptions: [{ id: 0, name: "Decile" }],
      legendStops: legendStops,
      additionalMetrics: [4, 5]
    },
    {
      id: 2,
      name: "Score (England & Wales)",
      field: "efd_ew_scr",
      decimals: 4,
      visible: false
    },
    {
      id: 3,
      name: "Rank (England & Wales)",
      field: "efd_ew_rnk",
      decimals: 0,
      visible: false
    },
    {
      id: 4,
      name: "Score (Scotland)",
      field: "efd_sc_scr",
      decimals: 4,
      visible: false
    },
    {
      id: 5,
      name: "Rank (Scotland)",
      field: "efd_sc_rnk",
      decimals: 0,
      visible: false
    }
  ]
};
