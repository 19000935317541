var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[(_vm.data.id)?_c('b-tab',{attrs:{"title":_vm.title,"active":""}},[_c('h5',{staticClass:"mb-1 text-center"},[_c('strong',[_vm._v(_vm._s(_vm.data.name))])]),_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.data.id))]),_vm._l((_vm.data.hierarchy),function(group){return _c('table',{key:group.id,staticClass:"table mt-3"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"width":"7rem"},attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(group.name)+" ")]),_c('th',{staticClass:"text-center",style:({
                backgroundColor: group.groupColour,
                color: group.lightText ? 'white' : 'black'
              }),attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(group.groupName)+" ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_c('h3',[_vm._v(_vm._s(group.groupID))])]),_c('td',[_vm._v(_vm._s(group.groupDescription))])]),_c('tr')])])})],2):_vm._e(),(_vm.data.region.id)?_c('b-tab',{attrs:{"title":_vm.type == 'London'
          ? 'Greater London'
          : _vm.type == 'USA'
          ? 'State'
          : 'Region'}},[_c('h5',{staticClass:"mb-1 text-center"},[_c('strong',[_vm._v(_vm._s(_vm.data.region.name))])]),_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.data.region.id))]),(!_vm.data.region.data.count_total)?_c('div',[_c('p',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"secondary","label":"Spinning"}})],1),_c('p',{staticClass:"text-center text-secondary"},[_vm._v("Getting Data")])]):_vm._e(),(_vm.data.region.data.count_total)?_c('div',_vm._l((_vm.hierarchy),function(group){return _c('div',{key:group.id},[_c('table',{staticClass:"table mt-3 table-sm"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(group.name)+" ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"50%"},attrs:{"scope":"col"}},[_vm._v(" Name ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" Percentage by Count ")])])]),_c('tbody',[_vm._l((group.groups),function(g){return _c('tr',{key:g.id},[_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(g.id))])]),_c('td',{style:({
                    backgroundColor: g.colour,
                    color: g.lightText ? 'white' : 'black'
                  })},[_c('strong',[_vm._v(_vm._s(g.name))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("toPercentage")((_vm.data.region.data[ "count_" + g.id .toString() .toLowerCase() .replace(".", "_") ] / _vm.data.region.data["count_total"])))+" ")])])}),_c('tr')],2)])])}),0):_vm._e()]):_vm._e(),(_vm.data.combined.id)?_c('b-tab',{attrs:{"title":"Combined Authority"}},[_c('h5',{staticClass:"mb-1 text-center"},[_c('strong',[_vm._v(_vm._s(_vm.data.combined.name))])]),_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.data.combined.id))]),(!_vm.data.combined.data.count_total)?_c('div',[_c('p',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"secondary","label":"Spinning"}})],1),_c('p',{staticClass:"text-center text-secondary"},[_vm._v("Getting Data")])]):_vm._e(),(_vm.data.combined.data.count_total)?_c('div',_vm._l((_vm.hierarchy),function(group){return _c('div',{key:group.id},[_c('table',{staticClass:"table mt-3 table-sm"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(group.name)+" ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"50%"},attrs:{"scope":"col"}},[_vm._v(" Name ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" Percentage by Count ")])])]),_c('tbody',[_vm._l((group.groups),function(g){return _c('tr',{key:g.id},[_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(g.id))])]),_c('td',{style:({
                    backgroundColor: g.colour,
                    color: g.lightText ? 'white' : 'black'
                  })},[_c('strong',[_vm._v(_vm._s(g.name))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("toPercentage")((_vm.data.combined.data[ "count_" + g.id .toString() .toLowerCase() .replace(".", "_") ] / _vm.data.combined.data["count_total"])))+" ")])])}),_c('tr')],2)])])}),0):_vm._e()]):_vm._e(),(_vm.data.pcon.id)?_c('b-tab',{attrs:{"title":"Constituency (2024)"}},[_c('h5',{staticClass:"mb-1 text-center"},[_c('strong',[_vm._v(_vm._s(_vm.data.pcon.name))])]),_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.data.pcon.id))]),(!_vm.data.pcon.data.count_total)?_c('div',[_c('p',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"secondary","label":"Spinning"}})],1),_c('p',{staticClass:"text-center text-secondary"},[_vm._v("Getting Data")])]):_vm._e(),(_vm.data.pcon.data.count_total)?_c('div',_vm._l((_vm.hierarchy),function(group){return _c('div',{key:group.id},[_c('table',{staticClass:"table mt-3 table-sm"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(group.name)+" ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"50%"},attrs:{"scope":"col"}},[_vm._v(" Name ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" Percentage by Count ")])])]),_c('tbody',[_vm._l((group.groups),function(g){return _c('tr',{key:g.id},[_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(g.id))])]),_c('td',{style:({
                    backgroundColor: g.colour,
                    color: g.lightText ? 'white' : 'black'
                  })},[_c('strong',[_vm._v(_vm._s(g.name))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("toPercentage")((_vm.data.pcon.data[ "count_" + g.id .toString() .toLowerCase() .replace(".", "_") ] / _vm.data.pcon.data["count_total"])))+" ")])])}),_c('tr')],2)])])}),0):_vm._e()]):_vm._e(),(_vm.data.lad.id)?_c('b-tab',{attrs:{"title":_vm.type == 'London'
          ? 'London Borough'
          : _vm.type == 'USA'
          ? 'County'
          : 'Local Authority (2023)'}},[_c('h5',{staticClass:"mb-1 text-center"},[_c('strong',[_vm._v(_vm._s(_vm.data.lad.name))])]),_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.data.lad.id))]),(!_vm.data.lad.data.count_total)?_c('div',[_c('p',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"secondary","label":"Spinning"}})],1),_c('p',{staticClass:"text-center text-secondary"},[_vm._v("Getting Data")])]):_vm._e(),(_vm.data.lad.data.count_total)?_c('div',_vm._l((_vm.hierarchy),function(group){return _c('div',{key:group.id},[_c('table',{staticClass:"table mt-3 table-sm"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(group.name)+" ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"50%"},attrs:{"scope":"col"}},[_vm._v(" Name ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" Percentage by Count ")])])]),_c('tbody',[_vm._l((group.groups),function(g){return _c('tr',{key:g.id},[_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(g.id))])]),_c('td',{style:({
                    backgroundColor: g.colour,
                    color: g.lightText ? 'white' : 'black'
                  })},[_c('strong',[_vm._v(_vm._s(g.name))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("toPercentage")((_vm.data.lad.data[ "count_" + g.id .toString() .toLowerCase() .replace(".", "_") ] / _vm.data.lad.data["count_total"])))+" ")])])}),_c('tr')],2)])])}),0):_vm._e()]):_vm._e(),(_vm.data.retail.id)?_c('b-tab',{attrs:{"title":"Retail Centre"}},[_c('h5',{staticClass:"mb-1 text-center"},[_c('strong',[_vm._v(_vm._s(_vm.data.retail.name))])]),_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.data.retail.id))]),(!_vm.data.retail.data.count_total)?_c('div',[_c('p',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"secondary","label":"Spinning"}})],1),_c('p',{staticClass:"text-center text-secondary"},[_vm._v("Getting Data")])]):_vm._e(),(_vm.data.retail.data.count_total)?_c('div',_vm._l((_vm.hierarchy),function(group){return _c('div',{key:group.id},[_c('table',{staticClass:"table mt-3 table-sm"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(group.name)+" ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"50%"},attrs:{"scope":"col"}},[_vm._v(" Name ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" Percentage by Count ")])])]),_c('tbody',[_vm._l((group.groups),function(g){return _c('tr',{key:g.id},[_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(g.id))])]),_c('td',{style:({
                    backgroundColor: g.colour,
                    color: g.lightText ? 'white' : 'black'
                  })},[_c('strong',[_vm._v(_vm._s(g.name))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("toPercentage")((_vm.data.retail.data[ "count_" + g.id .toString() .toLowerCase() .replace(".", "_") ] / _vm.data.retail.data["count_total"])))+" ")])])}),_c('tr')],2)])])}),0):_vm._e()]):_vm._e(),(_vm.data.adhoc.id)?_c('b-tab',{attrs:{"title":"Polygon"}},[_c('h5',{staticClass:"mb-1 text-center"},[_c('strong',[_vm._v("Drawn Polygon")])]),(!_vm.data.adhoc.data.count_total)?_c('div',[_c('p',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"secondary","label":"Spinning"}})],1),_c('p',{staticClass:"text-center text-secondary"},[_vm._v("Getting Data")])]):_vm._e(),(_vm.data.adhoc.data.count_total)?_c('div',_vm._l((_vm.hierarchy),function(group){return _c('div',{key:group.id},[_c('table',{staticClass:"table mt-3 table-sm"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(group.name)+" ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"50%"},attrs:{"scope":"col"}},[_vm._v(" Name ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"25%"},attrs:{"scope":"col"}},[_vm._v(" Percentage by Count ")])])]),_c('tbody',[_vm._l((group.groups),function(g){return _c('tr',{key:g.id},[_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(g.id))])]),_c('td',{style:({
                    backgroundColor: g.colour,
                    color: g.lightText ? 'white' : 'black'
                  })},[_c('strong',[_vm._v(_vm._s(g.name))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("toPercentage")((_vm.data.adhoc.data[ "count_" + g.id .toString() .toLowerCase() .replace(".", "_") ] / _vm.data.adhoc.data["count_total"])))+" ")])])}),_c('tr')],2)])])}),0):_vm._e()]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }