const cols =
  "the_geom_webmercator, wz11cd, lad11cd, lad11nm, group_code, subgroup_code";

export const LONDONWZC = {
  title: "London WZC",
  description:
    "Segmenting London’s workplace zones into categories based on workers’ and businesses’ characteristics.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/london-workplace-zone-classification",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM londonwzc2017",
  buildingSql: "SELECT " + cols + " FROM londonwzc2017_building",
  detailUrbanSql: "SELECT " + cols + " FROM londonwzc2017_detail_urban",
  urbanSql: "SELECT " + cols + " FROM londonwzc2017_urban",
  defaultLayers: "1111000",
  extent: 1,
  popup: true,
  popupType: "classification",
  idField: "wz11cd",
  geogNameField: "lad11nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "lad11cd",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.group_code = 'A' then 1 else 0 end) as count_A,
      sum(case when d.group_code = 'B' then 1 else 0 end) as count_B,
      sum(case when d.group_code = 'C' then 1 else 0 end) as count_C,
      sum(case when d.group_code = 'D' then 1 else 0 end) as count_D,
      sum(case when d.group_code = 'E' then 1 else 0 end) as count_E,
      sum(case when d.subgroup_code = 'A1' then 1 else 0 end) as count_A1,
      sum(case when d.subgroup_code = 'A2' then 1 else 0 end) as count_A2,
      sum(case when d.subgroup_code = 'B1' then 1 else 0 end) as count_B1,
      sum(case when d.subgroup_code = 'B2' then 1 else 0 end) as count_B2,
      sum(case when d.subgroup_code = 'C1' then 1 else 0 end) as count_C1,
      sum(case when d.subgroup_code = 'C2' then 1 else 0 end) as count_C2,
      sum(case when d.subgroup_code = 'D1' then 1 else 0 end) as count_D1,
      sum(case when d.subgroup_code = 'D2' then 1 else 0 end) as count_D2,
      sum(case when d.subgroup_code = 'D3' then 1 else 0 end) as count_D3,
      sum(case when d.subgroup_code = 'E1' then 1 else 0 end) as count_E1,
      sum(case when d.subgroup_code = 'E2' then 1 else 0 end) as count_E2
  FROM oliverobrien.londonwzc2017 d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Group",
      field: "group_code",
      lookup: "id",
      groups: [
        {
          id: "A",
          name: "Residential Services",
          colour: "#33a02c",
          visible: true,
          description:
            "These workplace zones are characterized by services offered to local communities by local community members. Occupations include classroom assistants, domestic assistants and self employed cleaners. Workers, particularly women are typically older than average and some above normal retirement age."
        },
        {
          id: "B",
          name: "City Focus",
          colour: "#ffff00",
          visible: true,
          description:
            "These areas bring focus to a range of specialised professional activities. They also host more general support services and retail activities. The portfolio of over‐all activities may also be catalytic to the vitality of nighttime economies. Workers in all of these activities are predominantly drawn from a core (age 25‐39) labour force."
        },
        {
          id: "C",
          name: "Infrastructure Support",
          colour: "#1f78b4",
          visible: true,
          description:
            "Workers in these areas provide direct or indirect support for the physical infrastructure of the economy – in transport, utilities and the retail trade. Workers are drawn from the traditional workforce and there is strong labour force participation from Asian ethnic minorities."
        },
        {
          id: "D",
          name: "Integrating and Independent Service Providers",
          colour: "#6a51a3",
          visible: true,
          description:
            "These areas are characterised by high levels of self employment, and significant numbers work part‐ time. Workers may be based at home, or travel to deliver services to local communities. The areas attest to the dynamism of London’s economy in recent years, providing employment for recent migrants and longer settled members of ethnic minorities. These zones predominantly make up an annular tract of land surrounding the inner core of London."
        },
        {
          id: "E",
          name: "Metropolitan Destinations",
          colour: "#ff7f00",
          visible: true,
          description:
            "These areas are overwhelmingly located in Inner London, especially its West End, and many serve as retail destinations. A very international range of workers provide a wide range of high value services as well as retailing. Many of these workers also reside in Central London."
        }
      ]
    },
    {
      id: 1,
      name: "Sub group",
      field: "subgroup_code",
      lookup: "id",
      groups: [
        {
          id: "A1",
          parent: "A",
          name: "Predominantly older, local education and health workers",
          colour: "#b2df8a",
          visible: true,
          description:
            "These workers are frequently sourced locally, and predominantly work in the health and education sectors. Caring and leisure services are well represented, and some individuals work in professional occupations."
        },
        {
          id: "A2",
          parent: "A",
          name:
            "Lowly qualified workers in construction and allied local trades",
          colour: "#33a02c",
          visible: true,
          description:
            "Work in construction and related skilled and unskilled trades predominates, although there is also some representation of health, social work and education‐related activities. Many workers are self employed or work for small companies. Workers tend to be drawn from the older age cohorts, although some are employed as apprentices."
        },
        {
          id: "B1",
          parent: "B",
          name: "Dynamic financial centres with extended operating hours",
          colour: "#ffff00",
          visible: true,
          description:
            "These areas form the close knit financial heart of the City. Much of the younger, and predominantly full time workforce commute by rail over significant distances. Many workers fulfil managerial roles within their organisations. The areas also host significant retail and leisure functions that contribute to a vibrant night time economy."
        },
        {
          id: "B2",
          parent: "B",
          name:
            "Professional, retail and leisure Services in dynamic central locations",
          colour: "#ffffcc",
          visible: true,
          description:
            "This predominantly full time, well qualified labour force often commutes long distances to work in Central and West London locations. These tight knit employment zones host a range of professional and scientific and technical activities. There is also strong representation of supporting retail and leisure services, and a night time economy."
        },
        {
          id: "C1",
          parent: "C",
          name:
            "Younger customer service workers in wholesale or retail occupations",
          colour: "#a6cee3",
          visible: true,
          description:
            "This younger, locally based labour force are employed at locations scattered widely across Outer London. Commuting is typically by car or bus. Employment includes retail and customer service with workers drawn disproportionately from Asian backgrounds.  Workers have relatively low level qualifications and part‐time working is common."
        },
        {
          id: "C2",
          parent: "C",
          name: "Blue collar, manufacturing and transport services",
          colour: "#1f78b4",
          visible: true,
          description:
            "These workers find employment at locations scattered throughout London, with some concentration on the Capital’s outermost fringes. Employment is found in a wide range of occupations and workers tend to have low or intermediate level educational qualifications. Travel to work is often by car."
        },
        {
          id: "D1",
          parent: "D",
          name: "Health care support staff and routine service occupations",
          colour: "#bcbddc",
          visible: true,
          description:
            "This heavily multicultural workforce is very locally based and employed in a wide range of occupations. Although some workers are skilled, many have low levels of educational qualifications and work in unskilled or semi‐routine occupations. Levels of self‐employment are high and residential context is characterised by higher than average unemployment."
        },
        {
          id: "D2",
          parent: "D",
          name: "Locally sourced, home helps and domestic or manual workers",
          colour: "#807dba",
          visible: true,
          description:
            "Domestic employers requiring caring, recreational and other services are an employment mainstay of these areas. Other trades and activities are present. Levels of self‐employment and work for small employers is higher than average."
        },
        {
          id: "D3",
          parent: "D",
          name: "Travelling or home-based general service providers",
          colour: "#6a51a3",
          visible: true,
          description:
            "This generally low‐skilled labour force has changed in markedly in recent years, in significant part as the result of immigration. Employment in low‐skilled manual and administrative occupations predominates."
        },
        {
          id: "E1",
          parent: "E",
          name: "High street destinations and domestic employers",
          colour: "#ff7f00",
          visible: true,
          description:
            "Employment in these areas has a strong international service orientation, although households also provide an important source of employment. Real estate and entertainment activities are in evidence, and various forms of retailing also underpin local economies. Journeys to work are typically short distances, mainly by public transport."
        },
        {
          id: "E2",
          parent: "E",
          name: "Accessible retail, leisure and tourist services",
          colour: "#fdbf6f",
          visible: true,
          description:
            "These densely occupied destinations offer services in retailing, leisure and accommodation. They have important night time economies. Public transport predominates in the journey to work over short to medium length commutes. There is high turnover in the workforce and routine occupations are relatively common."
        }
      ]
    }
  ]
};
