const cols =
  "the_geom_webmercator, oa_code, lad_code, lad_name, supgrp_cd, grp_cd";

export const LOAC = {
  title: "London OAC 2011",
  description: "Geodemographics for London based on the 2011 UK Census.",
  downloadLink: "https://data.cdrc.ac.uk/dataset/london-oac-2011",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM londonoac2011",
  buildingSql: "SELECT " + cols + " FROM londonoac2011_building",
  detailUrbanSql: "SELECT " + cols + " FROM londonoac2011_detail_urban",
  urbanSql: "SELECT " + cols + " FROM londonoac2011_urban",
  defaultLayers: "1111000",
  extent: 1,
  popup: true,
  popupType: "classification",
  idField: "oa_code",
  geogNameField: "lad_name",
  geogNamePrefix: "Part of ",
  ladCodeField: "lad_code",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.supgrp_cd = 'A' then 1 else 0 end) as count_A,
      sum(case when d.supgrp_cd = 'B' then 1 else 0 end) as count_B,
      sum(case when d.supgrp_cd = 'C' then 1 else 0 end) as count_C,
      sum(case when d.supgrp_cd = 'D' then 1 else 0 end) as count_D,
      sum(case when d.supgrp_cd = 'E' then 1 else 0 end) as count_E,
      sum(case when d.supgrp_cd = 'F' then 1 else 0 end) as count_F,
      sum(case when d.supgrp_cd = 'G' then 1 else 0 end) as count_G,
      sum(case when d.supgrp_cd = 'H' then 1 else 0 end) as count_H,
      sum(case when d.grp_cd = 'A1' then 1 else 0 end) as count_A1,
      sum(case when d.grp_cd = 'A2' then 1 else 0 end) as count_A2,
      sum(case when d.grp_cd = 'B1' then 1 else 0 end) as count_B1,
      sum(case when d.grp_cd = 'B2' then 1 else 0 end) as count_B2,
      sum(case when d.grp_cd = 'B3' then 1 else 0 end) as count_B3,
      sum(case when d.grp_cd = 'C1' then 1 else 0 end) as count_C1,
      sum(case when d.grp_cd = 'C2' then 1 else 0 end) as count_C2,
      sum(case when d.grp_cd = 'C3' then 1 else 0 end) as count_C3,
      sum(case when d.grp_cd = 'C4' then 1 else 0 end) as count_C4,
      sum(case when d.grp_cd = 'D1' then 1 else 0 end) as count_D1,
      sum(case when d.grp_cd = 'D2' then 1 else 0 end) as count_D2,  
      sum(case when d.grp_cd = 'E1' then 1 else 0 end) as count_E1,
      sum(case when d.grp_cd = 'E2' then 1 else 0 end) as count_E2,
      sum(case when d.grp_cd = 'F1' then 1 else 0 end) as count_F1,
      sum(case when d.grp_cd = 'F2' then 1 else 0 end) as count_F2,
      sum(case when d.grp_cd = 'G1' then 1 else 0 end) as count_G1,
      sum(case when d.grp_cd = 'G2' then 1 else 0 end) as count_G2,
      sum(case when d.grp_cd = 'H1' then 1 else 0 end) as count_H1,
      sum(case when d.grp_cd = 'H2' then 1 else 0 end) as count_H2
  FROM oliverobrien.londonoac2011 d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Supergroup",
      field: "supgrp_cd",
      lookup: "id",
      groups: [
        {
          id: "A",
          name: "Intermediate Lifestyles",
          colour: "#66C2A5",
          visible: true,
          description:
            "Although exhibiting no over-arching discriminating characteristics, households in this Super-Group are predominantly in later stages in life-cycle, and are predominantly White and born in the UK. Rather few households have dependent children and most live in single family terraced or semi detached properties. Households that rent their properties do so in the social rented sector. Employment levels are average for London, and are split between full and part time working in a range of intermediate occupations. Levels of highest qualifications are below the London average."
        },
        {
          id: "B",
          name: "High Density & High Rise Flats",
          colour: "#FC8D62",
          visible: true,
          description:
            "Concentrations of this supergroup are found in densely populated areas of flats. Many families have children of school age and many residents are of Bangladeshi origins, along with high numbers of households describing themselves as Black or as coming from Mixed or Other ethnic groups. There is a high incidence of households in which the main spoken language is not English. Levels of highest qualifications are below the London average, although some residents are full-time students living in shared accommodation. Levels of unemployment and part-time working are above the London average, while many of those in employment work in administration, or in accommodation and food services industries."
        },
        {
          id: "C",
          name: "Settled Asians",
          colour: "#8DA0CB",
          visible: true,
          description:
            "This supergroup lives in traditional single-family houses, above average numbers of which are owner-occupied. Although drawn from the full age range of London residents, the main language spoken in many households is not English. Those in employment have occupations drawn from a wide range of non-professional sectors. Members of this supergroup identify themselves with their Asian origins, although many are second or subsequent generation British residents."
        },
        {
          id: "D",
          name: "Urban Elites",
          colour: "#E78AC3",
          visible: true,
          description:
            "This supergroup comprises young professionals working in the science, technology, finance and insurance sectors. Additionally, large numbers of students rent rooms in centrally located communal establishments. Most others rent privately owned flats, large numbers of which are found in central locations. Residents are disproportionately drawn from pre 2001 EU countries, and there is also high representation of households from Chinese, Arab and other minority backgrounds."
        },
        {
          id: "E",
          name: "City Vibe",
          colour: "#A6D854",
          visible: true,
          description:
            "Many members of this group live in communal establishments, located in some of London's less fashionable central locations. Employment is typically in accommodation and food services. Black British residents are much in evidence, and citizens of post 2001 EU countries are also well represented relative to the London average."
        },
        {
          id: "F",
          name: "London Life-Cycle",
          colour: "#FFD92F",
          visible: true,
          description:
            "Predominantly White in ethnic composition (including individuals from other pre 2001 EU countries), these households cover the full family life-cycle age spectrum _ meaning that, over-all, fewer households than the London average include dependent children or students. Residents are highly qualified, employment rates are high and employment is concentrated in the technical, scientific, finance, insurance and real estate industries."
        },
        {
          id: "G",
          name: "Multi-Ethnic Suburbs",
          colour: "#E5C494",
          visible: true,
          description:
            "Members of this supergroup are drawn from a wide range of non-White ethnic groups and White groups are less represented than average for London. Citizens of countries that joined the EU post 2001 are well represented. Many households have young children or children of school age, and the over 65s are not much in evidence. There is above average incidence of family housing in overcrowded terraces, much of it rented within the social housing sector. Levels of unemployment are high. Employment is mainly found in blue collar occupations."
        },
        {
          id: "H",
          name: "Ageing City Fringe",
          colour: "#B3B3B3",
          visible: true,
          description:
            "Many of the residents in this supergroup are over 45, and many are above state pensionable age. There are high levels of marriage and established White residents are very much in evidence. By contrast, representation of ethnic minorities and EU migrants is very low, relative to the London average. Much of the dwelling stock comprises semi-detached and detached houses, occupied at low residential densities. Levels of qualifications are low, as might be expected for these age cohorts. Private vehicle ownership is high, with some households possessing two or more vehicles. Levels of unemployment are very low. Employment is drawn from a range of sectors and this is the only supergroup in which agriculture is an important source of employment."
        }
      ]
    },
    {
      id: 1,
      name: "Group",
      field: "grp_cd",
      lookup: "id",
      groups: [
        {
          id: "A1",
          parent: "A",
          name: "Struggling suburbs",
          colour: "#539B84",
          visible: true,
          description:
            "Populated by above average numbers of Black residents, these neighbourhoods also have high unemployment by London standards. Above average levels of housing are provided by the social rented sector _ often in terraces. Employment is found across a range of blue-collar occupations that are found within the Greater London area."
        },
        {
          id: "A2",
          parent: "A",
          name: "Suburban localities",
          colour: "#97C3B5",
          visible: true,
          description:
            "This group is predominantly comprised of White residents living in neighbourhoods that are part of a local labour market that may extend outside the Greater London area. Semi-detached housing is quite common and is predominantly owner-occupied. Households are heavily reliant upon private transport."
        },
        {
          id: "B1",
          parent: "B",
          name: "Disadvantaged diaspora",
          colour: "#CA714E",
          visible: true,
          description:
            "These neighbourhoods are characterised by high numbers of Black residents, and of families with young children. There is high incidence of divorce. Employment is often found in transport, health care support, social work, administration and support services."
        },
        {
          id: "B2",
          parent: "B",
          name: "Students and minority mix",
          colour: "#FC9872",
          visible: true,
          description:
            "These ethnically diverse areas also house some students in communal establishments. Rates of divorce are higher than the London average."
        },
        {
          id: "B3",
          parent: "B",
          name: "Bangladeshi enclaves",
          colour: "#FDAF91",
          visible: true,
          description:
            "These neighbourhoods have exceptionally high concentrations of residents of Bangladeshi origin; and also are areas characterised by particularly low levels of use of English as a first language. Families generally have a younger age structure, and a prevalent source of employment is in the accommodation and food services industry. The areas also have higher student populations than are typical for London, and residents who use bicycles to get to and from work or study."
        },
        {
          id: "C1",
          parent: "C",
          name: "Asian owner occupiers",
          colour: "#63708E",
          visible: true,
          description:
            "These neighbourhoods are characterised by a very high incidence of semi-detached and terraced houses, by average levels of full-and part-time employment, and employment in a range of blue-collar occupations."
        },
        {
          id: "C2",
          parent: "C",
          name: "Transport service workers",
          colour: "#465066",
          visible: true,
          description:
            "Principally but not exclusively occurring in the environs of Heathrow Airport, employment in these neighbourhoods is focused on catering, manufacturing and other airport-related services, as well as other local services. Young families typically live in semi-detached houses and there is a particularly high incidence of households in which English is not the first language."
        },
        {
          id: "C3",
          parent: "C",
          name: "East End Asians",
          colour: "#A4B3D5",
          visible: true,
          description:
            "With large concentrations in the environs of Ilford, these predominantly Asian neighbourhoods have fewer residents from post 2001 EU accession countries than the other groups in this supergroup. Many households comprise young families living in privately rented terraced houses. There is a high incidence of part time employment relative to the London average."
        },
        {
          id: "C4",
          parent: "C",
          name: "Elderly Asians",
          colour: "#C6D0E5",
          visible: true,
          description:
            "The numbers of elderly individuals are above the London average in these neighbourhoods, and some are resident in communal establishments. Detached or semi-detached houses are much more common than the London average."
        },
        {
          id: "D1",
          parent: "D",
          name: "Educational advantage",
          colour: "#EEADD5",
          visible: true,
          description:
            "Student lifestyles characterise this group, with residents living in centrally located communal establishments at high residential densities. Many individuals have origins in emerging economies of the Middle East and China, Levels of overcrowding are high."
        },
        {
          id: "D2",
          parent: "D",
          name: "City central",
          colour: "#B96E9C",
          visible: true,
          description:
            "Many of the residents of these neighbourhoods are employed in the financial, insurance and real estate industries, or are information and communications industry professionals engaged in a range of scientific and technical activities. Residents are more likely than average to be White."
        },
        {
          id: "E1",
          parent: "E",
          name: "City and student fringe",
          colour: "#74973B",
          visible: true,
          description:
            "Many members of this group live in communal establishments, located in some of London's less fashionable central locations. Employment is typically in accommodation and food services. Black British residents are much in evidence, and citizens of post 2001 EU countries are also well represented relative to the London average."
        },
        {
          id: "E2",
          parent: "E",
          name: "Graduation occupation",
          colour: "#CAE898",
          visible: true,
          description:
            "The residents of these areas are likely to be recent graduates who are employed in the communications, scientific and technical sectors. Pre 2001 EU countries are well represented, and travel to work on foot or by bicycle is more common than average for London."
        },
        {
          id: "F1",
          parent: "F",
          name: "City enclaves",
          colour: "#CCAE26",
          visible: true,
          description:
            "These neighbourhoods have the youngest demographic structure of this supergroup. Residents of overseas EU countries are much in evidence, although they are mainly drawn from pre-2001 accession states. Their typically central locations makes these areas more suitable for travel to work by foot or bicycle. Employment in finance, insurance and real estate activities is common."
        },
        {
          id: "F2",
          parent: "F",
          name: "Affluent suburbs",
          colour: "#FFE882",
          visible: true,
          description:
            "Many individuals in this group are in middle age or older. Semi-detached and detached housing in suburban locations is common, with semi-detached housing being particularly common relative to the London average. Some individuals are resident in retirement homes."
        },
        {
          id: "G1",
          parent: "G",
          name: "Affordable transitions",
          colour: "#B79D77",
          visible: true,
          description:
            "These neighbourhoods house large numbers of recent migrants from the EU, as well as students. Some of the more established and long-term residents have origins in the Indian sub-continent. Much of the housing is provided in the private rental sector."
        },
        {
          id: "G2",
          parent: "G",
          name: "Affluent suburbs",
          colour: "#EDD6B4",
          visible: true,
          description:
            "Many households in this group rent within the social housing sector. Unemployment is higher than the London average and most employment is found in services such as transport and health care."
        },
        {
          id: "H1",
          parent: "H",
          name: "Detached retirement",
          colour: "#7D7D7D",
          visible: true,
          description:
            "These neighbourhoods have much higher levels of elderly and very elderly residents than the London average. Many live in detached houses. Although predominantly White British, there are also significant numbers of Indian residents."
        },
        {
          id: "H2",
          parent: "H",
          name: "Not quite Home Counties",
          colour: "#D1D1D1",
          visible: true,
          description:
            "This group is found along the borders of Greater London. Employment is in primary industry and public administration. Semi-detached properties are more common than the London average."
        }
      ]
    }
  ]
};
