import colorbrewer from "colorbrewer";

const cols =
  "the_geom_webmercator, lsoa11_cd, lsoa11_nm, lad_code, " +
  "imde19_rk, imde15_rk, imde10_rk, imds20_rk, imds16_rk, imdw19_rk, imdw14_rk, " +
  "imdn17_rk, imde19_inc, imde19_emp, imde19_edu, imde19_hdd, imde19_cri, imde19_bhs, " +
  "imde19_env, imde19_idc, imde19_ido, imde19_cyp, imde19_as, imde19_gb, imde19_wb, " +
  "imde19_ind, imde19_out, imde15_inc, imde15_emp, imde15_edu, imde15_hdd, imde15_cri, " +
  "imde15_bhs, imde15_env, imde15_idc, imde15_ido, imde15_cyp, imde15_as, imde15_gb, " +
  "imde15_wb, imde15_ind, imde15_out, imds20_inc, imds20_emp, imds20_hea, imds20_edu, " +
  "imds20_acc, imds20_cri, imds20_hou, imds16_inc, imds16_emp, imds16_hea, imds16_edu, " +
  "imds16_acc, imds16_cri, imds16_hou, imdw19_inc, imdw19_emp, imdw19_hea, imdw19_edu, " +
  "imdw19_acc, imdw19_hou, imdw19_saf, imdw19_phy, imdn17_inc, imdn17_emp, imdn17_hea, " +
  "imdn17_edu, imdn17_acc, imdn17_liv, imdn17_cd, imdh19_dc";

const ramp = colorbrewer.RdYlBu[10];

const rowcountEng = 32844;
const rowcountSco = 6976;
const rowcountWal = 1909;
const rowcountNIr = 890;

const legendStopsEng = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      {
        value: 1,
        colour: ramp[0],
        name: "Most deprived decile"
      },
      { value: Math.ceil(rowcountEng * 0.1), colour: ramp[1], name: "2nd" },
      { value: Math.ceil(rowcountEng * 0.2), colour: ramp[2], name: "3rd" },
      { value: Math.ceil(rowcountEng * 0.3), colour: ramp[3], name: "4th" },
      { value: Math.ceil(rowcountEng * 0.4), colour: ramp[4], name: "5th" },
      { value: Math.ceil(rowcountEng * 0.5), colour: ramp[5], name: "6th" },
      { value: Math.ceil(rowcountEng * 0.6), colour: ramp[6], name: "7th" },
      { value: Math.ceil(rowcountEng * 0.7), colour: ramp[7], name: "8th" },
      { value: Math.ceil(rowcountEng * 0.8), colour: ramp[8], name: "9th" },
      {
        value: Math.ceil(rowcountEng * 0.9),
        colour: ramp[9],
        name: "Least deprived decile"
      }
    ]
  }
];
const legendStopsSco = [
  {
    id: 1,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      {
        value: 1,
        colour: ramp[0],
        name: "Most deprived decile"
      },
      { value: Math.ceil(rowcountSco * 0.1), colour: ramp[1], name: "2nd" },
      { value: Math.ceil(rowcountSco * 0.2), colour: ramp[2], name: "3rd" },
      { value: Math.ceil(rowcountSco * 0.3), colour: ramp[3], name: "4th" },
      { value: Math.ceil(rowcountSco * 0.4), colour: ramp[4], name: "5th" },
      { value: Math.ceil(rowcountSco * 0.5), colour: ramp[5], name: "6th" },
      { value: Math.ceil(rowcountSco * 0.6), colour: ramp[6], name: "7th" },
      { value: Math.ceil(rowcountSco * 0.7), colour: ramp[7], name: "8th" },
      { value: Math.ceil(rowcountSco * 0.8), colour: ramp[8], name: "9th" },
      {
        value: Math.ceil(rowcountSco * 0.9),
        colour: ramp[9],
        name: "Least deprived decile"
      }
    ]
  }
];
const legendStopsWal = [
  {
    id: 1,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      {
        value: 1,
        colour: ramp[0],
        name: "Most deprived decile"
      },
      { value: Math.ceil(rowcountWal * 0.1), colour: ramp[1], name: "2nd" },
      { value: Math.ceil(rowcountWal * 0.2), colour: ramp[2], name: "3rd" },
      { value: Math.ceil(rowcountWal * 0.3), colour: ramp[3], name: "4th" },
      { value: Math.ceil(rowcountWal * 0.4), colour: ramp[4], name: "5th" },
      { value: Math.ceil(rowcountWal * 0.5), colour: ramp[5], name: "6th" },
      { value: Math.ceil(rowcountWal * 0.6), colour: ramp[6], name: "7th" },
      { value: Math.ceil(rowcountWal * 0.7), colour: ramp[7], name: "8th" },
      { value: Math.ceil(rowcountWal * 0.8), colour: ramp[8], name: "9th" },
      {
        value: Math.ceil(rowcountWal * 0.9),
        colour: ramp[9],
        name: "Least deprived decile"
      }
    ]
  }
];
const legendStopsNIr = [
  {
    id: 2,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      {
        value: 1,
        colour: ramp[0],
        name: "Most deprived decile"
      },
      { value: Math.ceil(rowcountNIr * 0.1), colour: ramp[1], name: "2nd" },
      { value: Math.ceil(rowcountNIr * 0.2), colour: ramp[2], name: "3rd" },
      { value: Math.ceil(rowcountNIr * 0.3), colour: ramp[3], name: "4th" },
      { value: Math.ceil(rowcountNIr * 0.4), colour: ramp[4], name: "5th" },
      { value: Math.ceil(rowcountNIr * 0.5), colour: ramp[5], name: "6th" },
      { value: Math.ceil(rowcountNIr * 0.6), colour: ramp[6], name: "7th" },
      { value: Math.ceil(rowcountNIr * 0.7), colour: ramp[7], name: "8th" },
      { value: Math.ceil(rowcountNIr * 0.8), colour: ramp[8], name: "9th" },
      {
        value: Math.ceil(rowcountNIr * 0.9),
        colour: ramp[9],
        name: "Least deprived decile"
      }
    ]
  }
];
const legendStopsUK = [
  {
    id: 3,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      {
        value: 1,
        colour: ramp[0],
        name: "Most deprived decile"
      },
      { value: 2, colour: ramp[1], name: "2nd" },
      { value: 3, colour: ramp[2], name: "3rd" },
      { value: 4, colour: ramp[3], name: "4th" },
      { value: 5, colour: ramp[4], name: "5th" },
      { value: 6, colour: ramp[5], name: "6th" },
      { value: 7, colour: ramp[6], name: "7th" },
      { value: 8, colour: ramp[7], name: "8th" },
      { value: 9, colour: ramp[8], name: "9th" },
      {
        value: 10,
        colour: ramp[9],
        name: "Least deprived decile"
      }
    ]
  }
];

export const IMD = {
  title: "Index of Multiple Deprivation",
  description:
    "Small area measures of relative deprivation across across each of the nations of the UK, often broken into deprivation domains.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/index-multiple-deprivation-imd",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM lsoa11_uk",
  buildingSql: "SELECT " + cols + " FROM lsoa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM lsoa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM lsoa11_uk_urban",
  defaultLayers: "1111000",
  extent: 2,
  popup: false,
  idField: "lsoa11_cd",
  geogNameField: "lsoa11_nm",
  ladCodeField: "lad_code",
  hoverFieldMetricMode: "MetricOnly",
  metrics: [
    {
      id: 0,
      name: "CDRC Harmonised 2019 IMD",
      field: "imdh19_dc",
      legendOptions: [],
      display: "",
      decimals: 0,
      legendStops: legendStopsUK,
      additionalMetrics: [1, 5, 6, 8]
    },
    {
      id: 1,
      name: "English 2019 IMD (E19)",
      field: "imde19_rk",
      legendOptions: [],
      display: "Decile",
      rankCount: rowcountEng,
      legendStops: legendStopsEng,
      additionalMetrics: [9, 10, 11, 12, 13, 14, 15]
    },
    {
      id: 2,
      name: "English 2015 IMD (E15)",
      field: "imde15_rk",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng,
      additionalMetrics: [24, 25, 26, 27, 28, 29, 30]
    },
    {
      id: 3,
      name: "English 2010 IMD fitted (E10)",
      field: "imde10_rk",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 4,
      name: "Scottish 2020 SIMD (S20)",
      field: "imds20_rk",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco,
      additionalMetrics: [39, 40, 41, 42, 43, 44, 45]
    },
    {
      id: 5,
      name: "Scottish 2016 SIMD (S16)",
      field: "imds16_rk",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco,
      additionalMetrics: [46, 47, 48, 49, 50, 51, 52]
    },
    {
      id: 6,
      name: "Welsh 2019 WIMD (W19)",
      field: "imdw19_rk",
      display: "Decile",
      rankCount: rowcountWal,
      legendOptions: [],
      legendStops: legendStopsWal,
      additionalMetrics: [53, 54, 55, 56, 57, 58, 59, 60]
    },
    {
      id: 7,
      name: "Welsh 2014 WIMD (W14)",
      field: "imdw14_rk",
      display: "Decile",
      rankCount: rowcountWal,
      legendOptions: [],
      legendStops: legendStopsWal
    },
    {
      id: 8,
      name: "Northern Irish 2017 MDM (N17)",
      field: "imdn17_rk",
      display: "Decile",
      rankCount: rowcountNIr,
      legendOptions: [],
      legendStops: legendStopsNIr,
      additionalMetrics: [61, 62, 63, 64, 65, 66, 67]
    },
    {
      id: 9,
      name: "E19 Income domain",
      field: "imde19_inc",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng,
      additionalMetrics: [16, 17]
    },
    {
      id: 10,
      name: "E19 Employment domain",
      field: "imde19_emp",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 11,
      name: "E19 Education domain",
      field: "imde19_edu",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng,
      additionalMetrics: [18, 19]
    },
    {
      id: 12,
      name: "E19 Health domain",
      field: "imde19_hdd",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 13,
      name: "E19 Crime domain",
      field: "imde19_cri",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 14,
      name: "E19 Housing domain",
      field: "imde19_bhs",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng,
      additionalMetrics: [20, 21]
    },
    {
      id: 15,
      name: "E19 Environment domain",
      field: "imde19_env",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng,
      additionalMetrics: [22, 23]
    },
    {
      id: 16,
      name: "E19 IDACI (Children)",
      field: "imde19_idc",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 17,
      name: "E19 IDAOPI (Older People)",
      field: "imde19_ido",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 18,
      name: "E19 Children/YP sub-domain",
      field: "imde19_cyp",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 19,
      name: "E19 Adult Skills sub-domain",
      field: "imde19_as",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 20,
      name: "E19 Geographical Barriers sub-domain",
      field: "imde19_gb",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 21,
      name: "E19 Wider Barriers sub-domain",
      field: "imde19_wb",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 22,
      name: "E19 Indoor sub-domain",
      field: "imde19_ind",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 23,
      name: "E19 Outdoor sub-domain",
      field: "imde19_out",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 24,
      name: "E15 Income domain",
      field: "imde15_inc",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng,
      additionalMetrics: [30, 31]
    },
    {
      id: 25,
      name: "E15 Employment domain",
      field: "imde15_emp",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 26,
      name: "E15 Education domain",
      field: "imde15_edu",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng,
      additionalMetrics: [32, 33]
    },
    {
      id: 27,
      name: "E15 Health domain",
      field: "imde15_hdd",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 28,
      name: "E15 Crime domain",
      field: "imde15_cri",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 29,
      name: "E15 Housing domain",
      field: "imde15_bhs",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng,
      additionalMetrics: [34, 35]
    },
    {
      id: 30,
      name: "E15 Environment domain",
      field: "imde15_env",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng,
      additionalMetrics: [36, 37]
    },
    {
      id: 31,
      name: "E15 IDACI (Children)",
      field: "imde15_idc",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 32,
      name: "E15 IDAOPI (Older People)",
      field: "imde15_ido",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 33,
      name: "E15 Children/YP sub-domain",
      field: "imde15_cyp",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 34,
      name: "E15 Adult Skills sub-domain",
      field: "imde15_as",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 35,
      name: "E15 Geographical Barriers sub-domain",
      field: "imde15_gb",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 36,
      name: "E15 Wider Barriers sub-domain",
      field: "imde15_wb",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 37,
      name: "E15 Indoor sub-domain",
      field: "imde15_ind",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 38,
      name: "E15 Outdoor sub-domain",
      field: "imde15_out",
      display: "Decile",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 39,
      name: "S20 Income domain",
      field: "imds20_inc",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 40,
      name: "S20 Employment domain",
      field: "imds20_emp",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 41,
      name: "S20 Health domain",
      field: "imds20_hea",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 42,
      name: "S20 Education domain",
      field: "imds20_edu",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 43,
      name: "S20 Services domain",
      field: "imds20_acc",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 44,
      name: "S20 Crime domain",
      field: "imds20_cri",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 45,
      name: "S20 Housing domain",
      field: "imds20_hou",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 46,
      name: "S16 Income domain",
      field: "imds16_inc",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 47,
      name: "S16 Employment domain",
      field: "imds16_emp",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 48,
      name: "S16 Health domain",
      field: "imds16_hea",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 49,
      name: "S16 Education domain",
      field: "imds16_edu",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 50,
      name: "S16 Services domain",
      field: "imds16_acc",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 51,
      name: "S16 Crime domain",
      field: "imds16_cri",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 52,
      name: "S16 Housing domain",
      field: "imds16_hou",
      display: "Decile",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 53,
      name: "W19 Income domain",
      field: "imdw19_inc",
      display: "Decile",
      rankCount: rowcountWal,
      legendOptions: [],
      legendStops: legendStopsWal
    },
    {
      id: 54,
      name: "W19 Employment domain",
      field: "imdw19_emp",
      display: "Decile",
      rankCount: rowcountWal,
      legendOptions: [],
      legendStops: legendStopsWal
    },
    {
      id: 55,
      name: "W19 Health domain",
      field: "imdw19_hea",
      display: "Decile",
      rankCount: rowcountWal,
      legendOptions: [],
      legendStops: legendStopsWal
    },
    {
      id: 56,
      name: "W19 Education domain",
      field: "imdw19_edu",
      display: "Decile",
      rankCount: rowcountWal,
      legendOptions: [],
      legendStops: legendStopsWal
    },
    {
      id: 57,
      name: "W19 Services domain",
      field: "imdw19_acc",
      display: "Decile",
      rankCount: rowcountWal,
      legendOptions: [],
      legendStops: legendStopsWal
    },
    {
      id: 58,
      name: "W19 Housing domain",
      field: "imdw19_hou",
      display: "Decile",
      rankCount: rowcountWal,
      legendOptions: [],
      legendStops: legendStopsWal
    },
    {
      id: 59,
      name: "W19 Safety domain",
      field: "imdw19_saf",
      display: "Decile",
      rankCount: rowcountWal,
      legendOptions: [],
      legendStops: legendStopsWal
    },
    {
      id: 60,
      name: "W19 Environment domain",
      field: "imdw19_phy",
      display: "Decile",
      rankCount: rowcountWal,
      legendOptions: [],
      legendStops: legendStopsWal
    },
    {
      id: 61,
      name: "N17 Income domain",
      field: "imdn17_inc",
      display: "Decile",
      rankCount: rowcountNIr,
      legendOptions: [],
      legendStops: legendStopsNIr
    },
    {
      id: 62,
      name: "N17 Employment domain",
      field: "imdn17_emp",
      display: "Decile",
      rankCount: rowcountNIr,
      legendOptions: [],
      legendStops: legendStopsNIr
    },
    {
      id: 63,
      name: "N17 Health sub-domain",
      field: "imdn17_hea",
      display: "Decile",
      rankCount: rowcountNIr,
      legendOptions: [],
      legendStops: legendStopsNIr
    },
    {
      id: 64,
      name: "N17 Education sub-domain",
      field: "imdn17_edu",
      display: "Decile",
      rankCount: rowcountNIr,
      legendOptions: [],
      legendStops: legendStopsNIr
    },
    {
      id: 65,
      name: "N17 Services sub-domain",
      field: "imdn17_acc",
      display: "Decile",
      rankCount: rowcountNIr,
      legendOptions: [],
      legendStops: legendStopsNIr
    },
    {
      id: 66,
      name: "N17 Environment sub-domain",
      field: "imdn17_liv",
      display: "Decile",
      rankCount: rowcountNIr,
      legendOptions: [],
      legendStops: legendStopsNIr
    },
    {
      id: 67,
      name: "N17 Crime sub-domain",
      field: "imdn17_cd",
      display: "Decile",
      rankCount: rowcountNIr,
      legendOptions: [],
      legendStops: legendStopsNIr
    }
  ]
};
