const cols =
  "the_geom_webmercator, oa11_cd, la17_cd, la17_nm, oac_supcd, oac_grpcd, oac_subcd";

export const OAC = {
  title: "2011 OAC",
  description: "The Area Classification of Output Areas (OAC) 2011",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/output-area-classification-2011",
  relatedMaps: ["OAC2021"],
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM oa11_uk",
  buildingSql: "SELECT " + cols + " FROM oa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM oa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM oa11_uk_urban",
  defaultLayers: "1111000",
  extent: 2,
  popup: true,
  popupType: "classification",
  idField: "oa11_cd",
  geogNameField: "la17_nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la17_cd",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.oac_supcd = '1' then 1 else 0 end) as count_1,
      sum(case when d.oac_supcd = '2' then 1 else 0 end) as count_2,
      sum(case when d.oac_supcd = '3' then 1 else 0 end) as count_3,
      sum(case when d.oac_supcd = '4' then 1 else 0 end) as count_4,
      sum(case when d.oac_supcd = '5' then 1 else 0 end) as count_5,
      sum(case when d.oac_supcd = '6' then 1 else 0 end) as count_6,
      sum(case when d.oac_supcd = '7' then 1 else 0 end) as count_7,
      sum(case when d.oac_supcd = '8' then 1 else 0 end) as count_8,
      sum(case when d.oac_grpcd = '1a' then 1 else 0 end) as count_1a,
      sum(case when d.oac_grpcd = '1b' then 1 else 0 end) as count_1b,
      sum(case when d.oac_grpcd = '1c' then 1 else 0 end) as count_1c,
      sum(case when d.oac_grpcd = '2a' then 1 else 0 end) as count_2a,
      sum(case when d.oac_grpcd = '2b' then 1 else 0 end) as count_2b,
      sum(case when d.oac_grpcd = '2c' then 1 else 0 end) as count_2c,
      sum(case when d.oac_grpcd = '2d' then 1 else 0 end) as count_2d,
      sum(case when d.oac_grpcd = '3a' then 1 else 0 end) as count_3a,
      sum(case when d.oac_grpcd = '3b' then 1 else 0 end) as count_3b,
      sum(case when d.oac_grpcd = '3c' then 1 else 0 end) as count_3c,
      sum(case when d.oac_grpcd = '3d' then 1 else 0 end) as count_3d,  
      sum(case when d.oac_grpcd = '4a' then 1 else 0 end) as count_4a,
      sum(case when d.oac_grpcd = '4b' then 1 else 0 end) as count_4b,
      sum(case when d.oac_grpcd = '4c' then 1 else 0 end) as count_4c,
      sum(case when d.oac_grpcd = '5a' then 1 else 0 end) as count_5a,
      sum(case when d.oac_grpcd = '5b' then 1 else 0 end) as count_5b,
      sum(case when d.oac_grpcd = '6a' then 1 else 0 end) as count_6a,
      sum(case when d.oac_grpcd = '6b' then 1 else 0 end) as count_6b,
      sum(case when d.oac_grpcd = '7a' then 1 else 0 end) as count_7a,
      sum(case when d.oac_grpcd = '7b' then 1 else 0 end) as count_7b,
      sum(case when d.oac_grpcd = '7c' then 1 else 0 end) as count_7c,
      sum(case when d.oac_grpcd = '7d' then 1 else 0 end) as count_7d,
      sum(case when d.oac_grpcd = '8a' then 1 else 0 end) as count_8a,
      sum(case when d.oac_grpcd = '8b' then 1 else 0 end) as count_8b,
      sum(case when d.oac_grpcd = '8c' then 1 else 0 end) as count_8c,
      sum(case when d.oac_grpcd = '8d' then 1 else 0 end) as count_8d,
      sum(case when d.oac_subcd = '1a1' then 1 else 0 end) as count_1a1,
      sum(case when d.oac_subcd = '1a2' then 1 else 0 end) as count_1a2,
      sum(case when d.oac_subcd = '1a3' then 1 else 0 end) as count_1a3,
      sum(case when d.oac_subcd = '1a4' then 1 else 0 end) as count_1a4,
      sum(case when d.oac_subcd = '1b1' then 1 else 0 end) as count_1b1,
      sum(case when d.oac_subcd = '1b2' then 1 else 0 end) as count_1b2,
      sum(case when d.oac_subcd = '1b3' then 1 else 0 end) as count_1b3,
      sum(case when d.oac_subcd = '1c1' then 1 else 0 end) as count_1c1,
      sum(case when d.oac_subcd = '1c2' then 1 else 0 end) as count_1c2,
      sum(case when d.oac_subcd = '1c3' then 1 else 0 end) as count_1c3,
      sum(case when d.oac_subcd = '2a1' then 1 else 0 end) as count_2a1,
      sum(case when d.oac_subcd = '2a2' then 1 else 0 end) as count_2a2,
      sum(case when d.oac_subcd = '2a3' then 1 else 0 end) as count_2a3,
      sum(case when d.oac_subcd = '2b1' then 1 else 0 end) as count_2b1,
      sum(case when d.oac_subcd = '2b2' then 1 else 0 end) as count_2b2,
      sum(case when d.oac_subcd = '2c1' then 1 else 0 end) as count_2c1,
      sum(case when d.oac_subcd = '2c2' then 1 else 0 end) as count_2c2,
      sum(case when d.oac_subcd = '2c3' then 1 else 0 end) as count_2c3,
      sum(case when d.oac_subcd = '2d1' then 1 else 0 end) as count_2d1,
      sum(case when d.oac_subcd = '2d2' then 1 else 0 end) as count_2d2,
      sum(case when d.oac_subcd = '2d3' then 1 else 0 end) as count_2d3,
      sum(case when d.oac_subcd = '3a1' then 1 else 0 end) as count_3a1,
      sum(case when d.oac_subcd = '3a2' then 1 else 0 end) as count_3a2,
      sum(case when d.oac_subcd = '3b1' then 1 else 0 end) as count_3b1,
      sum(case when d.oac_subcd = '3b2' then 1 else 0 end) as count_3b2,
      sum(case when d.oac_subcd = '3b3' then 1 else 0 end) as count_3b3,
      sum(case when d.oac_subcd = '3c1' then 1 else 0 end) as count_3c1,
      sum(case when d.oac_subcd = '3c2' then 1 else 0 end) as count_3c2,
      sum(case when d.oac_subcd = '3d1' then 1 else 0 end) as count_3d1,
      sum(case when d.oac_subcd = '3d2' then 1 else 0 end) as count_3d2,
      sum(case when d.oac_subcd = '3d3' then 1 else 0 end) as count_3d3,
      sum(case when d.oac_subcd = '4a1' then 1 else 0 end) as count_4a1,
      sum(case when d.oac_subcd = '4a2' then 1 else 0 end) as count_4a2,
      sum(case when d.oac_subcd = '4a3' then 1 else 0 end) as count_4a3,
      sum(case when d.oac_subcd = '4b1' then 1 else 0 end) as count_4b1,
      sum(case when d.oac_subcd = '4b2' then 1 else 0 end) as count_4b2,
      sum(case when d.oac_subcd = '4c1' then 1 else 0 end) as count_4c1,
      sum(case when d.oac_subcd = '4c2' then 1 else 0 end) as count_4c2,
      sum(case when d.oac_subcd = '4c3' then 1 else 0 end) as count_4c3,
      sum(case when d.oac_subcd = '5a1' then 1 else 0 end) as count_5a1,
      sum(case when d.oac_subcd = '5a2' then 1 else 0 end) as count_5a2,
      sum(case when d.oac_subcd = '5a3' then 1 else 0 end) as count_5a3,
      sum(case when d.oac_subcd = '5b1' then 1 else 0 end) as count_5b1,
      sum(case when d.oac_subcd = '5b2' then 1 else 0 end) as count_5b2,
      sum(case when d.oac_subcd = '5b3' then 1 else 0 end) as count_5b3,
      sum(case when d.oac_subcd = '6a1' then 1 else 0 end) as count_6a1,
      sum(case when d.oac_subcd = '6a2' then 1 else 0 end) as count_6a2,
      sum(case when d.oac_subcd = '6a3' then 1 else 0 end) as count_6a3,
      sum(case when d.oac_subcd = '6a4' then 1 else 0 end) as count_6a4,
      sum(case when d.oac_subcd = '6b1' then 1 else 0 end) as count_6b1,
      sum(case when d.oac_subcd = '6b2' then 1 else 0 end) as count_6b2,
      sum(case when d.oac_subcd = '6b3' then 1 else 0 end) as count_6b3,
      sum(case when d.oac_subcd = '6b4' then 1 else 0 end) as count_6b4,
      sum(case when d.oac_subcd = '7a1' then 1 else 0 end) as count_7a1,
      sum(case when d.oac_subcd = '7a2' then 1 else 0 end) as count_7a2,
      sum(case when d.oac_subcd = '7a3' then 1 else 0 end) as count_7a3,
      sum(case when d.oac_subcd = '7b1' then 1 else 0 end) as count_7b1,
      sum(case when d.oac_subcd = '7b2' then 1 else 0 end) as count_7b2,
      sum(case when d.oac_subcd = '7b3' then 1 else 0 end) as count_7b3,
      sum(case when d.oac_subcd = '7c1' then 1 else 0 end) as count_7c1,
      sum(case when d.oac_subcd = '7c2' then 1 else 0 end) as count_7c2,
      sum(case when d.oac_subcd = '7c3' then 1 else 0 end) as count_7c3,
      sum(case when d.oac_subcd = '7d1' then 1 else 0 end) as count_7d1,
      sum(case when d.oac_subcd = '7d2' then 1 else 0 end) as count_7d2,
      sum(case when d.oac_subcd = '7d3' then 1 else 0 end) as count_7d3,
      sum(case when d.oac_subcd = '7d4' then 1 else 0 end) as count_7d4,
      sum(case when d.oac_subcd = '8a1' then 1 else 0 end) as count_8a1,
      sum(case when d.oac_subcd = '8a2' then 1 else 0 end) as count_8a2,
      sum(case when d.oac_subcd = '8b1' then 1 else 0 end) as count_8b1,
      sum(case when d.oac_subcd = '8b2' then 1 else 0 end) as count_8b2,
      sum(case when d.oac_subcd = '8c1' then 1 else 0 end) as count_8c1,
      sum(case when d.oac_subcd = '8c2' then 1 else 0 end) as count_8c2,
      sum(case when d.oac_subcd = '8c3' then 1 else 0 end) as count_8c3,
      sum(case when d.oac_subcd = '8d1' then 1 else 0 end) as count_8d1,
      sum(case when d.oac_subcd = '8d2' then 1 else 0 end) as count_8d2,
      sum(case when d.oac_subcd = '8d3' then 1 else 0 end) as count_8d3
  FROM oliverobrien.oa11_uk d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Supergroup",
      field: "oac_supcd",
      lookup: "id",
      groups: [
        {
          id: "1",
          name: "Rural Residents",
          colour: "#4DAF4A",
          visible: true,
          description:
            "The population of this supergroup live in rural areas that are far less densely populated compared with elsewhere in the country. They will tend to live in large detached properties which they own and work in the agriculture, forestry and fishing industries. The level of unemployment in these areas is below the national average. Each household is likely to have multiple motor vehicles, and these will be the preferred method of transport to their places of work. The population tends to be older, married and well educated. An above average proportion of the population in these areas provide unpaid care and an above average number of people live in communal establishments (most likely to be retirement homes). There is less ethnic integration in these areas and households tend to speak English or Welsh as their main language."
        },
        {
          id: "2",
          name: "Cosmopolitans",
          colour: "#E41A1C",
          visible: true,
          description:
            "The majority of the population in this supergroup live in densely populated urban areas. They are more likely to live in flats and communal establishments, and private renting is more prevalent than nationally. The group has a high ethnic integration, with an above average number of residents from EU accession countries coinciding with a below average proportion of persons stating their country of birth as the UK or Ireland. A result of this is that households are less likely to speak English or Welsh as their main language. The population of the group is characterised by young adults, with a higher proportion of single adults and households without children than nationally. There are also higher proportions of full-time students. Workers are more likely to be employed in the accommodation, information and communication, and financial related industries, and using public transport, or walking or cycling to get to work."
        },
        {
          id: "3",
          name: "Ethnicity Central",
          colour: "#F781BF",
          visible: true,
          description:
            "The population of this group is predominately located in the denser central areas of London, with other inner urban areas across the UK having smaller concentrations. All non-white ethnic groups have a higher representation than the UK average especially people of mixed ethnicity or who are Black, with an above average number of residents born in other EU countries. Residents are more likely to be young adults with slightly higher rates of divorce or separation than the national average, with a lower proportion of households having no children or non-dependent children. Residents are more likely to live in flats and more likely to rent. A higher proportion of people use public transport to get to work, with lower car ownership, and higher unemployment. Those in employment are more likely to work in the accommodation, information and communication, financial, and administrative related industries."
        },
        {
          id: "4",
          name: "Multicultural Metropolitans",
          colour: "#FF7F00",
          visible: true,
          description:
            "The population of this supergroup is concentrated in larger urban conurbations in the transitional areas between urban centres and suburbia. They are likely to live in terraced housing that is rented _ both private and social. The group has a high ethnic mix, but a below average number of UK and Irish born residents. A result of this is that households are less likely to speak English or Welsh as their main language. Residents are likely to be below retirement age. There is likely to be an above average number of families with children who attend school or college, or who are currently too young to do so. The rates of marriage and divorce are broadly comparable with the national average. The level of qualifications is just under the national average with the rates of unemployment being above the national average. Residents who are employed are more likely to work in the transport and administrative related industries. Public transport is the most likely method for individuals to get to and from work, since households are less likely to have multiple motor vehicles available to them."
        },
        {
          id: "5",
          name: "Urbanites",
          colour: "#A65628",
          visible: true,
          description:
            "The population of this group are most likely to be located in urban areas in southern England and in less dense concentrations in large urban areas elsewhere in the UK. They are more likely to live in either flats or terraces, and to privately rent their home. The supergroup has an average ethnic mix, with an above average number of residents from other EU countries. A result of this is households are less likely to speak English or Welsh as their main language. Those in employment are more likely to be working in the information and communication, financial, public administration and education related sectors. Compared with the UK, unemployment is lower."
        },
        {
          id: "6",
          name: "Suburbanites",
          colour: "#984EA3",
          visible: true,
          description:
            "The population of this supergroup is most likely to be located on the outskirts of urban areas. They are more likely to own their own home and to live in semi-detached or detached properties. The population tends to be a mixture of those above retirement age and middle-aged parents with school age children. The number of residents who are married or in civil-partnerships is above the national average. Individuals are likely to have higher-level qualifications than the national average, with the levels of unemployment in these areas being below the national average. All non-White ethnic groups have a lower representation when compared with the UK and the proportion of people born in the UK or Ireland is slightly higher. People are more likely to work in the information and communication, financial, public administration, and education sectors, and use private transport to get to work."
        },
        {
          id: "7",
          name: "Constrained City Dwellers",
          colour: "#377EB8",
          visible: true,
          description:
            "This supergroup has a lower proportion of people aged 5 to 14 and a higher level aged 65 and over than nationally. It is more densely populated than the UK average. People are more likely to be single or divorced. There is a lower representation of all the non-White ethnic groups and of people who were born in other EU countries. There is a lower proportion of households with no children. Households are more likely to live in flats and to live in social rented accommodation, and there is a higher prevalence of overcrowding. There is a higher proportion of people whose day-to- day activities are limited, and lower qualification levels than nationally. There is a higher level of unemployment in the supergroup. There are no particular industries in which workers are most likely to be employed, but some industries such as information and communication, and the education sector are underrepresented."
        },
        {
          id: "8",
          name: "Hard-Pressed Living",
          colour: "#FFCB1F",
          visible: true,
          description:
            "The population of this group is most likely to be found in urban surroundings, predominately in northern England and southern Wales. There is less non-White ethnic group representation than elsewhere in the UK, and a higher than average proportion of residents born in the UK and Ireland. Rates of divorce and separation are above the national average. Households are more likely to have non-dependent children and are more likely to live in semi-detached or terraced properties, and to socially rent. There is a smaller proportion of people with higher level qualifications, with rates of unemployment above the national average. Those in employment are more likely to be employed in the mining, manufacturing, energy, wholesale and retail, and transport related industries."
        }
      ]
    },
    {
      id: 1,
      name: "Group",
      field: "oac_grpcd",
      lookup: "id",
      groups: [
        {
          id: "1a",
          parent: "1",
          name: "Farming Communities",
          colour: "#398237",
          visible: true,
          description:
            "This group has a lower population density than the supergroup, and people are less likely to live in communal establishments. There is a higher proportion of households living in detached properties when compared with the supergroup and much lower proportions living in terraced properties and flats. Households are less likely to live in social rented accommodation. There is a higher proportion of people working in the agriculture industry compared with the supergroup."
        },
        {
          id: "1b",
          parent: "1",
          name: "Rural Tenants",
          colour: "#72af70",
          visible: true,
          description:
            "The age structure is very similar to the supergroup, though people are less likely to live in communal establishments. Compared with the parent supergroup, there is a higher proportion of households living in semi-detached, terraced properties and flats, with a higher proportion socially renting. People are less likely to work in the agriculture industry than for the parent supergroup."
        },
        {
          id: "1c",
          parent: "1",
          name: "Ageing Rural Dwellers",
          colour: "#aadba9",
          visible: true,
          description:
            "The age structure of this group shows has a lower proportion of people aged under 65, and higher proportions aged 65 and over, particularly for the 90 and over age group. People are more likely to live in communal establishments or in detached properties."
        },
        {
          id: "2a",
          parent: "2",
          name: "Students Around Campus",
          colour: "#8a0f11",
          visible: true,
          description:
            "Compared with the parent supergroup a higher proportion of people live in communal establishments. A lower proportion of people are married or divorced and a higher proportion are schoolchildren and full-time students. Households are more likely to live in terraced properties and to live in social rented accommodation compared with the national average. There is also a higher prevalence of workers in the accommodation or food service activities industries."
        },
        {
          id: "2b",
          parent: "2",
          name: "Inner-City Students",
          colour: "#ae4142",
          visible: true,
          description:
            "The age profile of this group shows a high proportion of schoolchildren, full-time students, and people aged 25 to 44, though a lower proportion married or divorced. Households are more likely to live in flats, to live in private rented accommodation, and to have overcrowded conditions. A lower proportion of people provide unpaid care, and a higher proportion work in accommodation or food service activities industries."
        },
        {
          id: "2c",
          parent: "2",
          name: "Comfortable Cosmopolitans",
          colour: "#d17274",
          visible: true,
          description:
            "The age profile of this group shows a higher proportion of people age 45 and over than the parent supergroup. A higher proportion of people are divorced. There is a lower representation for all non- White ethnic groups when compared with the supergroup and a lower proportion of people born in the old EU. There is a lower proportion of households with full-time students and a higher proportion who live in flats. A higher proportion of workers are employed in the mining and manufacturing industries, and travel to work using private transport."
        },
        {
          id: "2d",
          parent: "2",
          name: "Aspiring and Affluent",
          colour: "#f5a4a5",
          visible: true,
          description:
            "The proportion of people age 0 to 14 is higher than for the parent supergroup. A higher proportion of people are married. There is a higher proportion of people who are of mixed ethnicity. A lower proportion of households have full-time students. Compared with the supergroup a higher proportion of households live in semi-detached or terraced properties. People are more likely to work in the information and communication, and financial related industries, and use public transport to get to work."
        },
        {
          id: "3a",
          parent: "3",
          name: "Ethnic Family Life",
          colour: "#a70a5d",
          visible: true,
          description:
            "When compared with the parent supergroup, this group has a higher level of all non-White ethnic groups. There is a lower proportion of people born in the old EU but a higher proportion were born in the new EU. There is a higher proportion of people whose main language is not English or Welsh. Households are more likely to live in detached, semi-detached or terraced properties."
        },
        {
          id: "3b",
          parent: "3",
          name: "Endeavouring Ethnic Mix",
          colour: "#c34186",
          visible: true,
          description:
            "This group has a higher proportion of people who belong to the Bangladeshi ethnic group than the parent supergroup but a lower proportion of those in Pakistani and Indian ethnic groups. There is a higher proportion of people who were born in the old EU countries. Households are more likely to live in flats and to socially rent than for the supergroup. Overcrowding is also more prevalent, and public transport more commonly used to get to work."
        },
        {
          id: "3c",
          parent: "3",
          name: "Ethnic Dynamics",
          colour: "#de79af",
          visible: true,
          description:
            "In this group non-White ethnic groups are not represented as highly as in the parent supergroup and there is a higher proportion of people born in the UK or Ireland. Households are more likely to live in a flat and to socially rent. There is a higher proportion of unemployed in the group but those in employment are more likely to work in the manufacturing industry, and to use private transport to travel to work."
        },
        {
          id: "3d",
          parent: "3",
          name: "Aspirational Techies",
          colour: "#fab1d8",
          visible: true,
          description:
            "With the exception of the Indian and mixed ethnic group, this group has a lower representation of all non-White ethnic groups than in the parent supergroup. There is a higher proportion of people born in the old EU but a lower proportion whose main language is not English or Welsh. Households are more likely to live in semi-detached or terraced properties, and to live in privately rented accommodation. Workers are more likely to be employed in the information and communication industries, and to travel to work using public transport."
        },
        {
          id: "4a",
          parent: "4",
          name: "Rented Family Living",
          colour: "#e67300",
          visible: true,
          description:
            "This group has a higher representation of White and mixed ethnicity residents than the supergroup and a lower proportion of people whose main language is not English or Welsh. Households are more likely to live in terraced properties or flats, and to socially rent their property."
        },
        {
          id: "4b",
          parent: "4",
          name: "Challenged Asian Terraces",
          colour: "#e7903a",
          visible: true,
          description:
            "The population of this group has a higher proportion of non-White ethnic groups than the parent supergroup especially people of the Pakistani ethnic group, and a higher proportion of 0 to 14 year- olds. It is more likely that their main language is not English or Welsh. A higher proportion of households live in terraced properties, and overcrowding is more prevalent. When compared with the supergroup more people are likely to be unemployed, and those in employment to be working in the accommodation and food service industries."
        },
        {
          id: "4c",
          parent: "4",
          name: "Asian Traits",
          colour: "#e8ae74",
          visible: true,
          description:
            "The population of this group has a higher proportion of people who are of Chinese ethnicity and particularly of Indian ethnicity. Compared with the parent supergroup, households are more likely to live in detached and semi-detached properties, and to own their own home. A higher proportion of households have two or more cars, unemployment is lower, and workers are more likely to work in the Information and communication, and financial related industries."
        },
        {
          id: "5a",
          parent: "5",
          name: "Urban Professionals and Families",
          colour: "#974e24",
          visible: true,
          description:
            "The population of this group shows a noticeably higher proportion of children aged 0 to 14 than the parent supergroup and a lower proportion aged 90 and over. There is also a higher proportion of people with mixed ethnicity. Households in this group are more likely to live in terraced properties and to live in privately rented accommodation. Unemployment is slightly higher than for the parent supergroup."
        },
        {
          id: "5b",
          parent: "5",
          name: "Ageing Urban Living",
          colour: "#bf8361",
          visible: true,
          description:
            "The population of this group shows a higher proportion of people aged 65 and over than the parent supergroup. Residents are more likely to live in communal establishments, detached properties and flats than the supergroup, with a higher proportion of households living in socially rented accommodation."
        },
        {
          id: "6a",
          parent: "6",
          name: "Suburban Achievers",
          colour: "#944d9f",
          visible: true,
          description:
            "When compared with the parent supergroup a higher proportion of households live in detached properties and flats, and are less likely to rent their accommodation or live in overcrowded conditions. People of Indian ethnicity are over-represented when compared with the supergroup. Higher proportions of people have higher qualifications, and are more likely to work in the information and communication, and financial related industries."
        },
        {
          id: "6b",
          parent: "6",
          name: "Semi-Detached Suburbia",
          colour: "#b784bf",
          visible: true,
          description:
            "People in this group are slightly more likely to be divorced or separated than those in the supergroup. Households are more likely to live in semi-detached and terraced properties, with a higher proportion of households renting their accommodation."
        },
        {
          id: "7a",
          parent: "7",
          name: "Challenged Diversity",
          colour: "#1f4869",
          visible: true,
          description:
            "The population of this group have a higher level of people aged 0 to 14 in comparison with the supergroup. All non-White ethnic groups have a higher representation than nationally, especially people who have mixed ethnicity. A higher proportion of households live in terraced properties, and are more likely to live in private rented accommodation when compared with the supergroup. Car ownership is generally higher than the supergroup, and people are more likely to be employed in information and communication related industries."
        },
        {
          id: "7b",
          parent: "7",
          name: "Constrained Flat Dwellers",
          colour: "#4b7392",
          visible: true,
          description:
            "This group is characterised by people living in flats, with a higher proportion living in socially rented accommodation than for the supergroup. Ethnic groups generally have a similar representation as for the supergroup, persons of mixed ethnicity are underrepresented. There is a lower proportion of households with two or more cars."
        },
        {
          id: "7c",
          parent: "7",
          name: "White Communities",
          colour: "#779dbb",
          visible: true,
          description:
            "The population of this group are more likely to be white when compared with the parent supergroup, with a lower representation of all other ethnic groups, and a lower proportion of people born in other EU countries. There is a higher proportion of households with non-dependent children, with households more likely to be living in semi-detached and terraced properties, and owning their own accommodation."
        },
        {
          id: "7d",
          parent: "7",
          name: "Ageing City Dwellers",
          colour: "#a4c7e4",
          visible: true,
          description:
            "The population of this group shows a higher proportion of people aged 65 and over when compared with the parent supergroup, and residents are more likely to live in communal establishments and less likely to be single. There is a higher proportion of households living in detached properties and flats. A lower proportion of people are unemployed."
        },
        {
          id: "8a",
          parent: "8",
          name: "Industrious Communities",
          colour: "#bd9100",
          visible: true,
          description:
            "Age structure and ethnic group representation broadly reflects the parent supergroup. There is a higher proportion of households living in detached and semi-detached properties, with slightly higher property ownership than for the supergroup. Industrious communities have a broadly similar demographic to the supergroup in terms of age group, occupation and population density, however slightly less overcrowding exists in this group. Ownership of two or more cars or vans is also marginally higher."
        },
        {
          id: "8b",
          parent: "8",
          name: "Challenged Terraced Workers",
          colour: "#d3b141",
          visible: true,
          description:
            "A key difference with this group compared with the parent supergroup is the dominance of terraced housing over other types. Ownership of two or more cars and non-White ethnic group representation is also lower. The group has a similar age structure to the supergroup and similar employment characteristics."
        },
        {
          id: "8c",
          parent: "8",
          name: "Hard-Pressed Ageing Workers",
          colour: "#e9d181",
          visible: true,
          description:
            "Residents who live in this group have a broadly similar age structure to the supergroup, though a smaller proportion of young people and higher proportion of older people. There is less non-While ethnic group representation than with the parent supergroup. Employment characteristics for this group closely reflect those for the supergroup."
        },
        {
          id: "8d",
          parent: "8",
          name: "Migration and Churn",
          colour: "#fff1c2",
          visible: true,
          description:
            "This group has a higher proportion of children aged 0 to 14 than the supergroup, with a higher representation of non-While ethnic groups. Households are more likely to live in terraced houses or flats, and to socially rent their property. Unemployment is noticeably higher than for the supergroup, and people are more likely to be employed in the tertiary industry (service) sector, and use public transport to get to work."
        }
      ]
    },
    {
      id: 2,
      name: "Subgroup",
      field: "oac_subcd",
      lookup: "id",
      groups: [
        {
          id: "1a1",
          parent: "1a",
          name: "Rural Workers and Families",
          colour: "#295e28",
          visible: true,
          description:
            "The population of this subgroup has a slightly higher proportion of people aged 0 to 4 when compared with the group. Households are slightly more likely to live in detached properties, and less likely to live in other types of property. They are also slightly more likely to live in overcrowded conditions than the group and for household members to be unemployed."
        },
        {
          id: "1a2",
          parent: "1a",
          name: "Established Farming Communities",
          colour: "#3e6f3c",
          visible: true,
          description:
            "Compared with the parent supergroup, households in this subgroup are more likely to live in a terraced or end-terraced house. People are slightly more likely to use public transport to get to work. Those in employment are more likely to work in financial related industries."
        },
        {
          id: "1a3",
          parent: "1a",
          name: "Agricultural Communities",
          colour: "#527f51",
          visible: true,
          description:
            "This subgroup has a lower population density than the group. Compared with the group, a higher proportion of households live in terraced properties or flats, and privately rent their home. The proportion of people working in agricultural industries is higher than the parent group."
        },
        {
          id: "1a4",
          parent: "1a",
          name: "Older Farming Communities",
          colour: "#668f65",
          visible: true,
          description:
            "The age make up of this subgroup is lower than the group for younger ages, but higher for ages 65 and over, and residents tend to live in more densely populated areas. Households are more likely to live in flats, though less likely to live in privately or socially rented accommodation. The proportion of people working in agricultural industries is lower than for the parent group."
        },
        {
          id: "1b1",
          parent: "1b",
          name: "Rural Life",
          colour: "#7aa079",
          visible: true,
          description:
            "This subgroup is slightly more densely populated than the parent group. Households are less likely to live in flats. There are slightly more people working in the manufacturing and energy industries."
        },
        {
          id: "1b2",
          parent: "1b",
          name: "Rural White-Collar Workers",
          colour: "#8eb08e",
          visible: true,
          description:
            "This subgroup is slightly less densely populated than the parent group. When compared with the parent group, a higher proportion of people work in the information and communication, and financial related industries, whilst unemployment is lower."
        },
        {
          id: "1b3",
          parent: "1b",
          name: "Ageing Rural Flat Tenants",
          colour: "#a3c0a2",
          visible: true,
          description:
            "When compared with the parent group there is a higher proportion of people who are aged 65 and over, and they live in slightly denser populated areas. A higher proportion of households live in flats and socially rent, whilst a lower proportion of people work in the information and communication, and financial industries."
        },
        {
          id: "1c1",
          parent: "1c",
          name: "Rural Employment and Retirees",
          colour: "#b7d1b6",
          visible: true,
          description:
            "This subgroup has a lower proportion of people aged 90 or over compared with the parent group. It has a slightly higher proportion of people who were born in the EU and whose main language is not English or Welsh. The proportion of people working in agricultural industries is higher than the parent group, and a higher proportion of households live in private rented accommodation."
        },
        {
          id: "1c2",
          parent: "1c",
          name: "Renting Rural Retirement",
          colour: "#cbe1cb",
          visible: true,
          description:
            "This subgroup has a higher proportion of people aged 90 and over compared with the parent group. There is a higher proportion of households who live in terraced properties, and households are more likely to rent socially. The proportion of people working in agricultural industries is lower than the parent group."
        },
        {
          id: "1c3",
          parent: "1c",
          name: "Detached Rural Retirement",
          colour: "#dff2df",
          visible: true,
          description:
            "This subgroup has a higher proportion of people aged 90 and over compared with the main group, a slightly higher proportion of households who live in detached properties, and a lower proportion who rent socially."
        },
        {
          id: "2a1",
          parent: "2a",
          name: "Student Communal Living",
          colour: "#730c0e",
          visible: true,
          description:
            "This subgroup has a high proportion of people (largely students) living in communal establishments compared with the group. It also has a higher proportion of people who are of Chinese ethnicity. The proportion of people who are schoolchildren or full-time students is higher than the group."
        },
        {
          id: "2a2",
          parent: "2a",
          name: "Student Digs",
          colour: "#812324",
          visible: true,
          description:
            "When compared with the parent group, this subgroup has a much lower proportion of people living in communal establishments. There are lower proportions of people who are married or separated. The proportion of households with full-time students is higher than the parent group, and households are more likely to be living in terraced houses and rented accommodation."
        },
        {
          id: "2a3",
          parent: "2a",
          name: "Students and Professionals",
          colour: "#8f393a",
          visible: true,
          description:
            "The population in this subgroup contains higher proportions of children aged 0 to 14, and adults aged 25 and over than the parent group. The subgroup has a lower proportion of people living in communal establishments than the parent group, with higher proportions of people who are married or separated."
        },
        {
          id: "2b1",
          name: "Students and Commuters",
          parent: "2b",
          colour: "#9c4f50",
          visible: true,
          description:
            "The proportion of people who are white is slightly higher than for the parent group, however the representation of all other ethnic groups is lower. The proportion of people with level 1 or 2, or apprenticeship qualifications is higher when compared with the parent group. People are more likely to use private transport to travel to work."
        },
        {
          id: "2b2",
          parent: "2b",
          name: "Multicultural Student Neighbourhoods",
          colour: "#aa6566",
          visible: true,
          description:
            "The population in this subgroup has a lower proportion of people aged 45 to 89 when compared with the parent group. Its ethnic makeup has a higher proportion of persons of mixed ethnicity."
        },
        {
          id: "2c1",
          parent: "2c",
          name: "Migrant Families",
          colour: "#b87b7c",
          visible: true,
          description:
            "This subgroup has a higher proportion of people aged 0 to 14 when compared with the parent subgroup, with a higher proportion of residents of mixed ethnicity. Households are more likely to live in a detached, semi-detached or terraced property than the parent group. A higher proportion of workers are employed in manufacturing industries."
        },
        {
          id: "2c2",
          parent: "2c",
          name: "Migrant Commuters",
          colour: "#c69192",
          visible: true,
          description:
            "The population in this subgroup has a higher proportion of people who are of Pakistani ethnicity when compared with the parent group, and households are more likely to live in socially rented accommodation. A lower proportion of households had two or more cars."
        },
        {
          id: "2c3",
          parent: "2c",
          name: "Professional Service Cosmopolitans",
          colour: "#d3a7a7",
          visible: true,
          description:
            "This subgroup had a lower proportion of people whose country of birth is in the new EU, and a lower proportion whose main language is not English or Welsh. When compared with the parent group they are more likely to own their home, and less likely to live in overcrowded conditions. When compared with the other subgroups for the parent group, this subgroup has the lowest proportion of people who are unemployed."
        },
        {
          id: "2d1",
          parent: "2d",
          name: "Urban Cultural Mix",
          colour: "#e1bdbd",
          visible: true,
          description:
            "When compared with the group a higher proportion of people are of Indian ethnicity. A lower proportion of people were born in the old EU whereas a higher proportion were born in the new EU. They are more likely to live in a detached or semi-detached property."
        },
        {
          id: "2d2",
          parent: "2d",
          name: "Highly-Qualified Quaternary Workers",
          colour: "#efd3d3",
          visible: true,
          description:
            "The label ÔquaternaryÕ refers loosely to ÔintellectualÕ activities. In comparison with the parent group there is a higher proportion of people aged 5 to 14, and a lower proportion of persons of Indian ethnicity. Households are more likely to live in terraced or end-terraced properties."
        },
        {
          id: "2d3",
          parent: "2d",
          name: "EU White-Collar Workers",
          colour: "#fde9e9",
          visible: true,
          description:
            "A key characteristic for this subgroup is the higher proportion of persons born in other EU countries, and most noticeably in other old EU countries, relative to the overall UK figure, and the parent group. When compared with the parent group, a higher proportion of the people in this subgroup are Arab or of ÔotherÕ ethnic group. Households are more likely to live in flats and to be living in overcrowded conditions. Households are less likely to have two or more cars and also less likely to use private transport to travel to work."
        },
        {
          id: "3a1",
          parent: "3a",
          name: "Established Renting Families",
          colour: "#8f0950",
          visible: true,
          description:
            "This subgroup has a lower proportion of people who have Indian or Pakistani ethnicity when compared with the group. The population is less likely to have been born in the new EU, and more likely to have dependent children. Households are more likely to be in socially rented accommodation."
        },
        {
          id: "3a2",
          parent: "3a",
          name: "Young Families and Students",
          colour: "#9b2161",
          visible: true,
          description:
            "In comparison with the parent group this subgroup has a higher proportion of people who are of Indian or Pakistani ethnicity. Country of birth for residents is more likely to have been in one of the new EU countries, and residents are more likely to have higher level qualifications. Households are more likely to be privately renting than the parent group and less likely to be social renting."
        },
        {
          id: "3b1",
          parent: "3b",
          name: "Striving Service Workers",
          colour: "#a73973",
          visible: true,
          description:
            "The population of this subgroup has a lower proportion of people who have Bangladeshi ethnicity than the group but a higher proportion who have Black ethnicity. A lower proportion of households are likely to live in privately rented accommodation. Most of the other characteristics are similar to the parent group."
        },
        {
          id: "3b2",
          parent: "3b",
          name: "Bangladeshi Mixed Employment",
          colour: "#b45185",
          visible: true,
          description:
            "A lower proportion of people in this subgroup are of mixed or Black ethnic origin when compared with the group but a far higher proportion of people have Bangladeshi ethnicity. A higher proportion of people whose main language is not English or Welsh are present in the subgroup."
        },
        {
          id: "3b3",
          parent: "3b",
          name: "Multi-Ethnic Professional Service Workers",
          colour: "#c06997",
          visible: true,
          description:
            "When compared with the group there is a higher proportion of people who are of Indian ethnicity but a lower proportion of Bangladeshi ethnicity. There is a higher proportion of people born in other EU countries with households more likely to live in privately rented accommodation in comparison with the parent group."
        },
        {
          id: "3c1",
          parent: "3c",
          name: "Constrained Neighbourhoods",
          colour: "#cc81a9",
          visible: true,
          description:
            "In comparison with the group, this subgroup has a higher proportion of people who have mixed ethnicity. Households are more likely to live in terraced properties. People in work are slightly more likely to work in manufacturing industries, and households more likely to own two or more cars."
        },
        {
          id: "3c2",
          parent: "3c",
          name: "Constrained Commuters",
          colour: "#d899bb",
          visible: true,
          description:
            "The population of this subgroup has a lower proportion of people aged 65 and over than the parent group. It also has a lower proportion of people with mixed ethnicity. Households in this subgroup are more likely to live in flats, and to use public transport for getting to work."
        },
        {
          id: "3d1",
          parent: "3d",
          name: "New EU Tech Workers",
          colour: "#e5b1cc",
          visible: true,
          description:
            "The population of this subgroup has a higher proportion of people who are of Indian or Pakistani ethnicity than the parent group, and a higher proportion born in the new EU countries. Households are more likely to live in detached properties than the group, and to live in privately rented accommodation. A higher proportion of people work in mining related industries, and use private transport for travelling to work."
        },
        {
          id: "3d2",
          parent: "3d",
          name: "Established Tech Workers",
          colour: "#f1c9de",
          visible: true,
          description:
            "The population of this subgroup is slightly more likely to have Black ethnicity and more likely to be born in the UK or Ireland, and to have non-dependent children. There is a higher proportion of households who live in terraced housing, and a higher proportion living in socially rented accommodation. Households are also less likely to live in overcrowded conditions."
        },
        {
          id: "3d3",
          parent: "3d",
          name: "Old EU Tech Workers",
          colour: "#fde1f0",
          visible: true,
          description:
            "The population of this subgroup is more likely to have Bangladeshi ethnicity, and to have been born in old EU countries. A higher proportion of households live in flats, and households are more likely to be living in socially rented accommodation."
        },
        {
          id: "4a1",
          parent: "4a",
          name: "Social Renting Young Families",
          colour: "#e67300",
          visible: true,
          description:
            "This subgroup, when compared with the parent group, has a higher proportion of children aged 5 to 14, a higher proportion of people who have Pakistani ethnicity, and a higher proportion who were born in the UK or Ireland. Households are more likely to live in semi-detached properties, and to live in social rented accommodation. Unemployment is more prevalent when compared with the parent group."
        },
        {
          id: "4a2",
          parent: "4a",
          name: "Private Renting New Arrivals",
          colour: "#e8831d",
          visible: true,
          description:
            "When compared with the group, this population of this subgroup has a lower proportion of people who have Black or of mixed ethnicity. Residents are more likely to have been born in other EU countries. Households are more likely to be living in private rented accommodation."
        },
        {
          id: "4a3",
          parent: "4a",
          name: "Commuters with Young Families",
          colour: "#eb923a",
          visible: true,
          description:
            "The population of this subgroup has a lower proportion of people who are of Pakistani ethnicity, but a higher proportion of Black ethnicity when compared with the group. Households are more likely to live in flats and to live in overcrowded conditions. People are more likely to work in the information and communication, and financial related industries."
        },
        {
          id: "4b1",
          parent: "4b",
          name: "Asian Terraces and Flats",
          colour: "#eda257",
          visible: true,
          description:
            "The population of this subgroup has a higher representation of people from Indian, Black and Chinese ethnic groups, and a higher proportion of residents born in the new EU countries. Households are more likely to live in flats."
        },
        {
          id: "4b2",
          parent: "4b",
          name: "Pakistani Communities",
          colour: "#efb274",
          visible: true,
          description:
            "A key distinguishing feature of this subgroup is the high proportion of people with Pakistani ethnicity, though residents are also more likely to have been born in the UK or Ireland, though less likely to speak English or Welsh as their main language. There is a slightly higher proportion of households who live in terraced housing, and also a higher proportion owning their property."
        },
        {
          id: "4c1",
          parent: "4c",
          name: "Achieving Minorities",
          colour: "#f2c291",
          visible: true,
          description:
            "The population of this subgroup has a higher proportion of people who have Pakistani ethnicity, and lower proportions with Chinese and Black ethnicity than the parent group. A lower proportion of residents were born in other EU countries. Households are more likely to live in detached and semi-detached properties, and to own their own property. Households are also less likely to live in overcrowded conditions."
        },
        {
          id: "4c2",
          parent: "4c",
          name: "Multicultural New Arrivals",
          colour: "#f4d1ae",
          visible: true,
          description:
            "The population of this subgroup has a higher representation of all non-White ethnic groups than the parent group. A higher proportion of residents were born in new EU countries and a higher proportion without English or Welsh as their main language. Households are more likely to live in terraced properties or flats and to live in overcrowded conditions."
        },
        {
          id: "4c3",
          parent: "4c",
          name: "Inner City Ethnic Mix",
          colour: "#f6e1cc",
          visible: true,
          description:
            "Compared with the parent group, there is a higher representation of persons of mixed ethnicity, but lower representation for the other non-White ethnic groups, and a lower proportion of people whose main language is not English or Welsh. When compared with the parent group, there is a lower proportion of households with non-dependent children, and households are more likely to live in flats."
        },
        {
          id: "5a1",
          parent: "5a",
          name: "White Professionals",
          colour: "#82431f",
          visible: true,
          description:
            "The population of this subgroup has a lower representation of all ethnic groups, other than White when compared with the parent group. Residents are less likely to have been born in other EU countries and more likely to have English or Welsh as their main language. Households are more likely to live in detached or semi-detached properties."
        },
        {
          id: "5a2",
          parent: "5a",
          name: "Multi-Ethnic Professionals with Families",
          colour: "#965e3e",
          visible: true,
          description:
            "The population of this subgroup has a higher representation of all non-White ethnic groups than the parent group, and in particular representation of persons with Indian or mixed ethnicity. Households are more likely to live in detached or semi-detached properties, and to live in socially rented accommodation. There is a higher proportion of people working in the information and communication, and financial related industries."
        },
        {
          id: "5a3",
          parent: "5a",
          name: "Families in Terraces and Flats ",
          colour: "#a9795e",
          visible: true,
          description:
            "When compared with the parent group, this subgroup has a higher proportion of households living in terraced properties or flats, with households more likely to rent their accommodation, either privately or socially. Households are more likely to live in overcrowded conditions than the parent group and less likely to have two or more cars."
        },
        {
          id: "5b1",
          parent: "5b",
          name: "Delayed Retirement",
          colour: "#bd947d",
          visible: true,
          description:
            "The population of this group shows a lower proportion of people aged 90 and over than the parent group, and households are more likely to live in flats, though are less likely to socially rent. There is a higher proportion of people who use public transport to get to work and they are more likely to work in the information and communication, and financial related industries."
        },
        {
          id: "5b2",
          parent: "5b",
          name: "Communal Retirement",
          colour: "#d0af9c",
          visible: true,
          description:
            "A distinguishing feature of this subgroup is the high proportion of people living in communal establishments. The population of this subgroup shows a higher proportion of people aged 90 and over than the parent group. There is also a higher proportion of households living in terraced properties than the parent group."
        },
        {
          id: "5b3",
          parent: "5b",
          name: "Self-Sufficient Retirement",
          colour: "#e4cabc",
          visible: true,
          description:
            "A lower proportion of people live in communal establishments than the parent group. Compared with the group a higher proportion of households live in terraced properties and households are more likely to live in socially rented accommodation."
        },
        {
          id: "6a1",
          parent: "6a",
          name: "Indian Tech Achievers",
          colour: "#84458e",
          visible: true,
          description:
            "All non-White ethnic groups are well represented in this subgroup in comparison to the parent group, people of Indian ethnicity being particularly well represented. There is a higher proportion of people born in other EU countries, and whose main language is not English or Welsh. Households are more likely to live in semi-detached properties. Compared with the parent group there is a higher proportion of people working in the information and communication, and financial related industries, and workers using public transport."
        },
        {
          id: "6a2",
          parent: "6a",
          name: "Comfortable Suburbia",
          colour: "#925a9b",
          visible: true,
          description:
            "The population of this group has a higher proportion of people aged 0 to 44 but a lower proportion aged 65 and over than the parent group. Households are less likely to live in semi-detached properties or flats, but more likely to live in detached or terraced properties."
        },
        {
          id: "6a3",
          parent: "6a",
          name: "Detached Retirement Living",
          colour: "#a16fa8",
          visible: true,
          description:
            "This subgroup has a higher proportion of people aged 65 to 89 than the parent group. There is a lower representation of all non-White ethnic groups in the subgroup. Households are more likely to live in semi-detached properties."
        },
        {
          id: "6a4",
          parent: "6a",
          name: "Ageing in Suburbia",
          colour: "#af84b6",
          visible: true,
          description:
            "The population of this subgroup has a higher proportion of people aged 65 and over than the parent group. A much higher proportion of households live in flats, and households are more likely to live in privately rented accommodation. Households are also more likely to live in overcrowded conditions."
        },
        {
          id: "6b1",
          parent: "6b",
          name: "Multi-Ethnic Suburbia",
          colour: "#bd99c3",
          visible: true,
          description:
            "All the non-White ethnic groups are represented more highly in this subgroup in comparison with the parent group. There are also higher proportions of people born in the new EU countries and people whose main language is not English or Welsh. Households are more likely to live in semi-detached properties and to live in overcrowded conditions. A higher proportion of workers use public transport to commute to work."
        },
        {
          id: "6b2",
          parent: "6b",
          name: "White Suburban Communities",
          colour: "#cbaed0",
          visible: true,
          description:
            "Ethnic group representation, including persons with White ethnicity, is very similar to the parent group. The population of this subgroup has a lower proportion of people aged 65 and over than the parent group. Households are more likely to live in detached or terraced properties, and to live in privately rented accommodation."
        },
        {
          id: "6b3",
          parent: "6b",
          name: "Semi-Detached Ageing",
          colour: "#dac3dd",
          visible: true,
          description:
            "This subgroup has a higher proportion of people aged 65 to 89 than the parent group. All non- White ethnic groups have a lower representation in this subgroup when compared with the parent group. A higher proportion of households live in semi-detached properties, and own their own property."
        },
        {
          id: "6b4",
          parent: "6b",
          name: "Older Workers and Retirement",
          colour: "#e8d8eb",
          visible: true,
          description:
            "There is a higher proportion of residents aged 65 to 89 within this subgroup than the parent group, and households are more likely to live in detached properties or flats. A higher proportion of households socially rent their accommodation, and a higher proportion are likely to live in overcrowded conditions."
        },
        {
          id: "7a1",
          parent: "7a",
          name: "Transitional Eastern European Neighbourhoods",
          colour: "#193b55",
          visible: true,
          description:
            "All non-White ethnic groups have a lower representation in this subgroup when compared with the parent group. A higher proportion of people were born in the new EU countries and there is a higher proportion whose main language is not English or Welsh. Households are more likely to live in detached properties and a greater proportion live in privately rented accommodation when compared with the parent group. People are more likely to work in the agriculture and manufacturing related industries, and to use private transport to get to work."
        },
        {
          id: "7a2",
          parent: "7a",
          name: "Hampered Aspiration",
          colour: "#2a4963",
          visible: true,
          description:
            "The population of this subgroup has a lower representation of people of mixed ethnicity or of Black ethnicity when compared with the parent group. A higher proportion of households live in terraced houses and in privately rented accommodation when compared with the parent group. A higher proportion of people work in the information and communication, financial, and public administration related industries."
        },
        {
          id: "7a3",
          parent: "7a",
          name: "Multi-Ethnic Hardship",
          colour: "#3a5870",
          visible: true,
          description:
            "The age make-up of this subgroup is higher in the 5 to 14 age group when compared with the parent group. Whilst there are higher proportions of people of mixed or Black ethnicity, all ethnic groups are well represented, though a lower proportion of people were born in other EU countries. Households were more likely to live in semi-detached properties and were more likely to live in socially rented accommodation. Workers were more likely to be employed in transport or storage industries."
        },
        {
          id: "7b1",
          parent: "7b",
          name: "Eastern European Communities",
          colour: "#4b677d",
          visible: true,
          description:
            "The population of this group has a higher proportion of people aged 0 to 14 than the parent group and a lower proportion aged 65 and over. A higher proportion of people were born in the new EU countries. There is also a higher proportion of people whose main language is not English or Welsh. Households are more likely to live in socially rented accommodation, with workers more likely to be employed in manufacturing industries."
        },
        {
          id: "7b2",
          parent: "7b",
          name: "Deprived Neighbourhoods",
          colour: "#5b758b",
          visible: true,
          description:
            "The age structure for this subgroup is very similar to the parent group, with a lower proportion of people born in the new EU countries, and whose main language is not English or Welsh. Households are less likely to own their property and more likely to socially rent. There is a higher proportion of unemployed people."
        },
        {
          id: "7b3",
          parent: "7b",
          name: "Endeavouring Flat Dwellers",
          colour: "#6c8498",
          visible: true,
          description:
            "In this subgroup there is a lower proportion of people born in new EU countries. There is also a lower proportion of people whose main language is not English or Welsh. In comparison with the parent group households are more likely to have no children, and to own their accommodation, but they are also more likely to be living in privately rented accommodation. People are less likely to be unemployed and there is a higher proportion of people working in the information and communication, finance and public administration, and education related sectors."
        },
        {
          id: "7c1",
          parent: "7c",
          name: "Challenged Transitionaries",
          colour: "#7c93a5",
          visible: true,
          description:
            "Households in this subgroup are more likely to live in terraced properties than those in the parent group. There is a lower proportion social renting and a higher proportion renting privately compared with the parent group. People are less likely to be unemployed and are more likely to work in the information and communication, and financial related industries."
        },
        {
          id: "7c2",
          parent: "7c",
          name: "Constrained Young Families",
          colour: "#8da2b3",
          visible: true,
          description:
            "The population of this subgroup shows a higher proportion of people aged 0 to 14 than the parent group and a lower proportion of families with no children. Households are more likely to live in terraced properties and to socially rent their accommodation. There is a higher proportion of people who are unemployed and those in employment are more likely to work in the accommodation or food service activities industries."
        },
        {
          id: "7c3",
          parent: "7c",
          name: "Outer City Hardship",
          colour: "#9db0c0",
          visible: true,
          description:
            "The population of this subgroup has a higher proportion of people aged 65 and over when compared with the parent group. Households are more likely to live in detached and semi- detached properties, and private renting is slightly more prevalent than the parent group. There is a higher proportion of households with two or more cars and a lower proportion of people who use public transport to get to work."
        },
        {
          id: "7d1",
          parent: "7d",
          name: "Ageing Communities and Families",
          colour: "#aebfcd",
          visible: true,
          description:
            "The age profile of this subgroup shows a higher proportion of people aged 0 to 14 and a lower proportion aged 90 and over. People are less likely to live in communal establishments than the parent group. There is a higher proportion of households living in detached and semi-detached properties, and households owning their own property. Households are more likely to have two or more cars. There is a higher proportion of people working in the information and communication, and education related sectors."
        },
        {
          id: "7d2",
          parent: "7d",
          name: "Retired Independent City Dwellers",
          colour: "#becedb",
          visible: true,
          description:
            "When compared with the parent group there is a higher proportion of households living in flats, and in socially rented accommodation. There is a lower proportion of households with two or more cars, but higher unemployment amongst residents."
        },
        {
          id: "7d3",
          parent: "7d",
          name: "Retired Communal City Dwellers",
          colour: "#cfdde8",
          visible: true,
          description:
            "The population of this subgroup shows a lower proportion of people aged 0 to 14 than the parent group but a higher proportion aged 90 and over. A much higher proportion of people live in communal establishments. When compared with the parent group a higher proportion of households live in detached, semi-detached or terraced properties, privately rent their accommodation, and have two or more cars."
        },
        {
          id: "7d4",
          parent: "7d",
          name: "Retired City Hardship",
          colour: "#dfebf6",
          visible: true,
          description:
            "The age profile of this subgroup shows a higher proportion of the population aged 65 and over, though residents are less likely to live in communal establishments or to be single. There is a lower proportion of households with no children and non-dependent children. Households are more likely to live in flats, and more likely to rent socially. People in this subgroup are less likely to have qualifications, and due to the age structure are less likely to work in any of the identified industry categories."
        },
        {
          id: "8a1",
          parent: "8a",
          name: "Industrious Transitions",
          colour: "#a37d00",
          visible: true,
          description:
            "The subgroup is broadly similar to the parent group in terms of age groups and ethnic diversity. Compared with the parent group, social renting is less common while a higher proportion of residents live in detached properties. This subgroup exhibits slightly higher proportions of residents in the information and communication, and financial related industries, together with slightly higher levels of educational qualifications."
        },
        {
          id: "8a2",
          parent: "8a",
          name: "Industrious Hardship",
          colour: "#ae8b18",
          visible: true,
          description:
            "Age structure and ethnicity for this subgroup are consistent with the parent group. This subgroup has a higher proportion of residents who live in semi-detached properties, and social renting is more common, with marginally higher overcrowding. There is a smaller proportion of people in the information and communication, and financial related industries, and slightly higher levels of unemployment"
        },
        {
          id: "8b1",
          parent: "8b",
          name: "Deprived Blue-Collar Terraces",
          colour: "#b89831",
          visible: true,
          description:
            "Whilst for this subgroup, the age structure is broadly similar to the parent group, it is characterised by a higher degree of non-White ethnic group representation, and higher levels of households in private rented accommodation. There are also marginally higher levels of educational qualifications with employment in the information and communication, financial, and education sectors more prevalent than with the parent group."
        },
        {
          id: "8b2",
          parent: "8b",
          name: "Hard-Pressed Rented Terraces",
          colour: "#c2a649",
          visible: true,
          description:
            "For this subgroup, age structure and representation of ethnic groups are broadly similar to the parent group. There is a higher proportion of households living in semi-detached properties, and a noticeably higher proportion living in socially rented accommodation. There is also a slightly higher use of public transport for commuting to work."
        },
        {
          id: "8c1",
          parent: "8c",
          name: "Ageing Industrious Workers",
          colour: "#ccb362",
          visible: true,
          description:
            "This subgroup is characterised by a slightly older age profile, and a higher proportion of mixed ethnicity of the residents compared with the parent group. Households are more likely to live in detached properties, and to live in privately rented accommodation. Residents have higher educational qualifications, whilst workers are more likely to be employed in the information and communication, and financial related industries."
        },
        {
          id: "8c2",
          parent: "8c",
          name: "Ageing Rural Industry Workers",
          colour: "#d6c17a",
          visible: true,
          description:
            "The age structure is very similar to the parent group, though there is lower non-White ethnic group representation. Households are slightly more likely to live detached or terraced housing, and to be living in private rented accommodation when compared with the parent group. This subgroup contains considerably higher proportions of workers in agriculture, forestry and fishing occupations than the parent group, with a higher proportion of people walking and/or cycling to work."
        },
        {
          id: "8c3",
          parent: "8c",
          name: "Renting Hard-Pressed Workers",
          colour: "#e1ce92",
          visible: true,
          description:
            "This subgroup has smaller proportions of older residents, and ethnic group representation is reasonably similar to the parent group. A higher proportion of households live in semi-detached properties and flats compared with the parent group, and households are more likely to live in socially rented accommodation. Household overcrowding and unemployment is marginally higher than the parent group. Workers are more likely to work in transport or storage industries, and to use public transport to travel to work."
        },
        {
          id: "8d1",
          parent: "8d",
          name: "Young Hard-Pressed Families",
          colour: "#ebdcab",
          visible: true,
          description:
            "The age structure for this subgroup largely reflects the parent group. There is a lower non-White ethnic group representation, particularly for people of mixed ethnicity. Households in socially rented accommodation are more prevalent than with the parent group, and unemployment is higher. Certain industries such as the information and communication related industries are underrepresented with this subgroup."
        },
        {
          id: "8d2",
          parent: "8d",
          name: "Hard-Pressed Ethnic Mix",
          colour: "#f5e9c3",
          visible: true,
          description:
            "This subgroup is generally characterised by higher levels of non-White ethnic group representation, particularly of persons of mixed and Black ethnicity, together with an older age structure than for the parent group. Households are more likely to live in flats, and more likely to own their own property. There are also a higher proportion of workers in the information and communication, and financial related industries, whilst unemployment is marginally lower than for the parent group."
        },
        {
          id: "8d3",
          parent: "8d",
          name: "Hard-Pressed European Settlers",
          colour: "#fff7dc",
          visible: true,
          description:
            "The key characteristic of this subgroup is the higher proportion of residents who were born in new EU countries compared with the parent group, and a younger age structure. Main language not English or Welsh is also more prevalent. Households in this subgroup are more likely to live in privately rented accommodation when compared with the parent group. Unemployment is marginally lower than for the parent group, with those in employment more likely to travel to work by walking or cycling, and employed in agriculture and manufacturing related industries."
        }
      ]
    }
  ]
};
