const cols = "the_geom, the_geom_webmercator, rcid, rcname, groupid, typeid";

export const USRETAILCENTRES = {
  title: "US Retail Centres",
  description:
    "A map and classification of 10956 retail centres across the United States, based on 2021 retailer location data supplied by SafeGraph Inc.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/us-retail-centre-boundaries-and-classification",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM us_retailcentres",
  buildingSql: "SELECT " + cols + " FROM us_retailcentres",
  detailUrbanSql: "SELECT " + cols + " FROM us_retailcentres",
  urbanSql: "SELECT " + cols + " FROM us_retailcentres",
  defaultLayers: "1111010",
  extent: 6,
  border: true,
  popup: true,
  popupType: "classification",
  idField: "rcid",
  geogNameField: "rcname",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.groupid = '1' then 1 else 0 end) as count_1,
      sum(case when d.groupid = '2' then 1 else 0 end) as count_2,
      sum(case when d.groupid = '3' then 1 else 0 end) as count_3,
      sum(case when d.groupid = '4' then 1 else 0 end) as count_4,
      sum(case when d.typeid = '1.1' then 1 else 0 end) as count_1_1,
      sum(case when d.typeid = '1.2' then 1 else 0 end) as count_1_2,
      sum(case when d.typeid = '1.3' then 1 else 0 end) as count_1_3,
      sum(case when d.typeid = '1.4' then 1 else 0 end) as count_1_4,
      sum(case when d.typeid = '2.1' then 1 else 0 end) as count_2_1,
      sum(case when d.typeid = '2.2' then 1 else 0 end) as count_2_2,
      sum(case when d.typeid = '3.1' then 1 else 0 end) as count_3_1,
      sum(case when d.typeid = '3.2' then 1 else 0 end) as count_3_2,
      sum(case when d.typeid = '3.3' then 1 else 0 end) as count_3_3,
      sum(case when d.typeid = '3.4' then 1 else 0 end) as count_3_4,
      sum(case when d.typeid = '4.1' then 1 else 0 end) as count_4_1,
      sum(case when d.typeid = '4.2' then 1 else 0 end) as count_4_2,
      sum(case when d.typeid = '4.3' then 1 else 0 end) as count_4_3,
      sum(case when d.typeid = '4.4' then 1 else 0 end) as count_4_4
  FROM oliverobrien.us_retailcentres d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Group",
      field: "groupid",
      lookup: "id",
      groups: [
        {
          id: "1",
          name: "Major urban centres & established destinations",
          colour: "#ffff00",
          visible: true,
          description: ""
        },
        {
          id: "2",
          name: "Small city, town and primary neighbourhood centres",
          colour: "#ff0000",
          visible: true,
          description: ""
        },
        {
          id: "3",
          name: "Comparison/multipurpose shopping destinations",
          colour: "#ff00ff",
          visible: true,
          description: ""
        },
        {
          id: "4",
          name: "'Everyday' convenience and service retail centres",
          colour: "#0044ff",
          visible: true,
          description: ""
        }
      ]
    },
    {
      id: 1,
      name: "Type",
      field: "typeid",
      lookup: "id",
      // prettier-ignore
      groups: [
		{ id: "1.1", parent: "1", name: "Metropolitan/primary urban centres", colour: "#ffff00", visible: true, description: "" },
		{ id: "1.2", parent: "1", name: "Secondary metropolitan centres and iconic shopping districts", colour: "#dddd00", visible: true, description: "" },
		{ id: "1.3", parent: "1", name: "Inner city food/drink destinations", colour: "#bbbb00", visible: true, description: "" },
		{ id: "1.4", parent: "1", name: "Multipurpose food/drink destinations", colour: "#999900", visible: true, description: "" },
		{ id: "2.1", parent: "2", name: "Small city, primary neighbourhood cores & secondary food/drink destinations", colour: "#ff0000", visible: true, description: "" },
		{ id: "2.2", parent: "2", name: "Neighbourhood specialist service centres", colour: "#ff6666", visible: true, description: "" },
		{ id: "3.1", parent: "3", name: "Large, popular multipurpose destinations", colour: "#ff00ff", visible: true, description: "" },
		{ id: "3.2", parent: "3", name: "Premium outlets and leading fashion destinations", colour: "#ff33ff", visible: true, description: "" },
		{ id: "3.3", parent: "3", name: "Secondary fashion shopping destinations", colour: "#ff66ff", visible: true, description: "" },
		{ id: "3.4", parent: "3", name: "Off-price, non-specialist comparison destinations", colour: "#ff99ff", visible: true, description: "" },
		{ id: "4.1", parent: "4", name: "Affluent 'everyday' centres", colour: "#0044ff", visible: true, description: "" },
		{ id: "4.2", parent: "4", name: "Large/diverse 'everyday' centres", colour: "#3377ff", visible: true, description: "" },
		{ id: "4.3", parent: "4", name: "Popular discount convenience centres", colour: "#66aaff", visible: true, description: "" },
		{ id: "4.4", parent: "4", name: "Secondary discount convenience centres", colour: "#99ddff", visible: true, description: "" }
     ]
    }
  ]
};
