const cols = "the_geom_webmercator, rc_id, name_pretty, typ22_sgcd, typ22_gcd";

export const RETAILTYPE = {
  title: "Retail Centre Typology",
  description:
    "A multidimensional taxonomy of retail and consumption spaces in the UK focussing on four domains: the composition, diversity, function and economic health of the centres.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/retail-centre-boundaries-and-open-indicators",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM retailcentreboundaries",
  buildingSql: "SELECT " + cols + " FROM retailcentreboundaries",
  detailUrbanSql: "SELECT " + cols + " FROM retailcentreboundaries",
  urbanSql: "SELECT " + cols + " FROM retailcentreboundaries",
  defaultLayers: "1111000",
  extent: 0,
  border: true,
  popup: true,
  popupType: "classification",
  idField: "rc_id",
  geogNameField: "name_pretty",
  geogNamePrefix: "",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.typ22_sgcd = '1' then 1 else 0 end) as count_1,
      sum(case when d.typ22_sgcd = '2' then 1 else 0 end) as count_2,
      sum(case when d.typ22_sgcd = '3' then 1 else 0 end) as count_3,
      sum(case when d.typ22_sgcd = '4' then 1 else 0 end) as count_4,
      sum(case when d.typ22_gcd = '1.1' then 1 else 0 end) as count_1_1,
      sum(case when d.typ22_gcd = '1.2' then 1 else 0 end) as count_1_2,
      sum(case when d.typ22_gcd = '2.1' then 1 else 0 end) as count_2_1,
      sum(case when d.typ22_gcd = '2.2' then 1 else 0 end) as count_2_2,
      sum(case when d.typ22_gcd = '3.1' then 1 else 0 end) as count_3_1,
      sum(case when d.typ22_gcd = '3.2' then 1 else 0 end) as count_3_2,
      sum(case when d.typ22_gcd = '4.1' then 1 else 0 end) as count_4_1,
      sum(case when d.typ22_gcd = '4.2' then 1 else 0 end) as count_4_2
  FROM oliverobrien.retailcentreboundaries d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Supergroup",
      field: "typ22_sgcd",
      lookup: "id",
      groups: [
        {
          id: "1",
          name: "Local 'everyday' goods & service centres",
          colour: "#00ff44",
          visible: true,
          description:
            "Predominantly local and retail service centres, just under half of which are located in Greater London. They are often linear and provide a generally high independent offer, focusing on ‘everyday’ goods (e.g. groceries, confectionary), and offering a diverse range of services, with limited provision of comparison retail. They are typically characterised by higher proportions of convenience retail, business services, have seen recent increases and relatively high vacancy rates, and are typically comprised of higher average numbers of pawnbrokers, betting shops and fast food/takeaway outlets."
        },
        {
          id: "2",
          name: "Retail & shopping parks",
          colour: "#ff9900",
          visible: true,
          description:
            "A very distinctive cluster of typically out-of-town retail developments, occupied by high proportions of ‘clone’ retailers and anchor stores. These centres specialise in mass comparison retail goods, offer very limited business and consumer services, convenience retail and generally have a low vacancy rate. They are more attractive than most other retail centres, providing free car parking, and attracting typically car borne consumers from a much wider area."
        },
        {
          id: "3",
          name: "Leading comparison & leisure destinations",
          colour: "#ff0000",
          visible: true,
          description:
            "These are the main retail destinations of regional and sub-regional importance, and also generally including the larger market towns. These large, expansive and highly attractive centres have large catchment areas and have diverse and comprehensive retail, leisure and hospitality offers, comprising large numbers of national chains. They have smaller proportions of independent retailers, less spatial competition than other centres, but have experienced relatively high increases in vacancy rates."
        },
        {
          id: "4",
          name: "Traditional high streets & market towns",
          colour: "#ffff00",
          visible: true,
          description:
            "These are highly diverse but traditional high streets and market towns, which focus on hospitality, services, convenience goods and non-fashion comparison goods. These centres are characterised by high proportion of independent retailers, low vacancy rates, with typically low levels of premium, mass and value retailers. The spatial competition is relatively low and their catchments are characterised by low levels of deprivation."
        },
        {
          id: "",
          name: "Unclassified",
          colour: "#aaaaaa"
        }
      ]
    },
    {
      id: 1,
      name: "Group",
      field: "typ22_gcd",
      lookup: "id",
      groups: [
        {
          id: "1.1",
          parent: "1",
          name: "Local urban convenience centres",
          colour: "#99ffbb",
          visible: true,
          description:
            "Retail centres in this group comprise a high number of food retailers, convenience stores and newsagents, DIY and household goods and household services. They are typically occupied by large numbers of independents, with notable absence of mass and 'clone' retailers, and low levels of competition. These centres are located in more deprived neighbourhoods, experiencing higher than average levels of vacancy."
        },
        {
          id: "1.2",
          parent: "1",
          name: "District & urban service centres",
          colour: "#00ff44",
          visible: true,
          description:
            "Retail centres in this group also comprise a high number of independent and small multiple retailers and service providers. Typically there is a high proportion of food retailers, confectionary tobacconists and newsagents. Pawnbrokers and value retailers are much more common, but catchment deprivation is typically lower than 1.1. The ratio of retailers to service providers is typically much lower in this group, and vacancy rate is on an increase."
        },
        {
          id: "2.1",
          parent: "2",
          name: "Primary shopping centres & premium destinations",
          colour: "#ff9900",
          visible: true,
          description:
            "This group comprises many of the major out-of-town shopping centres, which typically have a more diverse offer, higher attractiveness scores and larger than average catchments. They consist of a fashion dominant and diverse comparison retail offering, with the largest numbers of premium brands and high proportions of clone retailers. These centres experience low levels of vacancy and contain very few independent, charity and value retailers."
        },
        {
          id: "2.2",
          parent: "2",
          name: "Secondary retail parks & shopping centres",
          colour: "#ffb84e",
          visible: true,
          description:
            "This group comprises many of the smaller retail parks and secondary shopping centres. They have a broad offer including mass and chain fashion retail, as well as an abundance of stores selling recreational, electrical and DIY goods. Typically anchored by a major fashion retailer, these centres have low vacancy rates and low levels of retail turnover."
        },
        {
          id: "3.1",
          parent: "3",
          name: "Large regional retail & leisure destinations",
          colour: "#ff0000",
          visible: true,
          description:
            "This group comprises the main regional retail and leisure destinations, often located at the cores of major cities. Centres are very large, expansive and highly attractive, with a highly diverse retail offering, but notable presence of premium brands, concessions and anchor stores, and large numbers of pubs, bars and nightclubs. Their catchment areas are very extensive with large numbers of people living near to these centres and there is high proportion of fashion and clone retailers. Catchment deprivation is low, with a small proportion of DIY, food, charity retailers, consumer services and retail change, but relatively high levels of vacancy."
        },
        {
          id: "3.2",
          parent: "3",
          name: "Sub-regional retail & leisure destinations",
          colour: "#ff6666",
          visible: true,
          description:
            "This group comprises many of the sub-regional retail destinations. They are smaller and less expansive than centres in 3.1, but are still characterised by a diverse retail and leisure offering, and are highly attractive. These centres contain a notably lower proportion of anchors, concessions and premium brands, instead containing higher proportions of charity shops and value retailers, experiencing higher than average increases in vacancy change . Their catchment areas are also large and spatial competition is relatively low."
        },
        {
          id: "4.1",
          parent: "4",
          name: "Mass & value traditional high streets",
          colour: "#bbbb00",
          visible: true,
          description:
            "These centres are typically high streets in relatively affluent neighbourhoods, comprising a ‘typical’ retail offering of small multiples and ‘clone’ retailers and consumer health and beauty services. Charity shops, value and mass retailers and pawnbrokers are more common in these retail centres, and vacancy rates are higher than average for the supergroup 4. They have relatively small catchments, and competition with other centres is low."
        },
        {
          id: "4.2",
          parent: "4",
          name: "'Indie' traditional high streets",
          colour: "#ffff00",
          visible: true,
          description:
            "These centres are typically high streets in more affluent neighbourhoods than 4.1, comprising a more unique retail, service and entertainment offering with greater proportions of independent retailers. There is a good variety of services and comparison goods in these centres, although retail change is notably high. Similar to 4.1, their catchments are small and competition is low, however vacancy rates are much lower in these centres."
        }
      ]
    }
  ]
};
