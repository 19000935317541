<template>
  <div class="map-layer-control d-none d-sm-block">
    <h5 v-b-toggle.layer-control-collapse tabindex="0" role="button">
      <strong>Map Layers</strong>
      <font-awesome-icon
        :icon="['fas', 'compress-alt']"
        class="opened text-secondary ml-1"
        style="float: right;"
      />
      <font-awesome-icon
        :icon="['fas', 'expand-alt']"
        class="closed text-secondary ml-1"
        style="float: right;"
      />
    </h5>
    <b-collapse id="layer-control-collapse" :visible="embed < 2">
      <b-form-checkbox
        id="checkbox-region"
        v-model="region"
        name="checkbox-region"
        value="visible"
        switch
        unchecked-value="none"
        v-on:change="checkboxChange('region', $event)"
      >
        {{
          type == "London"
            ? "Greater London"
            : type == "USA"
            ? "State"
            : "Region"
        }}
      </b-form-checkbox>
      <b-form-checkbox
        id="checkbox-lad"
        v-model="lad"
        name="checkbox-lad"
        value="visible"
        switch
        unchecked-value="none"
        v-on:change="checkboxChangeHide('lad', 'pcon', $event)"
      >
        {{
          type == "London"
            ? "London Borough"
            : type == "USA"
            ? "County"
            : "Local Authority"
        }}
      </b-form-checkbox>
      <b-form-checkbox
        v-if="type !== 'London' && type !== 'USA'"
        id="checkbox-combined"
        v-model="combined"
        name="checkbox-combined"
        value="visible"
        switch
        unchecked-value="none"
        v-on:change="checkboxChange('combined', $event)"
      >
        Combined Authority
      </b-form-checkbox>
      <b-form-checkbox
        v-if="type !== 'USA'"
        id="checkbox-pcon"
        v-model="pcon"
        name="checkbox-pcon"
        value="visible"
        switch
        unchecked-value="none"
        v-on:change="checkboxChangeHide('pcon', 'lad', $event)"
      >
        Constituency
      </b-form-checkbox>
      <hr class="m-2" />
      <b-form-checkbox
        v-if="type !== 'USA'"
        id="checkbox-retail"
        v-model="retail"
        name="checkbox-retail"
        value="visible"
        switch
        unchecked-value="none"
        v-on:change="checkboxChange('retail', $event)"
      >
        Retail Centres
      </b-form-checkbox>
      <hr class="m-2" />
      <b-form-checkbox
        id="checkbox-labels"
        v-model="labels"
        name="checkbox-labels"
        value="visible"
        switch
        unchecked-value="none"
        v-on:change="checkboxChange('name', $event)"
      >
        Place Names
      </b-form-checkbox>
      <b-form-checkbox
        id="checkbox-details"
        v-model="details"
        name="checkbox-details"
        value="visible"
        switch
        unchecked-value="none"
        v-on:change="checkboxChange('details', $event)"
      >
        Map details
      </b-form-checkbox>
      <b-form-checkbox
        id="checkbox-land"
        v-model="land"
        name="checkbox-land"
        value="1"
        switch
        unchecked-value="0"
        v-on:change="checkboxChange('land', $event)"
      >
        Urban / Buildings
      </b-form-checkbox>
      <slot />
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "MapLayerControl",
  props: {
    landVisibility: String,
    type: String,
    labelsVisibility: String,
    detailsVisibility: String,
    regionVisibility: String,
    ladVisibility: String,
    combinedVisibility: String,
    pconVisibility: String,
    retailVisibility: String,
    embed: Number
  },
  data() {
    return {
      land: this.landVisibility,
      details: this.detailsVisibility,
      labels: this.labelsVisibility,
      lad: this.ladVisibility,
      region: this.regionVisibility,
      combined: this.combinedVisibility,
      pcon: this.pconVisibility,
      retail: this.retailVisibility
    };
  },
  methods: {
    checkboxChange: function(layer, visibility) {
      this.$gtag.event(layer, {
        event_category: "layer",
        value: visibility
      });

      this.$emit("change", {
        layer: layer,
        visibility: visibility
      });
    },
    checkboxChangeHide: function(layer, hideLayer, visibility) {
      this.$gtag.event(layer, {
        event_category: "layer",
        value: visibility
      });

      this.$emit("change", {
        layer: layer,
        visibility: visibility
      });

      this.$emit("change", {
        layer: hideLayer,
        visibility: "none"
      });

      this[hideLayer] = false;
    }
  }
};
</script>

<style scoped>
.map-layer-control {
  position: absolute;
  bottom: 0px;
  left: 0;
  background: #fff;
  margin: 10px;
  overflow: auto;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  max-height: 40%;
  overflow: auto;
  z-index: 3; /* above mapbox controls */
}
.collapsed > .opened,
:not(.collapsed) > .closed {
  display: none;
}

h5:focus {
  outline: none;
}
</style>
