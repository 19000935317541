<template>
  <div class="legend mt-2">
    <div
      v-for="group in hierarchy[0].groups"
      :key="group.id"
      class="legend-item"
      v-b-toggle="'legend-accordion-' + group.id"
      tabindex="0"
      role="button"
      style="text-indent: -35px; margin-left: 35px;"
    >
      <font-awesome-icon
        :icon="['fas', 'minus-square']"
        class="opened text-secondary"
        style="float: right;"
        v-if="selectedHierarchy > 0 && !hierarchy[0].hideExpand"
      />
      <font-awesome-icon
        :icon="['fas', 'plus-square']"
        class="closed text-secondary"
        style="float: right;"
        v-if="selectedHierarchy > 0 && !hierarchy[0].hideExpand"
      />
      <span class="legend-key" v-bind:style="{ backgroundColor: group.colour }">
      </span>
      <small>{{ group.name }}</small>

      <b-collapse
        accordion="legend-accordion"
        :id="'legend-accordion-' + group.id"
        visible
        v-if="selectedHierarchy > 0 && !hierarchy[0].hideExpand"
        class="ml-2 mt-0 mb-1"
      >
        <div
          v-for="subgroup in hierarchy[1].groups.filter(function(element) {
            return element.parent == group.id;
          })"
          :key="subgroup.id"
          class="legend-item"
          v-b-toggle="'legend-accordion-' + group.id + '-' + subgroup.id"
          tabindex="1"
          role="button"
          @click.stop
        >
          <font-awesome-icon
            :icon="['fas', 'minus-square']"
            class="opened text-secondary"
            style="float: right;"
            v-if="selectedHierarchy > 1"
          />
          <font-awesome-icon
            :icon="['fas', 'plus-square']"
            class="closed text-secondary"
            style="float: right;"
            v-if="selectedHierarchy > 1"
          />
          <span
            class="legend-key"
            v-bind:style="{ backgroundColor: subgroup.colour }"
          >
          </span>
          <small>{{ subgroup.name }}</small>

          <b-collapse
            accordion="'legend-accordion-' + group.id"
            :id="'legend-accordion-' + group.id + '-' + subgroup.id"
            visible
            v-if="selectedHierarchy > 1"
            class="ml-2 mt-0 mb-1"
          >
            <div
              v-for="subsubgroup in hierarchy[2].groups.filter(function(
                element
              ) {
                return element.parent == subgroup.id;
              })"
              :key="subsubgroup.id"
              class="legend-item"
              @click.stop
            >
              <span
                class="legend-key"
                v-bind:style="{ backgroundColor: subsubgroup.colour }"
              >
              </span>
              <small>{{ subsubgroup.name }}</small>
            </div>
          </b-collapse>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapGroupLegend",
  props: { selectedHierarchy: Number, hierarchy: Array },
  components: {},
  methods: {}
};
</script>

<style scoped>
.legend-key {
  display: inline-block;
  border-radius: 20%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.legend-item.inactive {
  opacity: 0.25;
}

.legend-item:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

.collapsed > .opened,
:not(.collapsed) > .closed {
  display: none;
}
</style>
