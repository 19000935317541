import { RESIDENTIALMOVES } from "@/config/ResidentialMoves";

const RESIDENTIALMOVESLSOA = { ...RESIDENTIALMOVES };

const cols =
  "a.the_geom_webmercator, lad_code, lsoa11_nm, lsoa11_cd, " +
  "remolaavep, remolaavpe, remolaavar, remolamepc, remolampep, remolamare, " +
  "remilaavep, remilaavpe, remilaavar, remilamepc, remilampep, remilamare, " +
  "rdinla04, rdinla09, rdinla14, rdinla19, rdinla23, " +
  "rdoula04, rdoula09, rdoula14, rdoula19, rdoula23, " +
  "doinla04, doinla09, doinla14, doinla19, doinla23";

const jointablesql =
  " a, safeguarded_rm_lsoa11_combined b where a.lsoa11_cd = b.lsoa11";

RESIDENTIALMOVESLSOA.url =
  "https://cdrcuser.carto.com/api/v1/map?api_key=default_public";
RESIDENTIALMOVESLSOA.sql = "SELECT " + cols + " FROM lsoa11_uk" + jointablesql;
RESIDENTIALMOVESLSOA.buildingSql =
  "SELECT " + cols + " FROM lsoa11_uk_buildings" + jointablesql;
RESIDENTIALMOVESLSOA.detailUrbanSql =
  "SELECT " + cols + " FROM lsoa11_uk_detail_urban" + jointablesql;
RESIDENTIALMOVESLSOA.urbanSql =
  "SELECT " + cols + " FROM lsoa11_uk_urban" + jointablesql;
RESIDENTIALMOVESLSOA.initialValue = 15;
RESIDENTIALMOVESLSOA.idField = "lsoa11_cd";
RESIDENTIALMOVESLSOA.geogNameField = "lsoa11_nm";
RESIDENTIALMOVESLSOA.ladCodeField = "lad_code";

export { RESIDENTIALMOVESLSOA };
