const cols =
  "the_geom_webmercator, lsoa11_cd, lsoa11_nm, lad_code, " +
  "chn2011, chn2012, chn2013, chn2014, chn2015, chn2016, chn2017, chn2018, chn2019, chn2020, chn2021, chn2022";

const legendStops = [
  {
    id: 0,
    stops: [
      { value: 0, colour: "#313695", name: "Change of 0 - 5%" },
      { value: 0.05, colour: "#4575b4", name: "5 - 10%" },
      { value: 0.1, colour: "#74add1", name: "10 - 15%" },
      { value: 0.15, colour: "#abd9e9", name: "15 - 20%" },
      { value: 0.2, colour: "#e0f3f8", name: "20 - 25%" },
      { value: 0.25, colour: "#fee090", name: "25 - 30%" },
      { value: 0.3, colour: "#fdae61", name: "30 - 35%" },
      { value: 0.35, colour: "#f46d43", name: "35 - 40%" },
      { value: 0.4, colour: "#d73027", name: "40 - 45%" },
      { value: 0.45, colour: "#a50026", name: "45 - 100%" }
    ]
  }
];

export const CHURN = {
  title: "Residential Mobility Index (Churn)",
  description:
    "An estimate of the proportion of households that have changed between the end of the year selected and early 2023.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/cdrc-residential-mobility-index",
  storyLinks: [
    {
      link: "https://data.cdrc.ac.uk/story/churn",
      title:
        "New data reveal substantial neighbourhood changes since the last census"
    }
  ],
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM lsoa11_uk",
  buildingSql: "SELECT " + cols + " FROM lsoa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM lsoa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM lsoa11_uk_urban",
  defaultLayers: "1111000",
  initialValue: 10,
  extent: 2,
  popup: false,
  idField: "lsoa11_cd",
  geogNameField: "lsoa11_nm",
  ladCodeField: "lad_code",
  hoverFieldMetricMode: "All",
  metrics: [
    {
      id: 0,
      name: "2011 to 2023",
      field: "chn2011",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 1,
      name: "2012 to 2023",
      field: "chn2012",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 2,
      name: "2013 to 2023",
      field: "chn2013",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 3,
      name: "2014 to 2023",
      field: "chn2014",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 4,
      name: "2015 to 2023",
      field: "chn2015",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 5,
      name: "2016 to 2023",
      field: "chn2016",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 6,
      name: "2017 to 2023",
      field: "chn2017",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 7,
      name: "2018 to 2023",
      field: "chn2018",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 8,
      name: "2019 to 2023",
      field: "chn2019",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 9,
      name: "2020 to 2023",
      field: "chn2020",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 10,
      name: "2021 to 2023",
      field: "chn2021",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 11,
      name: "2022 to 2023",
      field: "chn2022",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    }
  ]
};
