import colorbrewer from "colorbrewer";

const cols =
  "the_geom_webmercator, lsoa11_cd, lsoa11_nm, lad_code, " +
  "imde19_rk, imde15_rk, imde10_rk, imds20_rk, imds16_rk, imdw19_rk, imdw14_rk, " +
  "imde19_inc, imde19_emp, imde19_edu, imde19_hdd, imde19_cri, imde19_bhs, imde19_env, " +
  "imde19_idc, imde19_ido, imde19_cyp, imde19_gb, imde19_wb, imde19_ind, imde19_out, " +
  "imde15_inc, imde15_emp, imde15_edu, imde15_hdd, imde15_cri, imde15_bhs, imde15_env, " +
  "imde15_idc, imde15_ido, imde15_cyp, imde15_gb, imde15_wb, imde15_ind, imde15_out, " +
  "imds20_inc, imds20_emp, imds20_hea, imds20_edu, imds20_acc, imds20_cri, imds16_inc, " +
  "imds16_emp, imds16_hea, imds16_edu, imds16_acc, imds16_cri";

const ramp = colorbrewer.RdBu[11];

const rowcountEng = 32844;
const rowcountSco = 6976;
const rowcountWal = 1909;
//const rowcountNIr = 890;

const legendStopsEng = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: ramp[0], name: "20%+ rank increase" },
      {
        value: -rowcountEng * 0.2,
        colour: ramp[1],
        name: "16-20% rank increase"
      },
      {
        value: -rowcountEng * 0.16,
        colour: ramp[2],
        name: "12-16% rank increase"
      },
      {
        value: -rowcountEng * 0.12,
        colour: ramp[3],
        name: "8-12% rank increase"
      },
      {
        value: -rowcountEng * 0.08,
        colour: ramp[4],
        name: "4-8% rank increase"
      },
      {
        value: -rowcountEng * 0.04,
        colour: ramp[5],
        name: "No significant change"
      },
      {
        value: rowcountEng * 0.04,
        colour: ramp[6],
        name: "4-8% rank decrease"
      },
      {
        value: rowcountEng * 0.08,
        colour: ramp[7],
        name: "8-12% rank decrease"
      },
      {
        value: rowcountEng * 0.12,
        colour: ramp[8],
        name: "12-16% rank decrease"
      },
      {
        value: rowcountEng * 0.16,
        colour: ramp[9],
        name: "16-20% rank decrease"
      },
      { value: rowcountEng * 0.2, colour: ramp[10], name: "20%+ rank decrease" }
    ]
  }
];

const legendStopsSco = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: ramp[0], name: "20%+ rank increase" },
      {
        value: -rowcountSco * 0.2,
        colour: ramp[1],
        name: "16-20% rank increase"
      },
      {
        value: -rowcountSco * 0.16,
        colour: ramp[2],
        name: "12-16% rank increase"
      },
      {
        value: -rowcountSco * 0.12,
        colour: ramp[3],
        name: "8-12% rank increase"
      },
      {
        value: -rowcountSco * 0.08,
        colour: ramp[4],
        name: "4-8% rank increase"
      },
      {
        value: -rowcountSco * 0.04,
        colour: ramp[5],
        name: "No significant change"
      },
      {
        value: rowcountSco * 0.04,
        colour: ramp[6],
        name: "4-8% rank decrease"
      },
      {
        value: rowcountSco * 0.08,
        colour: ramp[7],
        name: "8-12% rank decrease"
      },
      {
        value: rowcountSco * 0.12,
        colour: ramp[8],
        name: "12-16% rank decrease"
      },
      {
        value: rowcountSco * 0.16,
        colour: ramp[9],
        name: "16-20% rank decrease"
      },
      { value: rowcountSco * 0.2, colour: ramp[10], name: "20%+ rank decrease" }
    ]
  }
];

const legendStopsWal = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: ramp[0], name: "20%+ rank increase" },
      {
        value: -rowcountWal * 0.2,
        colour: ramp[1],
        name: "16-20% rank increase"
      },
      {
        value: -rowcountWal * 0.16,
        colour: ramp[2],
        name: "12-16% rank increase"
      },
      {
        value: -rowcountWal * 0.12,
        colour: ramp[3],
        name: "8-12% rank increase"
      },
      {
        value: -rowcountWal * 0.08,
        colour: ramp[4],
        name: "4-8% rank increase"
      },
      {
        value: -rowcountWal * 0.04,
        colour: ramp[5],
        name: "No significant change"
      },
      {
        value: rowcountWal * 0.04,
        colour: ramp[6],
        name: "4-8% rank decrease"
      },
      {
        value: rowcountWal * 0.08,
        colour: ramp[7],
        name: "8-12% rank decrease"
      },
      {
        value: rowcountWal * 0.12,
        colour: ramp[8],
        name: "12-16% rank decrease"
      },
      {
        value: rowcountWal * 0.16,
        colour: ramp[9],
        name: "16-20% rank decrease"
      },
      { value: rowcountWal * 0.2, colour: ramp[10], name: "20%+ rank decrease" }
    ]
  }
];

export const IMDChange = {
  title: "Deprivation Rank Change",
  description:
    "The rank change in relative deprivation for small areas, for the Index of Multiple Deprivation and its domains.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/index-multiple-deprivation-imd",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM lsoa11_uk",
  buildingSql: "SELECT " + cols + " FROM lsoa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM lsoa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM lsoa11_uk_urban",
  defaultLayers: "1111000",
  extent: 2,
  popup: false,
  idField: "lsoa11_cd",
  geogNameField: "lsoa11_nm",
  ladCodeField: "lad_code",
  hoverFieldMetricMode: "MetricOnly",
  metrics: [
    {
      id: 0,
      name: "English 2015-2019 IMD change (E15-19)",
      field: "imde19_rk",
      compField: "imde15_rk",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 1,
      name: "English 2010-2015 IMD change",
      field: "imde15_rk",
      compField: "imde10_rk",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 2,
      name: "English 2010-2019 IMD change",
      field: "imde19_rk.chg",
      compField: "imde10_rk",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 3,
      name: "Scottish 2016-2020 SIMD change (S16-20)",
      field: "imds20_rk",
      compField: "imds16_rk",
      display: "Percentage",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 4,
      name: "Welsh 2014-2019 WIMD change",
      field: "imdw19_rk",
      compField: "imdw14_rk",
      display: "Percentage",
      rankCount: rowcountWal,
      legendOptions: [],
      legendStops: legendStopsWal
    },
    {
      id: 5,
      name: "E15-19 Income domain change",
      field: "imde19_inc",
      compField: "imde15_inc",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 6,
      name: "E15-19 Employment domain change",
      field: "imde19_emp",
      compField: "imde15_emp",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 7,
      name: "E15-19 Education domain change",
      field: "imde19_edu",
      compField: "imde15_edu",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 8,
      name: "E15-19 Health domain change",
      field: "imde19_hdd",
      compField: "imde15_hdd",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 9,
      name: "E15-19 Crime domain change",
      field: "imde19_cri",
      compField: "imde15_cri",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 10,
      name: "E15-19 Housing domain change",
      field: "imde19_bhs",
      compField: "imde15_bhs",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 11,
      name: "E15-19 Environment domain change",
      field: "imde19_env",
      compField: "imde15_env",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 12,
      name: "E15-19 IDACI (Children) change",
      field: "imde19_idc",
      compField: "imde15_idc",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 13,
      name: "E15-19 IDAOPI (Older People) change",
      field: "imde19_ido",
      compField: "imde15_ido",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 14,
      name: "E15-19 Children/YP sub-domain change",
      field: "imde19_cyp",
      compField: "imde15_cyp",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 15,
      name: "E15-19 Geographical Barriers sub-domain change",
      field: "imde19_gb",
      compField: "imde15_gb",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 16,
      name: "E15-19 Wider Barriers sub-domain change",
      field: "imde19_wb",
      compField: "imde15_wb",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 17,
      name: "E15-19 Indoor sub-domain change",
      field: "imde19_ind",
      compField: "imde15_ind",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 18,
      name: "E15-19 Outdoor sub-domain change",
      field: "imde19_out",
      compField: "imde15_out",
      display: "Percentage",
      rankCount: rowcountEng,
      legendOptions: [],
      legendStops: legendStopsEng
    },
    {
      id: 19,
      name: "S16-20 Income domain change",
      field: "imds20_inc",
      compField: "imds16_inc",
      display: "Percentage",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 20,
      name: "S16-20 Employment domain change",
      field: "imds20_emp",
      compField: "imds16_emp",
      display: "Percentage",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 21,
      name: "S16-20 Health domain change",
      field: "imds20_hea",
      compField: "imds16_hea",
      display: "Percentage",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 22,
      name: "S16-20 Education domain change",
      field: "imds20_edu",
      compField: "imds16_edu",
      display: "Percentage",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 23,
      name: "S16-20 Services domain change",
      field: "imds20_acc",
      compField: "imds16_acc",
      display: "Percentage",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    },
    {
      id: 24,
      name: "S16-20 Crime domain change",
      field: "imds20_cri",
      compField: "imds16_cri",
      display: "Percentage",
      rankCount: rowcountSco,
      legendOptions: [],
      legendStops: legendStopsSco
    }
  ]
};
