import colorbrewer from "colorbrewer";

const cols =
  "a.the_geom_webmercator, a.lad21cd, a.lad21nm, " +
  "b.doinla04, b.doinla09, b.doinla14, b.doinla19, b.doinla23, b.rdinla04, b.rdinla09, b.rdinla14, " +
  "b.rdinla19, b.rdinla23, b.rdoula04, b.rdoula09, b.rdoula14, b.rdoula19, b.rdoula23, " + 
  "b.remilamepc, b.remilampep, b.remilamare, " +
  "b.mimed_5, b.mimdep_5, b.mimdarea_5, b.momdarea_5, b.wimdarea_5";
const jointablesql = " a, rm_la21_combine b where a.lad21cd = b.lad21cd";

const ramp = colorbrewer.PiYG[11];
const legendStops = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0.001, colour: ramp[0], name: "Under 20 km" },
      { value: 20, colour: ramp[1], name: "20-30 km" },
      { value: 30, colour: ramp[2], name: "30-40 km" },
      { value: 40, colour: ramp[3], name: "40-50 km" },
      { value: 50, colour: ramp[4], name: "50-60 km" },
      { value: 60, colour: ramp[5], name: "60-70 km" },
      { value: 70, colour: ramp[6], name: "70-80 km" },
      { value: 80, colour: ramp[7], name: "80-90 km" },
      { value: 90, colour: ramp[8], name: "90-100 km" },
      { value: 100, colour: ramp[9], name: "100-120 km" },
      { value: 120, colour: ramp[10], name: "120 km+" }
    ]
  }
];

const rampEleChg = colorbrewer.RdBu[9];

const legendStopsEleChg = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampEleChg[0], name: "15%+ worse" },
      { value: -15, colour: rampEleChg[1], name: "10-15% worse" },
      { value: -10, colour: rampEleChg[2], name: "5-10% worse" },
      { value: -5, colour: rampEleChg[3], name: "2-5% worse" },
      { value: -2, colour: rampEleChg[4], name: "<2% change" },
      { value: 2, colour: rampEleChg[5], name: "2-5% better" },
      { value: 5, colour: rampEleChg[6], name: "5-10% better" },
      { value: 10, colour: rampEleChg[7], name: "10-15% better" },
      { value: 15, colour: rampEleChg[8], name: "15%+ better" }
    ]
  }
];

const rampAreaChg6 = colorbrewer.RdBu[11];
const rampAreaChg = colorbrewer.RdBu[9];

const legend_epc_chg_mi = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampAreaChg[0], name: "7+ decrease" },
      { value: -7, colour: rampAreaChg[1], name: "5-7 decrease" },
      { value: -5, colour: rampAreaChg[2], name: "3-5 decrease" },
      { value: -3, colour: rampAreaChg[3], name: "1-3 decrease" },
      { value: -1, colour: rampAreaChg[4], name: "<1 change" },
      { value: 1, colour: rampAreaChg[5], name: "1-3 increase" },
      { value: 3, colour: rampAreaChg[6], name: "3-5 increase" },
      { value: 5, colour: rampAreaChg[7], name: "5-7 increase" },
      { value: 7, colour: rampAreaChg[8], name: "7+ increase" }
    ]
  }
];

const legend_pepc_chg_moi = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampAreaChg6[1], name: "3+ decrease" },
      { value: -3, colour: rampAreaChg6[2], name: "2-3 decrease" },
      { value: -2, colour: rampAreaChg6[3], name: "1-2 decrease" },
      { value: -1, colour: rampAreaChg6[4], name: "<1 decrease" },
      { value: 0, colour: rampAreaChg6[6], name: "<1 increase" },
      { value: 1, colour: rampAreaChg6[7], name: "1-2 increase" },
      { value: 2, colour: rampAreaChg6[8], name: "2-3 increase" },
      { value: 3, colour: rampAreaChg6[9], name: "3-4 increase" },
      { value: 4, colour: rampAreaChg6[10], name: "4+ increase" }
    ]
  }
];

const legend_floor_chg_all = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampAreaChg6[0], name: "15 sqm+ smaller" },
      { value: -15, colour: rampAreaChg6[1], name: "11-15 sqm smaller" },
      { value: -11, colour: rampAreaChg6[2], name: "7.5-11 sqm smaller" },
      { value: -7.5, colour: rampAreaChg6[3], name: "4-7.5 sqm smaller" },
      { value: -4, colour: rampAreaChg6[4], name: "<4 sqm smaller" },
      { value: 0, colour: rampAreaChg6[6], name: "<4 sqm larger" },
      { value: 4, colour: rampAreaChg6[7], name: "4-7.5 larger" },
      { value: 7.5, colour: rampAreaChg6[8], name: "7.5-11 sqm larger" },
      { value: 11, colour: rampAreaChg6[9], name: "11-15 sqm larger" },
      { value: 15, colour: rampAreaChg6[10], name: "15 sqm+ larger" }
    ]
  }
];

function getMetrics() {
  // prettier-ignore
  return [
    { id: 0, name: "Distance (Moves in, 2004)", field: "doinla04", display: "km", decimals: 0, legendOptions: [], legendStops: legendStops, additionalMetrics: [0, 1, 2, 3, 4] },
    { id: 1, name: "Distance (Moves in, 2009)", field: "doinla09", display: "km", decimals: 0, legendOptions: [], legendStops: legendStops, additionalMetrics: [0, 1, 2, 3, 4] },
    { id: 2, name: "Distance (Moves in, 2014)", field: "doinla14", display: "km", decimals: 0, legendOptions: [], legendStops: legendStops, additionalMetrics: [0, 1, 2, 3, 4] },
    { id: 3, name: "Distance (Moves in, 2019)", field: "doinla19", display: "km", decimals: 0, legendOptions: [], legendStops: legendStops, additionalMetrics: [0, 1, 2, 3, 4] },
    { id: 4, name: "Distance (Moves in, 2023)", field: "doinla23", display: "km", decimals: 0, legendOptions: [], legendStops: legendStops, additionalMetrics: [0, 1, 2, 3, 4] },
    { id: 5, name: "Deprivation Chg (Moves in, 2004)", field: "rdinla04", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [5, 6, 7, 8, 9] },
    { id: 6, name: "Deprivation Chg (Moves in, 2009)", field: "rdinla09", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [5, 6, 7, 8, 9] },
    { id: 7, name: "Deprivation Chg (Moves in, 2014)", field: "rdinla14", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [5, 6, 7, 8, 9] },
    { id: 8, name: "Deprivation Chg (Moves in, 2019)", field: "rdinla19", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [5, 6, 7, 8, 9] },
    { id: 9, name: "Deprivation Chg (Moves in, 2023)", field: "rdinla23", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [5, 6, 7, 8, 9] },
    { id: 10, name: "Deprivation Chg (Moves out, 2004)", field: "rdoula04", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [10, 11, 12, 13, 14] },
    { id: 11, name: "Deprivation Chg (Moves out, 2009)", field: "rdoula09", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [10, 11, 12, 13, 14] },
    { id: 12, name: "Deprivation Chg (Moves out, 2014)", field: "rdoula14", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [10, 11, 12, 13, 14] },
    { id: 13, name: "Deprivation Chg (Moves out, 2019)", field: "rdoula19", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [10, 11, 12, 13, 14] },
    { id: 14, name: "Deprivation Chg (Moves out, 2023)", field: "rdoula23", display: "%", decimals: 0, legendOptions: [], legendStops: legendStopsEleChg, additionalMetrics: [10, 11, 12, 13, 14] },
    { id: 15, name: "EPC Rating Chg Med (Moves into, 2009-23)", field: "remilamepc", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [15, 16, 17, 18] },
    { id: 16, name: "EPC Rating Chg Med (Moves into, 2021-23)", field: "mimed_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_epc_chg_mi, additionalMetrics: [115, 16, 17, 18] },
    { id: 17, name: "EPC Poten. Rating Chg Med (Moves into, 2009-23)", field: "remilampep", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [15, 16, 17, 18] },
    { id: 18, name: "EPC Poten. Rating Chg Med (Moves into, 2021-23)", field: "mimdep_5", display: "", decimals: 0, legendOptions: [], legendStops: legend_pepc_chg_moi, additionalMetrics: [15, 16, 17, 18] },
    { id: 19, name: "Floorspace Chg Med (Moves into, 2009-23)", field: "remilamare", display: "sqm", decimals: 1, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [19, 20, 21, 22] },
    { id: 20, name: "Floorspace Chg Med (Moves into, 2021-23)", field: "mimdarea_5", display: "sqm", decimals: 1, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [19, 20, 21, 22] },
    { id: 21, name: "Floorspace Chg Med (Moves out, 2021-23)", field: "momdarea_5", display: "sqm", decimals: 1, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [19, 20, 21, 22] },
    { id: 22, name: "Floorspace Chg Med (Moves within, 2021-23)", field: "wimdarea_5", display: "sqm", decimals: 1, legendOptions: [], legendStops: legend_floor_chg_all, additionalMetrics: [19, 20, 21, 22] }
  ]
}

export const RESIDENTIALMOVES = {
  title: "Distances, Deprivation and EPC Changes in Residential Moves",
  description:
    "Residential mobility: Average distance of moves (DoRM), deprivation life measure changes following moves (RMD), and resulting changes recorded on EPCs (RMEPC). ",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/cdrc-residential-mobility-and-deprivation-rmd-index-lad-geography",
  storyLinks: [
    {
      link:
        "https://data.cdrc.ac.uk/stories/consumer-data-insights-housing-policy",
      title: "Consumer Data Insights for Housing Policy"
    }
  ],
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM la21_uk" + jointablesql,
  buildingSql: "SELECT " + cols + " FROM la21_uk_bua22" + jointablesql,
  detailUrbanSql: "SELECT " + cols + " FROM la21_uk_bua22" + jointablesql,
  urbanSql: "SELECT " + cols + " FROM la21_uk_bua22" + jointablesql,
  defaultLayers: "1111000",
  initialValue: 19,
  extent: 2,
  popup: false,
  idField: "lad21cd",
  geogNameField: "lad21nm",
  ladCodeField: "lad21cd",
  hoverFieldMetricMode: "Some",
  metrics: getMetrics()
};
