import colorbrewer from "colorbrewer";

const cols =
  "the_geom_webmercator, rc_id, name_pretty, " +
  "classif_intid, nunits, pctclon, vulnera, onlinee, eresili, " +
  "composition, diversity, vacancy";

const legendStops = [
  {
    id: 0,
    stops: [
      { value: 1, colour: "#882255", name: "1: Major City/Regional Centre" },
      { value: 2, colour: "#332288", name: "2: Major Town/Sub Reg. Centre" },
      { value: 3, colour: "#117733", name: "3: Town Centre" },
      { value: 4, colour: "#44AA99", name: "4: Market Town" },
      { value: 5, colour: "#999933", name: "5 District Centre" },
      { value: 6, colour: "#DDCC77", name: "6: Local Centre" },
      { value: 7, colour: "#88CCEE", name: "7: Small Local Centre" },
      { value: 8, colour: "#CC6677", name: "8: Large Retail Park" },
      { value: 9, colour: "#E0A1AB", name: "9: Small Retail Park" },
      { value: 10, colour: "#AA4499", name: "10: Large Shopping Centre" },
      { value: 11, colour: "#CD83C1", name: "11: Small Shopping Centre" }
    ]
  }
];

let ramp = colorbrewer.YlOrRd[9];

const legendStopsClone = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: ramp[1], name: "0 - 10%" },
      { value: 10, colour: ramp[2], name: "10 - 15%" },
      { value: 15, colour: ramp[3], name: "15 - 20%" },
      { value: 20, colour: ramp[4], name: "20 - 25%" },
      { value: 25, colour: ramp[5], name: "25 - 30%" },
      { value: 30, colour: ramp[6], name: "30 - 35%" },
      { value: 35, colour: ramp[7], name: "35 - 40%" },
      { value: 40, colour: ramp[8], name: "40%+" }
    ]
  }
];

const legendStopsUnits = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 50, colour: "#ffeeee", name: "50 - 100" },
      { value: 100, colour: "#ffcccc", name: "100 - 200" },
      { value: 200, colour: "#ffaaaa", name: "200 - 300" },
      { value: 300, colour: "#ff8888", name: "300 - 400" },
      { value: 400, colour: "#ff6666", name: "400 - 600" },
      { value: 600, colour: "#ff4444", name: "600 - 800" },
      { value: 800, colour: "#ff2222", name: "800 - 1000" },
      { value: 1000, colour: "#ff0000", name: "1000+" }
    ]
  }
];

ramp = colorbrewer.PiYG[10];

const legendStopsDecile = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: ramp[9], name: "1 - 10" },
      { value: 10, colour: ramp[8], name: "10 - 20" },
      { value: 20, colour: ramp[7], name: "20 - 30" },
      { value: 30, colour: ramp[6], name: "30 - 40" },
      { value: 40, colour: ramp[5], name: "40 - 50" },
      { value: 50, colour: ramp[4], name: "50 - 60" },
      { value: 60, colour: ramp[3], name: "60 - 70" },
      { value: 70, colour: ramp[2], name: "70 - 80" },
      { value: 80, colour: ramp[1], name: "80 - 90" },
      { value: 90, colour: ramp[0], name: "90 - 100" }
    ]
  }
];

export const RETAILCENTRES = {
  title: "Retail Centres",
  description: "A map of retail centres",
  downloadLink: "https://data.cdrc.ac.uk/dataset/retail-centre-boundaries",
  storyLink: [
    {
      link:
        "https://data.cdrc.ac.uk/stories/retail-indicators-i-identifying-clone-towns-across-uk",
      title: "Retail Indicators I. Identifying 'clone towns' across the UK"
    },
    {
      link:
        "https://data.cdrc.ac.uk/stories/why-some-retail-centres-outperform-others",
      title: "Why some retail centres outperform others"
    }
  ],
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM retailcentreboundaries",
  buildingSql: "SELECT " + cols + " FROM retailcentreboundaries",
  detailUrbanSql: "SELECT " + cols + " FROM retailcentreboundaries",
  urbanSql: "SELECT " + cols + " FROM retailcentreboundaries",
  defaultLayers: "1111000",
  extent: 2,
  border: true,
  popup: true,
  popupType: "custom",
  popupFeatureConfig: {
    sections: [
      {
        id: 0,
        title: null,
        headers: ["Field", "Value", "Range"],
        rows: [
          {
            name: "Classification",
            field: "classif_intid",
            format: "number",
            decimalPlaces: 0
          },
          {
            name: "Unit Count",
            field: "nunits",
            format: "number",
            decimalPlaces: 0,
            label: "min 50, mean 237, max 7626"
          },
          {
            name: "Clone Town Measure",
            field: "pctclon",
            format: "%",
            decimalPlaces: 0,
            label: "min 1, mean 23, max 65"
          },
          {
            name: "Supply Vulnerability Index",
            field: "vulnera",
            format: "number",
            decimalPlaces: 0,
            label: "min 1, mean 56, max 100"
          },
          {
            name: "Online Exposure Index",
            field: "onlinee",
            format: "number",
            decimalPlaces: 0,
            label: "min 1, mean 48, max 100"
          },
          {
            name: "E-Resilience Measure",
            field: "eresili",
            format: "number",
            decimalPlaces: 0,
            label: "min 1, mean 56, max 100"
          },
          {
            name: "Additional Composition Indicators",
            field: "composition",
            format: "string",
            label: "comparison, convenience, service & leisure units"
          },
          {
            name: "Additional Diversity Indicators",
            field: "diversity",
            format: "string",
            label: "chain & independent units"
          },
          {
            name: "Additional Vacancy Indicators",
            field: "vacancy",
            format: "string",
            label: "vacancy, structural vacancies and vacancy change"
          }
        ]
      }
    ]
  },
  popupGeographyConfig: {
    sections: [
      {
        id: 0,
        title: null,
        headers: ["Classification", "Percentage By Count"],
        rows: [
          {
            id: 1,
            name: legendStops[0].stops.find(element => element.value == 1).name,
            field: "classif_intid_1",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 2,
            name: legendStops[0].stops.find(element => element.value == 2).name,
            field: "classif_intid_2",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 3,
            name: legendStops[0].stops.find(element => element.value == 3).name,
            field: "classif_intid_3",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 4,
            name: legendStops[0].stops.find(element => element.value == 4).name,
            field: "classif_intid_4",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 5,
            name: legendStops[0].stops.find(element => element.value == 5).name,
            field: "classif_intid_5",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 6,
            name: legendStops[0].stops.find(element => element.value == 6).name,
            field: "classif_intid_6",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 7,
            name: legendStops[0].stops.find(element => element.value == 7).name,
            field: "classif_intid_7",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 8,
            name: legendStops[0].stops.find(element => element.value == 8).name,
            field: "classif_intid_8",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 9,
            name: legendStops[0].stops.find(element => element.value == 9).name,
            field: "classif_intid_9",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 10,
            name: legendStops[0].stops.find(element => element.value == 10)
              .name,
            field: "classif_intid_10",
            format: "percent",
            decimalPlaces: 0
          },
          {
            id: 11,
            name: legendStops[0].stops.find(element => element.value == 11)
              .name,
            field: "classif_intid_11",
            format: "percent",
            decimalPlaces: 0
          }
        ]
      }
    ]
  },
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    sum(case when d.classif_intid = 1 then 1 else 0 end) / cast(count(*)as numeric) as classif_intid_1,
    sum(case when d.classif_intid = 2 then 1 else 0 end) / cast(count(*)as numeric) as classif_intid_2,
    sum(case when d.classif_intid = 3 then 1 else 0 end) / cast(count(*)as numeric) as classif_intid_3,
    sum(case when d.classif_intid = 4 then 1 else 0 end) / cast(count(*)as numeric) as classif_intid_4,
    sum(case when d.classif_intid = 5 then 1 else 0 end) / cast(count(*)as numeric) as classif_intid_5,
    sum(case when d.classif_intid = 6 then 1 else 0 end) / cast(count(*)as numeric) as classif_intid_6,
    sum(case when d.classif_intid = 7 then 1 else 0 end) / cast(count(*)as numeric) as classif_intid_7,
    sum(case when d.classif_intid = 8 then 1 else 0 end) / cast(count(*)as numeric) as classif_intid_8,
    sum(case when d.classif_intid = 9 then 1 else 0 end) / cast(count(*)as numeric) as classif_intid_9,
    sum(case when d.classif_intid = 10 then 1 else 0 end) / cast(count(*)as numeric) as classif_intid_10,
    sum(case when d.classif_intid = 11 then 1 else 0 end) / cast(count(*)as numeric) as classif_intid_11
  FROM oliverobrien.retailcentreboundaries d

  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  idField: "rc_id",
  geogNameField: "name_pretty",
  hoverFieldMetricMode: "All",
  metrics: [
    {
      id: 0,
      name: "Classification",
      field: "classif_intid",
      display: "Stop Name",
      decimals: 0,
      extent: 2,
      legendOptions: [],
      legendStops: legendStops
    },
    {
      id: 1,
      name: "Unit Count",
      field: "nunits",
      decimals: 0,
      extent: 0,
      legendOptions: [],
      legendStops: legendStopsUnits
    },
    {
      id: 2,
      name: "Clone Town Measure",
      field: "pctclon",
      decimals: 0,
      extent: 0,
      display: "%",
      legendOptions: [],
      legendStops: legendStopsClone
    },
    {
      id: 3,
      name: "Supply Vulnerability Index",
      field: "vulnera",
      decimals: 0,
      extent: 0,
      legendOptions: [],
      legendStops: legendStopsDecile
    },
    {
      id: 4,
      name: "Online Exposure Index",
      field: "onlinee",
      decimals: 0,
      extent: 0,
      legendOptions: [],
      legendStops: legendStopsDecile
    },
    {
      id: 5,
      name: "E-Resilience Measure",
      field: "eresili",
      decimals: 0,
      extent: 0,
      legendOptions: [],
      legendStops: legendStopsDecile
    }
  ]
};
