const cols =
  "the_geom_webmercator, oa11_cd, la17_cd, la17_nm, " +
  "bba225_dow, bba215_dow, bba205_dow, bba199_dow, bba185_dow, bba175_dow, bba166_dow, " +
  "bba225_uf, bba215_uf, bba205_uf, bba199_uf, bba185_uf, bba175_uf, bba166_uf";

import colorbrewer from "colorbrewer";

const ramp = colorbrewer.PiYG[11];

const legendStops = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramp[0], name: "Under 10 Mbps" },
      { value: 10, colour: ramp[1], name: "10-20 Mbps" },
      { value: 20, colour: ramp[2], name: "20-30 Mbps" },
      { value: 30, colour: ramp[3], name: "30-40 Mbps" },
      { value: 40, colour: ramp[4], name: "40-50 Mbps" },
      { value: 50, colour: ramp[5], name: "50-60 Mbps" },
      { value: 60, colour: ramp[6], name: "60-75 Mbps" },
      { value: 75, colour: ramp[7], name: "75-100 Mbps" },
      { value: 100, colour: ramp[8], name: "100-125 Mbps" },
      { value: 125, colour: ramp[9], name: "125-150 Mbps" },
      { value: 150, colour: ramp[10], name: "Over 150 Mpbs" }
    ]
  }
];

const ramp2 = colorbrewer.YlOrRd[6];

const legendStops2 = [
  {
    id: 0,
    stops: [
      { value: 0, colour: "#000000", name: "0%" },
      { value: 1, colour: ramp2[5], name: "1-20%" },
      { value: 20, colour: ramp2[4], name: "20-40%" },
      { value: 40, colour: ramp2[3], name: "40-60%" },
      { value: 60, colour: ramp2[2], name: "60-80%" },
      { value: 80, colour: ramp2[1], name: "80-99%" },
      { value: 99.5, colour: ramp2[0], name: "100%" }
    ]
  }
];

const rampSpeedDiff = colorbrewer.Purples[6];

const legendStopsSpeedDiff = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: "#ff0000", name: "10%+ fall" },
      { value: -0.1, colour: rampSpeedDiff[0], name: "Little change" },
      { value: 0.1, colour: rampSpeedDiff[1], name: "10-20% rise" },
      { value: 0.2, colour: rampSpeedDiff[2], name: "20-30% rise" },
      { value: 0.3, colour: rampSpeedDiff[3], name: "30-40% rise" },
      { value: 0.4, colour: rampSpeedDiff[4], name: "40-50%+ rise" },
      { value: 0.5, colour: rampSpeedDiff[5], name: "50%+ rise" }
    ]
  }
];

export const BROADBANDSPEED = {
  title: "Broadband Speed and Ultrafast (UFBB) Availability",
  description:
    "The average wired broadband download speeds for local addresses (residential and business), and estimates of the proportion with access to (300Mb/s+) service.",
  downloadLink: "https://data.cdrc.ac.uk/dataset/broadband-speed",
  storyLinks: [
    {
      link: "https://data.cdrc.ac.uk/stories/broadband",
      title: "The UK’s Broadband hotspots and notspots mapped"
    }
  ],
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM oa11_uk",
  buildingSql: "SELECT " + cols + " FROM oa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM oa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM oa11_uk_urban",
  defaultLayers: "1111000",
  extent: 2,
  popup: false,
  idField: "oa11_cd",
  geogNameField: "la17_nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la17_cd",
  hoverFieldMetricMode: "All",
  metrics: [
    {
      id: 0,
      name: "2022 Speed",
      field: "bba225_dow",
      display: " Mbps",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 1,
      name: "2021 Speed",
      field: "bba215_dow",
      display: " Mbps",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 2,
      name: "2020 Speed",
      field: "bba205_dow",
      display: " Mbps",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 3,
      name: "2019 Speed",
      field: "bba199_dow",
      display: " Mbps",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 4,
      name: "2018 Speed",
      field: "bba185_dow",
      display: " Mbps",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 5,
      name: "2017 Speed",
      field: "bba175_dow",
      display: " Mbps",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 6,
      name: "2016 Speed",
      field: "bba166_dow",
      display: " Mbps",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 7,
      name: "2022 Availability",
      field: "bba225_uf",
      display: "%",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops2
    },
    {
      id: 8,
      name: "2021 Availability",
      field: "bba215_uf",
      display: "%",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops2
    },
    {
      id: 9,
      name: "2020 Availability",
      field: "bba205_uf",
      display: "%",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops2
    },
    {
      id: 10,
      name: "2019 Availability",
      field: "bba199_uf",
      display: "%",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops2
    },
    {
      id: 11,
      name: "2018 Availability",
      field: "bba185_uf",
      display: "%",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops2
    },
    {
      id: 12,
      name: "2017 Availability",
      field: "bba175_uf",
      display: "%",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops2
    },
    {
      id: 13,
      name: "2016 Availability",
      field: "bba166_uf",
      display: "%",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops2
    },
    {
      id: 14,
      name: "2021-22 Speed Change",
      field: "bba225_dow.chg",
      compField: "bba215_dow",
      denomField: "bba215_dow",
      display: "Percentage",
      decimals: 0,
      legendOptions: [],
      legendStops: legendStopsSpeedDiff
    },
    {
      id: 15,
      name: "2019-20 Speed Change",
      field: "bba205_dow.chg",
      compField: "bba199_dow",
      denomField: "bba199_dow",
      display: "Percentage",
      decimals: 0,
      legendOptions: [],
      legendStops: legendStopsSpeedDiff
    },
    {
      id: 16,
      name: "2016-17 Speed Change",
      field: "bba175_dow.chg",
      compField: "bba166_dow",
      denomField: "bba166_dow",
      display: "Percentage",
      decimals: 0,
      legendOptions: [],
      legendStops: legendStopsSpeedDiff
    }
  ]
};
