const cols = "the_geom_webmercator, oa11_cd, la17_cd, la17_nm, vul_clascd";

export const CONSUMERVULNERABILITY = {
  title: "Consumer Vulnerability",
  description:
    "Some consumers are more vulnerable to marketing practices due to their personal traits such as age, health or household makeup. This vulnerability varies by product and industry.",
  downloadLink: "https://data.cdrc.ac.uk/dataset/consumer-vulnerability",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM oa11_uk",
  buildingSql: "SELECT " + cols + " FROM oa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM oa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM oa11_uk_urban",
  defaultLayers: "1111000",
  extent: 2,
  popup: true,
  popupType: "classification",
  idField: "oa11_cd",
  geogNameField: "la17_nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la17_cd",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.vul_clascd = 'A' then 1 else 0 end) as count_A,
      sum(case when d.vul_clascd = 'B' then 1 else 0 end) as count_B,
      sum(case when d.vul_clascd = 'C' then 1 else 0 end) as count_C,
      sum(case when d.vul_clascd = 'D' then 1 else 0 end) as count_D,
      sum(case when d.vul_clascd = 'E' then 1 else 0 end) as count_E,
      sum(case when d.vul_clascd = 'F' then 1 else 0 end) as count_F
  FROM oliverobrien.oa11_uk d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Group",
      field: "vul_clascd",
      lookup: "id",
      groups: [
        {
          id: "A",
          name: "Prosperous Professionals",
          colour: "#4575b4",
          visible: true,
          description:
            "This group contains a high proportion of people in professional and technical occupations including senior positions. Home ownership in this group high, as is detached housing. There are a large number of married and cohabiting people in this cluster, in particular with children. Full time employment, very good health and 2 or more cars per household are all characteristic of this group. The proportion of people in the age range of 30-44 is high of this group. The level of vulnerability for this group is considered very low as there are very few characteristics associated with vulnerability."
        },
        {
          id: "B",
          name: "Well Established",
          colour: "#91bfdb",
          visible: true,
          description:
            "This group contains larger proportion of demographics age 45 and older including a large proportion of retirees and people in professional occupations. A large proportion of the non-retired workers are self-employed. The proportion of home ownership is high, as is people living in detached housing. The proportion of married and cohabiting people is high in this group. The proportion of one person households above 65 is also relatively high, as are people who provide unpaid care. People in this group typically have higher level qualifications. Households typically contain 2 or more cars. The level of vulnerability for this group is considered low as overall there are few characteristics associated with vulnerability. However, the proportion of households containing a lone person over 60 and people providing unpaid care does increase vulnerability somewhat."
        },
        {
          id: "C",
          name: "Students and Young Professionals",
          colour: "#e0f3f8",
          visible: true,
          description:
            "This group contains a high proportion of full time students and full time employees of around ages 20 to 29. For housing the proportion of flats is high as is private rent. High levels of qualifications and professional occupations are characteristic of this group. Very good health is characteristic of this group. There reasonably high proportion of people with poor English skills in this cluster and households with no cars. This group does not exhibit many vulnerable characteristics except for poor English speakers and a lack of cars in the household."
        },
        {
          id: "D",
          name: "On a Budget",
          colour: "#fee090",
          visible: true,
          description:
            "This group contains a high proportion of people in trades, sales, and leisure, as well as a high proportion of unemployed or long term sick/disabled. Qualification levels are generally low. The proportion of people with fair or poor health is high, as well as those with limited day to day activities. This group contains high proportions of married/cohabiting people with children and also lone parents. Semi-detached and terraced housing is characteristic of this cluster. There are several indicators of vulnerability in this cluster meaning that consumer vulnerability is considered fair for this group."
        },
        {
          id: "E",
          name: "Vulnerable Communities",
          colour: "#fc8d59",
          visible: true,
          description:
            "This group contains a high proportion of people with poor English skills, lone parents, and long term sick/disabled. Flats or terraced housing, and social renting is characteristic of this group. The health of people in this group varies. Unemployment is high and those that are in work are typically in lower paying jobs. A large proportion of homes do not have a car. The age range is young to mid-aged at around 15 to 44. This group exhibits several vulnerable characteristics suggesting a high level of vulnerability can be found in this group."
        },
        {
          id: "F",
          name: "Vulnerable Pensioners",
          colour: "#d73027",
          visible: true,
          description:
            "The age range for this group is around 60 to 90 with a high proportion of one person households over 65. Social rented flats are characteristic of this cluster, as is having no cars in the household. There are a high proportion of people with limited day-to-day activities, as well as fair to very bad health. The proportion of people with good health is low and with very good health is very low. People in this group who are retired or long-term sick/disabled are characteristic of this group. Those that work are typically in elementary occupations or process, plant and machine operatives. The level of vulnerability this group is very high as many vulnerable characteristics are present."
        }
      ]
    }
  ]
};
