<template>
  <div class="map-sidebar">
    <h5 v-b-toggle.sidebar-collapse tabindex="0" role="button">
      <font-awesome-icon
        :icon="['fas', 'compress-alt']"
        class="opened text-secondary ml-1"
        style="float: right;"
      />
      <font-awesome-icon
        :icon="['fas', 'expand-alt']"
        class="closed text-secondary ml-2"
        style="float: right;"
      />
      <strong>{{ title }} </strong>
      <b-badge v-if="isUnlistedMap" variant="warning">
        Unlisted
      </b-badge>
    </h5>
    <b-collapse id="sidebar-collapse" visible>
      <p v-if="embed == 0" class="description" v-html="description"></p>
      <p v-if="storyLinks && embed < 2">
        <span v-for="item in storyLinks" v-bind:key="item.link">
          <div>
            Data story:
            <a class="text-info" v-bind:href="item.link" target="_top">{{
              item.title
            }}</a>
          </div>
        </span>
      </p>
      <p v-if="downloadLink && embed != 1">
        <a v-bind:href="downloadLink" class="text-info" target="_top">
          Download the data
        </a>
      </p>
      <p v-if="relatedMaps && embed < 1">
        <span v-for="item in relatedMaps" v-bind:key="item">
          <div>
            <small
              >Related map:
              <a
                v-bind:href="'/#' + maps.find(x => x.id == item).route"
                class="text-info"
                target="_top"
                >{{ maps.find(x => x.id == item).name }}</a
              >
            </small>
          </div>
        </span>
      </p>
      <slot></slot>
    </b-collapse>
  </div>
</template>

<script>
import { MAPS } from "@/config/Maps";

export default {
  name: "MapSidebar",
  props: {
    title: String,
    description: String,
    downloadLink: String,
    storyLinks: Array,
    relatedMaps: Array,
    embed: Number
  },
  data() {
    return {
      maps: MAPS,
      isUnlistedMap:
        MAPS.find(x => x.id == this.$route.name).hidden == true &&
        this.embed < 1
          ? true
          : false
    };
  }
};
</script>

<style scoped>
h5:focus {
  outline: 0;
}
.collapsed > .opened,
:not(.collapsed) > .closed {
  display: none;
}

.map-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  margin: 10px;
  overflow: auto;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  max-height: calc(100% - 20px);
  overflow: auto;
  z-index: 3; /* above mapbox controls */
}

@media (max-width: 576px) {
  .map-sidebar {
    max-width: 180px;
  }
  .description {
    display: none;
  }
}

.map-sidebar p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.map-sidebar hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
