import MapContainer from "../components/MapContainer.vue";
import { OAC } from "@/config/OAC";
import { OAC2021 } from "@/config/OAC2021";
import { OAC2021UK } from "@/config/OAC2021UK";
import { TOAC } from "@/config/TOAC";
import { LOAC } from "@/config/LOAC";
import { LOAC2021 } from "@/config/LOAC2021";
import { IUC } from "@/config/IUC";
import { COWZ } from "@/config/COWZ";
import { COWZEW } from "@/config/COWZEW";
import { LONDONWZC } from "@/config/LondonWZC";
import { RETAILTYPE } from "@/config/RetailType";
import { AHAH } from "@/config/AHAH";
import { CHURN } from "@/config/Churn";
import { MEP } from "@/config/MEP";
import { MODUM } from "@/config/MODUM";
import { CONSUMERVULNERABILITY } from "@/config/ConsumerVulnerability";
import { LIC } from "@/config/LIC";
import { EFDI } from "@/config/EFDI";
import { IMD } from "@/config/IMD";
import { IMDChange } from "@/config/IMDChange";
import { BROADBANDSPEED } from "@/config/BroadbandSpeed";
import { DWELLINGAGESPRICES } from "@/config/DwellingNewBuilds";
import { WMMOBILITY } from "@/config/WMMobility";
import { WMACCESSIBILITY } from "@/config/WMAccessibility";
import { RESIDENTIALMOVES } from "@/config/ResidentialMoves";
import { RESIDENTIALMOVESLSOA } from "@/config/ResidentialMovesLSOA";
import { RETAILCENTRES } from "@/config/RetailCentres";
import { AIPC } from "@/config/AIPC";
import { ENERGY } from "@/config/Energy";
import { DOMESTICBUILDINGS } from "@/config/DomesticBuildings";
import { NONDOMESTICBUILDINGS } from "@/config/NonDomesticBuildings";
import { PPFI } from "@/config/PPFI";
import { PPFI2 } from "@/config/PPFI2";
import { USRETAILCENTRES } from "@/config/USRetailCentres";
import { NEIGHBOURHOODTRAJECTORIES } from "@/config/NeighbourhoodTrajectories";
import { SECONDLANGUAGES } from "@/config/SecondLanguages";
import { POSTCODECONTEXT } from "@/config/PostcodeContext";
import { SPATIALSIGNATURES } from "@/config/SpatialSignatures";
import { INCLUSIVEECONOMY } from "@/config/InclusiveEconomy";
import { EDC } from "@/config/EDC";

export const MAPS = [
  {
    id: "OAC",
    name: "Output Area Classification 2011",
    route: "/output-area-classification-2011",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: OAC,
    default: false
  },
  {
    id: "OAC2021",
    name: "Output Area Classification 2021",
    route: "/output-area-classification-2021",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: OAC2021,
    default: true
  },
  {
    id: "OAC2021UK",
    name: "UK Output Area Classification",
    route: "/uk-output-area-classification",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: OAC2021UK,
    default: true
  },
  {
    id: "TOAC",
    name: "Temporal Output Area Classification",
    route: "/temporal-output-area-classification",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: TOAC,
    default: false
  },
  {
    id: "LOAC",
    name: "London Output Area Classification 2011",
    route: "/london-output-area-classification",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: LOAC,
    default: false,
    hidden: true
  },
  {
    id: "LOAC2021",
    name: "London Output Area Classification",
    route: "/london-oac",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: LOAC2021,
    default: false
  },
  {
    id: "Churn",
    name: "Residential Mobility Index (Churn)",
    route: "/population-churn",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: CHURN,
    default: false
  },
  {
    id: "MEP",
    name: "Ethnicity Estimator",
    route: "/modelled-ethnicity-proportions",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: MEP,
    default: false
  },
  {
    id: "AHAH",
    name: "Access to Healthy Assets & Hazards",
    route: "/access-healthy-assets-hazards",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: AHAH,
    default: false
  },
  {
    id: "IMD",
    name: "Deprivation Indices (IMD)",
    route: "/index-of-multiple-deprivation",
    topics: ["All", "Population & Mobility", "Finance & Economy"],
    component: MapContainer,
    metadata: IMD,
    default: false
  },
  {
    id: "IMDChange",
    name: "Deprivation Change",
    route: "/deprivation-change",
    topics: ["All", "Population & Mobility", "Finance & Economy"],
    component: MapContainer,
    metadata: IMDChange,
    default: false
  },
  {
    id: "IUC",
    name: "Internet User Classification",
    route: "/internet-user-classification",
    topics: ["All", "Digital"],
    component: MapContainer,
    metadata: IUC,
    default: false
  },
  {
    id: "PostcodeContextClassification",
    name: "Postcode Context Classification",
    route: "/postcode-context-classification",
    topics: ["All", "Digital"],
    component: MapContainer,
    metadata: POSTCODECONTEXT,
    default: false
  },
  {
    id: "SpatialSignatures",
    name: "Spatial Signatures",
    route: "/spatial-signatures",
    topics: ["All", "Digital"],
    component: MapContainer,
    metadata: SPATIALSIGNATURES,
    default: false
  },
  {
    id: "AIPC",
    name: "Ageing in Place Classification",
    route: "/ageing-in-place-classification",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: AIPC,
    default: false
  },
  {
    id: "COWZ",
    name: "Workplace Zone Classification",
    route: "/classification-of-workplace-zones",
    topics: ["All", "Finance & Economy"],
    component: MapContainer,
    metadata: COWZ,
    default: false
  },
  {
    id: "COWZEW",
    name: "Classification of Workplace Zones (England/Wales)",
    route: "/classification-of-workplace-zones-ew",
    topics: ["All", "Finance & Economy"],
    component: MapContainer,
    metadata: COWZEW,
    default: false,
    hidden: true
  },
  {
    id: "LWZC",
    name: "London Workplace Zone Classification",
    route: "/london-workplace-zone-classification",
    topics: ["All", "Finance & Economy"],
    component: MapContainer,
    metadata: LONDONWZC,
    default: false
  },
  {
    id: "RetailType",
    name: "Retail Centre Typology",
    route: "/retail-centre-typology",
    topics: ["All", "Retail Futures"],
    component: MapContainer,
    metadata: RETAILTYPE,
    default: false
  },
  {
    id: "MODUM",
    name: "Urban Morphology",
    route: "/urban-morphology",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: MODUM,
    default: false
  },
  {
    id: "ConsumerVulnerability",
    name: "Consumer Vulnerability",
    route: "/consumer-vulnerability",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: CONSUMERVULNERABILITY,
    default: false
  },
  {
    id: "LIC",
    name: "Local Internet Content",
    route: "/local-internet-content",
    topics: ["All", "Digital"],
    component: MapContainer,
    metadata: LIC,
    default: false
  },
  {
    id: "EFDI",
    name: "E-Food Desert Index",
    route: "/e-food-desert-index",
    topics: ["All", "Retail Futures"],
    component: MapContainer,
    metadata: EFDI,
    default: false
  },
  {
    id: "BroadbandSpeed",
    name: "Broadband Speed and Availability",
    route: "/broadband-speed",
    topics: ["All", "Digital"],
    component: MapContainer,
    metadata: BROADBANDSPEED,
    default: false
  },
  {
    id: "DwellingAgesPrices",
    name: "Dwelling Ages and Prices",
    route: "/dwelling-ages-prices",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: DWELLINGAGESPRICES,
    default: false
  },
  {
    id: "WMMobility",
    name: "West Midlands Mobility",
    route: "/wm-mobility",
    topics: ["All", "Transport & Movement"],
    component: MapContainer,
    metadata: WMMOBILITY,
    default: false
  },
  {
    id: "WMAccessibility",
    name: "West Midlands Accessibility",
    route: "/wm-accessibility",
    topics: ["All", "Transport & Movement"],
    component: MapContainer,
    metadata: WMACCESSIBILITY,
    default: false
  },
  {
    id: "ResidentialMoves",
    name: "Residential Moves: Distance/Deprivation",
    route: "/residential-moves",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: RESIDENTIALMOVES,
    default: false
  },
  {
    id: "ResidentialMovesLSOA",
    name: "Residential Moves: Distance/Deprivation LSOA",
    route: "/residential-moves-lsoa",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: RESIDENTIALMOVESLSOA,
    default: false,
    hidden: true
  },
  {
    id: "RetailCentres",
    name: "Retail Centres",
    route: "/retail-centres",
    topics: ["All", "Retail Futures"],
    component: MapContainer,
    metadata: RETAILCENTRES,
    default: false
  },
  {
    id: "Energy",
    name: "Energy Usage and Performance",
    route: "/energy",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: ENERGY,
    default: false
  },
  {
    id: "DomesticBuildings",
    name: "Building Attributes (Residential)",
    route: "/building-attributes",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: DOMESTICBUILDINGS,
    default: false,
    hidden: true
  },
  {
    id: "NonDomesticBuildings",
    name: "Building Attributes (Commercial)",
    route: "/building-attributes-commercial",
    topics: ["All", "Retail Futures"],
    component: MapContainer,
    metadata: NONDOMESTICBUILDINGS,
    default: false,
    hidden: true
  },
  {
    id: "PriorityPlacesForFood",
    name: "Priority Places for Food Index (Version 1)",
    route: "/priority-places-for-food-index",
    topics: ["All", "Population & Mobility", "Retail Futures"],
    component: MapContainer,
    metadata: PPFI,
    default: false
  },
  {
    id: "PriorityPlacesForFood2",
    name: "Priority Places for Food Index (Version 2)",
    route: "/food",
    topics: ["All", "Population & Mobility", "Retail Futures"],
    component: MapContainer,
    metadata: PPFI2,
    default: false
  },
  {
    id: "USRetailCentres",
    name: "Retail Centres (US)",
    route: "/us-retail-centres",
    topics: ["All", "Retail Futures"],
    component: MapContainer,
    metadata: USRETAILCENTRES,
    default: false
  },
  {
    id: "NeighbourhoodTrajectories",
    name: "Neighbourhood Trajectories",
    route: "/neighbourhood-trajectories",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: NEIGHBOURHOODTRAJECTORIES,
    default: false
  },
  {
    id: "InclusiveEconomy",
    name: "Inclusive Economy",
    route: "/inclusive-economy",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: INCLUSIVEECONOMY,
    default: false,
    hidden: true
  },
  {
    id: "SecondLanguages",
    name: "Second Languages",
    route: "/second-languages",
    topics: ["All", "Population & Mobility"],
    component: MapContainer,
    metadata: SECONDLANGUAGES,
    default: false,
    hidden: true
  },
  {
    id: "EDC",
    name: "Energy Deprivation Classification",
    route: "/energy-deprivation-classification",
    topics: ["All", "Population & Mobility", "Finance & Economy"],
    component: MapContainer,
    metadata: EDC,
    default: false,
    hidden: false
  }
];
