import Vue from "vue";
import VueRouter from "vue-router";
import { MAPS } from "@/config/Maps.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect:
      MAPS.filter(x => x.default == true).length > 0
        ? MAPS.filter(x => x.default == true)[0].route
        : MAPS[0].route
  },
  {
    path: "/london-output-area-classification-2021",
    redirect: { name: "LOAC2021" }
  }
];

MAPS.forEach(element => {
  routes.push({
    path: element.route,
    name: element.id,
    meta: { title: "CDRC Mapmaker: " + element.name },
    component: element.component,
    props: { metadata: element.metadata }
  });
});

const router = new VueRouter({
  /*mode: "history",*/
  routes: routes
});

router.beforeEach((to, from, next) => {
  if (
    Object.prototype.hasOwnProperty.call(from.query, "lat") &&
    Object.prototype.hasOwnProperty.call(from.query, "lon") &&
    Object.prototype.hasOwnProperty.call(from.query, "zoom")
  ) {
    // we have an old map that has a lat, long and zoom that we might need to apply to the new map

    //does new map have a lat long and zoom
    if (
      !Object.prototype.hasOwnProperty.call(to.query, "lat") ||
      !Object.prototype.hasOwnProperty.call(to.query, "lon") ||
      !Object.prototype.hasOwnProperty.call(to.query, "zoom")
    ) {
      to.query.lat = from.query.lat;
      to.query.lon = from.query.lon;
      to.query.zoom = from.query.zoom;
    }
  }
  if (Object.prototype.hasOwnProperty.call(from.query, "d")) {
    // we have an old map that has a data set that we might need to apply to the new map if a new one isn't already set
    if (!Object.prototype.hasOwnProperty.call(to.query, "d")) {
      to.query.d = from.query.d;
    }
  }

  next();
});

export default router;
