<template>
  <div class="map-nav" v-if="uiSettings.embed == 0">
    <b-navbar toggleable="lg" type="light">
      <img
        class="nav-logo"
        href="https://cdrc.ac.uk/"
        alt="CDRC logo"
        src="../assets/cdrc_logo.svg"
      />
      <b-navbar-brand
        ><a href="/">CDRC Mapmaker </a>
        <b-badge
          v-if="uiSettings.showNonProdAlert"
          variant="warning"
          v-bind:title="uiSettings.lastModified"
        >
          Beta
        </b-badge>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown v-for="topic in topics" :key="topic.id" right>
            <template #button-content
              ><span
                class="h6 font-icon-select-1 mr-2"
                :class="topic.iconClass"
              ></span
              >{{ topic.display }}</template
            >
            <b-dropdown-item
              v-for="map in maps
                .filter(function(element) {
                  return element.topics.includes(topic.name) && !element.hidden;
                })
                .sort((a, b) => (a.name > b.name ? 1 : -1))"
              v-bind:to="map.route"
              :key="map.id"
              >{{ map.name }}</b-dropdown-item
            >
            <b-dropdown-divider v-if="topic.id == 1"></b-dropdown-divider>
            <b-dropdown-item
              v-if="topic.id == 1"
              href="https://gladys.geog.ucl.ac.uk/maps.cdrc.ac.uk/#/indicators/ee_ain/"
              >Legacy Maps (on CDRC Maps)</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { MAPS } from "@/config/Maps";
import { TOPICS } from "@/config/Topics";

export default {
  name: "MapNav",
  methods: {
    setInitialUISettings: function() {
      let initialUISettings = {
        embed: 0,
        showNonProdAlert:
          window.location.hostname != "mapmaker.cdrc.ac.uk" &&
          window.location.hostname != "cdrc-maps.carto.solutions",
        lastModified: new Date(document.lastModified).toISOString()
      };
      const params = this.$route.query;

      if (
        Object.prototype.hasOwnProperty.call(params, "embed") &&
        !isNaN(params.embed)
      ) {
        initialUISettings.embed = Number(params.embed);
      }
      return initialUISettings;
    }
  },
  data() {
    /* DEBUG */
    if (process.env.NODE_ENV == "development") {
      console.log(
        "MapID\tMapName\tColumns\tIndividualIDField\tIDField\tLAField\tChoroplethTable\tUrbanTable\tDetailUrbanTable\tBuildingTable\tExtentID\t"
      );
      MAPS.forEach(function(m) {
        console.log(
          m.id +
            "\t" +
            m.name +
            "\t" +
            m.metadata.sql.split(" FROM ")[0] +
            "\t" +
            m.metadata.individualIdField +
            "\t" +
            m.metadata.idField +
            "\t" +
            m.metadata.ladCodeField +
            "\t" +
            m.metadata.sql.split(" FROM ")[1].split(" where ")[0] +
            "\t" +
            m.metadata.urbanSql.split(" FROM ")[1].split(" where ")[0] +
            "\t" +
            m.metadata.detailUrbanSql.split(" FROM ")[1].split(" where ")[0] +
            "\t" +
            m.metadata.buildingSql.split(" FROM ")[1].split(" where ")[0] +
            "\t" +
            m.metadata.extent +
            "\t"
        );
      });
    }
    /* END DEBUG */
    return {
      topics: TOPICS,
      maps: MAPS,
      uiSettings: this.setInitialUISettings()
    };
  }
};
</script>

<style scoped>
.navbar-brand {
  font-family: Museo, sans-serif;
  height: 45px;
  padding: 12px;
}

.navbar-brand a {
  text-decoration: none;
}

.nav-logo {
  height: 45px;
  display: inline-block;
  margin-right: 1rem;
  line-height: inherit;
  white-space: nowrap;
}
nav {
  z-index: 100;
  background: white;
}

.top-nav-collapse {
  padding: 10px 0;
}

@media screen and (max-width: 640px) {
  .nav-logo {
    display: none;
  }
  .navbar-brand {
    padding: 12px 0;
  }
}
</style>
