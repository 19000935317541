<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab :title="title" active v-if="data.feature.id">
        <h5 class="mb-1 text-center">
          <strong>{{ data.feature.name }}</strong>
        </h5>
        <p class="text-center">{{ data.feature.id }}</p>

        <table class="table mt-3">
          <thead>
            <tr>
              <th scope="col" class="text-center">
                {{ data.featureConfig.sections[0].headers[0] }}
              </th>
              <th scope="col" class="text-center">
                {{ data.featureConfig.sections[0].headers[1] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in nonEmptyMetrics" v-bind:key="row.id">
              <td class="text-center">
                {{ row.name }}
                <div
                  v-if="row.label"
                  class="text-secondary"
                  style="font-size: x-small"
                >
                  {{ row.label }}
                </div>
              </td>
              <td class="text-center">
                {{
                  data.feature.data[row.field]
                    | toFormatted(row.format, row.decimalPlaces)
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-tab>
      <b-tab
        :title="
          type == 'London'
            ? 'Greater London'
            : type == 'USA'
            ? 'State'
            : 'Region'
        "
        v-if="data.region.id"
      >
        <h5 class="mb-1 text-center">
          <strong>{{ data.region.name }}</strong>
        </h5>
        <p class="text-center">{{ data.region.id }}</p>

        <div v-if="Object.keys(data.region.data).length == 0">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="Object.keys(data.region.data).length > 0">
          <div
            v-for="section in data.geographyConfig.sections"
            v-bind:key="section.id"
          >
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[0] }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[1] }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in data.geographyConfig.sections[0].rows"
                  v-bind:key="row.id"
                >
                  <td class="text-center">
                    {{ row.name }}
                  </td>
                  <td class="text-center">
                    {{
                      data.region.data[row.field]
                        | toFormatted(row.format, row.decimalPlaces)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab title="Combined Authority" v-if="data.combined.id">
        <h5 class="mb-1 text-center">
          <strong>{{ data.combined.name }}</strong>
        </h5>
        <p class="text-center">{{ data.combined.id }}</p>

        <div v-if="Object.keys(data.combined.data).length == 0">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="Object.keys(data.combined.data).length > 0">
          <div
            v-for="section in data.geographyConfig.sections"
            v-bind:key="section.id"
          >
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[0] }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[1] }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in data.geographyConfig.sections[0].rows"
                  v-bind:key="row.id"
                >
                  <td class="text-center">
                    {{ row.name }}
                  </td>
                  <td class="text-center">
                    {{
                      data.combined.data[row.field]
                        | toFormatted(row.format, row.decimalPlaces)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab title="Constituency (2024)" v-if="data.pcon.id">
        <h5 class="mb-1 text-center">
          <strong>{{ data.pcon.name }}</strong>
        </h5>
        <p class="text-center">{{ data.pcon.id }}</p>

        <div v-if="Object.keys(data.pcon.data).length == 0">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="Object.keys(data.pcon.data).length > 0">
          <div
            v-for="section in data.geographyConfig.sections"
            v-bind:key="section.id"
          >
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[0] }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[1] }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in data.geographyConfig.sections[0].rows"
                  v-bind:key="row.id"
                >
                  <td class="text-center">
                    {{ row.name }}
                  </td>
                  <td class="text-center">
                    {{
                      data.pcon.data[row.field]
                        | toFormatted(row.format, row.decimalPlaces)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab
        :title="
          type == 'London'
            ? 'London Borough'
            : type == 'USA'
            ? 'County'
            : 'Local Authority (2023)'
        "
        v-if="data.lad.id"
      >
        <h5 class="mb-1 text-center">
          <strong>{{ data.lad.name }}</strong>
        </h5>
        <p class="text-center">{{ data.lad.id }}</p>

        <div v-if="Object.keys(data.lad.data).length == 0">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="Object.keys(data.lad.data).length > 0">
          <div
            v-for="section in data.geographyConfig.sections"
            v-bind:key="section.id"
          >
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[0] }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[1] }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in data.geographyConfig.sections[0].rows"
                  v-bind:key="row.id"
                >
                  <td class="text-center">
                    {{ row.name }}
                  </td>
                  <td class="text-center">
                    {{
                      data.lad.data[row.field]
                        | toFormatted(row.format, row.decimalPlaces)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab title="Retail Centre" v-if="data.retail.id">
        <h5 class="mb-1 text-center">
          <strong>{{ data.retail.name }}</strong>
        </h5>
        <p class="text-center">{{ data.retail.id }}</p>

        <div v-if="Object.keys(data.retail.data).length == 0">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="Object.keys(data.retail.data).length > 0">
          <div
            v-for="section in data.geographyConfig.sections"
            v-bind:key="section.id"
          >
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[0] }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[1] }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in data.geographyConfig.sections[0].rows"
                  v-bind:key="row.id"
                >
                  <td class="text-center">
                    {{ row.name }}
                  </td>
                  <td class="text-center">
                    {{
                      data.retail.data[row.field]
                        | toFormatted(row.format, row.decimalPlaces)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab title="Polygon" v-if="data.adhoc.id">
        <h5 class="mb-1 text-center">
          <strong>{{ data.adhoc.name }}</strong>
        </h5>
        <p class="text-center">{{ data.adhoc.id }}</p>

        <div v-if="Object.keys(data.adhoc.data).length == 0">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="Object.keys(data.adhoc.data).length > 0">
          <div
            v-for="section in data.geographyConfig.sections"
            v-bind:key="section.id"
          >
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[0] }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    {{ data.geographyConfig.sections[0].headers[1] }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in data.geographyConfig.sections[0].rows"
                  v-bind:key="row.id"
                >
                  <td class="text-center">
                    {{ row.name }}
                  </td>
                  <td class="text-center">
                    {{
                      data.adhoc.data[row.field]
                        | toFormatted(row.format, row.decimalPlaces)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  name: "MapCustomPopupContents",
  props: { data: Object, title: String, type: String },
  computed: {
    nonEmptyMetrics: function() {
      var rows = this.data.featureConfig.sections[0].rows;
      return rows;
      //return rows.filter(i => this.data.feature.data[i.field] !== undefined);
    }
  },
  filters: {
    toFormatted(value, type, decimalPlaces) {
      if (type == "string" && !value) {
        return "No data";
      } else if (value === undefined) {
        return "No data";
      }
      if (decimalPlaces === null) {
        decimalPlaces = 1;
      }

      if (type == "percent") {
        value = value * 100;
      }

      if (type != "string") {
        value =
          Math.round(value * Math.pow(10, decimalPlaces)) /
          Math.pow(10, decimalPlaces);
      }

      if (type == "percent" || type == "%") {
        value = value + "%";
      }

      if (type == "currency") {
        value = "£" + value;
      }

      return value;
    }
  }
};
</script>
