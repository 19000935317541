<template>
  <div class="map-search">
    <vue-bootstrap-typeahead
      v-model="searchTerm"
      :data="results"
      :serializer="item => item.rm_format"
      @hit="selectedResult = $event"
      placeholder="Jump to postcode..."
    />
  </div>
</template>

<script>
import "whatwg-fetch";
export default {
  name: "MapNav",
  methods: {
    termChanged: function() {}
  },
  data() {
    return {
      selectedResult: null,
      searchTerm: "",
      results: []
    };
  },
  watch: {
    searchTerm(newTerm) {
      const vm = this;
      const formData = new FormData();
      formData.append(
        "q",
        "SELECT rm_format, st_x(the_geom) as x, st_y(the_geom) as y FROM postcode where str_format like '" +
          newTerm.toUpperCase().replace(" ", "") +
          "%' limit 100;"
      );
      fetch(
        "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
        {
          method: "POST",
          body: formData
        }
      )
        .then(e => e.json())
        .then(results => {
          vm.results = results.rows;
        });
    },
    selectedResult(selectedResult) {
      this.$emit("selected", {
        lat: selectedResult.y,
        long: selectedResult.x,
        zoom: 15
      });
    }
  }
};
</script>

<style scoped>
.map-search {
  position: absolute;
  top: 0;
  left: 40px;
  margin: 10px;
  max-width: 175px;
}
</style>
