const cols = "the_geom_webmercator, lsoa11_cd, lsoa11_nm, lad_code, iu2grp_cd";

export const IUC = {
  title: "Internet User Classification",
  description:
    "How people living in different parts of Great Britain interact with the Internet.",
  downloadLink: "https://data.cdrc.ac.uk/dataset/internet-user-classification",
  storyLinks: [
    {
      link: "https://data.cdrc.ac.uk/stories/iuc",
      title: "The Great British geography of Internet use and engagement"
    }
  ],
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",

  sql: "SELECT " + cols + " FROM lsoa11_uk where iu2grp_cd != '0'",
  buildingSql:
    "SELECT " + cols + " FROM lsoa11_uk_buildings where iu2grp_cd != '0'",
  detailUrbanSql:
    "SELECT " + cols + " FROM lsoa11_uk_detail_urban where iu2grp_cd != '0'",
  urbanSql: "SELECT " + cols + " FROM lsoa11_uk_urban where iu2grp_cd != '0'",
  defaultLayers: "1111000",
  extent: 0,
  popup: true,
  popupType: "classification",
  idField: "lsoa11_cd",
  geogNameField: "lsoa11_nm",
  ladCodeField: "lad_code",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.iu2grp_cd = '1' then 1 else 0 end) as count_1,
      sum(case when d.iu2grp_cd = '2' then 1 else 0 end) as count_2,
      sum(case when d.iu2grp_cd = '3' then 1 else 0 end) as count_3,
      sum(case when d.iu2grp_cd = '4' then 1 else 0 end) as count_4,
      sum(case when d.iu2grp_cd = '5' then 1 else 0 end) as count_5,
      sum(case when d.iu2grp_cd = '6' then 1 else 0 end) as count_6,
      sum(case when d.iu2grp_cd = '7' then 1 else 0 end) as count_7,
      sum(case when d.iu2grp_cd = '8' then 1 else 0 end) as count_8,
      sum(case when d.iu2grp_cd = '9' then 1 else 0 end) as count_9,
      sum(case when d.iu2grp_cd = '10' then 1 else 0 end) as count_10
  FROM oliverobrien.lsoa11_uk d 
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Group",
      field: "iu2grp_cd",
      lookup: "id",
      groups: [
        {
          id: "1",
          name: "e-Cultural Creators",
          colour: "#ea4d78",
          visible: true,
          description:
            "This Group has high levels of Internet engagement, particularly regarding social networks, communication, streaming and gaming, but relatively low levels of online shopping, besides groceries. They are new but very active users, with a very high proportion of the population engaging on a daily basis. Their online behaviour can be explained by a demographic base that suggests a transitionary nature; the age structure of the Group is young, typically aged between 18 to 24, and with a strong presence of multicultural and student populations. They have a well-above average ownership of laptop devices, and an above average Internet access via mobile and at public places. Geographically, this Group is mainly located close to the city centre or within the proximity of Higher Education Institutes, where infrastructure accessibility, such as cable broadband, is sufficient. "
        },
        {
          id: "2",
          name: "e-Professionals",
          colour: "#f36d5a",
          visible: true,
          description:
            "The e-Professionals Group have high levels of Internet engagement, and comprises fairly young populations of urban professionals, typically aged between 25 and 34. They are experienced users and engage with the Internet daily and in a variety of settings. While communication and entertainment activities are very common, they tend to favour entertainment, such as gaming, more than social networks. They also carry out a significant portion of shopping activities online, particularly for non-groceries, and they use a variety of devices and methods to access the Internet. This Group is ethnically diverse, with a very strong representation of white, non-British populations. They are well-qualified and have very high availability of Internet at work. This Group tends to be found at in residential areas abutting city centres or within affluent suburbs."
        },
        {
          id: "3",
          name: "e-Veterans",
          colour: "#e4a5d0",
          visible: true,
          description:
            "The e-Veterans Group represents affluent families, usually located within low-density suburbs, with populations of mainly middle-aged and highly qualified professionals. They are more likely to be frequent and experienced users of the Internet, having the second highest levels of Internet access at work after the e-Professionals users. They engage with the Internet using multiple devices and in a variety of ways. They are fairly mature users and as such they have higher levels of engagement for information seeking, online services and shopping, but relatively less so for communication and entertainment, particularly social networks or gaming."
        },
        {
          id: "4",
          name: "Youthful Urban Fringe",
          colour: "#ffd39b",
          visible: true,
          description:
            "This Group often resides at the edge of city centres and are often young and drawn from ethnic minorities. These include a mixture of students and other young urbanites living in informal households, often at the edges of materially deprived communities. Access through desktop devices is particularly low, suggesting a young and mobile profile of individuals. Access to broadband is average, possibly due to other modes of access, such as Internet usage in public places. The levels of Internet engagement are average over-all, with high levels of social media usage but low patronage of online retailing."
        },
        {
          id: "5",
          name: "e-Rational Utilitarians",
          colour: "#a5cfbc",
          visible: true,
          description:
            "Comprising mainly rural and semi-rural areas at the city fringe, high demand for Internet services by members of this Group is constrained by poor infrastructure. Users undertake online shopping, particularly for groceries, perhaps because of the limited offer from 'bricks and mortar' retailers. Users tend to be late middle-aged or elderly, and as might be expected, include a high percentage of retired home owners. The preferred method of engagement with the Internet is personal computers located at home, with low levels of mobile access. In addition to shopping, users search for information or access online banking rather than engage with social networks or gaming: the Internet is used as a utility rather than a conduit for entertainment."
        },
        {
          id: "6",
          name: "e-Mainstream",
          colour: "#d2d1ab",
          visible: true,
          description:
            "This Group exhibit modal Internet user characteristics but are drawn from a wide range of social echelons as defined using conventional socioeconomic data, and most likely represent heterogeneous neighbourhoods. Geographically, the Group is usually located at the periphery of urban areas or in transitional neighbourhoods. Their level of engagement is average across most attributes, characterising the typical user."
        },
        {
          id: "7",
          name: "Passive and Uncommitted Users",
          colour: "#79cdcd",
          visible: true,
          description:
            "Many individuals have limited or no interaction with the Internet. They tend to reside outside city centres and close to the suburbs or semi-rural areas. Members of this Group have few distinctive characteristics in conventional socioeconomic terms, albeit higher levels of employment in semi-skilled and blue-collar occupations. Individuals are rarely online, and most commonly report use once a week or less. Access to broadband is well below average, and for those online, there is mild preference for access via smartphones. The Internet is typically used for social networks, gaming and some limited online shopping. "
        },
        {
          id: "8",
          name: "Digital Seniors",
          colour: "#dd7cdc",
          visible: true,
          description:
            "Members of this Group are ageing and predominantly White British, retired and relatively affluent. They make average use of the Internet, typically using a personal computer at home. Despite being infrequent users, they are adept enough to use the Internet for information seeking, financial services and online shopping, but less so for social networks, streaming or gaming. Members of this Group typically reside in semi-rural or coastal regions, where infrastructure provision is often limited."
        },
        {
          id: "9",
          name: "Settled Offline Communities",
          colour: "#808fee",
          visible: true,
          description:
            "Most members of this Group are elderly, White British and retired, and tend to reside in semi-rural areas. They undertake only limited engagement with the Internet, they may have only rare access or indeed no access to it at all. Any online behaviour tends to be through home computers rather than mobile devices, and is focused upon information seeking and limited online shopping (particularly for more bulky items such as white goods) rather than social networking, gaming or media streaming."
        },
        {
          id: "10",
          name: "e-Withdrawn",
          colour: "#8470ff",
          visible: true,
          description:
            "This Group is mainly characterised by individuals who are the least engaged with the Internet. Their geography is expressed by areas that are associated with those more deprived neighbourhoods of urban regions. The socio-economic profile of the population is characterised by less affluent white British individuals or areas of high ethnic diversity; and it has the highest rate of unemployment and social housing among all other Groups. The e-Withdrawn Group appears to have the highest ratio of people that donÕt have access, or have access but never engage with the Internet. It also expresses the lowest rates of engagement in terms of information seeking and financial services, as well as the lowest rate in terms of online access via a mobile device. Online shopping is also particularly low, with the exception of Clothing on Credit, suggesting an opportunistic dimension to Internet usage. This is further reinforced by the higher than average access to Cable broadband by TV Provider, which may suggest that some individuals have opted into broadband mainly for the TV-associated benefits. It is possible that many people within this Group have opted out of online engagement, either because it is considered unnecessary or because of economic reasons. "
        }
      ]
    }
  ]
};
