import colorbrewer from "colorbrewer";
const ramp = colorbrewer.Spectral[11];

const cols =
  "thecount, the_geom_webmercator, type_id, cer_id, fuel_id, aircon_id, " +
  "floor_area ";

const legendStopsFuel = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#555555", name: "No data" },
      { value: 1, colour: ramp[1], name: "Electricity (Grid)" },
      { value: 2, colour: ramp[9], name: "Natural Gas" },
      { value: 3, colour: ramp[4], name: "Oil or LPG" },
      { value: 4, colour: ramp[3], name: "District Heating" },
      { value: 5, colour: ramp[6], name: "Biomass/Biogas" },
      { value: 6, colour: ramp[7], name: "Other" }
    ]
  }
];

const legendStopsType = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#555555", name: "No data" },
      { value: 1, colour: "#CC2200", name: "Retail" },
      { value: 2, colour: "#FFFF00", name: "Restaurant, Pub" },
      { value: 3, colour: "#6600AA", name: "Office, Workshop" },
      { value: 4, colour: "#AAFF66", name: "Warehouse, Storage" },
      { value: 5, colour: "#00FF00", name: "Industrial" },
      { value: 6, colour: "#0072CE", name: "Medical, Care" },
      { value: 7, colour: "#FFFFFF", name: "Education" },
      { value: 8, colour: "#FFAA00", name: "Hotel" },
      { value: 9, colour: "#FF00FF", name: "Community Centre" },
      { value: 10, colour: "#00FFFF", name: "Prison, Court" },
      { value: 11, colour: "#FFAAAA", name: "Cultural" },
      { value: 12, colour: "#BBBBFF", name: "Communal Dwelling" },
      { value: 13, colour: "#0000FF", name: "Leisure" },
      { value: 14, colour: "#AAFFFF", name: "Transport Hub" },
      { value: 15, colour: "#000000", name: "Other (e.g. Services)" }
    ]
  }
];

const legendStopsEPC = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#555555", name: "No data" },
      { value: 1, colour: ramp[10], name: "A+" },
      { value: 2, colour: ramp[9], name: "A" },
      { value: 3, colour: ramp[8], name: "B" },
      { value: 4, colour: ramp[7], name: "C" },
      { value: 5, colour: ramp[6], name: "D" },
      { value: 6, colour: ramp[4], name: "E" },
      { value: 7, colour: ramp[2], name: "F" },
      { value: 8, colour: ramp[0], name: "G" }
    ]
  }
];

const legendStopsYesNo = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#555555", name: "No data" },
      { value: 1, colour: ramp[3], name: "Yes" },
      { value: 2, colour: ramp[9], name: "No" }
    ]
  }
];

const legendStopsArea = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#555555", name: "No data" },
      { value: 0.001, colour: ramp[10], name: "Under 25sqm" },
      { value: 25, colour: ramp[9], name: "25-50sqm" },
      { value: 50, colour: ramp[8], name: "50-100sqm" },
      { value: 100, colour: ramp[7], name: "100-250sqm" },
      { value: 250, colour: ramp[6], name: "250-500sqm" },
      { value: 500, colour: ramp[5], name: "500-1000sqm" },
      { value: 1000, colour: ramp[4], name: "1000-2500sqm" },
      { value: 2500, colour: ramp[3], name: "2500-5000sqm" },
      { value: 5000, colour: ramp[2], name: "5000-10000sqm" },
      { value: 10000, colour: ramp[1], name: "10000-25000sqm" },
      { value: 25000, colour: ramp[0], name: "Over 25000sqm" }
    ]
  }
];
/*
const legendStopsNumber = [
    {
      id: 0,
      stops: [
        { value: -999999, colour: "#555555", name: "No data" },
        { value: 0, colour: ramp[10], name: "0" },
        { value: 1, colour: ramp[9], name: "1" },
        { value: 2, colour: ramp[8], name: "2" },
        { value: 3, colour: ramp[7], name: "3" },
        { value: 4, colour: ramp[6], name: "4" },
        { value: 5, colour: ramp[5], name: "5-10" },
        { value: 10, colour: ramp[4], name: "10-20" },
        { value: 20, colour: ramp[3], name: "20-30" },
        { value: 30, colour: ramp[2], name: "30-40" },
        { value: 40, colour: ramp[1], name: "40-50" },
        { value: 50, colour: ramp[0], name: "Over 50" }
      ]
    }
  ]; */

export const NONDOMESTICBUILDINGS = {
  title: "Building Attributes (Commercial)",
  description:
    "Individual building details based on data from EPC certificates for non-domestic (commercial/industrial) premises, with modal values shown for workzone area centroids at lower zoom levels. ",
  //"Note: recent build years may show property conversion dates, and floor areas are for individual dwellings.",
  downloadLink: "",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  individualSql:
    "SELECT 'UPRN: ' || uprn as uprn, la_cd, la_nm, 1 as " +
    cols +
    " FROM uprn22_gb_ndcdata",
  sql: "SELECT " + cols + ", wz_cd, la_cd, la_nm FROM wz11_gb_pwcs_ndcdata",
  buildingSql:
    "SELECT the_geom_webmercator, '' as wz_cd, la17_cd as la_cd, la17_nm as la_nm FROM oa11_uk_buildings",
  detailUrbanSql:
    "SELECT " + cols + ", wz_cd, la_cd, la_nm FROM wz11_gb_pwcs_ndcdata",
  urbanSql:
    "SELECT " + cols + ", wz_cd, la_cd, la_nm FROM wz11_gb_pwcs_ndcdata",
  defaultLayers: "1111000",
  extent: 0,
  popup: false,
  idField: "wz_cd",
  individualIdField: "uprn",
  geogNameField: "la_nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la_cd",
  hoverFieldMetricMode: "All",
  metrics: [
    {
      id: 0,
      name: "Building Use",
      field: "type_id",
      display: "Stop Name",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsType,
      additionalMetrics: []
    },
    {
      id: 1,
      name: "EPC Rating",
      field: "cer_id",
      display: "Stop Name",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Name" }],
      legendStops: legendStopsEPC,
      additionalMetrics: []
    },
    {
      id: 2,
      name: "Main Fuel",
      field: "fuel_id",
      display: "Stop Name",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsFuel,
      additionalMetrics: []
    },
    {
      id: 3,
      name: "Air Conditioning (not Scotland)",
      field: "aircon_id",
      display: "Stop Name",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsYesNo,
      additionalMetrics: []
    },
    {
      id: 4,
      name: "Floor Area",
      field: "floor_area",
      display: "sqm",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsArea,
      additionalMetrics: []
    } /*,
    {
        id: 5,
        name: "Number of Buildings",
        field: "thecount",
        display: "",
        decimals: 0,
        legendOptions: [{ id: 0, name: "Value" }],
        legendStops: legendStopsNumber,
        additionalMetrics: []
      } */
  ]
};
