const cols =
  "the_geom_webmercator, geogcode, geogname, lad23cd, edc_sup, edc_grp";

export const EDC = {
  title: "Energy Deprivation Classification",
  description:
    "A hierarchical geodemographic classification that identifies small areas of the country with similar characteristics of inability and insecurity in access to adequate energy services.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/energy-deprivation-classification",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",

  sql: "SELECT " + cols + " FROM ppfi2 where edc_sup is not null",
  buildingSql: "SELECT " + cols + " FROM ppfi2_vmd23 where edc_sup is not null",
  detailUrbanSql:
    "SELECT " + cols + " FROM ppfi2_bua22 where edc_sup is not null",
  urbanSql: "SELECT " + cols + " FROM ppfi2_bua22 where edc_sup is not null",
  defaultLayers: "1111000",
  initialValue: 1,
  extent: 10,
  popup: true,
  popupType: "classification",
  idField: "geogcode",
  geogNameField: "geogname",
  ladCodeField: "lad23cd",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.edc_sup = 'A' then 1 else 0 end) as count_A,
      sum(case when d.edc_sup = 'B' then 1 else 0 end) as count_B,
      sum(case when d.edc_sup = 'C' then 1 else 0 end) as count_C,
      sum(case when d.edc_sup = 'D' then 1 else 0 end) as count_D,
      sum(case when d.edc_sup = 'E' then 1 else 0 end) as count_E,
      sum(case when d.edc_sup = 'F' then 1 else 0 end) as count_F,
      sum(case when d.edc_grp = 'A1' then 1 else 0 end) as count_A1,
      sum(case when d.edc_grp = 'A2' then 1 else 0 end) as count_A2,
      sum(case when d.edc_grp = 'A3' then 1 else 0 end) as count_A3,
      sum(case when d.edc_grp = 'B1' then 1 else 0 end) as count_B1,
      sum(case when d.edc_grp = 'B2' then 1 else 0 end) as count_B2,
      sum(case when d.edc_grp = 'C1' then 1 else 0 end) as count_C1,
      sum(case when d.edc_grp = 'C2' then 1 else 0 end) as count_C2,
      sum(case when d.edc_grp = 'D1' then 1 else 0 end) as count_D1,
      sum(case when d.edc_grp = 'D2' then 1 else 0 end) as count_D2,  
      sum(case when d.edc_grp = 'E1' then 1 else 0 end) as count_E1,
      sum(case when d.edc_grp = 'E2' then 1 else 0 end) as count_E2,
      sum(case when d.edc_grp = 'E3' then 1 else 0 end) as count_E3,
      sum(case when d.edc_grp = 'F1' then 1 else 0 end) as count_F1,
      sum(case when d.edc_grp = 'F2' then 1 else 0 end) as count_F2
  FROM oliverobrien.ppfi2 d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Supergroup",
      field: "edc_sup",
      lookup: "id",
      groups: [
        {
          id: "A",
          name: "Energy Efficient Suburbs",
          colour: "#66C2A5",
          visible: true,
          description:
            "Residents of the Supergroup typically live in new properties built from 2003 onwards with the highest energy efficiency ratings (i.e., A and B) compared to other Supergroups. They tend to own these properties financed using a mortgage, loans, or via a shared ownership. Properties within this Supergroup are typically semi-detached or terraced, and well-connected to the gas grid. A higher proportion of families have very young children below four years old. The Supergroup is found throughout suburban areas in Great Britain, particularly in the south of England."
        },
        {
          id: "B",
          name: "Energy Secure Fringes",
          colour: "#FC8D62",
          visible: true,
          description:
            "This Supergroup is characterised by residents of retirement age or higher incomes, who own their detached or semi-detached properties either outright, with mortgages, loans or through shared ownership. Properties are typically well supplied by energy, including well-connected to central heating and mains gas. However, properties tend to be under-occupied and given many residents are ageing, there tend to be higher gas consumption than might be the case in smaller homes or of younger ages. The Supergroup is pervasive in urban outskirts, and towns close to cities."
        },
        {
          id: "C",
          name: "Energy Isolated Urbanities",
          colour: "#8DA0CB",
          visible: true,
          description:
            "Many residents within this Supergroup are economically inactive full-time students and ethnic minorities, concentrating in high-density neighbourhoods of privately rented flats or shared houses. They rely heavily on electricity for heating, cooking, and lighting, as they are often not connected to the gas grid, and central heating is often limited within their properties. Alongside these densely occupied properties, some residents also reside in newer flats with higher energy efficiency ratings (i.e., A and B). The Supergroup is predominately concentrated in the city centres of Great Britain."
        },
        {
          id: "D",
          name: "Rural Energy Inefficiency",
          colour: "#E78AC3",
          visible: true,
          description:
            "Neighbourhoods classified in this Supergroup are predominately located across rural parts of Great Britain. Residents are typically ageing, retired and of the white ethnic group, tending to live in detached houses that they own outright. Properties within these areas were typically built before 1930 and lack a gas grid connection due to their rurality. Most properties have a relatively low energy efficiency (i.e., E, F and G) and produced higher carbon dioxide emissions annually. Compared to other Supergroups, more households use only renewable energy to meet their energy demands."
        },
        {
          id: "E",
          name: "Energy Vulnerable Communities",
          colour: "#A6D854",
          visible: true,
          description:
            "Residents living in this Supergroup typically have imbalanced energy demands and supplies, predominantly concentrated at neighbourhoods in urban edges in northern and the midlands of England, the southern parts of Wales, and the middle of Scotland. Properties within these areas are typically terraced social housing or rented flats. A higher proportion of residents receive government welfare to help with essential living costs. Many households of this Supergroup comprise lone parents with dependent children, provide unpaid care, or have a long-term sickness or disability. Residents of this Supergroup are more likely to use prepayment electricity meters to manage their energy bills, which are more expensive than other payment methods, further exacerbating their inability to afford adequate energy services."
        },
        {
          id: "F",
          name: "Energy Deprived Periphery",
          colour: "#FFD92F",
          visible: true,
          description:
            "Areas represented by this Supergroup contain households that are the most energy deprived. Neighbourhoods have a mixture of rented terraced houses, flats, and shared occupancy older properties, that often have constrained access to energy, including no central heating and dependence on prepayment electricity meters. These low-income areas have a high proportion of full-time students, ethnic minorities, lone parent households and young dependent children. Households are more likely to live within overcrowded properties with more than five people and have more family caring responsibilities. The Supergroup is prevalent in peripheral parts of urban areas."
        }
      ]
    },
    {
      id: 1,
      name: "Group",
      field: "edc_grp",
      lookup: "id",
      groups: [
        {
          id: "A1",
          parent: "A",
          name: "Terraced Communities",
          colour: "#539B84",
          visible: true,
          description:
            "This Group includes residents who tend to live in their own terraced or semi-detached homes with slightly older population, more unpaid care, and more elementary occupation than the Supergroup average. These residents are more likely to be the white ethnic group and live within smaller household size. Additionally, larger portions of households within this Group rely on a prepayment electricity meter compared to other areas within this Supergroup. Many concentrations of this Group occur in small towns or suburban areas around greenspace throughout Great Britain."
        },
        {
          id: "A2",
          parent: "A",
          name: "Family Nest-Builders",
          colour: "#97C3B5",
          visible: true,
          description:
            "This Group is typified by young families with dependent children under four years old, living in newly built detached houses. Properties within this Group are typically owned based on a mortgage or shared ownership. Most houses have central heating and high energy efficiency ratings (i.e., A and B), leading to low carbon emissions annually. Populations in this Group are located across suburbia and in commuter towns. They are particularly concentrated in some outer areas of London."
        },
        {
          id: "A3",
          parent: "A",
          name: "Ethnic Minority Workers",
          colour: "#C5FFEC",
          visible: true,
          description:
            "This Group is characterised by well-paid ethnic minorities who tend to live in semi-detached or shared houses with property ownership obtained through mortgages, loans, or shared ownership. Residents within this Group are more likely to live in larger household size than the Supergroup average, leading to higher gas consumption. This group is commonly found in village and civil parishes across Great Britain."
        },
        {
          id: "B1",
          parent: "B",
          name: "Semi-Detached Owner-Occupiers",
          colour: "#CA714E",
          visible: true,
          description:
            "This Group encompasses the largest number of small areas in Great Britain. In addition to detached properties, a substantial portion of the housing also comprises semi-detached houses, which are predominantly owned outright by the residents. Compared to another group within the Supergroup, residents of these neighbourhoods are more likely to be ageing people who are retired and live in smaller households. The group is primarily located in towns and suburban areas across northern and western England, southern Wales, and the urban fringes of Scotland."
        },
        {
          id: "B2",
          parent: "B",
          name: "Detached Networked Profligates",
          colour: "#FC9872",
          visible: true,
          description:
            "This Group is characterised by residents of higher-skilled occupation and higher household incomes who own their detached properties either outright, with mortgages, loans or through shared ownership. Many properties within this group are under-occupied, and therefore have higher gas/electricity consumption amounts for heating, producing more carbon dioxide emissions per capita annually. The Group is often found in the surroundings of London and urban fringe areas in Great Britain."
        },
        {
          id: "C1",
          parent: "C",
          name: "Old-Shared & Multi-Occupancy Renters",
          colour: "#63708E",
          visible: true,
          description:
            "This Group is distributed across the outskirts of dense urban cores and other central locations within towns. Inheriting many characteristics from the Supergroup, this Group also comprises a higher proportion of houses built before 1930, as well as part of converted or shared houses. Households in these houses typically have higher incomes. This Group is commonly found in the central areas of historical cities and towns."
        },
        {
          id: "C2",
          parent: "C",
          name: "Electricity Intense Renters",
          colour: "#C6D0E5",
          visible: true,
          description:
            "Properties in this Group are predominantly comprising rented flats constructed after 2003, featuring high energy efficiency ratings (A or B). Compared to the average for the Supergroup, these properties are more likely to lack access to central heating and mains gas connections, leading to a heavy reliance on electricity and the use of prepayment meters for managing energy payments. Additionally, this group exhibits a higher unemployment rate and a greater acceptance of Universal Credit. These neighbourhoods are typically located in the urban cores of cities."
        },
        {
          id: "D1",
          parent: "D",
          name: "Ageing Gas-Scarce Profligates",
          colour: "#B96E9C",
          visible: true,
          description:
            "Most houses in this Group were primarily constructed before 1930 and possess some of the lowest energy efficiency ratings (E, F or G), leading to higher annual carbon emissions. These are typically detached houses with at least one unoccupied room. Due to their high prevalence of not being connected to the gas grid and lacking access to central heating, residents in these houses tend to rely solely on electricity or renewable energy sources to satisfy their energy demand. This group is commonly found in rural areas across Great Britain."
        },
        {
          id: "D2",
          parent: "D",
          name: "Rural Energy Security",
          colour: "#EEADD5",
          visible: true,
          description:
            "Residents in this Group are more likely to be older, retired individuals, and they tend to have better access to central heating compared to the Supergroup average. The energy efficiency of the houses in this Group is relatively better than that of another group within the Supergroup. These neighbourhoods are dispersed throughout most rural areas of Wales and Scotland, as well as the southwest, east, and north of England."
        },
        {
          id: "E1",
          parent: "E",
          name: "Energy Burdened",
          colour: "#74973B",
          visible: true,
          description:
            "Compared to the Supergroup average, residents of this Group are more likely to rely solely on electricity and prepayment meters for energy payments. A higher proportion of residents live in flats, with entry-level qualifications and unemployment status. Most of these neighbourhoods are concentrated in the outer areas of Southern Wales and Northern England."
        },
        {
          id: "E2",
          parent: "E",
          name: "Semi-Detached Strivers",
          colour: "#A0BB6A",
          visible: true,
          description:
            "This Group is predominantly characterized by semi-detached houses, with a slightly higher proportion of retired individuals compared to the Supergroup average. Residents tend to have higher levels of qualifications, which correspond to a lower unemployment rate in this Group. The neighbourhoods are primarily concentrated in the suburban areas of Scotland."
        },
        {
          id: "E3",
          parent: "E",
          name: "Hard-Pressed Young Families",
          colour: "#CAE898",
          visible: true,
          description:
            "Residents of this Group are more likely to be social renters and use prepayment electricity meters. There are many lone parents with dependent children and young children aged below four years old within these areas. Additionally, a higher rate of residents has responsibilities for care giving to family members with a disability or long-term health issue. Residents tend to be employed in elementary occupations and many receive Universal Credit to support their living costs. These communities are typically found on the outer edges of many towns and cities across Great Britain."
        },
        {
          id: "F1",
          parent: "F",
          name: "Overcrowded Energy Precarity",
          colour: "#CCAE26",
          visible: true,
          description:
            "Old housing with poor energy efficiency ratings is common in this Group, which typically comprises of often overcrowded dwellings. The housing landscape primarily consists of privately rented terraced homes or shared houses. A significant proportion of residents living in these areas are ethnic minorities who cannot speak English, some of which have higher family care duties compared to the Supergroup average. This Group is mostly found in outer parts of urban areas in England."
        },
        {
          id: "F2",
          parent: "F",
          name: "Energy Strapped Enclaves",
          colour: "#FFE882",
          visible: true,
          description:
            "This Group is differentiated by the higher proportion of residents who live in socially rented flats, with heavy reliance on electricity and prepayment meters. Residents are more likely to be lone parents with dependent children or have a disability or chronic health issues. On average, properties are relatively lacking access to mains gas and are concentrated on the periphery of primary cities in England and Wales."
        }
      ]
    }
  ]
};
