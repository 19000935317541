const cols = "the_geom_webmercator, id, code";

export const SPATIALSIGNATURES = {
  title: "Spatial Signatures",
  description:
    "Spatial signatures characterise space based on form and function in a way designed to understand urban environments. Part of the Urban Grammar project, this maps shows a typology of spatial signatures in Great Britain. Each type has a distinct character capturing what the place looks like (form) and how it is used (function).",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/spatial-signatures-great-britain",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",

  sql: "SELECT " + cols + " FROM spatial_signatures_gb_clean_simplified",
  buildingSql:
    "SELECT " + cols + " FROM spatial_signatures_gb_clean_simplified",
  detailUrbanSql:
    "SELECT " + cols + " FROM spatial_signatures_gb_clean_simplified",
  urbanSql: "SELECT " + cols + " FROM spatial_signatures_gb_clean_simplified",
  defaultLayers: "1111000",
  extent: 0,
  popup: true,
  popupType: "classification",
  idField: "code",
  geogNameField: "id",
  geogNamePrefix: "Signature ID ",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.code = 'ACS' then 1 else 0 end) as count_ACS,
      sum(case when d.code = 'CRN' then 1 else 0 end) as count_CRN,
      sum(case when d.code = 'COA' then 1 else 0 end) as count_COA,
      sum(case when d.code = 'DRN' then 1 else 0 end) as count_DRN,
      sum(case when d.code = 'DUN' then 1 else 0 end) as count_DUN,
      sum(case when d.code = 'DIS' then 1 else 0 end) as count_DIS,
      sum(case when d.code = 'DIU' then 1 else 0 end) as count_DIU,
      sum(case when d.code = 'GRQ' then 1 else 0 end) as count_GRQ,
      sum(case when d.code = 'HDU' then 1 else 0 end) as count_HDU,
      sum(case when d.code = 'LOU' then 1 else 0 end) as count_LOU,
      sum(case when d.code = 'MEU' then 1 else 0 end) as count_MEU,
      sum(case when d.code = 'OPS' then 1 else 0 end) as count_OPS,
      sum(case when d.code = 'REU' then 1 else 0 end) as count_REU,
      sum(case when d.code = 'URB' then 1 else 0 end) as count_URB,
      sum(case when d.code = 'WAL' then 1 else 0 end) as count_WAL,
      sum(case when d.code = 'WIC' then 1 else 0 end) as count_WIC
  FROM oliverobrien.spatial_signatures_gb_clean_simplified d 
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Category",
      field: "code",
      lookup: "id",
      groups: [
        {
          id: "ACS",
          name: "Accessible suburbia",
          colour: "#8fa37e",
          visible: true,
          description:
            "This covers residential development on the urban periphery with a relatively legible and connected street network, albeit less so than other more urban signature types. Areas in this signature feature low density, both in terms of population and built-up area, lack of jobs and services. For these reasons, 'accessible suburbia' largely acts as dormitories."
        },
        {
          id: "CRN",
          name: "Connected residential neighbourhoods",
          colour: "#94666e",
          visible: true,
          description:
            "These are relatively dense urban areas, both in terms of population and built-up area, that tend to be formed around well-connected street networks. They have access to services and some jobs but may be further away from city centres leading to higher dependency on cars and public transport for their residents."
        },
        {
          id: "COA",
          name: "Countryside agriculture",
          colour: "#f2e6c7",
          visible: true,
          description:
            "This features much of the English countryside and displays a high degree of agriculture including both fields and pastures. There are a few buildings scattered across the area but, for the most part, it is green space."
        },
        {
          id: "DRN",
          name: "Dense residential neighbourhoods",
          colour: "#678ea6",
          visible: true,
          description:
            "This is an abundant signature often covering large parts of cities outside of their centres. It has primarily residential purpose and high population density, varied street network patterns, and some services and jobs but not in high intensity."
        },
        {
          id: "DUN",
          name: "Dense urban neighbourhoods",
          colour: "#efc758",
          visible: true,
          description:
            "These are areas of inner-city with high population and built-up density of a predominantly residential nature but with direct access to jobs and services. This signature type tends to be relatively walkable and, in the case of some towns, may even form their centres."
        },
        {
          id: "DIS",
          name: "Disconnected suburbia",
          colour: "#f0d17d",
          visible: true,
          description:
            "This includes residential developments in the outskirts of cities or even towns and villages with convoluted, disconnected street networks, low built-up and population densities, and lack of jobs and services. This signature type is entirely car-dependent."
        },
        {
          id: "DIU",
          name: "Concentrated urbanity",
          colour: "#333432",
          visible: true,
          description:
            "This is a signature type found in the city centre of London and nowhere else in Great Britain. It reflects the uniqueness of London in the British context with an extremely high number of jobs and amenities located nearby, as well as high built-up and population densities. Buildings in this signature are large and tightly packed, forming complex shapes with courtyards and little green space."
        },
        {
          id: "GRQ",
          name: "Gridded residential quarters",
          colour: "#e4cbc8",
          visible: true,
          description:
            "These are areas with street networks forming a well-connected grid-like (high density of 4-way intersections) pattern, resulting in places with smaller blocks and higher granularity. This signature is mostly residential but includes some services and jobs, and it tends to be located away from city centres."
        },
        {
          id: "HDU",
          name: "Hyper concentrated urbanity",
          colour: "#a7b799",
          visible: true,
          description:
            "The epitome of urbanity in the British context. This is a signature type present only in the centre of London, around the Soho district, and covering Oxford and Regent streets. This signature is the result of centuries of urban primacy, with a multitude of historical layers interwoven, very high built-up and population density, and extreme abundance of amenities, services and jobs."
        },
        {
          id: "LOU",
          name: "Local urbanity",
          colour: "#3b6e8c",
          visible: true,
          description:
            "This reflects town centres, outer parts of city centres or even district centres. In all cases, this signature is very much urban in essence, combining high population and built-up density, access to amenities and jobs. Yet, it is on the lower end of the hierarchy of signature types denoting urban centres with only a local significance."
        },
        {
          id: "MEU",
          name: "Metropolitan urbanity",
          colour: "#bc5b4f",
          visible: true,
          description:
            "This captures the centre of the largest cities in Great Britain such as Glasgow, Birmingham or Manchester. It is characterised by a very high number of jobs in the area, high built-up density and often high population density. This type serves as the core centre of the entire metropolitan areas."
        },
        {
          id: "OPS",
          name: "Open sprawl",
          colour: "#d7a59f",
          visible: true,
          description:
            "This represents the transition between countryside and urbanised land. It is located in the outskirts of cities or around smaller towns and is typically made up of large open space areas intertwined with different kinds of human development, from highways to smaller neighbourhoods."
        },
        {
          id: "REU",
          name: "Regional urbanity",
          colour: "#ab888e",
          visible: true,
          description:
            "This captures centres of mid-size cities with regional importance such as Liverpool, Plymouth or Newcastle upon Tyne. It is often encircled by “Local urbanity” signatures and can form outer rings of city centres in large cities. It features high population density, as well as a high number of jobs and amenities within walkable distance."
        },
        {
          id: "URB",
          name: "Urban buffer",
          colour: "#c2d0d9",
          visible: true,
          description:
            "This can be characterised as a green belt around cities. This signature includes mostly agricultural land in the immediate adjacency of towns and cities, often including edge development. It still feels more like countryside than urban, but these signatures are much smaller compared to other countryside types."
        },
        {
          id: "WAL",
          name: "Warehouse/Park land",
          colour: "#c3abaf",
          visible: true,
          description:
            "This covers predominantly industrial areas and other work-related developments made of box-like buildings with large footprints. It contains many jobs of manual nature such as manufacturing or construction, and very little population live here compared to the rest of urban areas. Occasionally this type also covers areas of parks with large scale green open areas."
        },
        {
          id: "WIC",
          name: "Wild countryside",
          colour: "#d7ded1",
          visible: true,
          description:
            "Here, human influence is the least intensive. This signature covers large open spaces in the countryside where no urbanisation happens apart from occasional roads, cottages, and pastures. You can find it across the Scottish Highlands, numerous national parks such as Lake District, or in the majority of Wales."
        }
      ]
    }
  ]
};
