const cols = "the_geom_webmercator, oa21cd, la23cd, la23nm, loac21sg, loac21g";

export const LOAC2021 = {
  title: "London OAC",
  description: "Geodemographics for London based on the 2021 UK Census.",
  downloadLink: "https://data.cdrc.ac.uk/dataset/london-oac",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM oa21_ew",
  buildingSql: "SELECT " + cols + " FROM oa21_ew_vmd23",
  detailUrbanSql: "SELECT " + cols + " FROM oa21_ew_bua22",
  urbanSql: "SELECT " + cols + " FROM oa21_ew_bua22",
  defaultLayers: "1111000",
  initialValue: 1,
  extent: 8,
  popup: true,
  popupType: "classification",
  idField: "oa21cd",
  geogNameField: "la23nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la23cd",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.loac21sg = 'A' then 1 else 0 end) as count_A,
      sum(case when d.loac21sg = 'B' then 1 else 0 end) as count_B,
      sum(case when d.loac21sg = 'C' then 1 else 0 end) as count_C,
      sum(case when d.loac21sg = 'D' then 1 else 0 end) as count_D,
      sum(case when d.loac21sg = 'E' then 1 else 0 end) as count_E,
      sum(case when d.loac21sg = 'F' then 1 else 0 end) as count_F,
      sum(case when d.loac21sg = 'G' then 1 else 0 end) as count_G,
      sum(case when d.loac21g = 'A1' then 1 else 0 end) as count_A1,
      sum(case when d.loac21g = 'A2' then 1 else 0 end) as count_A2,
      sum(case when d.loac21g = 'A3' then 1 else 0 end) as count_A3,
      sum(case when d.loac21g = 'B1' then 1 else 0 end) as count_B1,
      sum(case when d.loac21g = 'B2' then 1 else 0 end) as count_B2,
      sum(case when d.loac21g = 'C1' then 1 else 0 end) as count_C1,
      sum(case when d.loac21g = 'C2' then 1 else 0 end) as count_C2,
      sum(case when d.loac21g = 'D1' then 1 else 0 end) as count_D1,
      sum(case when d.loac21g = 'D2' then 1 else 0 end) as count_D2,  
      sum(case when d.loac21g = 'D3' then 1 else 0 end) as count_D3,  
      sum(case when d.loac21g = 'E1' then 1 else 0 end) as count_E1,
      sum(case when d.loac21g = 'E2' then 1 else 0 end) as count_E2,
      sum(case when d.loac21g = 'F1' then 1 else 0 end) as count_F1,
      sum(case when d.loac21g = 'F2' then 1 else 0 end) as count_F2,
      sum(case when d.loac21g = 'G1' then 1 else 0 end) as count_G1,
      sum(case when d.loac21g = 'G2' then 1 else 0 end) as count_G2
  FROM oliverobrien.oa21_ew d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Supergroup",
      field: "loac21sg",
      lookup: "id",
      groups: [
        {
          id: "A",
          name: "Professional Employment and Family Lifecycles",
          colour: "#66C2A5",
          visible: true,
          description:
            "These neighbourhoods house people of all ages, predominantly of White British or European extraction. Resident turnover is low. Religious affiliation is less common than average and tends to be Christian if expressed. Homeownership, typically of terraced houses, is common but use of the social rented sector is not. Employment is typically in professional, managerial and associate professional or technical occupations. There are few full-time students. Level 4 qualifications are common. More households lack dependent children than have them which, considered alongside low levels of crowding and over-all age structure, indicates that many households may be post child-rearing and in late middle age. Incidence of disability is low, as is residence in communal establishments."
        },
        {
          id: "B",
          name: "The Greater London Mix",
          colour: "#FC8D62",
          visible: true,
          description:
            "A Supergroup embodying London’s diversity in many respects, apart from low numbers of residents identifying as of Bangladeshi, Indian, Pakistani or Other (non-Chinese) Asian ethnicity. There is lower than average prevalence of families with dependent children, while there are above average occurrences of never-married individuals and single-person households. The age distribution is skewed towards younger, single residents and couples without children, with many individuals identifying as of mixed or multiple ethnicity. Social rented or private rented housing is slightly more prevalent than average, and many residents live in flats. Individuals typically work in professional and associated roles in public administration, education or health rather than in elementary occupations in agriculture, energy, water, construction or manufacturing. Incidence of students is slightly below average. Individuals declaring no religion are more prevalent than average and non-use of English at home is below average."
        },
        {
          id: "C",
          name: "Suburban Asian Communities",
          colour: "#8DA0CB",
          visible: true,
          description:
            "Many residents of these neighbourhoods are of (non-Chinese) Asian descent, with many identifying as Indian, Pakistani or Bangladeshi. Neighbourhoods are located across large areas of suburban west, north-east and south London. Detached, semi-detached and terraced houses are more prevalent than flats and socially rented housing is uncommon. Few residents live in communal establishments. Many families have dependent children, sometimes in overcrowded accommodation, and few households are ethnically mixed. Marriage rates are above the London average. The even age distribution, relative absence of individuals living alone and frequent incidence of households with children suggests that multi-generation households may be relatively common. Employment is often in skilled trades, elementary, sales and customer service occupations, and roles as process, plant, and machine operatives. Manufacturing and construction are well represented, along with employment in distribution, hotels, and restaurants. Many adults have only level 1, 2, or apprenticeship qualifications. English is not used at home by some residents. Religious affiliation is above average."
        },
        {
          id: "D",
          name: "Central Connected Professionals and Managers",
          colour: "#E78AC3",
          visible: true,
          description:
            "Adult residents of these neighbourhoods are typically aged 25 to 44, working full-time in professional, managerial or associate professional occupations. There are few families with dependent children. The predominantly Inner London neighbourhoods have an international character, including many residents born elsewhere in Europe alongside high numbers of individuals identifying as of Chinese ethnicity. Many individuals are never married, childless and/or living alone. Above average numbers of individuals, likely to be full-time students, live in communal establishments. Elsewhere, privately rented flats are the dominant housing type. Residents of these areas are well-qualified, with a significant number holding Level 4 or above qualifications. There is a correspondingly high level of individuals employed full-time in professional, managerial and associated professional or technical occupations. Employing industries are financial, real estate, professional, administration, and, to a lesser degree, transport and communications. Unemployment is uncommon."
        },
        {
          id: "E",
          name: "Social Rented Sector Families with Children",
          colour: "#A6D854",
          visible: true,
          description:
            "Residents of these neighbourhoods include sizable numbers identifying with ethnicities originating outside Europe, particularly in Africa or Bangladesh. The proportion of residents identifying as White, Indian or Pakistani is well below the London average. Neighbourhood age profiles are skewed towards younger adults, and above average numbers of families have children. Rates of use of English at home are below average. Marriage rates are low, and levels of separation or divorce are above average. Housing is predominantly in flats, and renting in the social rented sector the norm – few residents are owner occupiers. Housing is often overcrowded, and neighbourhoods are amongst the most densely populated in London. Disability rates are above average, although levels of unpaid care provision are about average. Employment is in caring, leisure, other service occupations, sales and customer service, or process, plant, and machine operation. Part time working and full-time student study are common. Levels of unemployment are slightly above average. Most residents have only Level 1 or 2 educational qualifications or have completed apprenticeships."
        },
        {
          id: "F",
          name: "Young Families and Mainstream Employment",
          colour: "#FFD92F",
          visible: true,
          description:
            "Many families in these neighbourhoods have young children. Housing is principally in the social rented sector, in terraced or semi-detached units. While over-all residential densities are low, overcrowding is also prevalent locally. Residents are drawn from a range of ethnic minorities, with many identifying as Black and above average numbers born in Africa. Numbers identifying as of Chinese, Indian or White ethnicity are below average. Levels of proficiency in English are below average. Levels of separation or divorce and incidence of disability are both above average. Education is typically limited to Level 1, 2, or apprenticeship qualifications. Few residents work in professional or managerial occupations but the employment structure is otherwise diverse: it includes skilled trades, caring, leisure and other service occupations, sales and customer service occupations, construction, and work as process, plant, and machine operatives."
        },
        {
          id: "G",
          name: "Older Residents in Owner-Occupied Suburbs",
          colour: "#D9DDDF",
          visible: true,
          description:
            "The age distribution of these neighbourhoods is skewed towards the middle-aged and old, although few residents live alone or in communal establishments and numbers of dependent children are around average. Owner occupation is the norm, as is residence in detached or semi-detached houses. Residential densities are low and many households have spare rooms. Most residents were born in the UK and, aside from some identifying as members of Chinese or Indian ethnicities, identify as White. Mixed ethnicity households are rare. Incidence of married couples is higher than average and few individuals have never been married. A large proportion of individuals still in employment work in administrative and secretarial occupations, or in the construction industry. Few residents are students, and many households own more than one car."
        }
      ]
    },
    {
      id: 1,
      name: "Group",
      field: "loac21g",
      lookup: "id",
      groups: [
        {
          id: "A1",
          parent: "A",
          name: "Established Homeowners with Children",
          colour: "#539B84",
          visible: true,
          description:
            "These predominantly British-born residents are typically married/in civil partnerships and own the properties in which they are raising their children. Parents are typically over 45, and many other residents are beyond normal retirement age. Detached and semi-detached houses predominate and multiple car ownership is common."
        },
        {
          id: "A2",
          parent: "A",
          name: "Inner London Working Professionals",
          colour: "#97C3B5",
          visible: true,
          description:
            "These primarily Inner London neighbourhoods are more densely populated than the Supergroup average. Residents have a younger over-all age profile than the Supergroup as a whole, and are less likely to be owner occupiers. Full time employment is more common than elsewhere in the Supergroup and multiple car ownership is uncommon. Chinese and non-EU-born European migrants are less in evidence than elsewhere in the Supergroup."
        },
        {
          id: "A3",
          parent: "A",
          name: "European Enclaves",
          colour: "#C5FFEC",
          visible: true,
          description:
            "Many residents of these accessible neighbourhoods have wide-ranging non-UK European origins. Typically residing in privately rented flats, many residents live alone and are beyond normal retirement age. There are more students than elsewhere in the Supergroup, some of which live in communal establishments. Household residents are often drawn from different ethnic groups."
        },
        {
          id: "B1",
          parent: "B",
          name: "Social Rented Sector Professional Support Workers",
          colour: "#CA714E",
          visible: true,
          description:
            "Mainly located in Inner London, these neighbourhoods retain a diverse employment structure, with some concentration in associated professional and technical occupations rather than skilled trades or construction. Social renting is more common and levels of homeownership are low. Many residents identify as Black. There is a lower than average rate of marriage or civil partnership, few that are very old (85 or over) and higher than average incidence of disability."
        },
        {
          id: "B2",
          parent: "B",
          name: "Skilled Trades and Construction Workers",
          colour: "#FC9872",
          visible: true,
          description:
            "These scattered, peripheral and often low residential density neighbourhoods house more workers in skilled trades and construction. Few households rent social housing and there are few students. Multiple car ownership is higher than the Supergroup average, perhaps because of poorer public transport connectivity. Incidence of mixed or multiple ethnicity is below the Supergroup average, and the absence of individuals identifying as Pakistani or Other Asian groups is also less pronounced. Flatted accommodation is less dominant than elsewhere in the Supergroup."
        },
        {
          id: "C1",
          parent: "C",
          name: "Young Asian Family Terraces",
          colour: "#63708E",
          visible: true,
          description:
            "These households with dependent children typically live in terraced housing and are of (non-Chinese) Asian extraction. Individuals with Bangladeshi origins are particularly in evidence. Employment is often in elementary occupations or as process, plant or machine operatives, and part-time work is common. Students are much in evidence."
        },
        {
          id: "C2",
          parent: "C",
          name: "Settled Semi-Detached Asians",
          colour: "#C6D0E5",
          visible: true,
          description:
            "These residents share Supergroup characteristics of large (non-Chinese) Asian populations but those identifying as Bangladeshi are notably absent. Many residents were born in the UK, while other more recent migrants have African birthplaces. Semi-detached housing, much of it owner occupied, prevails in these suburban residential locations."
        },
        {
          id: "D1",
          parent: "D",
          name: "City Support Workers",
          colour: "#B96E9C",
          visible: true,
          description:
            "Scattered throughout Inner London, these areas house relatively few workers in the most senior roles within organisations, and greater prevalence of administrative roles relative to the Supergroup mean. Residents are less likely to be of Chinese ethnicity and are more likely to have been born in Africa. Relative to the Supergroup average, residents are also more likely to live in social housing and live in overcrowded conditions."
        },
        {
          id: "D2",
          parent: "D",
          name: "Central and City",
          colour: "#EEADD5",
          visible: true,
          description:
            "These high density central neighbourhoods are characterised by high levels of residential turnover. Few children are in evidence. Few individuals experience live with disability, with many in full-time employment or study. Levels of separation or divorce are low relative to the Supergroup average. The workforce is well-educated but not in the top flight of managerial occupations. Levels of affiliation to non-Christian religions are high."
        },
        {
          id: "D3",
          parent: "D",
          name: "Senior Professionals",
          colour: "#FFB9DA",
          visible: true,
          description:
            "These very central neighbourhoods house residents whose ages are more skewed towards older age cohorts than elsewhere in the Supergroup. Few households have young children. Rates of illness are low. Indian ethnicity is rare compared to the Supergroup mean. Property under occupation is more common, despite the centrality of neighbourhoods, and more residents live in communal establishments than elsewhere in the Supergroup."
        },
        {
          id: "E1",
          parent: "E",
          name: "Challenged Inner London Communities",
          colour: "#74973B",
          visible: true,
          description:
            "Resident in some of Inner London’s most over-crowded communities, many families have children and marriage/civil partnership rates are above the Supergroup average. Other adults such as students live in communal establishments. Few residents have Level 4 educational qualifications, levels of unemployment are above the Supergroup average, and employment is concentrated in service occupations such as distribution, hotels and restaurants.  Relative to the Supergroup average, fewer residents identify as being of mixed/multiple ethnicities, Black or Other Asian."
        },
        {
          id: "E2",
          parent: "E",
          name: "Social Rented Sector Pockets",
          colour: "#CAE898",
          visible: true,
          description:
            "Found in pockets across London, residents are less likely to live in private sector rentals and fewer adults are students. Fewer individuals work in transport and communications occupations relative to the Supergroup average. More individuals identify as Black and were born in Africa."
        },
        {
          id: "F1",
          parent: "F",
          name: "Terraced and Semi-Detached Suburbs",
          colour: "#CCAE26",
          visible: true,
          description:
            "Mainly concentrated in suburban areas, these terraced and semi-detached developments are less overcrowded than the Supergroup average, and resident households are more likely to own two or more cars. There are fewer residents aged 25-44, and a larger share of residents employed in administrative and secretarial occupations. Residents are more likely to have been born in the UK, less likely to have been born in the EU or Africa, and much less likely to self-identify as Bangladeshi."
        },
        {
          id: "F2",
          parent: "F",
          name: "Social Rented Sector and Diverse Origins",
          colour: "#FFE882",
          visible: true,
          description:
            "Scattered across London’s Inner and Outer suburbs, residents of these neighbourhoods are typically housed in the social rented sector. Although terraced and semi-detached houses predominate, more residents live in flats than elsewhere in the Supergroup. Neighbourhoods are more ethnically diverse than the Supergroup average. Those identifying as of Bangladeshi, Pakistani and some Black ethnicities are more prevalent. Europeans born in a overseas non-EU countries make up more of the lower proportion of residents identifying as White. Few residents are very old (85+). Employment in distribution, hotels and restaurants is more common than elsewhere in the Supergroup."
        },
        {
          id: "G1",
          parent: "G",
          name: "Professional Periphery",
          colour: "#7D7D7D",
          visible: true,
          description:
            "These neighbourhoods predominantly house residents aged 45+, with many aged 85+. Most employed residents work in senior roles, and relatively few work in unskilled jobs. Terraced housing is comparatively rare, but communal living is more common. More residents identify as of Indian ethnicity and more affiliate with non-Christian religions. Disability levels are below the Supergroup average."
        },
        {
          id: "G2",
          parent: "G",
          name: "London Fringe",
          colour: "#D1D1D1",
          visible: true,
          description:
            "Predominantly located in neighbourhoods on the outskirts of Greater London, residents of these neighbourhoods typically have their highest qualifications below degree (Level 4) level, with those still in work engaged in skilled trades and occupations in distribution, hotels and restaurants. There is low ethnic diversity in these neighbourhoods and high levels of Christian religious affiliation. Detached or terraced houses predominate, often with spare rooms."
        }
      ]
    }
  ]
};
