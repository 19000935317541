<template>
  <div>
    <b-button class="text-info p-0 mb-2" variant="link" @click="toggleModal()">
      Download PDF report
    </b-button>
    <b-modal
      id="pdf-modal"
      hide-header
      hide-footer
      scrollable
      centered
      size="lg"
    >
      <div class="top">
        <button
          type="button"
          class="btn btn-outline-secondary print-btn"
          onclick="window.print()"
          aria-label="Print"
        >
          Print
        </button>
      </div>
      <img class="nav-logo" alt="CDRC logo" src="../assets/cdrc_logo.svg" />
      <div class="map-image">
        <img :src="mapImage" />
        <div class="map-scale" :style="{ width: scaleWidth }">
          <span class="value">{{ scaleValue }}</span>
          <span class="unit">{{ scaleUnit }}</span>
        </div>
        <img
          class="carto-logo"
          alt="CARTO"
          src="@/assets/carto-logo-negative.svg"
        />
      </div>
      <main>
        <div class="left">
          <div class="legend-wrapper">
            <div
              class="hierarchy-legend"
              v-if="selectedHierarchy || selectedHierarchy === 0"
            >
              <p
                class="legend-item"
                v-for="group in hierarchy[0].groups"
                :key="group.id"
              >
                <span
                  class="colour"
                  :style="{ backgroundColor: group.colour }"
                ></span>
                <span>{{ group.name }}</span>
              </p>
            </div>
            <div class="metrics-legend" v-if="metricsLegend">
              <p
                class="legend-item"
                v-for="stop in metricsLegend"
                :key="stop.name"
              >
                <span
                  class="colour"
                  :style="{ backgroundColor: stop.colour }"
                ></span>
                <span>{{ stop.name }}</span>
              </p>
            </div>
          </div>
          <div class="map-link block">
            <p class="title">Link map</p>
            <a :href="fullLink" class="text-info">{{ fullLink }}</a>
          </div>
        </div>
        <header class="header">
          <p class="title">{{ metadata.title }}</p>
          <p class="topics">{{ topics }}</p>
          <p class="description">{{ metadata.description }}</p>
        </header>
        <div class="block">
          <p class="title">local authority is</p>
          <p class="value">{{ lad.text }}</p>
        </div>
        <div
          class="block two-col"
          v-for="metric in metricFilters"
          :key="metric.id"
        >
          <div class="column">
            <p class="title">And data field</p>
            <p class="value">{{ metric.name }}</p>
          </div>
          <div class="column">
            <p class="title">{{ metric.type }}</p>
            <p class="value">{{ metric.value }}</p>
          </div>
        </div>
        <div class="block" v-for="h in hierarchyFilters" :key="h.id">
          <p class="title">And {{ h.label }} is</p>
          <p
            class="hierarchy"
            v-for="opt in h.options"
            :key="opt.value"
            :style="{ backgroundColor: opt.colour }"
          >
            {{ opt.text }}
          </p>
        </div>
      </main>
    </b-modal>
  </div>
</template>
<style scoped>
.top {
  position: absolute;
  top: 16px;
  right: 16px;
}

.carto-logo {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  height: 16px;
}

.nav-logo {
  height: calc(48px * var(--factor));
  margin-top: calc(32px * var(--factor));
  margin-left: calc(38px * var(--factor));
  margin-bottom: calc(16px * var(--factor));
}

.map-image {
  position: relative;
}

.map-scale {
  position: absolute;
  bottom: calc(10px * var(--factor));
  right: calc(10px * var(--factor));

  font-size: calc(10px * var(--factor));
  border: 2px solid #333;
  border-top-color: transparent;
  padding: 0 5px;
  color: #333;
  float: right;

  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.map-scale .value {
  flex-shrink: 0;
  margin-right: 2px;
}

.map-scale .unit {
  flex-shrink: 1;
}

.map-image img {
  max-width: 100%;
}

main {
  padding: calc(36px * var(--factor)) calc(38px * var(--factor))
    calc(32px * var(--factor)) calc(50% + 8px);
  position: relative;
}

main .left {
  position: absolute;
  top: calc(-50px * var(--factor));
  left: calc(16px * var(--factor));
  max-width: calc(172px * var(--factor));
}

.legend-wrapper {
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: calc(16px * var(--factor)) calc(16px * var(--factor))
    calc(12px * var(--factor)) calc(20px * var(--factor));
  margin-bottom: calc(24px * var(--factor));
}

.legend-item {
  margin: 0;
  margin-bottom: calc(4px * var(--factor));
  line-height: calc(10px * var(--factor));
  display: flex;
  align-items: center;
  font-size: calc(8px * var(--factor));
}

.legend-item .colour {
  display: block;
  width: calc(8px * var(--factor));
  height: calc(8px * var(--factor));
  border-radius: 1px;
  margin-right: calc(6px * var(--factor));
}

.map-link {
  margin-left: calc(16px * var(--factor));
  margin-bottom: calc(32px * var(--factor));
  font-size: calc(8px * var(--factor));
}

.map-link a {
  margin-top: calc(8px * var(--factor));
  display: block;
}

.block,
.header {
  margin-bottom: calc(21px * var(--factor));
}

.block .title {
  font-size: calc(8px * var(--factor));
  text-transform: uppercase;
  letter-spacing: -0.16px;
  color: #878787;
  margin-bottom: calc(4px * var(--factor));
}

.block .value {
  font-size: calc(12px * var(--factor));
  color: #1d1d1b;
  letter-spacing: -0.12px;
  margin: 0;
}

.two-col {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.two-col .column:first-child {
  flex: 1 1 66%;
}
.two-col .column:last-child {
  flex: 1 1 33%;
}

.header {
  border-bottom: 1px solid #e6e6e6;
}

.header .title {
  font-size: calc(20px * var(--factor));
  font-weight: normal;
  margin-bottom: calc(4px * var(--factor));
}

.header .topics {
  font-size: calc(12px * var(--factor));
  line-height: calc(20px * var(--factor));
}

.header .description {
  font-size: calc(10px * var(--factor));
  line-height: calc(14px * var(--factor));
  color: #878787;
}

.hierarchy {
  margin: 0;
  font-size: calc(10px * var(--factor));
  line-height: calc(16px * var(--factor));
  color: #31302b;
  padding-left: calc(6px * var(--factor));
}

@media print {
  .print-btn {
    display: none;
  }

  .legend-wrapper {
    position: relative;
  }

  .legend-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
    print-color-adjust: exact;
    filter: opacity(1);
  }

  a:not(.btn) {
    text-decoration: none;
  }
}
</style>
<script>
import { MAPS } from "@/config/Maps.js";
import { LAD } from "@/config/LAD";

export default {
  name: "PdfReport",
  props: {
    metadata: {
      type: Object,
      required: true
    },
    selectedStop: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedHierarchy: this.getInitialHierarchy(),
      selectedMetric: this.getInitialMetric(),
      lad: this.getInitialLAD(),
      hierarchyFilters: this.makeHierarchyFilters(),
      metricFilters: this.makeMetricFilters(),
      metricsLegend: this.getMetricsLegend(),
      mapImage: null,
      scaleWidth: null,
      scaleValue: null,
      scaleUnit: null,
      fullLink: null
    };
  },
  computed: {
    routeData() {
      const id = this.$route.name;
      return MAPS.find(m => m.id === id);
    },
    topics() {
      return (
        this.routeData &&
        this.routeData.topics.filter(t => t !== "All").join(",")
      );
    },
    metrics() {
      return this.metadata.metrics || [];
    },
    hierarchy() {
      return this.metadata.hierarchy || [];
    }
  },
  methods: {
    toggleModal() {
      this.update();
      this.fullLink = window.location.href;
      this.mapImage = document.querySelector("canvas").toDataURL();
      const scale = document.querySelector(".maplibregl-ctrl-scale");
      const width = scale.style.width.replace("px", "");
      const value = scale.textContent;
      const MODAL_WIDTH = 595;
      const factor = MODAL_WIDTH / window.innerWidth;

      this.scaleWidth = `${Number(width) * factor}px`;
      this.scaleValue = parseInt(value);
      this.scaleUnit = value.replace(this.scaleValue, "").trim();

      this.$bvModal.show("pdf-modal");
    },
    getQueryParam(key) {
      const parts = location.href.split("?");
      const query = parts[1];
      if (!query) {
        return null;
      }
      const splitQuery = query.split("&").map(param => {
        const [key, value] = param.split("=");
        return { key, value };
      });

      const param = splitQuery.find(s => s.key === key);
      return param && decodeURIComponent(param.value);
    },
    getInitialHierarchy: function() {
      let initialValue = null;

      if (Object.prototype.hasOwnProperty.call(this.metadata, "hierarchy")) {
        initialValue = 0; //this.metadata.hierarchy.length - 1;

        const param = this.getQueryParam("h");
        if (!isNaN(param) && Number(param) < this.metadata.hierarchy.length) {
          initialValue = Number(param);
        }
      }

      return initialValue;
    },
    getInitialMetric: function() {
      let initialValue = null;

      if (Object.prototype.hasOwnProperty.call(this.metadata, "metrics")) {
        initialValue = this.metadata.metrics[0].id;

        const param = this.getQueryParam("m");
        if (!isNaN(param) && Number(param) < this.metadata.metrics.length) {
          initialValue = Number(param);
        }
      }
      return initialValue;
    },
    getInitialLAD() {
      let selected = null;
      const param = this.getQueryParam("f(lad)");
      if (param) {
        selected = param;
      }
      const options = [
        { value: null, text: "All Local Authorities" },
        ...LAD.map(x => ({ value: x.id, text: x.name }))
      ];

      return options.find(opt => opt.value === selected);
    },
    makeHierarchyFilters() {
      let config = [];
      (this.metadata.hierarchy || []).forEach(level => {
        let selected = [];
        const param = this.getQueryParam(`f(h${level.id})`);
        if (param) {
          selected = param.split(",");
        }
        let data = {
          selected,
          label: level.name,
          field: level.field,
          options: []
        };
        level.groups.forEach(group => {
          const groupSelected = selected.indexOf(group.id) !== -1;
          if (groupSelected || selected.length === 0) {
            data.options.push({
              value: group[level.lookup],
              text: `${group.id} - ${group.name}`,
              colour: group.colour,
              parent: group.parent
            });
          }
        });
        config.push(data);
      });
      return config;
    },
    makeMetricFilters() {
      let filters = [];

      for (let i = 0; i < 10; i++) {
        const param = this.getQueryParam(`f(m${i})`);
        if (param) {
          const data = param.split(",");
          if (data.length == 3) {
            const [field, type, value] = data;
            const metrics = this.metadata.metrics || [];
            const metric = metrics.find(m => String(m.id) === field);
            filters.push({
              id: i,
              name: metric && metric.name,
              type: type === ">" ? "Greater than" : "Less than",
              value
            });
          }
        }
      }

      return filters;
    },
    getMetricsLegend() {
      const metrics = this.metadata.metrics || [];
      const hasSelection = this.selectedMetric || this.selectedMetric === 0;
      if (!hasSelection || metrics.length === 0) {
        return null;
      }
      return this.metrics[this.selectedMetric].legendStops[this.selectedStop]
        .stops;
    },
    update() {
      this.selectedHierarchy = this.getInitialHierarchy();
      this.selectedMetric = this.getInitialMetric();
      this.lad = this.getInitialLAD();
      this.hierarchyFilters = this.makeHierarchyFilters();
      this.metricFilters = this.makeMetricFilters();
      this.metricsLegend = this.getMetricsLegend();
    }
  }
};
</script>
