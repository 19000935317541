import colorbrewer from "colorbrewer";
const ramp = colorbrewer.Spectral[11];

const cols =
  "the_geom_webmercator, age_id, tenure_id, fuel_id, type_id, cer_id, " +
  "photo_supply, total_floor_area ";

const legendStopsYear = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#555555", name: "No data" },
      { value: 1899, colour: ramp[10], name: "Pre-1900 (Scotland pre-1919)" },
      { value: 1900, colour: ramp[9], name: "1900-1929 (Scotland 1919-29)" },
      { value: 1930, colour: ramp[8], name: "1930-1949" },
      { value: 1950, colour: ramp[7], name: "1950-1966 (Scotland 1950-64)" },
      { value: 1967, colour: ramp[6], name: "1967-1975 (Scotland 1965-75)" },
      { value: 1976, colour: ramp[5], name: "1976-1982 (Scotland 1976-83)" },
      { value: 1983, colour: ramp[4], name: "1983-1990 (Scotland 1984-91)" },
      { value: 1991, colour: ramp[3], name: "1991-1995 (Scotland 1992-98)" },
      { value: 1996, colour: ramp[2], name: "1996-2002 (Scotland 1999-02)" },
      { value: 2003, colour: ramp[1], name: "2003-2006 (Scotland 2003-07)" },
      { value: 2007, colour: ramp[0], name: "2007- (Scotland 2008-)" },
      { value: 2012, colour: "rgb(128,0,0)", name: "2012-" },
      { value: 2017, colour: "rgb(96,0,0)", name: "2017-" }
    ]
  }
];

const legendStopsTenure = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#555555", name: "No data" },
      { value: 1, colour: ramp[9], name: "Owner occupier" },
      { value: 2, colour: ramp[2], name: "Private rent" },
      { value: 3, colour: ramp[4], name: "Social rent" }
    ]
  }
];

const legendStopsFuel = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#555555", name: "No data" },
      { value: 1, colour: ramp[9], name: "Gas" },
      { value: 2, colour: ramp[1], name: "Electricity" },
      { value: 3, colour: ramp[4], name: "Oil or LPG" },
      { value: 4, colour: ramp[7], name: "Other" }
    ]
  }
];

const legendStopsType = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#555555", name: "No data" },
      { value: 1, colour: ramp[2], name: "Bungalow" },
      { value: 11, colour: ramp[1], name: "Flat" },
      { value: 21, colour: ramp[9], name: "House: Detached" },
      { value: 22, colour: ramp[8], name: "House: Back-to-Back" },
      { value: 24, colour: ramp[7], name: "House: Terrace" },
      { value: 27, colour: ramp[5], name: "House: Semi-Detached" },
      { value: 31, colour: ramp[3], name: "Maisonette" },
      { value: 41, colour: ramp[10], name: "Park home" }
    ]
  }
];

const legendStopsEPC = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#555555", name: "No data" },
      { value: 1, colour: ramp[10], name: "A" },
      { value: 2, colour: ramp[9], name: "B" },
      { value: 3, colour: ramp[8], name: "C" },
      { value: 4, colour: ramp[6], name: "D" },
      { value: 5, colour: ramp[4], name: "E" },
      { value: 6, colour: ramp[2], name: "F" },
      { value: 7, colour: ramp[0], name: "G" }
    ]
  }
];

const legendStopsSolar = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "rgba(0,0,0,0)", name: "No data" },
      { value: 0, colour: "rgba(0,0,0,0)", name: "No PVs" },
      { value: 0.001, colour: ramp[5], name: "Under 20%" },
      { value: 20, colour: ramp[4], name: "20-40%" },
      { value: 40, colour: ramp[3], name: "40-60%" },
      { value: 60, colour: ramp[2], name: "60-80%" },
      { value: 80, colour: ramp[1], name: "80-100%" }
    ]
  }
];

const legendStopsArea = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#555555", name: "No data" },
      { value: 0.001, colour: ramp[10], name: "Under 40sqm" },
      { value: 40, colour: ramp[9], name: "40-60sqm" },
      { value: 60, colour: ramp[8], name: "60-80sqm" },
      { value: 80, colour: ramp[7], name: "80-100sqm" },
      { value: 100, colour: ramp[6], name: "100-125sqm" },
      { value: 125, colour: ramp[5], name: "125-150sqm" },
      { value: 150, colour: ramp[4], name: "150-200sqm" },
      { value: 200, colour: ramp[3], name: "200-250sqm" },
      { value: 250, colour: ramp[2], name: "250-300sqm" },
      { value: 300, colour: ramp[1], name: "300-400sqm" },
      { value: 400, colour: ramp[0], name: "Over 400sqm" }
    ]
  }
];

export const DOMESTICBUILDINGS = {
  title: "Building Attributes (Residential)",
  description:
    "Individual building details based on data from EPC certificates for domestic properties (dwellings), with modal values shown for output area centroids at lower zoom levels. ",
  //"Note: recent build years may show property conversion dates, and floor areas are for individual dwellings.",
  downloadLink: "",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  individualSql:
    "SELECT 'UPRN: ' || uprn as uprn, " + cols + " FROM uprn22_gb_epcdata",
  sql: "SELECT " + cols + ", oa_cd, la_cd, la_nm FROM oa21_gb_pwcs_epcdata",
  buildingSql:
    "SELECT the_geom_webmercator, oa11_cd as oa_cd, la17_cd as la_cd, la17_nm as la_nm FROM oa11_uk_buildings",
  detailUrbanSql:
    "SELECT " + cols + ", oa_cd, la_cd, la_nm FROM oa21_gb_pwcs_epcdata",
  urbanSql:
    "SELECT " + cols + ", oa_cd, la_cd, la_nm FROM oa21_gb_pwcs_epcdata",
  defaultLayers: "1111000",
  extent: 0,
  popup: false,
  idField: "oa_cd",
  individualIdField: "uprn",
  geogNameField: "la_nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la_cd",
  hoverFieldMetricMode: "All",
  metrics: [
    {
      id: 0,
      name: "Build Year",
      field: "age_id",
      display: "Stop Name",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsYear,
      additionalMetrics: []
    },
    {
      id: 1,
      name: "Tenure",
      field: "tenure_id",
      display: "Stop Name",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsTenure,
      additionalMetrics: []
    },
    {
      id: 2,
      name: "Main Fuel",
      field: "fuel_id",
      display: "Stop Name",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsFuel,
      additionalMetrics: []
    },
    {
      id: 3,
      name: "Property Type",
      field: "type_id",
      display: "Stop Name",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsType,
      additionalMetrics: []
    },
    {
      id: 4,
      name: "EPC Rating",
      field: "cer_id",
      display: "Stop Name",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Name" }],
      legendStops: legendStopsEPC,
      additionalMetrics: [4]
    },
    {
      id: 5,
      name: "Solar PV Roof % (not Scotland)",
      field: "photo_supply",
      display: "%",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsSolar,
      additionalMetrics: [5]
    },
    {
      id: 6,
      name: "Floor Area",
      field: "total_floor_area",
      display: "sqm",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsArea,
      additionalMetrics: [6]
    }
  ]
};
