<template>
  <div class="hover-feature">
    <hr class="my-2" v-if="data.id || data.additionalFields.length > 0" />
    <div v-if="data.id">
      <h5 class="my-0">
        <strong>{{ data.name }}</strong>
      </h5>
      {{ data.id }} <br />
      <div v-if="data.hierarchy.length < 6">
        <h6
          v-for="group in data.hierarchy"
          class="my-1 mb-0 pb-1"
          :key="group.id"
          v-bind:style="{
            backgroundColor: group.groupColour,
            color: group.lightText ? 'white' : 'black'
          }"
        >
          <b style="font-size: x-small;" v-html="group.name"></b>
          <div style="font-size: small;" v-html="group.groupName"></div>
        </h6>
      </div>
      <div v-else class="mt-1">
        <h6
          v-for="group in data.hierarchy"
          class="mt-0 mb-0 pb-0 px-2"
          :key="group.id"
          v-bind:style="{
            backgroundColor: group.groupColour,
            color: group.lightText ? 'white' : 'black'
          }"
        >
          <div style="font-size: x-small; text-align: left;">
            <b v-html="group.name"></b>: <span v-html="group.groupName"></span>
          </div>
        </h6>
      </div>
    </div>

    <table
      v-if="data.metrics.length > 10"
      style="width: 100%"
      class="table table-sm my-1 py-0"
    >
      <colgroup>
        <col span="1" />
        <col span="1" />
      </colgroup>
      <tbody>
        <tr v-for="metric in directColourExcluded" :key="metric.id">
          <td style="font-size: x-small" class="py-0 my-0 text-left">
            <strong v-html="metric.name"></strong>
          </td>
          <td
            v-if="metric.display === 'Stop Name'"
            style="font-size: x-small"
            class="py-0 my-0 text-right"
          >
            {{ metric.stopName }}
          </td>
          <td v-else style="font-size: x-small" class="py-0 my-0 text-right">
            {{ metric.value }}
          </td>
        </tr>
        <tr v-for="metric in directColourOnly" :key="metric.id">
          <td
            colspan="2"
            v-bind:style="{ backgroundColor: metric.value, color: 'white' }"
          >
            <strong v-html="metric.name"></strong>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      v-else-if="data.metrics.length > 0"
      style="width: 100%"
      class="table table-sm my-2"
    >
      <colgroup>
        <col span="1" />
        <col span="1" />
      </colgroup>
      <tbody>
        <tr v-for="metric in directColourExcluded" :key="metric.id">
          <td class="text-left">
            <strong v-html="metric.name"></strong>
          </td>
          <td v-if="!metric.value">
            No data
          </td>
          <td v-else-if="metric.stopName === 'No data'">
            No data
          </td>
          <td v-else-if="metric.display === 'Stop Name'">
            <!-- <div v-bind:style="{ backgroundColor: metric.stopColour, width: '10px', height: '10px', float: 'left' }">&nbsp;</div> -->
            {{ metric.stopName }}
          </td>
          <td v-else class="text-right">
            {{ metric.value }}
          </td>
        </tr>
        <tr v-for="metric in directColourOnly" :key="metric.id">
          <td
            colspan="2"
            v-bind:style="{ backgroundColor: metric.value, color: 'white' }"
          >
            <strong v-html="metric.name"></strong>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-for="data in data.additionalFields"
      :key="data.field"
      class="border"
      style="padding:1px;margin-bottom: 5px;"
    >
      <small
        ><strong>{{ data.field }} </strong></small
      >
      <br />

      {{ data.value }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MapHoverFeature",
  props: {
    data: Object
  },
  computed: {
    directColourExcluded: function() {
      return this.data.metrics.filter(i => i.display != "Colour");
    },
    directColourOnly: function() {
      return this.data.metrics.filter(
        i => i.display === "Colour" && !isNaN(i.value)
      );
    }
  }
};
</script>

<style scoped>
.hover-feature {
  text-align: center;
}

h3,
h4 {
  margin-bottom: 0;
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
