const cols =
  "the_geom_webmercator, lsoa11_cd, lsoa11_nm, lad_code, " +
  "pp_smkt_proximity, pp_smkt_accessibility, pp_ecommerce_access, " +
  "pp_socio_demographic, pp_nonsupermarket_proximity, pp_food_for_families, " +
  "pp_fuel_poverty, pp_combined";

const legendStops = [
  {
    id: 0,
    stops: [
      { value: 0, colour: "#aaaaaa", name: "No data" },
      { value: 1, colour: "#0d0887", name: "Highest priority decile" },
      { value: 2, colour: "#41049d", name: "2nd" },
      { value: 3, colour: "#6a00a8", name: "3rd" },
      { value: 4, colour: "#8f0da4", name: "4th" },
      { value: 5, colour: "#b12a90", name: "5th" },
      { value: 6, colour: "#c94a79", name: "6th" },
      { value: 7, colour: "#db6a68", name: "7th" },
      { value: 8, colour: "#e58858", name: "8th" },
      { value: 9, colour: "#e8a34a", name: "9th" },
      { value: 10, colour: "#e1bf40", name: "Lowest priority decile" }
    ]
  }
];

export const PPFI = {
  title: "Priority Places for Food Index Version 1",
  description:
    "Identifying neighbourhoods that are most vulnerable to increases in the cost of living and which have a lack of accessibility to cheap, healthy, and sustainable sources of food.",
  downloadLink: "https://data.cdrc.ac.uk/dataset/priority-places-food-index",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM lsoa11_uk",
  buildingSql: "SELECT " + cols + " FROM lsoa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM lsoa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM lsoa11_uk_urban",
  defaultLayers: "1111000",
  extent: 2,
  popup: false,
  idField: "lsoa11_cd",
  geogNameField: "lsoa11_nm",
  ladCodeField: "lad_code",
  hoverFieldMetricMode: "All",
  metrics: [
    {
      id: 0,
      name: "Overall decile",
      field: "pp_combined",
      decimals: 0,
      extent: 2,
      legendOptions: [{ id: 0, name: "Decile" }],
      legendStops: legendStops
    },
    {
      id: 1,
      name: "Supermarket proximity",
      field: "pp_smkt_proximity",
      decimals: 0,
      extent: 2,
      legendOptions: [{ id: 0, name: "Decile" }],
      legendStops: legendStops
    },
    {
      id: 2,
      name: "Supermarket accessibility",
      field: "pp_smkt_accessibility",
      decimals: 0,
      extent: 2,
      legendOptions: [{ id: 0, name: "Decile" }],
      legendStops: legendStops
    },
    {
      id: 3,
      name: "Access to online deliveries",
      field: "pp_ecommerce_access",
      decimals: 0,
      extent: 2,
      legendOptions: [{ id: 0, name: "Decile" }],
      legendStops: legendStops
    },
    {
      id: 4,
      name: "Socio-economic barriers",
      field: "pp_socio_demographic",
      decimals: 0,
      extent: 2,
      legendOptions: [{ id: 0, name: "Decile" }],
      legendStops: legendStops
    },
    {
      id: 5,
      name: "Non-supermarket food proximity",
      field: "pp_nonsupermarket_proximity",
      decimals: 0,
      extent: 2,
      legendOptions: [{ id: 0, name: "Decile" }],
      legendStops: legendStops
    },
    {
      id: 6,
      name: "Family food support",
      field: "pp_food_for_families",
      decimals: 0,
      extent: 2,
      legendOptions: [{ id: 0, name: "Decile" }],
      legendStops: legendStops
    },
    {
      id: 7,
      name: "Fuel poverty",
      field: "pp_fuel_poverty",
      decimals: 0,
      extent: 2,
      legendOptions: [{ id: 0, name: "Decile" }],
      legendStops: legendStops
    }
  ]
};
