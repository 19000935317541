var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend mt-2"},_vm._l((_vm.hierarchy[0].groups),function(group){return _c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('legend-accordion-' + group.id),expression:"'legend-accordion-' + group.id"}],key:group.id,staticClass:"legend-item",staticStyle:{"text-indent":"-35px","margin-left":"35px"},attrs:{"tabindex":"0","role":"button"}},[(_vm.selectedHierarchy > 0 && !_vm.hierarchy[0].hideExpand)?_c('font-awesome-icon',{staticClass:"opened text-secondary",staticStyle:{"float":"right"},attrs:{"icon":['fas', 'minus-square']}}):_vm._e(),(_vm.selectedHierarchy > 0 && !_vm.hierarchy[0].hideExpand)?_c('font-awesome-icon',{staticClass:"closed text-secondary",staticStyle:{"float":"right"},attrs:{"icon":['fas', 'plus-square']}}):_vm._e(),_c('span',{staticClass:"legend-key",style:({ backgroundColor: group.colour })}),_c('small',[_vm._v(_vm._s(group.name))]),(_vm.selectedHierarchy > 0 && !_vm.hierarchy[0].hideExpand)?_c('b-collapse',{staticClass:"ml-2 mt-0 mb-1",attrs:{"accordion":"legend-accordion","id":'legend-accordion-' + group.id,"visible":""}},_vm._l((_vm.hierarchy[1].groups.filter(function(element) {
          return element.parent == group.id;
        })),function(subgroup){return _c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('legend-accordion-' + group.id + '-' + subgroup.id),expression:"'legend-accordion-' + group.id + '-' + subgroup.id"}],key:subgroup.id,staticClass:"legend-item",attrs:{"tabindex":"1","role":"button"},on:{"click":function($event){$event.stopPropagation();}}},[(_vm.selectedHierarchy > 1)?_c('font-awesome-icon',{staticClass:"opened text-secondary",staticStyle:{"float":"right"},attrs:{"icon":['fas', 'minus-square']}}):_vm._e(),(_vm.selectedHierarchy > 1)?_c('font-awesome-icon',{staticClass:"closed text-secondary",staticStyle:{"float":"right"},attrs:{"icon":['fas', 'plus-square']}}):_vm._e(),_c('span',{staticClass:"legend-key",style:({ backgroundColor: subgroup.colour })}),_c('small',[_vm._v(_vm._s(subgroup.name))]),(_vm.selectedHierarchy > 1)?_c('b-collapse',{staticClass:"ml-2 mt-0 mb-1",attrs:{"accordion":"'legend-accordion-' + group.id","id":'legend-accordion-' + group.id + '-' + subgroup.id,"visible":""}},_vm._l((_vm.hierarchy[2].groups.filter(function(
              element
            ) {
              return element.parent == subgroup.id;
            })),function(subsubgroup){return _c('div',{key:subsubgroup.id,staticClass:"legend-item",on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"legend-key",style:({ backgroundColor: subsubgroup.colour })}),_c('small',[_vm._v(_vm._s(subsubgroup.name))])])}),0):_vm._e()],1)}),0):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }