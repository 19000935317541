import colorbrewer from "colorbrewer";

const colsPrefix =
  "a.the_geom_webmercator, a.wd22cd, lad22cd, lad22nm, wd22nm || ' (' || lad22nm || ')' wdla, ";

const ramp1 = colorbrewer.Greens[9];

const legendStops08_10 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp1[0], name: "Under 84%" },
      { value: 0.84, colour: ramp1[1], name: "84-86%" },
      { value: 0.86, colour: ramp1[2], name: "86-88%" },
      { value: 0.88, colour: ramp1[3], name: "88-90%" },
      { value: 0.9, colour: ramp1[4], name: "90-92%" },
      { value: 0.92, colour: ramp1[5], name: "92-94%" },
      { value: 0.94, colour: ramp1[6], name: "94-96%" },
      { value: 0.96, colour: ramp1[7], name: "96-98%" },
      { value: 0.98, colour: ramp1[8], name: "Over 98%" }
    ]
  }
];

const legendStops04_10 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp1[0], name: "Under 40%" },
      { value: 0.4, colour: ramp1[1], name: "40-45%" },
      { value: 0.45, colour: ramp1[2], name: "45-50%" },
      { value: 0.5, colour: ramp1[3], name: "50-55%" },
      { value: 0.55, colour: ramp1[4], name: "55-60%" },
      { value: 0.6, colour: ramp1[5], name: "60-65%" },
      { value: 0.65, colour: ramp1[6], name: "65-70%" },
      { value: 0.7, colour: ramp1[7], name: "70-75%" },
      { value: 0.75, colour: ramp1[8], name: "Over 75%" }
    ]
  }
];

const ramp = colorbrewer.RdPu[9];

const legendStops0_022 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp[0], name: "None" },
      { value: 0.00001, colour: ramp[1], name: "0-1%" },
      { value: 0.01, colour: ramp[2], name: "1-2%" },
      { value: 0.02, colour: ramp[3], name: "2-3%" },
      { value: 0.03, colour: ramp[4], name: "3-5%" },
      { value: 0.05, colour: ramp[5], name: "5-10%" },
      { value: 0.1, colour: ramp[6], name: "10-15%" },
      { value: 0.15, colour: ramp[7], name: "15-20%" },
      { value: 0.2, colour: ramp[8], name: "Over 20%" }
    ]
  }
];

const ramp2 = colorbrewer.YlOrRd[5];

const legendStops0_10 = [
  {
    id: 0,
    stops: [
      { value: 0, colour: "#000000", name: "0%" },
      { value: 0.01, colour: ramp2[4], name: "1-20%" },
      { value: 0.2, colour: ramp2[3], name: "20-40%" },
      { value: 0.4, colour: ramp2[2], name: "40-60%" },
      { value: 0.6, colour: ramp2[1], name: "60-80%" },
      { value: 0.8, colour: ramp2[0], name: "80-100%" }
    ]
  }
];

const ramp0_120 = colorbrewer.PiYG[6];

const legendStops0_120 = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramp0_120[0], name: "0-2" },
      { value: 2, colour: ramp0_120[1], name: "2-4" },
      { value: 4, colour: ramp0_120[2], name: "4-6" },
      { value: 6, colour: ramp0_120[3], name: "6-8" },
      { value: 8, colour: ramp0_120[4], name: "8-10" },
      { value: 10, colour: ramp0_120[5], name: "Over 10" }
    ]
  }
];

const legendStops0_1200 = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramp0_120[0], name: "0-20" },
      { value: 20, colour: ramp0_120[1], name: "20-40" },
      { value: 40, colour: ramp0_120[2], name: "40-60" },
      { value: 60, colour: ramp0_120[3], name: "60-80" },
      { value: 80, colour: ramp0_120[4], name: "80-100" },
      { value: 100, colour: ramp0_120[5], name: "Over 100" }
    ]
  }
];

const legendStops350_550 = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramp0_120[0], name: "Under 40" },
      { value: 40, colour: ramp0_120[1], name: "40-44" },
      { value: 44, colour: ramp0_120[2], name: "44-48" },
      { value: 48, colour: ramp0_120[3], name: "48-52" },
      { value: 52, colour: ramp0_120[4], name: "Over 52" }
    ]
  }
];

const legendStopsCluster = [
  {
    id: 0,
    stops: [
      { value: 1, colour: "#E69F00", name: "Cluster 1" },
      { value: 2, colour: "#56B4E9", name: "Cluster 2" },
      { value: 3, colour: "#009E73", name: "Cluster 3" },
      { value: 4, colour: "#F0E442", name: "Cluster 4" }
    ]
  }
];

// TDR (total dependency ratio)

const ramp0_175 = colorbrewer.RdPu[8];

const legendStops0_175 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0.0, colour: ramp0_175[0], name: "0-25%" },
      { value: 0.25, colour: ramp0_175[1], name: "25-50%" },
      { value: 0.5, colour: ramp0_175[2], name: "50-75%" },
      { value: 0.75, colour: ramp0_175[3], name: "75-100%" },
      { value: 1.0, colour: ramp0_175[4], name: "100-125%" },
      { value: 1.25, colour: ramp0_175[5], name: "125-150%" },
      { value: 1.5, colour: ramp0_175[6], name: "150-175%" },
      { value: 1.75, colour: ramp0_175[7], name: "Over 175%" }
    ]
  }
];

// SR (sex ratio)

const ramp07_23 = colorbrewer.RdPu[8];

const legendStops07_23 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0.7, colour: ramp07_23[0], name: "0.7-0.9" },
      { value: 0.9, colour: ramp07_23[1], name: "0.9-1.1" },
      { value: 1.1, colour: ramp07_23[2], name: "1.1-1.3" },
      { value: 1.3, colour: ramp07_23[3], name: "1.3-1.5" },
      { value: 1.5, colour: ramp07_23[4], name: "1.5-1.7" },
      { value: 1.7, colour: ramp07_23[5], name: "1.7-1.9" },
      { value: 1.9, colour: ramp07_23[6], name: "1.9-2.1" },
      { value: 2.1, colour: ramp07_23[7], name: "Over 2.1" }
    ]
  }
];

const jointablesql = " a, ie b where a.wd22cd = b.wd22cd";

export const INCLUSIVEECONOMY = {
  title: "Inclusive Economy",
  description: "Description to follow",
  downloadLink: "https://data.cdrc.ac.uk/",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + getCols() + " FROM wd22_gb" + jointablesql,
  buildingSql: "SELECT " + getCols() + " FROM wd22_gb_vmd23" + jointablesql,
  detailUrbanSql: "SELECT " + getCols() + " FROM wd22_gb_bua22" + jointablesql,
  urbanSql: "SELECT " + getCols() + " FROM wd22_gb_bua22" + jointablesql,
  defaultLayers: "1111000",
  extent: 10,
  popup: false,
  idField: "wd22cd",
  geogNameField: "wdla",
  geogNamePrefix: "",
  ladCodeField: "lad22cd",
  hoverFieldMetricMode: "All",
  metrics: getMetricList()
};

function getCols() {
  return (
    colsPrefix +
    getMetricList()
      .map(function(a) {
        return a.field;
      })
      .join(", ")
  );
}

function getMetricList() {
  //const minYear = 1997;
  //const maxYear = 2020;
  let list = [];
  let i = 0;
  let ic = 0;

  const cats = {
    a1: [
      "1A: Participation in paid employment",
      true,
      legendStops04_10,
      "Percentage of working-age people (aged 16-64) who are employed."
    ],
    b1: [
      "1B: Skills and qualifications",
      true,
      legendStops04_10,
      "Percentage of adults aged 20-49 with a Level 2 or higher National Vocational Qualification (NVQ)."
    ],
    a2: [
      "2A: Involuntary exclusion from the labour market",
      true,
      legendStops0_022,
      "Percentage of working-age people (aged 16-64) who are inactive due to ill health or disability."
    ],
    b2: [
      "2B: Digital exclusion",
      true,
      legendStops0_10,
      "Percentage of individuals who are classified as a) e-withdrawn, b) passive and uncommitted internet users, or c) settled offline communities; based on the Internet User Classification (IUC)."
    ],
    a3: [
      "3A: Wealth inequality",
      false,
      legendStops0_120,
      "Ratio of median house prices in most expensive Lower Super Output Area (LSOAs)/Data Zones (DZs) to median in least expensive."
    ],
    b3: [
      "3B: Physical connectivity",
      true,
      legendStops0_10,
      "Public transport accessibility measure, percentage of Lower Super Output Area (LSOAs)/Data Zones (DZs) within the electoral ward area that are among the 50% most accessible LSOAs/DZs for each devolved nation."
    ],
    a4: [
      "4A: Earnings inequality",
      false,
      legendStops0_120,
      "Ratio of weekly earnings for residents in full-time work between 80th and 20th percentiles."
    ],
    b4: [
      "4B: Housing affordability",
      false,
      legendStops0_1200,
      "Ratio of median house prices to median gross annual earnings."
    ],
    a5: [
      "5A: Poverty",
      true,
      legendStops0_10,
      "Percentage of children living in low income households (based on national relative threshold, after adjustment for housing costs)."
    ],
    b5: [
      "5B: Costs of living",
      true,
      legendStops0_022,
      "Percentage of households that are defined as fuel poor, according to national definition."
    ],
    a6: [
      "6A: Decent pay",
      true,
      legendStops04_10,
      "Percentage of employee jobs that are paid below the Living Wage (as defined by the Living Wage Foundation)."
    ],
    b6: [
      "6B: Inclusion in decision-making",
      true,
      legendStops0_10,
      "Percentage of eligible voters participating in local elections."
    ],
    a7: [
      "7A: Job security/precarity",
      true,
      legendStops08_10,
      "Percentage of employees on a permanent contract."
    ],
    tdr: [
      "Total dependency ratio (TDR)",
      true,
      legendStops0_175,
      "Ratio of the number of dependents aged zero to 15 and over 65, compared with the total population aged 16 to 64. Definition: (N(0-15) + N(65+)) / N(16-64)."
    ],
    sr: [
      "Sex ratio (SR)",
      false,
      legendStops07_23,
      "Ratio of the number of males to number of females. Definition: N(males) / N(females)."
    ],
    sf12mcsm: [
      "Wellbeing score, mental component, males only",
      false,
      legendStops350_550,
      "SF-12 wellbeing score, mental health component, males only."
    ],
    sf12pcsm: [
      "Wellbeing score, physical component, males only",
      false,
      legendStops350_550,
      "SF-12 wellbeing score, physical health component, males only."
    ],
    sf12mcsf: [
      "Wellbeing score, mental component, females only",
      false,
      legendStops350_550,
      "SF-12 wellbeing score, mental health component, females only."
    ],
    sf12pcsf: [
      "Wellbeing score, physical component, females only",
      false,
      legendStops350_550,
      "SF-12 wellbeing score, physical health component, females only."
    ]
  };

  const years = ["2018", "2019", "2020"];

  Object.keys(cats).forEach(function(key) {
    //for (let j = minYear; j <= maxYear; j++)
    for (const year in years) {
      const j = years[year];
      list.push({
        id: i,
        name: cats[key][0] + " " + j,
        description: cats[key][3],
        field: "ie" + j.substring(2) + "_" + key,
        display: cats[key][1] ? "Percentage" : "",
        decimals: 1,
        //legendStops: key == "wbr" ? legendStopsWBR : legendStops,
        legendStops: cats[key][2],
        legendOptions: [],
        additionalMetrics: Array(years.length)
          .fill()
          .map((v, k) => k + ic)
        //additionalMetrics: Array(maxYear-minYear+1).fill().map((v,k)=>k+ic)
      });
      i++;
    }
    ic = i;
  });
  list.push({
    id: i,
    name: "Clustering",
    field: "ie20_cluster",
    display: "Stop Name",
    decimals: 0,
    legendStops: legendStopsCluster,
    legendOptions: [],
    description:
      "Four-cluster k-means solution, computed for 2020 only. This is a grouping of local authorities according to similarities between values of the indicators. (1) Less inclusive, (2) More inclusive, (3) Average, (4) Mix of extremes."
  });
  return list;
}
