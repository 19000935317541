export const EXTENTS = [
  {
    id: 0,
    name: "Great Britain",
    lat: 51.53016,
    long: -1.56236,
    zoom: 7.9,
    minLat: 45,
    maxLat: 65,
    minLong: -16,
    maxLong: 12
  },
  {
    id: 1,
    name: "London", //Needs to be called "London" for control filters.
    lat: 51.5,
    long: -0.1,
    zoom: 9,
    minLat: 51.25,
    maxLat: 51.75,
    minLong: -0.6,
    maxLong: 0.3
  },
  {
    id: 2,
    name: "United Kingdom",
    lat: 53.7,
    long: -2.5,
    zoom: 7,
    minLat: 45,
    maxLat: 65,
    minLong: -16,
    maxLong: 12
  },
  {
    id: 3,
    name: "England and Wales",
    lat: 52.5,
    long: -1.93,
    zoom: 7,
    minLat: 47,
    maxLat: 58,
    minLong: -8,
    maxLong: 6
  },
  {
    id: 4,
    name: "Scotland",
    lat: 56,
    long: -4,
    zoom: 8,
    minLat: 53,
    maxLat: 65,
    minLong: -8,
    maxLong: 3
  },
  {
    id: 5,
    name: "West Midlands CA",
    lat: 52.5,
    long: -1.8,
    zoom: 10,
    minLat: 52.2,
    maxLat: 52.8,
    minLong: -2.3,
    maxLong: -1.3
  },
  {
    id: 6,
    name: "USA", //Needs to be called "USA" for control filters.
    lat: 40,
    long: -83,
    zoom: 6,
    minLat: 18.91619,
    maxLat: 71.3577635769,
    minLong: -171.791110603,
    maxLong: -66.96466
  },
  {
    id: 7,
    name: "England and Wales (2023 LAs)",
    lat: 52.5,
    long: -1.93,
    zoom: 7,
    minLat: 47,
    maxLat: 58,
    minLong: -8,
    maxLong: 6
  },
  {
    id: 8,
    name: "London", //Needs to be called "London" for control filters.
    lat: 51.5,
    long: -0.1,
    zoom: 9,
    minLat: 51.25,
    maxLat: 51.75,
    minLong: -0.6,
    maxLong: 0.3
  },
  {
    id: 9,
    name: "United Kingdom (2023 LAs)",
    lat: 53.7,
    long: -2.5,
    zoom: 7,
    minLat: 45,
    maxLat: 65,
    minLong: -16,
    maxLong: 12
  },
  {
    id: 10,
    name: "Great Britain (2023 LAs)",
    lat: 51.53016,
    long: -1.56236,
    zoom: 7.9,
    minLat: 45,
    maxLat: 65,
    minLong: -16,
    maxLong: 12
  }
];

//TODO. If the current view is not in the extent of the currenet map,
//show a popup advising viewer of this. In some maps, different metrics have different extents,
//so ask user if they want to switch to that extent, or switch them to the extent automatically
//if first time into this map.
//Example:
//Viewer currently looking at Scotland on OAC map (extent 2) is fine.
//If they switch to MODUM (extent 3) then we popup a jump button for them, unless they scroll into extent 3.
//If they switch to EFDI (extent 0 but with default metric extent 3 and another map extent 4) then, as user
//is out of 3 but in 4, then we switch automatically to 4. If they switch back, we don't switch again but show
//the jump button.
