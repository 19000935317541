const cols = "the_geom_webmercator, wz11_cd, la_nm, la11_cd, cow_sg, cow_g";

export const COWZ = {
  title: "Classification of Workplace Zones",
  description:
    "A geodemographic classification based on the 2011 UK Census. An interim England/Wales classification was also published and can be viewed <a href='/#/classification-of-workplace-zones-ew'>here</a>.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/classification-workplace-zones-cowz",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM wz11_uk",
  buildingSql: "SELECT " + cols + " FROM wz11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM wz11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM wz11_uk_urban",
  defaultLayers: "1111000",
  extent: 2,
  popup: true,
  popupType: "classification",
  idField: "wz11_cd",
  geogNameField: "la_nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la11_cd",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.cow_sg = 'A' then 1 else 0 end) as count_a,
      sum(case when d.cow_sg = 'B' then 1 else 0 end) as count_b,
      sum(case when d.cow_sg = 'C' then 1 else 0 end) as count_c,
      sum(case when d.cow_sg = 'D' then 1 else 0 end) as count_d,
      sum(case when d.cow_sg = 'E' then 1 else 0 end) as count_e,
      sum(case when d.cow_sg = 'F' then 1 else 0 end) as count_f,
      sum(case when d.cow_sg = 'G' then 1 else 0 end) as count_g,
      sum(case when d.cow_g = 'A1' then 1 else 0 end) as count_a1,
      sum(case when d.cow_g = 'A2' then 1 else 0 end) as count_a2,
      sum(case when d.cow_g = 'A3' then 1 else 0 end) as count_a3,
      sum(case when d.cow_g = 'A4' then 1 else 0 end) as count_a4,
      sum(case when d.cow_g = 'A5' then 1 else 0 end) as count_a5,
      sum(case when d.cow_g = 'A6' then 1 else 0 end) as count_a6,
      sum(case when d.cow_g = 'B1' then 1 else 0 end) as count_b1,
      sum(case when d.cow_g = 'B2' then 1 else 0 end) as count_b2,
      sum(case when d.cow_g = 'B3' then 1 else 0 end) as count_b3,
      sum(case when d.cow_g = 'B4' then 1 else 0 end) as count_b4,
      sum(case when d.cow_g = 'B5' then 1 else 0 end) as count_b5,  
      sum(case when d.cow_g = 'C1' then 1 else 0 end) as count_c1,
      sum(case when d.cow_g = 'C2' then 1 else 0 end) as count_c2,
      sum(case when d.cow_g = 'C3' then 1 else 0 end) as count_c3,
      sum(case when d.cow_g = 'C4' then 1 else 0 end) as count_c4,
      sum(case when d.cow_g = 'C5' then 1 else 0 end) as count_c5,
      sum(case when d.cow_g = 'D1' then 1 else 0 end) as count_d1,
      sum(case when d.cow_g = 'D2' then 1 else 0 end) as count_d2,
      sum(case when d.cow_g = 'E1' then 1 else 0 end) as count_e1,
      sum(case when d.cow_g = 'E2' then 1 else 0 end) as count_e2,
      sum(case when d.cow_g = 'E3' then 1 else 0 end) as count_e3,
      sum(case when d.cow_g = 'E4' then 1 else 0 end) as count_e4,
      sum(case when d.cow_g = 'F1' then 1 else 0 end) as count_f1,
      sum(case when d.cow_g = 'F2' then 1 else 0 end) as count_f2,
      sum(case when d.cow_g = 'F3' then 1 else 0 end) as count_f3,
      sum(case when d.cow_g = 'F4' then 1 else 0 end) as count_f4,
      sum(case when d.cow_g = 'G1' then 1 else 0 end) as count_g1,
      sum(case when d.cow_g = 'G2' then 1 else 0 end) as count_g2,
      sum(case when d.cow_g = 'G3' then 1 else 0 end) as count_g3
  FROM oliverobrien.wz11_uk d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Supergroup",
      field: "cow_sg",
      lookup: "id",
      groups: [
        {
          id: "A",
          name: "Retail",
          colour: "#ffff00",
          visible: true,
          description:
            "The retail supergroup is characterised by high density, higher than average female, young and student workers, working part-time, in semi-routine occupations in the retail, motor repair, real estate, food and accommodation services, and finance and insurance sectors. They tend to travel very short distances to work by public transport, bike or on foot."
        },
        {
          id: "B",
          name: "City and Business Parks",
          colour: "#ff4444",
          visible: true,
          description:
            "This supergroup is characterised by high workplace population density and a low OA:WZ ratio (splits), higher than average percentage of young females, high Black, Asian and European ethnicities, high percentages of Level 4 qualifications and employees, high on ICT, finance, professional, scientific and technical activities, high on higher managerial and lower managerial, low on routine occupations, high on travelling more than 20km to work, low on working from home/no fixed place, low on part time working. This group is mainly found in large numbers in the major metropolitan centres. There are small numbers of isolated WZs in this group in other locations e.g. Science Parks etc."
        },
        {
          id: "C",
          name: "Metro suburbs",
          colour: "#8888dd",
          visible: true,
          description:
            "This supergroup is characterised by a higher than average percentage of non-white workers, self-employed workers and workers working from home or with no fixed place of work, together with a higher than average percentage of people using public transport to get to work. Geographically, this supergroup is mostly in large metropolitan areas, particularly those with a significant ethnic population and public transport system. It is particularly obvious in large cities such as London, Manchester and Birmingham and other cities with multicultural populations. In the very large cities where the top jobs supergroup is present, the metro suburbs supergroup is generally found in a ring outside of these top jobs, but is located inside supergroup four (suburban services). In areas which do not have a core of top jobs, the metro suburbs are often found just outside the retail centre of the city, but still inside the suburban services."
        },
        {
          id: "D",
          name: "Suburban services",
          colour: "#bbbbbb",
          visible: true,
          description:
            "This supergroup is characterised by slightly higher than average white ethnicity, high percentages working from home/no fixed place of work, slightly higher than average education, health/social work, construction and utilities and a low proportion of people travelling more than 20km. It is fairly low density, being characterised by a higher than average OA:WZ ratio (mergers). It is the largest supergroup by number of WZs and is mostly found outside of the metro suburbs and across lower-density urban areas such as the South Wales valleys."
        },
        {
          id: "E",
          name: "Manufacturing and distribution",
          colour: "#ff8800",
          visible: true,
          description:
            "The manufacturing, transport and distribution supergroup is characterised by a slightly lower than average OA:WZ ratio, low percentage of female workers, low percentage high qualifications, high percentage low qualifications, high employees, low part-time, high manufacturing, energy/utilities, transport and storage, lower status working, low on working from home and no fixed place of work, slightly high on >20km."
        },
        {
          id: "F",
          name: "Rural",
          colour: "#41ab5d",
          visible: true,
          description:
            "The Rural supergroup is characterised by higher than average percentages of older, White, workers who are self-employed (both with and without employees), who work from home or have no fixed place of work. Workplace population density is low. Workers are primarily engaged in agriculture, forestry and fishing, and, to a lesser extent, other sectors such as mining and quarrying, manufacturing, energy/utilities, construction and education. This supergroup is the largest in terms of number of WZs and is found in rural and outer suburban areas throughout England and Wales."
        },
        {
          id: "G",
          name: "Servants of society",
          colour: "#aa66aa",
          visible: true,
          description:
            "The servants of society supergoup is characterised by higher than average density, females and young females. It is just below average on non-White and European groups and just above for White. Level 4 qualifications are high, as are employees, while self-employment of any type is low. Both extremes of working hours are low, with part time 16-30 just on the average. The only industrial sectors above average are public administration and defence, education and health. Higher managerial jobs are a little above average, but lower managerial and intermediate status more so. Both extremes of travel distance are above average but working from home and without a fixed place of work are well below. In summary, these tend to be higher status, well-qualified employees in large public service organizations with higher than average female representation in the workforce. They include hospitals, schools and colleges, prisons and government offices and are widely spread nationally, although somewhat clustered in the major service centres."
        }
      ]
    },
    {
      id: 1,
      name: "Group",
      field: "cow_g",
      lookup: "id",
      // prettier-ignore
      groups: [
		{ id: "A1", parent: "A", name: "Low density wholesale and retail", colour: "#ffffbb", visible: true, description: "A workforce characterised by above-average levels of White British ethnicity, employees and students, with particularly high levels of part-time working and employment in wholesale and retail trades, semi-routine occupations and short travel to work distances. This group is widely dispersed nationally but is found primarily on urban fringes." },
		{ id: "A2", parent: "A", name: "Market squares", colour: "#ffff88", visible: true, description: "This group has a mainly White British workforce with high levels of female participation, self-employment (both with and without employees), students, part-time working. The presence of wholesale and retail is equalled by accommodation and food services and exceeded by financial and insurance activities. Travel to work distances are short and percentages travelling on foot or by bicycle are high. There are very low levels of working from home or at no fixed location. The group is very widely dispersed and found primarily in smaller towns." },
		{ id: "A3", parent: "A", name: "Multicultural urban high streets", colour: "#ffff44", visible: true, description: "This group has high levels of female participation in a generally younger workforce with high percentages of Black and Asian ethnicities, students and part- time working. Accommodation and food services, financial and insurance activities occur with retail and wholesale as the dominant industries. Distances travelled are low and there is high use of public transport as well as travelling on foot or bicycle. These retail centres are generally found in larger cities and in outer centres of the major conurbations." },
		{ id: "A4", parent: "A", name: "Traditional high streets ", colour: "#ffff00", visible: true, description: "Very high female participation in workforce, which is generally young and White. High percentages of students, part-time working and with accommodation and food services, financial and insurance activities occuring alongside wholesale and retail. Widely dispersed nationally, mostly in non- metropolitan areas." },
		{ id: "A5", parent: "A", name: "Shop until you drop", colour: "#dddd00", visible: true, description: "High female participation in the workforce, which is young and with above average levels of Black and Asian ethnicities. Very high representation of students and part-time working. Retail and wholesale exceeds all other activities. Travel to work distances are short and percentages travelling on foot or by bicycle are high. This group includes major national and regional retail centres, including large purpose-built out of town and in-town developments." },
		{ id: "A6", parent: "A", name: "Eat, drink and be merry", colour: "#bbbb00", visible: true, description: "Areas characterised by very high numbers working in accommodation and food services, arts, entertainment and recreation industries in addition to wholesale and retail. European workers are present to an above-average level and there are many students and high levels of part-time working. Travel to work distances are short and percentages travelling on foot or by bicycle are high. There are very low levels of working from home or at no fixed location. Widespread geographically and covering a wide range of locations including coastal locations and other tourist destinations." },
		{ id: "B1", parent: "B", name: "Global business", colour: "#ff0000", visible: true, description: "This group is characterised by concentration in ICT, finance and insurance, professional, scientific and technical activities. The workforce has above average levels of Black, Asian and European ethnicities and high percentages of Level 4 qualifications. It is dominated by the highest status managerial, administrative and professional occupations, with very high percentages travelling more than 20km to work by public transport. It occurs almost exclusively in London, where it is widespread in the City of London and Docklands, with only a very few WZs in the centres of other metropolitan cities." },
		{ id: "B2", parent: "B", name: "Administrative centres", colour: "#ff2222", visible: true, description: "While the demographic, occupational status and travel to work characteristics of this group are very similar to the high status city commuters, these areas are marked by a very different industrial structure, with public administration and defence being the largest activity. This group is found only in the centres of large cities, but is less concentrated in London than the high status city commuters." },
		{ id: "B3", parent: "B", name: "Big city life", colour: "#ff4444", visible: true, description: "The workforce has a high proportion of non-British workers, with above-average levels of female participation. Activity is spread across a range of industries with a concentration in accommodation and food services but also ICT, financial and insurance activities and other professional services. Many travel more than 20km to work by public transport. This group is found exclusively close to the centres of the largest metropolitan areas and includes much of London's entertainment districts." },
		{ id: "B4", parent: "B", name: "Regional business centres", colour: "#ff6666", visible: true, description: "This group is dominated by financial and insurance activities, together with other professional services and high status occupations dominate. The workforce includes many females and is moderately multicultural. There is a high percentage with Level 4 qualifications and most workers are employees. Travel to work distances are high, as is public transport use. This group is remarkably absent from Central London and is rather found in the business quarters of many regional cities." },
		{ id: "B5", parent: "B", name: "Science and business parks", colour: "#ff8888", visible: true, description: "This group comprises a number of individual sites with a highly qualified multiethnic workforce concentrated particularly in ICT and professional, scientific and technical activities. The geographical distribution is wide, although it is clustered in and around urban centres and generally close to major transportation corridors. Percentages travelling more than 20km are very high and public transport use is above average." },
		{ id: "C1", parent: "C", name: "Teachers and carers in metro suburbs", colour: "#6666bb", visible: true, description: "A multicultural workforce with above average levels of employment in education, health and social work activities and with lower managerial, adminstrative and technical occupations slightly above average. Widely dispersed in multicultural urban and suburban areas but entirely absent elsewhere." },
		{ id: "C2", parent: "C", name: "Independent professional metro services", colour: "#7777cc", visible: true, description: "A muliticultural workforce in which Black, Asian and European groups are all above average, as is self-employment. ICT, financial and insurance activities and professional services are all above average, as are higher status occupations. Working from home or no fixed place and travel by public transport are all high. A very concentrated geographical distribution limited to especially inner suburban areas of London and a few major cities but entirely absent elsewhere." },
		{ id: "C3", parent: "C", name: "Metro suburban distribution industries", colour: "#8888dd", visible: true, description: "Above average Black and Asian ethnicities and post-2001 EU accession countries in a workforce which is average in very many dimensions but in which transport and storage is the most prominent industry. Wholesale and retail, accommodation and food services are also above average. Scattered across outer suburban areas of major metropolitan centres." },
		{ id: "C4", parent: "C", name: "Self-employed tradespeople in multicultural metro suburbs", colour: "#aaaaee", visible: true, description: "Above average Black and Asian ethnicities and post-2001 EU accession countries in a workforce in which construction, transport and storage, ICT are all above average as is self-employment without employees. Strongly marked by working from home or at no fixed place. Occurring in quite dense bands around the outer edges of multicultural urban areas and forming distinctive concentric rings around cities such as London, Birmingham and Leicester." },
		{ id: "C5", parent: "C", name: "Professional home-workers in outer suburbs", colour: "#bbbbff", visible: true, description: "A muliticultural workforce in which Black, Asian and European groups are all above average, as is self-employment. ICT, financial and insurance activities, professional services and education are all above average, as are higher status occupations. Working from home or no fixed place are high. A very distinctive geographical distribution present in outer suburban areas of north, west and south London and many major towns and cities but entirely absent elsewhere." },
		{ id: "D1", parent: "D", name: "Non-metropolitan suburban areas", colour: "#bbbbbb", visible: true, description: "Mostly White suburban areas with employment across a range of industries but with local services such as education, health and public administration all above average. Above average female participation in the workforce, mainly lowest status occupations, low long-distance commuting and high percentages travelling under 5km, working from home or no fixed place. Widespread suburban distribution, but mostly away from the larger metropolitan centres." },
		{ id: "D2", parent: "D", name: "Primarily residential suburbs", colour: "#dddddd", visible: true, description: "Mostly white suburban areas with above average levels of self-employment without employees, ranging across many industries with energy, utilities, construction, ICT and education all above average. Very little commuting over 20km but very high levels of working from home or no fixed place. Widely dispersed geographical distribution, but mostly on the edges of larger urban areas." },
		{ id: "E1", parent: "E", name: "Mining and quarrying facilities", colour: "#ff9944", visible: true, description: "A workforce with low female participation, high percentage of White British and employees, greatly above average in mining and quarrying, manufacturing and energy and utilities, transport and storage. Lower supervisory, technical and routine occupations are all above average. Working from home or no fixed place are well below average. Widely dispersed geographically but mainly away from urban centres, some concentration around ports." },
		{ id: "E2", parent: "E", name: "Industrial units", colour: "#ff8800", visible: true, description: "A multiethnic workforce with low female participation, with high proportions of no qualfications, employees. Industrial structure dominated by manufacturing and transport and storage and lower status, especially routine occupations. Found in industrial areas on the outer edges of larger cities." },
		{ id: "E3", parent: "E", name: "Business parks", colour: "#dd7700", visible: true, description: "Workforce comprising mostly employees with below average rates of females and part-time working, dominated by manufacturing and energy industries but ICT also above average. Covering broad spectrum of occupational statuses from higher managerial to routine. Greatly above average travelling more than 20km to work but low working from home or no fixed place. Widely dispersed nationally." },
		{ id: "E4", parent: "E", name: "Manufacturing, energy and utilities", colour: "#bb6600", visible: true, description: "Below average female participation rate, above average rates of post-2001 EU workforce and employees. Industrial structure dominated by manufacturing and energy with transport and storage. Above average lower status occupations with both high over 20km and under 5km travel to work. Extremely widely dispersed and often away from major urban areas." },
		{ id: "F1", parent: "F", name: "Town fringe countryside", colour: "#a1d99b", visible: true, description: "Very high levels of white ethnicity and employment in agriculture, forestry and fishing. Very low presence of non-white ethnic groups. Some public service employment but distinctively low on professional services. Some bias towards lower status occupational groups. Widely distributed, mainly on the edges of smaller towns." },
		{ id: "F2", parent: "F", name: "Accessible countryside", colour: "#74c476", visible: true, description: "Very high employment in agriculture, forestry and fishing, ethnic mix closer to national average than all other rural groups. More higher status employment than other rural groups. High levels of working from home. Found mostly towards the south and east of the UK in areas accessible from major metropolitan centres." },
		{ id: "F3", parent: "F", name: "Rural with mining or quarrying", colour: "#41ab5d", visible: true, description: "Low female participation in a workforce which is older, mostly White and dominated by agriculture, forestry and fishing activities but in combination with mining and quarrying activities as well as energy and construction. Above average working from home or no fixed place. Widespread and low density, often in remote areas." },
		{ id: "F4", parent: "F", name: "Traditional countryside", colour: "#238b45", visible: true, description: "In many ways the most extreme rural profile, with above average White, elderly workforce, self-employment and working from home. Industrial structure dominated by agriculture, forestry and fishing, with some energy and construction. Geographically extensive and widely dispersed, covering many of the most remote rural areas." },
		{ id: "G1", parent: "G", name: "Public administration and security", colour: "#cc88cc", visible: true, description: "Very white, concentrated in public administration, defence and compulsory social security. Intermediate occupations. Widespread, predominently in urban areas. Includes many city halls, council offices and prisons." },
		{ id: "G2", parent: "G", name: "Healthcare and public administration", colour: "#aa66aa", visible: true, description: "Major hospitals, especially in towns and cities. Often also including public administration such as city halls and other public services. Multi-ethnic and highly qualified workforces. High females. Above average part-time working." },
		{ id: "G3", parent: "G", name: "Large scale education", colour: "#884488", visible: true, description: "This group has a distinct profile with high female participation in a highly qualified workforce which is massively dominated by education. Lower managerial, administrative and professional occupations are pre-eminent. The geographical distribution is very widespread, reflecting not only the presence of universities but also of large schools and colleges within communities." }
      ]
    }
  ]
};
