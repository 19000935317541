<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab :title="title" active v-if="data.id">
        <h5 class="mb-1 text-center">
          <strong>{{ data.name }}</strong>
        </h5>
        <p class="text-center">{{ data.id }}</p>

        <table
          v-for="group in data.hierarchy"
          v-bind:key="group.id"
          class="table mt-3"
        >
          <thead>
            <tr>
              <th style="width: 7rem" scope="col" class="text-center">
                {{ group.name }}
              </th>
              <th
                scope="col"
                v-bind:style="{
                  backgroundColor: group.groupColour,
                  color: group.lightText ? 'white' : 'black'
                }"
                class="text-center"
              >
                {{ group.groupName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">
                <h3>{{ group.groupID }}</h3>
              </td>
              <td>{{ group.groupDescription }}</td>
            </tr>

            <tr></tr>
          </tbody>
        </table>
      </b-tab>
      <b-tab
        :title="
          type == 'London'
            ? 'Greater London'
            : type == 'USA'
            ? 'State'
            : 'Region'
        "
        v-if="data.region.id"
      >
        <h5 class="mb-1 text-center">
          <strong>{{ data.region.name }}</strong>
        </h5>
        <p class="text-center">{{ data.region.id }}</p>
        <div v-if="!data.region.data.count_total">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="data.region.data.count_total">
          <div v-for="group in hierarchy" v-bind:key="group.id">
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 25%" class="text-center">
                    {{ group.name }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    Name
                  </th>
                  <th scope="col" style="width: 25%" class="text-center">
                    Percentage by Count
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="g in group.groups" v-bind:key="g.id">
                  <td class="text-center">
                    <strong>{{ g.id }}</strong>
                  </td>
                  <td
                    v-bind:style="{
                      backgroundColor: g.colour,
                      color: g.lightText ? 'white' : 'black'
                    }"
                  >
                    <strong>{{ g.name }}</strong>
                  </td>
                  <td class="text-center">
                    {{
                      (data.region.data[
                        "count_" +
                          g.id
                            .toString()
                            .toLowerCase()
                            .replace(".", "_")
                      ] /
                        data.region.data["count_total"])
                        | toPercentage
                    }}
                  </td>
                </tr>

                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>

      <b-tab title="Combined Authority" v-if="data.combined.id">
        <h5 class="mb-1 text-center">
          <strong>{{ data.combined.name }}</strong>
        </h5>
        <p class="text-center">{{ data.combined.id }}</p>
        <div v-if="!data.combined.data.count_total">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="data.combined.data.count_total">
          <div v-for="group in hierarchy" v-bind:key="group.id">
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 25%" class="text-center">
                    {{ group.name }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    Name
                  </th>
                  <th scope="col" style="width: 25%" class="text-center">
                    Percentage by Count
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="g in group.groups" v-bind:key="g.id">
                  <td class="text-center">
                    <strong>{{ g.id }}</strong>
                  </td>
                  <td
                    v-bind:style="{
                      backgroundColor: g.colour,
                      color: g.lightText ? 'white' : 'black'
                    }"
                  >
                    <strong>{{ g.name }}</strong>
                  </td>
                  <td class="text-center">
                    {{
                      (data.combined.data[
                        "count_" +
                          g.id
                            .toString()
                            .toLowerCase()
                            .replace(".", "_")
                      ] /
                        data.combined.data["count_total"])
                        | toPercentage
                    }}
                  </td>
                </tr>

                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab title="Constituency (2024)" v-if="data.pcon.id">
        <h5 class="mb-1 text-center">
          <strong>{{ data.pcon.name }}</strong>
        </h5>
        <p class="text-center">{{ data.pcon.id }}</p>
        <div v-if="!data.pcon.data.count_total">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="data.pcon.data.count_total">
          <div v-for="group in hierarchy" v-bind:key="group.id">
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 25%" class="text-center">
                    {{ group.name }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    Name
                  </th>
                  <th scope="col" style="width: 25%" class="text-center">
                    Percentage by Count
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="g in group.groups" v-bind:key="g.id">
                  <td class="text-center">
                    <strong>{{ g.id }}</strong>
                  </td>
                  <td
                    v-bind:style="{
                      backgroundColor: g.colour,
                      color: g.lightText ? 'white' : 'black'
                    }"
                  >
                    <strong>{{ g.name }}</strong>
                  </td>
                  <td class="text-center">
                    {{
                      (data.pcon.data[
                        "count_" +
                          g.id
                            .toString()
                            .toLowerCase()
                            .replace(".", "_")
                      ] /
                        data.pcon.data["count_total"])
                        | toPercentage
                    }}
                  </td>
                </tr>

                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab
        :title="
          type == 'London'
            ? 'London Borough'
            : type == 'USA'
            ? 'County'
            : 'Local Authority (2023)'
        "
        v-if="data.lad.id"
      >
        <h5 class="mb-1 text-center">
          <strong>{{ data.lad.name }}</strong>
        </h5>
        <p class="text-center">{{ data.lad.id }}</p>
        <div v-if="!data.lad.data.count_total">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="data.lad.data.count_total">
          <div v-for="group in hierarchy" v-bind:key="group.id">
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 25%" class="text-center">
                    {{ group.name }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    Name
                  </th>
                  <th scope="col" style="width: 25%" class="text-center">
                    Percentage by Count
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="g in group.groups" v-bind:key="g.id">
                  <td class="text-center">
                    <strong>{{ g.id }}</strong>
                  </td>
                  <td
                    v-bind:style="{
                      backgroundColor: g.colour,
                      color: g.lightText ? 'white' : 'black'
                    }"
                  >
                    <strong>{{ g.name }}</strong>
                  </td>
                  <td class="text-center">
                    {{
                      (data.lad.data[
                        "count_" +
                          g.id
                            .toString()
                            .toLowerCase()
                            .replace(".", "_")
                      ] /
                        data.lad.data["count_total"])
                        | toPercentage
                    }}
                  </td>
                </tr>

                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab title="Retail Centre" v-if="data.retail.id">
        <h5 class="mb-1 text-center">
          <strong>{{ data.retail.name }}</strong>
        </h5>
        <p class="text-center">{{ data.retail.id }}</p>
        <div v-if="!data.retail.data.count_total">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="data.retail.data.count_total">
          <div v-for="group in hierarchy" v-bind:key="group.id">
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 25%" class="text-center">
                    {{ group.name }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    Name
                  </th>
                  <th scope="col" style="width: 25%" class="text-center">
                    Percentage by Count
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="g in group.groups" v-bind:key="g.id">
                  <td class="text-center">
                    <strong>{{ g.id }}</strong>
                  </td>
                  <td
                    v-bind:style="{
                      backgroundColor: g.colour,
                      color: g.lightText ? 'white' : 'black'
                    }"
                  >
                    <strong>{{ g.name }}</strong>
                  </td>
                  <td class="text-center">
                    {{
                      (data.retail.data[
                        "count_" +
                          g.id
                            .toString()
                            .toLowerCase()
                            .replace(".", "_")
                      ] /
                        data.retail.data["count_total"])
                        | toPercentage
                    }}
                  </td>
                </tr>

                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab title="Polygon" v-if="data.adhoc.id">
        <h5 class="mb-1 text-center">
          <strong>Drawn Polygon</strong>
        </h5>
        <div v-if="!data.adhoc.data.count_total">
          <p class="text-center">
            <b-spinner variant="secondary" label="Spinning"></b-spinner>
          </p>
          <p class="text-center text-secondary">Getting Data</p>
        </div>
        <div v-if="data.adhoc.data.count_total">
          <div v-for="group in hierarchy" v-bind:key="group.id">
            <table class="table mt-3 table-sm">
              <thead>
                <tr>
                  <th scope="col" style="width: 25%" class="text-center">
                    {{ group.name }}
                  </th>
                  <th scope="col" style="width: 50%" class="text-center">
                    Name
                  </th>
                  <th scope="col" style="width: 25%" class="text-center">
                    Percentage by Count
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="g in group.groups" v-bind:key="g.id">
                  <td class="text-center">
                    <strong>{{ g.id }}</strong>
                  </td>
                  <td
                    v-bind:style="{
                      backgroundColor: g.colour,
                      color: g.lightText ? 'white' : 'black'
                    }"
                  >
                    <strong>{{ g.name }}</strong>
                  </td>
                  <td class="text-center">
                    {{
                      (data.adhoc.data[
                        "count_" +
                          g.id
                            .toString()
                            .toLowerCase()
                            .replace(".", "_")
                      ] /
                        data.adhoc.data["count_total"])
                        | toPercentage
                    }}
                  </td>
                </tr>

                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  name: "MapClassificationPopupContents",
  props: { data: Object, hierarchy: Array, title: String, type: String },
  filters: {
    toPercentage(value) {
      if (!value) {
        value = 0;
      }
      const decimalPlaces = 1;
      value = value * 100;

      value =
        Math.round(value * Math.pow(10, decimalPlaces)) /
        Math.pow(10, decimalPlaces);
      value = value + "%";
      return value;
    }
  }
};
</script>
