export const LAD = [
  { id: "S12000034", name: "Aberdeenshire", london: false },
  { id: "E07000223", name: "Adur", london: false },
  { id: "E07000026", name: "Allerdale", london: false },
  { id: "E07000032", name: "Amber Valley", london: false },
  { id: "S12000041", name: "Angus", london: false },
  { id: "95T", name: "Antrim", london: false },
  { id: "95X", name: "Ards", london: false },
  { id: "S12000035", name: "Argyll & Bute", london: false },
  { id: "95O", name: "Armagh", london: false },
  { id: "E07000224", name: "Arun", london: false },
  { id: "E07000170", name: "Ashfield", london: false },
  { id: "E07000105", name: "Ashford", london: false },
  { id: "E07000004", name: "Aylesbury Vale", london: false },
  { id: "E07000200", name: "Babergh", london: false },
  { id: "95G", name: "Ballymena", london: false },
  { id: "95D", name: "Ballymoney", london: false },
  { id: "95Q", name: "Banbridge", london: false },
  { id: "E09000002", name: "Barking and Dagenham", london: true },
  { id: "E09000003", name: "Barnet", london: true },
  { id: "E08000016", name: "Barnsley", london: false },
  { id: "E07000027", name: "Barrow-in-Furness", london: false },
  { id: "E07000066", name: "Basildon", london: false },
  { id: "E07000084", name: "Basingstoke and Deane", london: false },
  { id: "E07000171", name: "Bassetlaw", london: false },
  { id: "E06000022", name: "Bath and North East Somerset", london: false },
  { id: "E06000055", name: "Bedford", london: false },
  { id: "95Z", name: "Belfast", london: false },
  { id: "E09000004", name: "Bexley", london: true },
  { id: "E08000025", name: "Birmingham", london: false },
  { id: "E07000129", name: "Blaby", london: false },
  { id: "E06000008", name: "Blackburn with Darwen", london: false },
  { id: "E06000009", name: "Blackpool", london: false },
  { id: "W06000019", name: "Blaenau Gwent", london: false },
  { id: "E07000033", name: "Bolsover", london: false },
  { id: "E08000001", name: "Bolton", london: false },
  { id: "E07000136", name: "Boston", london: false },
  { id: "E06000028", name: "Bournemouth", london: false },
  { id: "E06000036", name: "Bracknell Forest", london: false },
  { id: "E08000032", name: "Bradford", london: false },
  { id: "E07000067", name: "Braintree", london: false },
  { id: "E07000143", name: "Breckland", london: false },
  { id: "E09000005", name: "Brent", london: true },
  { id: "E07000068", name: "Brentwood", london: false },
  { id: "W06000013", name: "Bridgend", london: false },
  { id: "E06000043", name: "Brighton and Hove", london: false },
  { id: "E06000023", name: "Bristol, City of", london: false },
  { id: "E07000144", name: "Broadland", london: false },
  { id: "E09000006", name: "Bromley", london: true },
  { id: "E07000234", name: "Bromsgrove", london: false },
  { id: "E07000095", name: "Broxbourne", london: false },
  { id: "E07000172", name: "Broxtowe", london: false },
  { id: "E07000117", name: "Burnley", london: false },
  { id: "E08000002", name: "Bury", london: false },
  { id: "W06000018", name: "Caerphilly", london: false },
  { id: "E08000033", name: "Calderdale", london: false },
  { id: "E07000008", name: "Cambridge", london: false },
  { id: "E09000007", name: "Camden", london: true },
  { id: "E07000192", name: "Cannock Chase", london: false },
  { id: "E07000106", name: "Canterbury", london: false },
  { id: "W06000015", name: "Cardiff", london: false },
  { id: "E07000028", name: "Carlisle", london: false },
  { id: "W06000010", name: "Carmarthenshire", london: false },
  { id: "95V", name: "Carrickfergus", london: false },
  { id: "E07000069", name: "Castle Point", london: false },
  { id: "95Y", name: "Castlereagh", london: false },
  { id: "E06000056", name: "Central Bedfordshire", london: false },
  { id: "W06000008", name: "Ceredigion", london: false },
  { id: "E07000130", name: "Charnwood", london: false },
  { id: "E07000070", name: "Chelmsford", london: false },
  { id: "E07000078", name: "Cheltenham", london: false },
  { id: "E07000177", name: "Cherwell", london: false },
  { id: "E06000049", name: "Cheshire East", london: false },
  { id: "E06000050", name: "Cheshire West and Chester", london: false },
  { id: "E07000034", name: "Chesterfield", london: false },
  { id: "E07000225", name: "Chichester", london: false },
  { id: "E07000005", name: "Chiltern", london: false },
  { id: "E07000118", name: "Chorley", london: false },
  { id: "E07000048", name: "Christchurch", london: false },
  { id: "E09000001", name: "City of London", london: true },
  { id: "S12000005", name: "Clackmannanshire", london: false },
  { id: "E07000071", name: "Colchester", london: false },
  { id: "95C", name: "Coleraine", london: false },
  { id: "W06000003", name: "Conwy", london: false },
  { id: "95I", name: "Cookstown", london: false },
  { id: "E07000029", name: "Copeland", london: false },
  { id: "E07000150", name: "Corby", london: false },
  { id: "E06000052", name: "Cornwall", london: false },
  { id: "E07000079", name: "Cotswold", london: false },
  { id: "E06000047", name: "County Durham", london: false },
  { id: "E08000026", name: "Coventry", london: false },
  { id: "95N", name: "Craigavon", london: false },
  { id: "E07000163", name: "Craven", london: false },
  { id: "E07000226", name: "Crawley", london: false },
  { id: "E09000008", name: "Croydon", london: true },
  { id: "E07000096", name: "Dacorum", london: false },
  { id: "E06000005", name: "Darlington", london: false },
  { id: "E07000107", name: "Dartford", london: false },
  { id: "E07000151", name: "Daventry", london: false },
  { id: "W06000004", name: "Denbighshire", london: false },
  { id: "E06000015", name: "Derby", london: false },
  { id: "E07000035", name: "Derbyshire Dales", london: false },
  { id: "95A", name: "Derry", london: false },
  { id: "E08000017", name: "Doncaster", london: false },
  { id: "E07000108", name: "Dover", london: false },
  { id: "95R", name: "Down", london: false },
  { id: "E08000027", name: "Dudley", london: false },
  { id: "S12000006", name: "Dumfries & Galloway", london: false },
  { id: "S12000042", name: "Dundee City", london: false },
  { id: "95M", name: "Dungannon and South Tyrone", london: false },
  { id: "E09000009", name: "Ealing", london: true },
  { id: "S12000008", name: "East Ayrshire", london: false },
  { id: "E07000009", name: "East Cambridgeshire", london: false },
  { id: "E07000040", name: "East Devon", london: false },
  { id: "E07000049", name: "East Dorset", london: false },
  { id: "S12000045", name: "East Dunbartonshire", london: false },
  { id: "E07000085", name: "East Hampshire", london: false },
  { id: "E07000242", name: "East Hertfordshire", london: false },
  { id: "E07000137", name: "East Lindsey", london: false },
  { id: "S12000010", name: "East Lothian", london: false },
  { id: "E07000152", name: "East Northamptonshire", london: false },
  { id: "S12000011", name: "East Renfrewshire", london: false },
  { id: "E06000011", name: "East Riding of Yorkshire", london: false },
  { id: "E07000193", name: "East Staffordshire", london: false },
  { id: "E07000061", name: "Eastbourne", london: false },
  { id: "E07000086", name: "Eastleigh", london: false },
  { id: "E07000030", name: "Eden", london: false },
  { id: "S12000036", name: "Edinburgh, City of", london: false },
  { id: "S12000013", name: "Eilean Siar", london: false },
  { id: "E07000207", name: "Elmbridge", london: false },
  { id: "E09000010", name: "Enfield", london: true },
  { id: "E07000072", name: "Epping Forest", london: false },
  { id: "E07000208", name: "Epsom and Ewell", london: false },
  { id: "E07000036", name: "Erewash", london: false },
  { id: "E07000041", name: "Exeter", london: false },
  { id: "S12000014", name: "Falkirk", london: false },
  { id: "E07000087", name: "Fareham", london: false },
  { id: "E07000010", name: "Fenland", london: false },
  { id: "95L", name: "Fermanagh", london: false },
  { id: "S12000015", name: "Fife", london: false },
  { id: "W06000005", name: "Flintshire", london: false },
  { id: "E07000201", name: "Forest Heath", london: false },
  { id: "E07000080", name: "Forest of Dean", london: false },
  { id: "E07000119", name: "Fylde", london: false },
  { id: "E08000037", name: "Gateshead", london: false },
  { id: "E07000173", name: "Gedling", london: false },
  { id: "S12000046", name: "Glasgow City", london: false },
  { id: "E07000081", name: "Gloucester", london: false },
  { id: "E07000088", name: "Gosport", london: false },
  { id: "E07000109", name: "Gravesham", london: false },
  { id: "E07000145", name: "Great Yarmouth", london: false },
  { id: "E09000011", name: "Greenwich", london: true },
  { id: "E07000209", name: "Guildford", london: false },
  { id: "W06000002", name: "Gwynedd", london: false },
  { id: "E09000012", name: "Hackney", london: true },
  { id: "E06000006", name: "Halton", london: false },
  { id: "E07000164", name: "Hambleton", london: false },
  { id: "E09000013", name: "Hammersmith and Fulham", london: true },
  { id: "E07000131", name: "Harborough", london: false },
  { id: "E09000014", name: "Haringey", london: true },
  { id: "E07000073", name: "Harlow", london: false },
  { id: "E07000165", name: "Harrogate", london: false },
  { id: "E09000015", name: "Harrow", london: true },
  { id: "E07000089", name: "Hart", london: false },
  { id: "E06000001", name: "Hartlepool", london: false },
  { id: "E07000062", name: "Hastings", london: false },
  { id: "E07000090", name: "Havant", london: false },
  { id: "E09000016", name: "Havering", london: true },
  { id: "E06000019", name: "Herefordshire, County of", london: false },
  { id: "E07000098", name: "Hertsmere", london: false },
  { id: "E07000037", name: "High Peak", london: false },
  { id: "S12000017", name: "Highland", london: false },
  { id: "E09000017", name: "Hillingdon", london: true },
  { id: "E07000132", name: "Hinckley and Bosworth", london: false },
  { id: "E07000227", name: "Horsham", london: false },
  { id: "E09000018", name: "Hounslow", london: true },
  { id: "E07000011", name: "Huntingdonshire", london: false },
  { id: "E07000120", name: "Hyndburn", london: false },
  { id: "S12000018", name: "Inverclyde", london: false },
  { id: "E07000202", name: "Ipswich", london: false },
  { id: "W06000001", name: "Isle of Anglesey", london: false },
  { id: "E06000046", name: "Isle of Wight", london: false },
  { id: "E06000053", name: "Isles of Scilly", london: false },
  { id: "E09000019", name: "Islington", london: true },
  { id: "E09000020", name: "Kensington and Chelsea", london: true },
  { id: "E07000153", name: "Kettering", london: false },
  { id: "E07000146", name: "King's Lynn and West Norfolk", london: false },
  { id: "E06000010", name: "Kingston upon Hull, City of", london: false },
  { id: "E09000021", name: "Kingston upon Thames", london: true },
  { id: "E08000034", name: "Kirklees", london: false },
  { id: "E08000011", name: "Knowsley", london: false },
  { id: "E09000022", name: "Lambeth", london: true },
  { id: "E07000121", name: "Lancaster", london: false },
  { id: "95F", name: "Larne", london: false },
  { id: "E08000035", name: "Leeds", london: false },
  { id: "E06000016", name: "Leicester", london: false },
  { id: "E07000063", name: "Lewes", london: false },
  { id: "E09000023", name: "Lewisham", london: true },
  { id: "E07000194", name: "Lichfield", london: false },
  { id: "95B", name: "Limavady", london: false },
  { id: "E07000138", name: "Lincoln", london: false },
  { id: "95S", name: "Lisburn", london: false },
  { id: "E08000012", name: "Liverpool", london: false },
  { id: "E06000032", name: "Luton", london: false },
  { id: "95H", name: "Magherafelt", london: false },
  { id: "E07000110", name: "Maidstone", london: false },
  { id: "E07000074", name: "Maldon", london: false },
  { id: "E07000235", name: "Malvern Hills", london: false },
  { id: "E08000003", name: "Manchester", london: false },
  { id: "E07000174", name: "Mansfield", london: false },
  { id: "E06000035", name: "Medway", london: false },
  { id: "E07000133", name: "Melton", london: false },
  { id: "E07000187", name: "Mendip", london: false },
  { id: "W06000024", name: "Merthyr Tydfil", london: false },
  { id: "E09000024", name: "Merton", london: true },
  { id: "E07000042", name: "Mid Devon", london: false },
  { id: "E07000203", name: "Mid Suffolk", london: false },
  { id: "E07000228", name: "Mid Sussex", london: false },
  { id: "E06000002", name: "Middlesbrough", london: false },
  { id: "S12000019", name: "Midlothian", london: false },
  { id: "E06000042", name: "Milton Keynes", london: false },
  { id: "E07000210", name: "Mole Valley", london: false },
  { id: "W06000021", name: "Monmouthshire", london: false },
  { id: "S12000020", name: "Moray", london: false },
  { id: "95E", name: "Moyle", london: false },
  { id: "W06000012", name: "Neath Port Talbot", london: false },
  { id: "E07000091", name: "New Forest", london: false },
  { id: "E07000175", name: "Newark and Sherwood", london: false },
  { id: "E08000021", name: "Newcastle upon Tyne", london: false },
  { id: "E07000195", name: "Newcastle-under-Lyme", london: false },
  { id: "E09000025", name: "Newham", london: true },
  { id: "W06000022", name: "Newport", london: false },
  { id: "95P", name: "Newry and Mourne", london: false },
  { id: "95U", name: "Newtownabbey", london: false },
  { id: "S12000021", name: "North Ayrshire", london: false },
  { id: "E07000043", name: "North Devon", london: false },
  { id: "E07000050", name: "North Dorset", london: false },
  { id: "95W", name: "North Down", london: false },
  { id: "E07000038", name: "North East Derbyshire", london: false },
  { id: "E06000012", name: "North East Lincolnshire", london: false },
  { id: "E07000099", name: "North Hertfordshire", london: false },
  { id: "E07000139", name: "North Kesteven", london: false },
  { id: "S12000044", name: "North Lanarkshire", london: false },
  { id: "E06000013", name: "North Lincolnshire", london: false },
  { id: "E07000147", name: "North Norfolk", london: false },
  { id: "E06000024", name: "North Somerset", london: false },
  { id: "E08000022", name: "North Tyneside", london: false },
  { id: "E07000218", name: "North Warwickshire", london: false },
  { id: "E07000134", name: "North West Leicestershire", london: false },
  { id: "E07000154", name: "Northampton", london: false },
  { id: "E06000057", name: "Northumberland", london: false },
  { id: "E07000148", name: "Norwich", london: false },
  { id: "E06000018", name: "Nottingham", london: false },
  { id: "E07000219", name: "Nuneaton and Bedworth", london: false },
  { id: "E07000135", name: "Oadby and Wigston", london: false },
  { id: "E08000004", name: "Oldham", london: false },
  { id: "95K", name: "Omagh", london: false },
  { id: "S12000023", name: "Orkney Islands", london: false },
  { id: "E07000178", name: "Oxford", london: false },
  { id: "W06000009", name: "Pembrokeshire", london: false },
  { id: "E07000122", name: "Pendle", london: false },
  { id: "S12000024", name: "Perth & Kinross", london: false },
  { id: "E06000031", name: "Peterborough", london: false },
  { id: "E06000026", name: "Plymouth", london: false },
  { id: "E06000029", name: "Poole", london: false },
  { id: "E06000044", name: "Portsmouth", london: false },
  { id: "W06000023", name: "Powys", london: false },
  { id: "E07000123", name: "Preston", london: false },
  { id: "E07000051", name: "Purbeck", london: false },
  { id: "E06000038", name: "Reading", london: false },
  { id: "E09000026", name: "Redbridge", london: true },
  { id: "E06000003", name: "Redcar and Cleveland", london: false },
  { id: "E07000236", name: "Redditch", london: false },
  { id: "E07000211", name: "Reigate and Banstead", london: false },
  { id: "S12000038", name: "Renfrewshire", london: false },
  { id: "W06000016", name: "Rhondda Cynon Taf", london: false },
  { id: "E07000124", name: "Ribble Valley", london: false },
  { id: "E09000027", name: "Richmond upon Thames", london: true },
  { id: "E07000166", name: "Richmondshire", london: false },
  { id: "E08000005", name: "Rochdale", london: false },
  { id: "E07000075", name: "Rochford", london: false },
  { id: "E07000125", name: "Rossendale", london: false },
  { id: "E07000064", name: "Rother", london: false },
  { id: "E08000018", name: "Rotherham", london: false },
  { id: "E07000220", name: "Rugby", london: false },
  { id: "E07000212", name: "Runnymede", london: false },
  { id: "E07000176", name: "Rushcliffe", london: false },
  { id: "E07000092", name: "Rushmoor", london: false },
  { id: "E06000017", name: "Rutland", london: false },
  { id: "E07000167", name: "Ryedale", london: false },
  { id: "E08000006", name: "Salford", london: false },
  { id: "E08000028", name: "Sandwell", london: false },
  { id: "E07000168", name: "Scarborough", london: false },
  { id: "S12000026", name: "Scottish Borders", london: false },
  { id: "E07000188", name: "Sedgemoor", london: false },
  { id: "E08000014", name: "Sefton", london: false },
  { id: "E07000169", name: "Selby", london: false },
  { id: "E07000111", name: "Sevenoaks", london: false },
  { id: "E08000019", name: "Sheffield", london: false },
  { id: "E07000112", name: "Shepway", london: false },
  { id: "S12000027", name: "Shetland Islands", london: false },
  { id: "E06000051", name: "Shropshire", london: false },
  { id: "E06000039", name: "Slough", london: false },
  { id: "E08000029", name: "Solihull", london: false },
  { id: "S12000028", name: "South Ayrshire", london: false },
  { id: "E07000006", name: "South Bucks", london: false },
  { id: "E07000012", name: "South Cambridgeshire", london: false },
  { id: "E07000039", name: "South Derbyshire", london: false },
  { id: "E06000025", name: "South Gloucestershire", london: false },
  { id: "E07000044", name: "South Hams", london: false },
  { id: "E07000140", name: "South Holland", london: false },
  { id: "E07000141", name: "South Kesteven", london: false },
  { id: "E07000031", name: "South Lakeland", london: false },
  { id: "S12000029", name: "South Lanarkshire", london: false },
  { id: "E07000149", name: "South Norfolk", london: false },
  { id: "E07000155", name: "South Northamptonshire", london: false },
  { id: "E07000179", name: "South Oxfordshire", london: false },
  { id: "E07000126", name: "South Ribble", london: false },
  { id: "E07000189", name: "South Somerset", london: false },
  { id: "E07000196", name: "South Staffordshire", london: false },
  { id: "E08000023", name: "South Tyneside", london: false },
  { id: "E06000045", name: "Southampton", london: false },
  { id: "E06000033", name: "Southend-on-Sea", london: false },
  { id: "E09000028", name: "Southwark", london: true },
  { id: "E07000213", name: "Spelthorne", london: false },
  { id: "E07000240", name: "St Albans", london: false },
  { id: "E07000204", name: "St Edmundsbury", london: false },
  { id: "E08000013", name: "St. Helens", london: false },
  { id: "E07000197", name: "Stafford", london: false },
  { id: "E07000198", name: "Staffordshire Moorlands", london: false },
  { id: "E07000243", name: "Stevenage", london: false },
  { id: "S12000030", name: "Stirling", london: false },
  { id: "E08000007", name: "Stockport", london: false },
  { id: "E06000004", name: "Stockton-on-Tees", london: false },
  { id: "E06000021", name: "Stoke-on-Trent", london: false },
  { id: "95J", name: "Strabane", london: false },
  { id: "E07000221", name: "Stratford-on-Avon", london: false },
  { id: "E07000082", name: "Stroud", london: false },
  { id: "E07000205", name: "Suffolk Coastal", london: false },
  { id: "E08000024", name: "Sunderland", london: false },
  { id: "E07000214", name: "Surrey Heath", london: false },
  { id: "E09000029", name: "Sutton", london: true },
  { id: "E07000113", name: "Swale", london: false },
  { id: "W06000011", name: "Swansea", london: false },
  { id: "E06000030", name: "Swindon", london: false },
  { id: "E08000008", name: "Tameside", london: false },
  { id: "E07000199", name: "Tamworth", london: false },
  { id: "E07000215", name: "Tandridge", london: false },
  { id: "E07000190", name: "Taunton Deane", london: false },
  { id: "E07000045", name: "Teignbridge", london: false },
  { id: "E06000020", name: "Telford and Wrekin", london: false },
  { id: "E07000076", name: "Tendring", london: false },
  { id: "E07000093", name: "Test Valley", london: false },
  { id: "E07000083", name: "Tewkesbury", london: false },
  { id: "E07000114", name: "Thanet", london: false },
  { id: "W06000014", name: "The Vale of Glamorgan", london: false },
  { id: "E07000102", name: "Three Rivers", london: false },
  { id: "E06000034", name: "Thurrock", london: false },
  { id: "E07000115", name: "Tonbridge and Malling", london: false },
  { id: "E06000027", name: "Torbay", london: false },
  { id: "W06000020", name: "Torfaen", london: false },
  { id: "E07000046", name: "Torridge", london: false },
  { id: "E09000030", name: "Tower Hamlets", london: true },
  { id: "E08000009", name: "Trafford", london: false },
  { id: "E07000116", name: "Tunbridge Wells", london: false },
  { id: "E07000077", name: "Uttlesford", london: false },
  { id: "E07000180", name: "Vale of White Horse", london: false },
  { id: "E08000036", name: "Wakefield", london: false },
  { id: "E08000030", name: "Walsall", london: false },
  { id: "E09000031", name: "Waltham Forest", london: true },
  { id: "E09000032", name: "Wandsworth", london: true },
  { id: "E06000007", name: "Warrington", london: false },
  { id: "E07000222", name: "Warwick", london: false },
  { id: "E07000103", name: "Watford", london: false },
  { id: "E07000206", name: "Waveney", london: false },
  { id: "E07000216", name: "Waverley", london: false },
  { id: "E07000065", name: "Wealden", london: false },
  { id: "E07000156", name: "Wellingborough", london: false },
  { id: "E07000241", name: "Welwyn Hatfield", london: false },
  { id: "E06000037", name: "West Berkshire", london: false },
  { id: "E07000047", name: "West Devon", london: false },
  { id: "E07000052", name: "West Dorset", london: false },
  { id: "S12000039", name: "West Dunbartonshire", london: false },
  { id: "E07000127", name: "West Lancashire", london: false },
  { id: "E07000142", name: "West Lindsey", london: false },
  { id: "S12000040", name: "West Lothian", london: false },
  { id: "E07000181", name: "West Oxfordshire", london: false },
  { id: "E07000191", name: "West Somerset", london: false },
  { id: "E09000033", name: "Westminster", london: true },
  { id: "E07000053", name: "Weymouth and Portland", london: false },
  { id: "E08000010", name: "Wigan", london: false },
  { id: "E06000054", name: "Wiltshire", london: false },
  { id: "E07000094", name: "Winchester", london: false },
  { id: "E06000040", name: "Windsor and Maidenhead", london: false },
  { id: "E08000015", name: "Wirral", london: false },
  { id: "E07000217", name: "Woking", london: false },
  { id: "E06000041", name: "Wokingham", london: false },
  { id: "E08000031", name: "Wolverhampton", london: false },
  { id: "E07000237", name: "Worcester", london: false },
  { id: "E07000229", name: "Worthing", london: false },
  { id: "W06000006", name: "Wrexham", london: false },
  { id: "E07000238", name: "Wychavon", london: false },
  { id: "E07000007", name: "Wycombe", london: false },
  { id: "E07000128", name: "Wyre", london: false },
  { id: "E07000239", name: "Wyre Forest", london: false },
  { id: "E06000014", name: "York", london: false }
];
