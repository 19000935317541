const cols =
  "the_geom_webmercator, oa21cd, la23cd, la23nm, oac21sup, oac21grp, oac21sub";
const dataTable = "oa21_ew";
const polygonSQLPre = `SELECT
count(*) as count_total,
sum(case when d.oac21sup = '1' then 1 else 0 end) as count_1,
  sum(case when d.oac21sup = '2' then 1 else 0 end) as count_2,
  sum(case when d.oac21sup = '3' then 1 else 0 end) as count_3,
  sum(case when d.oac21sup = '4' then 1 else 0 end) as count_4,
  sum(case when d.oac21sup = '5' then 1 else 0 end) as count_5,
  sum(case when d.oac21sup = '6' then 1 else 0 end) as count_6,
  sum(case when d.oac21sup = '7' then 1 else 0 end) as count_7,
  sum(case when d.oac21sup = '8' then 1 else 0 end) as count_8,
  sum(case when d.oac21grp = '1a' then 1 else 0 end) as count_1a,
  sum(case when d.oac21grp = '1b' then 1 else 0 end) as count_1b,
  sum(case when d.oac21grp = '1c' then 1 else 0 end) as count_1c,
  sum(case when d.oac21grp = '2a' then 1 else 0 end) as count_2a,
  sum(case when d.oac21grp = '2b' then 1 else 0 end) as count_2b,
  sum(case when d.oac21grp = '2c' then 1 else 0 end) as count_2c,
  sum(case when d.oac21grp = '3a' then 1 else 0 end) as count_3a,
  sum(case when d.oac21grp = '3b' then 1 else 0 end) as count_3b,
  sum(case when d.oac21grp = '3c' then 1 else 0 end) as count_3c,
  sum(case when d.oac21grp = '4a' then 1 else 0 end) as count_4a,
  sum(case when d.oac21grp = '4b' then 1 else 0 end) as count_4b,
  sum(case when d.oac21grp = '4c' then 1 else 0 end) as count_4c,
  sum(case when d.oac21grp = '5a' then 1 else 0 end) as count_5a,
  sum(case when d.oac21grp = '5b' then 1 else 0 end) as count_5b,
  sum(case when d.oac21grp = '6a' then 1 else 0 end) as count_6a,
  sum(case when d.oac21grp = '6b' then 1 else 0 end) as count_6b,
  sum(case when d.oac21grp = '6c' then 1 else 0 end) as count_6c,
  sum(case when d.oac21grp = '7a' then 1 else 0 end) as count_7a,
  sum(case when d.oac21grp = '7b' then 1 else 0 end) as count_7b,
  sum(case when d.oac21grp = '8a' then 1 else 0 end) as count_8a,
  sum(case when d.oac21grp = '8b' then 1 else 0 end) as count_8b,
  sum(case when d.oac21sub = '1a1' then 1 else 0 end) as count_1a1,
  sum(case when d.oac21sub = '1a2' then 1 else 0 end) as count_1a2,
  sum(case when d.oac21sub = '1b1' then 1 else 0 end) as count_1b1,
  sum(case when d.oac21sub = '1b2' then 1 else 0 end) as count_1b2,
  sum(case when d.oac21sub = '1c1' then 1 else 0 end) as count_1c1,
  sum(case when d.oac21sub = '1c2' then 1 else 0 end) as count_1c2,
  sum(case when d.oac21sub = '2a1' then 1 else 0 end) as count_2a1,
  sum(case when d.oac21sub = '2a2' then 1 else 0 end) as count_2a2,
  sum(case when d.oac21sub = '2a3' then 1 else 0 end) as count_2a3,
  sum(case when d.oac21sub = '2b1' then 1 else 0 end) as count_2b1,
  sum(case when d.oac21sub = '2b2' then 1 else 0 end) as count_2b2,
  sum(case when d.oac21sub = '2c1' then 1 else 0 end) as count_2c1,
  sum(case when d.oac21sub = '2c2' then 1 else 0 end) as count_2c2,
  sum(case when d.oac21sub = '3a1' then 1 else 0 end) as count_3a1,
  sum(case when d.oac21sub = '3a2' then 1 else 0 end) as count_3a2,
  sum(case when d.oac21sub = '3a3' then 1 else 0 end) as count_3a3,
  sum(case when d.oac21sub = '3a4' then 1 else 0 end) as count_3a4,
  sum(case when d.oac21sub = '3b1' then 1 else 0 end) as count_3b1,
  sum(case when d.oac21sub = '3b2' then 1 else 0 end) as count_3b2,
  sum(case when d.oac21sub = '3c1' then 1 else 0 end) as count_3c1,
  sum(case when d.oac21sub = '3c2' then 1 else 0 end) as count_3c2,
  sum(case when d.oac21sub = '4a1' then 1 else 0 end) as count_4a1,
  sum(case when d.oac21sub = '4a2' then 1 else 0 end) as count_4a2,
  sum(case when d.oac21sub = '4a3' then 1 else 0 end) as count_4a3,
  sum(case when d.oac21sub = '4b1' then 1 else 0 end) as count_4b1,
  sum(case when d.oac21sub = '4b2' then 1 else 0 end) as count_4b2,
  sum(case when d.oac21sub = '4b3' then 1 else 0 end) as count_4b3,
  sum(case when d.oac21sub = '4b4' then 1 else 0 end) as count_4b4,
  sum(case when d.oac21sub = '4c1' then 1 else 0 end) as count_4c1,
  sum(case when d.oac21sub = '4c2' then 1 else 0 end) as count_4c2,
  sum(case when d.oac21sub = '5a1' then 1 else 0 end) as count_5a1,
  sum(case when d.oac21sub = '5a2' then 1 else 0 end) as count_5a2,
  sum(case when d.oac21sub = '5a3' then 1 else 0 end) as count_5a3,
  sum(case when d.oac21sub = '5b1' then 1 else 0 end) as count_5b1,
  sum(case when d.oac21sub = '5b2' then 1 else 0 end) as count_5b2,
  sum(case when d.oac21sub = '6a1' then 1 else 0 end) as count_6a1,
  sum(case when d.oac21sub = '6a2' then 1 else 0 end) as count_6a2,
  sum(case when d.oac21sub = '6a3' then 1 else 0 end) as count_6a3,
  sum(case when d.oac21sub = '6b1' then 1 else 0 end) as count_6b1,
  sum(case when d.oac21sub = '6b2' then 1 else 0 end) as count_6b2,
  sum(case when d.oac21sub = '6b3' then 1 else 0 end) as count_6b3,
  sum(case when d.oac21sub = '6c1' then 1 else 0 end) as count_6c1,
  sum(case when d.oac21sub = '6c2' then 1 else 0 end) as count_6c2,
  sum(case when d.oac21sub = '7a1' then 1 else 0 end) as count_7a1,
  sum(case when d.oac21sub = '7a2' then 1 else 0 end) as count_7a2,
  sum(case when d.oac21sub = '7b1' then 1 else 0 end) as count_7b1,
  sum(case when d.oac21sub = '7b2' then 1 else 0 end) as count_7b2,
  sum(case when d.oac21sub = '8a1' then 1 else 0 end) as count_8a1,
  sum(case when d.oac21sub = '8a2' then 1 else 0 end) as count_8a2,
  sum(case when d.oac21sub = '8b1' then 1 else 0 end) as count_8b1,
  sum(case when d.oac21sub = '8b2' then 1 else 0 end) as count_8b2,
  sum(case when d.oac21sub = '8b3' then 1 else 0 end) as count_8b3`;

export const OAC2021 = {
  title: "Output Area Classification",
  description: "Output Area Classification (OAC) for England/Wales",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/output-area-classification-2021",
  relatedMaps: ["OAC", "LOAC2021"],
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: `SELECT ${cols} FROM ${dataTable}`,
  buildingSql: `SELECT ${cols} FROM ${dataTable}_vmd23`,
  detailUrbanSql: `SELECT ${cols} FROM ${dataTable}_bua22`,
  urbanSql: `SELECT ${cols} FROM ${dataTable}_bua22`,
  defaultLayers: "1111000",
  extent: 7,
  popup: true,
  popupType: "classification",
  idField: "oa21cd",
  geogNameField: "la23nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la23cd",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  polygonSQLPre: polygonSQLPre,
  makePolygonSQL: function(polygonSubquery) {
    return (
      polygonSQLPre +
      `  FROM oliverobrien.${dataTable} d
    inner join (${polygonSubquery}) p
    on st_intersects(d.the_geom, p.the_geom)`
    );
  },
  hierarchy: [
    {
      id: 0,
      name: "Supergroup",
      field: "oac21sup",
      lookup: "id",
      groups: [
        {
          id: "1",
          name: "Retired Professionals",
          colour: "#ccbb99",
          visible: true,
          description:
            "Typically married but no longer with resident dependent children, these well-educated households either remain working in their managerial, professional, administrative or other skilled occupations, or are retired from them – the modal individual age is beyond normal retirement age. Underoccupied detached and semi-detached properties predominate, and unpaid care is more prevalent than reported disability. The prevalence of this Supergroup outside most urban conurbations indicates that rural lifestyles prevail, typically sustained by using two or more cars per household."
        },
        {
          id: "2",
          name: "Suburbanites & Peri-Urbanites",
          colour: "#4daf4a",
          visible: true,
          description:
            "Pervasive throughout the UK, members of this Supergroup typically own (or are buying) their detached, semi-detached or terraced homes. They are also typically educated to A Level/Highers or degree level and work in skilled or professional occupations. Typically born in the UK, some families have children, although the median adult age is above 45 and some property has become under-occupied after children have left home. This Supergroup is pervasive not only in suburban locations, but also in neighbourhoods at or beyond the edge of cities that adjoin rural parts of the country."
        },
        {
          id: "3",
          name: "Multicultural & Educated Urbanites",
          colour: "#ff0000",
          visible: true,
          description:
            "Established populations comprising ethnic minorities together with persons born outside the UK predominate in this Supergroup. Residents present diverse personal characteristics and circumstances: while generally well-educated and practising skilled occupations, some residents live in overcrowded rental sector housing. English may not be the main language used by people in this Group. Although the typical adult resident is middle aged, single person households are common and marriage rates are low by national standards. This Supergroup predominates in Inner London, with smaller enclaves in many other densely populated metropolitan areas."
        },
        {
          id: "4",
          name: "Low-Skilled Migrant & Student Communities",
          colour: "#f781bf",
          visible: true,
          description:
            "Young adults, many of whom are students, predominate in these high-density and overcrowded neighbourhoods of rented terrace houses or flats. Most ethnic minorities are present in these communities, as are people born in European countries that are not part of the EU. Students aside, low skilled occupations predominate, and unemployment rates are above average. Overall, the mix of students and more sedentary households means that neighbourhood average numbers of children are not very high. The Mixed or Multiple ethnic group composition of neighbourhoods is often associated with low rates of affiliation to Christian religions. This Supergroup predominates in non-central urban locations across the UK, particularly within England in the Midlands and the outskirts of west, south and north-east London."
        },
        {
          id: "5",
          name: "Ethnically Diverse Suburban Professionals",
          colour: "#ff7f00",
          visible: true,
          description:
            "Those working within the managerial, professional and administrative occupations typically reflect a wide range of ethnic groups, and reside in detached or semi-detached housing. Their residential locations at the edges of cities and conurbations and car-based lifestyles are more characteristic of Supergroup membership than birthplace or participation in child-rearing. Houses are typically owner-occupied and marriage rates are lower than the national average. This Supergroup is found throughout suburban UK."
        },
        {
          id: "6",
          name: "Baseline UK",
          colour: "#ffcb1f",
          visible: true,
          description:
            "This Supergroup exemplifies the broad base to the UK’s social structure, encompassing as it does the average or modal levels of many neighbourhood characteristics, including all housing tenures, a range of levels of educational attainment and religious affiliations, and a variety of pre-retirement age structures. Yet, in combination, these mixes are each distinctive of the parts of the UK. Overall, terraced houses and flats are the most prevalent, as is employment in intermediate or low-skilled occupations. However, this Supergroup is also characterised by above average levels of unemployment and lower levels of use of English as the main language. Many neighbourhoods occur in south London and the UK’s other major urban centres."
        },
        {
          id: "7",
          name: "Semi- & Un-Skilled Workforce",
          colour: "#377eff",
          visible: true,
          description:
            "Living in terraced or semi-detached houses, residents of these neighbourhoods typically lack high levels of education and work in elementary or routine service occupations. Unemployment is above average. Residents are predominantly born in the UK, and residents are also predominantly from ethnic minorities. Social (but not private sector) rented sector housing is common. This Supergroup is found throughout the UK’s conurbations and industrial regions but is also an integral part of smaller towns."
        },
        {
          id: "8",
          name: "Legacy Communities",
          colour: "#984ea3",
          visible: true,
          description:
            "These neighbourhoods characteristically comprise pockets of flats that are scattered across the UK, particularly in towns that retain or have legacies of heavy industry or are in more remote seaside locations. Employed residents of these neighbourhoods work mainly in low-skilled occupations. Residents typically have limited educational qualifications. Unemployment is above average. Some residents live in overcrowded housing within the social rented sector and experience long-term disability. All adult age groups are represented, although there is an overall age bias towards elderly people in general and the very old in particular. Individuals identifying as belonging to ethnic minorities or Mixed or Multiple ethnic groups are uncommon."
        }
      ]
    },
    {
      id: 1,
      name: "Group",
      field: "oac21grp",
      lookup: "id",
      groups: [
        {
          id: "1a",
          parent: "1",
          name: "Spacious Rural Living",
          colour: "#ccbb99",
          visible: true,
          description:
            "These predominantly ageing households typically have no resident dependent children. Most are owner-occupiers and live in detached houses in low density residential developments (although renting is more common than in the rest of the Supergroup). White ethnicity predominates. Residents are typically beyond retirement age but those still in work have managerial, professional or skilled trade occupations. White ethnicity and Christian religious affiliation predominate. Neighbourhoods are located throughout rural UK."
        },
        {
          id: "1b",
          parent: "1",
          name: "Small Town Suburbia",
          colour: "#e4d3b1",
          visible: true,
          description:
            "This Group is predominantly comprised of married couples with no resident dependent children, living in areas characterised neither by under-occupancy nor overcrowding throughout the UK in or adjacent to small towns. White ethnic groups and affiliation with Christianity predominates. Housing tends to be predominantly semi-detached or detached and workers are employed principally in managerial and professional occupations, with semi-skilled occupations also in evidence. These areas of the Supergroup are of higher population density."
        },
        {
          id: "1c",
          parent: "1",
          name: "Established Mature Families",
          colour: "#ffeecc",
          visible: true,
          description:
            "Married couples predominate, many with older dependent children. Detached housing is common. Homeownership rates are the highest within this Supergroup. The presence of some students suggests that households are towards the end of a child rearing phase. Many residents have degree level qualifications, and the occupational profile is heavily skewed towards managerial and professional occupations. Residential developments commonly occur on the periphery of major urban cities or conurbations."
        },
        {
          id: "2a",
          parent: "2",
          name: "Inner Suburbs & Small Town Living",
          colour: "#2d7f2a",
          visible: true,
          description:
            "Families with resident dependent children (but not students) are common. Established family groups and White ethnicity predominate, as do individuals born in the UK. They are more likely than the Supergroup average to have been resident in their terraced, semi-detached, or detached houses for more than one year. Levels of multiple car ownership are high. Properties are owned and typically have surplus living space. Associate professionals and administrative occupations are prevalent, and parents are likely to be in middle age or approaching retirement. Educational attainment is above the Supergroup average. Scattered developments and concentrations are found in many small towns."
        },
        {
          id: "2b",
          parent: "2",
          name: "Rural Amenity",
          colour: "#4daf4a",
          visible: true,
          description:
            "This Group comprises older parents or retirees, with no resident dependent children, and with the lowest residential densities in this Supergroup. Predominantly UK-born, residents typically live in detached houses, although others do live in semi-detached and terraced properties. The level of multiple car ownership is the highest in this Supergroup. Most houses are owner occupied although social renting is also present. Many concentrations occur in high amenity rural locations, such as Areas of Outstanding Natural Beauty."
        },
        {
          id: "2c",
          parent: "2",
          name: "Ageing Communities",
          colour: "#8def8a",
          visible: true,
          description:
            "Many residents are of normal retirement age or above and live in communal establishments, and there are few dependent children. The dominant property type is a mix of retirement flats and detached houses. Those in work are likely to be employed in managerial and professional occupations, and many residents are educated to degree level. Levels of owner occupation are high, but the private rental sector is also present. Rural locations predominate."
        },
        {
          id: "3a",
          parent: "3",
          name: "Student Living & Professional Footholds",
          colour: "#ff0000",
          visible: true,
          description:
            "The Group includes many students, some of whom reside in communal residences. Single-person households are the most prevalent and the modal age band is 25 to 44. There are few families with dependent children. A significant number of White residents were born in EU countries (although UK-born residents are more common than in the rest of the Group), and households reflect a diversity of ethnic groups. Residential turnover is exceptionally high and, communal properties aside, flats are the norm. Some properties, including those in the private rental sector, are over-crowded. Many residents are professionals and technicians educated to degree level, and the Group is particularly common near the campuses of established university towns and cities."
        },
        {
          id: "3b",
          parent: "3",
          name: "Ethnically Diverse Young Families",
          colour: "#cc0000",
          visible: true,
          description:
            "This Group includes many younger parents born overseas (particularly in Africa or EU countries) with children aged 0-4. Individuals identifying as of Mixed or Multiple ethnicities are also common. English may not be the primary language spoken. Accommodation consists principally of flats, and many properties are socially rented and/or overcrowded. Students are also present, unemployment is common, and other adults tend to work in low skilled jobs."
        },
        {
          id: "3c",
          parent: "3",
          name: "Diverse Educated Urban Singles",
          colour: "#990000",
          visible: true,
          description:
            "This Group includes many never-married individuals not living with dependent children. Many were born in EU countries and are now aged between 25-44. This Group is characterised by its ethnic group diversity, although those identifying as Asian are not well represented. Affiliation with the Christian religion amongst residents is low. Reported disability rates are low. Neighbourhoods include some central locations in London and other major cities. Private renting is the norm, and there is some overcrowding. Many individuals are educated to degree level, and full-time employment is common, particularly in managerial and professional occupations."
        },
        {
          id: "4a",
          parent: "4",
          name: "Ethnically Diverse Families in Less Connected Locations",
          colour: "#f781bf",
          visible: true,
          description:
            "This Group is often found in less central parts of London and other major towns and cities. Adults are more likely than the Supergroup average to have never been married and are typically aged less than 45 years. Many have young dependent children and individuals may have been born in Africa. There are many members identifying with a Black ethnic group, with the other ethnic groups (as listed in the glossary) also represented, though Chinese less so. Accommodation in flats, frequently socially rented, is common in these neighbourhoods. Part time employment is also common, and work is often in elementary occupations, while unemployment is also the highest within this Supergroup."
        },
        {
          id: "4b",
          parent: "4",
          name: "Established Multi-Ethnic Communities",
          colour: "#f761af",
          visible: true,
          description:
            "Parents and young children in this Group are drawn from diverse ethnic backgrounds in broadly similar proportions. Employment is typically in elementary occupations, though workers in professional, intermediate or skilled trades occupations are also present. The residential landscape is dominated by terraced housing, although semi-detached houses and flats are also present. This Group is found in London and in many provincial towns and cities throughout the U.K."
        },
        {
          id: "4c",
          parent: "4",
          name: "Challenged Multicultural Communities & Students",
          colour: "#f7419f",
          visible: true,
          description:
            "Married couples with dependent children are common in this Group, with many parents born in Africa or the EU. The representation of residents amongst different ethnic minority groups is high, particularly for individuals of Pakistani ethnic group. For many residents, English is not their main language, and affiliation to Christian religions is less common. Privately rented terrace properties predominate and levels of overcrowding are high. Part time work is common, with many employed in elementary occupations and sales and customer services. There are also many students living within these areas, and overall unemployment levels are high."
        },
        {
          id: "5a",
          parent: "5",
          name: "Outer Suburbs",
          colour: "#ff7f00",
          visible: true,
          description:
            "These neighbourhoods are found on the outer edges of many towns and cities. Many residents were born outside the UK. Indian ethnic group representation is high. There are high numbers of families with dependent children aged 5 to 14. Incidences of disability and of provision of unpaid care are low. Neighbourhoods provide a mix of detached housing and flats, and terraced housing is not uncommon. Levels of overcrowding are low and homeownership rates are high. Professional and managerial occupations are prevalent: unemployment is low and education to degree level is the norm."
        },
        {
          id: "5b",
          parent: "5",
          name: "Suburban Professionals",
          colour: "#ffaf00",
          visible: true,
          description:
            "Employment in this Group is typically in managerial and professional occupations, and education to degree level is common. Residents are typically of working age, many of whom identify with an Indian ethnicity. Households are unlikely to be of Mixed or Multiple ethnicities, and English is not the main language used in some households. This Group is found on the outskirts of most conurbations as well as in the suburbs of some free-standing towns."
        },
        {
          id: "6a",
          parent: "6",
          name: "Challenged Communities",
          colour: "#ffcb1f",
          visible: true,
          description:
            "Residents of these neighbourhoods typically live in households with dependent children, and there are fewer-than-average residents of normal retirement age or over. Identification with ethnic minorities, particularly Black or Mixed or Multiple ethnicities is common. The rate of Christian religious affiliation is low. Housing predominantly consists of semi-detached houses, along with a significant number of terraced properties and flats. Overcrowded social housing is common, and private renting occurs at average UK levels. Those in employment work mainly in caring leisure and other services; process, plant and machine operation; or elementary occupations. Unemployment is high, and few individuals have degree level qualifications. Many of these neighbourhoods occur in commuter towns or less accessible areas of larger towns and cities."
        },
        {
          id: "6b",
          parent: "6",
          name: "Legacy Industrial & Coastal Communities",
          colour: "#cc9b1f",
          visible: true,
          description:
            "Single-person households are common in these neighbourhoods, and these residents are typically divorced rather than never married. A high proportion of residents were born outside the UK in the EU. There are many young adults, some with young children, but relatively few residents are of normal retirement age or over. Although levels of identification with ethnic minorities are in line with the Supergroup average, individuals identifying with Mixed or Multiple ethnicities is more common than average. High long-term disability rates are observed, and unpaid care is more common than in the rest of the Group. The predominant housing types are terraced houses and flats, which are typically part of the social rented sector. This Group is commonly found in coastal areas and (present-day or former) industrial towns and cities."
        },
        {
          id: "6c",
          parent: "6",
          name: "Multicultural Inner Suburbs",
          colour: "#996b1f",
          visible: true,
          description:
            "These neighbourhoods house many younger and middle-aged adults with children. All ethnic minorities, apart from those identifying as Pakistani or Bangladeshi, appear to be present in above average proportions. Affiliation to Christian religions is uncommon. Long-term disability rates are low, mirrored in limited provision of unpaid care. Privately rented terrace houses and flats are the norm. Managerial, professional and technical occupations are prevalent, and work is rarely part time. Many individuals have degree level qualifications. These areas form the inner suburbs of many of the UK’s towns and cities."
        },
        {
          id: "7a",
          parent: "7",
          name: "Established but Challenged",
          colour: "#377eff",
          visible: true,
          description:
            "Many households in these neighbourhoods comprise separated or divorced single parents with dependent children. Residents are typically born in the UK, and these neighbourhoods have relatively few members of ethnic minorities. The prevalence of children, their parents and those at or above normal retirement age, suggests neighbourhood structures may be long-established. Levels of unpaid care are high, and long-term disability is more common than in the Supergroup as a whole. Use of the social rented sector is common, often in terraced houses. Levels of overcrowding are above the Supergroup average. Unemployment is high, while those in work are employed in elementary occupations such as caring, leisure and customer services. Many residents have low level qualifications. Neighbourhood concentrations of this Group are found in the South Wales Valleys, Belfast, Londonderry and the Central Lowlands of Scotland."
        },
        {
          id: "7b",
          parent: "7",
          name: "Young Families in Industrial Towns",
          colour: "#77aeff",
          visible: true,
          description:
            "These neighbourhoods house predominantly young, UK-born individuals identifying with a White ethnic group with dependent children. Long-term disability and unpaid care are prevalent, and religious affiliations are uncommon. Housing is terraced or semi-detached and social rented sector housing is the norm. Unemployment is above the Supergroup average, and employment is principally in elementary occupations, as process plant and machine operatives, or in caring and leisure services. Educational attainment is low. The group is scattered throughout former industrial towns in the Midlands and the South Wales Valleys."
        },
        {
          id: "8a",
          parent: "8",
          name: "Routine Occupations or Retirement",
          colour: "#984ea3",
          visible: true,
          description:
            "These neighbourhoods are characterised by high proportions of single, often never-married adults of normal retirement age or older, including many that are in the most advanced age groups. Most adults are UK born and live at high residential densities, and many of the children living with parents are in adulthood. Individuals identifying as members of ethnic minorities are uncommon, but above average proportions of households include individuals that identify with different ethnic groups. Long-term disability is relatively common, and the dominant accommodation type is flats. Unemployment rates are high, with most of those employed working in routine occupations. Few individuals have high level qualifications. Car ownership is not high."
        },
        {
          id: "8b",
          parent: "8",
          name: "Legacy & Demographically Mixed Communities",
          colour: "#b784bf",
          visible: true,
          description:
            "Households in these areas often include divorced or separated parents and commonly include children and young adults. The age structure is heavily skewed towards the most advanced age groups. Individuals identifying as members of ethnic minorities are not present in large numbers. Flats predominate, with some terraced, semi-detached, and detached units. Multiple car ownership is low, and housing is predominantly in the private and social rented sectors. Employment is less skewed towards traditional routine industrial occupations. Levels of educational attainment are generally low. The Group occurs principally in the Central Lowlands of Scotland and other Scottish towns."
        }
      ]
    },
    {
      id: 2,
      name: "Subgroup",
      field: "oac21sub",
      lookup: "id",
      groups: [
        {
          id: "1a1",
          parent: "1a",
          name: "Pre-Retirement Spacious Living",
          colour: "#ccbb99",
          visible: true,
          description:
            "Although the Subgroup predominantly comprises elderly and middle aged (aged 45 or over) people, there are average UK proportions of children aged between 5 and 14, indicating that families with children tend to have older parents. Residential densities are much lower than the UK and Group average, and include many remote rural areas. Remoteness frequently requires households to maintain two or more cars."
        },
        {
          id: "1a2",
          parent: "1a",
          name: "Retirement Spacious Living",
          colour: "#cccc99",
          visible: true,
          description:
            "The Subgroup is differentiated by its higher proportion of residents aged 65 years and above than elsewhere in this Group, and its lower proportions of families with dependent children and higher rates of residence in communal establishments. Provision of unpaid care and recorded disability rates are above the UK and Group averages, indicative of high rates of advanced retirement. Many of the areas in which this Subgroup occurs have low residential densities, and are widely distributed across the UK."
        },
        {
          id: "1b1",
          parent: "1b",
          name: "Younger Established Suburban Communities",
          colour: "#e4d3b1",
          visible: true,
          description:
            "The age distribution within this Subgroup is relatively uniform, apart from lower frequencies in the 64 to 85 age range. This age distribution results in a slightly lower disability ratio compared to both the UK and Group averages. Geographically, the Subgroup is dispersed throughout the UK, with a particular concentration across all of the Midlands."
        },
        {
          id: "1b2",
          parent: "1b",
          name: "Older Established Suburban Communities",
          colour: "#e4e4b1",
          visible: true,
          description:
            "This Subgroup is dominated by residents of normal retirement age or over, who are also more likely than the average in the Group to have been married and born in the UK. The Subgroup is predominantly White. Whilst overall levels of qualifications are around the UK average, there are slightly higher proportions of individuals qualified to level 1, level 2 or Apprenticeship. The Subgroup age structure results in higher levels of unpaid care than is found elsewhere in this Group."
        },
        {
          id: "1c1",
          parent: "1c",
          name: "Affluent Mature Families",
          colour: "#ffeecc",
          visible: true,
          description:
            "Members of this Subgroup are significantly older than the rest of the Group, with many residents aged 65 or over and the proportion of young children is below the UK average. Although levels of disability are slightly above the UK average, rates of provision of unpaid care are around the Group average. Many residents are educated to degree level and the prevalence of senior managerial roles indicates that financial circumstances generally compare favourably with the rest of the Group. Members of this Subgroup are more likely to reside outside London."
        },
        {
          id: "1c2",
          parent: "1c",
          name: "Burgeoning Mature Families",
          colour: "#ffffcc",
          visible: true,
          description:
            "This Subgroup is characterised by high proportions of families with children in the 5 to 14 age band, and parents and other residents aged 45 to 64. Incidence of the oldest retirees is below the national average. Terraced houses are rare but more common than the Group average, and, whilst residents hold a variety of occupations and managerial positions prevail, workers are often working as process, plant and machine operatives."
        },
        {
          id: "2a1",
          parent: "2a",
          name: "Younger Suburban Family Renters",
          colour: "#2d7f2a",
          visible: true,
          description:
            "This Subgroup is characterised by a higher proportion of families with dependent children (especially aged 0 to 4) than the Group averages. Young adults predominate, especially over retirees. Many households rent within the private sector, are well qualified to A-Level/Highers or degree level, and work in professional, technical and administrative occupations."
        },
        {
          id: "2a2",
          parent: "2a",
          name: "Settled Owner-Occupied Suburbs",
          colour: "#2d6f2a",
          visible: true,
          description:
            "Adults of this Subgroup are typically more likely than elsewhere in the Group to be married, born in the UK, identify with Christian religion and speak English at home. Residents tend to be slightly older than the Group average, with those aged 65 to 84 particularly in evidence. The settled status of the Subgroup is evidenced by low rates of recent residential moves into the predominantly detached housing stock. Members of this Subgroup are more likely than members of other Supergroups and Groups to provide unpaid social care."
        },
        {
          id: "2a3",
          parent: "2a",
          name: "Terraced Communities",
          colour: "#2d5f2a",
          visible: true,
          description:
            "Members of this Subgroup have a greater likelihood of living in terraced houses. Population density is highest in this Group and Supergroup. The incidence of ownership of two or more cars is lower than for the Group and Supergroup. Members of the Subgroup are more likely to be employed in various sales and service occupations, or in unskilled work."
        },
        {
          id: "2b1",
          name: "Ageing Rural Communities",
          parent: "2b",
          colour: "#4daf4a",
          visible: true,
          description:
            "In these neighbourhoods, the proportions of older people, are substantially higher than the Group average, with those aged 65 to 84 being in the most common age group. There is a correspondingly low share of child and young adult residents, indicative of ageing communities. Religious affiliation is predominantly Christian, and residents are of predominantly White ethnicity. Unemployment is rare, with most of the employed working as managers, professionals, skilled traders or in officialdom."
        },
        {
          id: "2b2",
          parent: "2b",
          name: "Rural Mix",
          colour: "#8dd68d",
          visible: true,
          description:
            "Members of this Subgroup are more demographically diverse than the Group as a whole. There are high proportions of families with dependent children of all ages (0 to 14). Full-time students are scarce, but nevertheless more prevalent than elsewhere in the Group. Many residents are of Mixed or Multiple ethnic groups. Many may be tenants in the social rented sector. Religious affiliation is predominantly Christian, but other religious affiliations are more common than elsewhere in the Group. For the most part, this Subgroup resides in socially rented semi-detached houses, and work as professionals and associate professionals."
        },
        {
          id: "2c1",
          parent: "2c",
          name: "Communal Retirement Living",
          colour: "#8def8a",
          visible: true,
          description:
            "The age structure of this Subgroup is heavily skewed towards those well beyond the normal retirement age (particularly those aged 85 years or over), with low proportions of all other age groups. An exceptionally high rate of residence in communal establishments suggests that many reside in care homes. The incidence of disability is higher than for the Group, and privately rented detached houses are also common. Managers and directors are amongst the most common occupations of those in the workforce."
        },
        {
          id: "2c2",
          parent: "2c",
          name: "Ageing Independent Living",
          colour: "#bdffbd",
          visible: true,
          description:
            "Neighbourhood residents more likely to be below retirement age than the Group average. The proportion of residents living within flats is the highest in the Group. Residency in communal establishments is not common. Of the two Subgroups within the Ageing Communities Group, the Ageing Independent Living Subgroup has the lowest ethnic diversity. The occupation structure mirrors the Group pattern of high proportions of managers and professionals, and there is low incidence of elementary occupations."
        },
        {
          id: "3a1",
          parent: "3a",
          name: "University Centric",
          colour: "#ff0000",
          visible: true,
          description:
            "Members of this Subgroup are exceptionally likely to live in communal establishments such as halls of residence. Typically, students and others aged 16 to 24 are educated to at least A Level/Highers level. They are unlikely to have been married and are very unlikely to be homeowners. Non-communal living typically means residence in flats or sometimes terraced houses. Part-time working is particularly common, mostly in sales, customer service and elementary occupations. Individuals identifying as Chinese are more numerous than any other ethnic minority."
        },
        {
          id: "3a2",
          parent: "3a",
          name: "Professional Progression",
          colour: "#ff3333",
          visible: true,
          description:
            "Relative to the Group, this Subgroup is more likely to be aged 25 to 44. They are unlikely to be married (or divorced), and even pre-childbearing families are uncommon relative to the Group average. High numbers of individuals identifying as Chinese or Indian occur in this Subgroup. Average Standardised Disability Ratios are low as are rates of provision of unpaid care. Households are likely to be private renters and not to own multiple cars. The Subgroup is highly qualified to degree level and many residents work as managers, directors or senior officials."
        },
        {
          id: "3a3",
          parent: "3a",
          name: "Urbanite Mix",
          colour: "#ff6666",
          visible: true,
          description:
            "This Subgroup is characterised by diversity of household circumstances: English is often not the main language spoken; single person households are common, as is divorce; and unemployment rates are high, albeit that some of those in in employment work as managers, directors and senior officials."
        },
        {
          id: "3a4",
          parent: "3a",
          name: "Affluent Graduate Living",
          colour: "#ff9999",
          visible: true,
          description:
            "Members of this Subgroup are likely to be married, and the numbers of households with children are above the Group average. Adults are more likely to be in the 25 to 44 age group, and older residents are also more prevalent than elsewhere in this Group. The Subgroup is less ethnically diverse than the Group average, with slightly more White ethnic group members, and has more secular attitudes to religion than the Group as a whole. Residence in the social rented sector is very rare. Households in this Subgroup are more likely to own multiple cars than those assigned elsewhere in the Group. Students are relatively less common, although individuals are highly skilled and educated to degree standard. Unemployment or part-time work is rare, and many individuals are employed in associate professional, technical, administrative or secretarial occupations."
        },
        {
          id: "3b1",
          parent: "3b",
          name: "Private Rental Ethnic Minority Families",
          colour: "#cc0000",
          visible: true,
          description:
            "This Subgroup has the highest Group rates of European-born residents, who are typically aged 25-44. The Subgroup also exhibits considerable ethnic diversity, with the highest Group share of individuals identifying as Indian or Black ethnicity. Social renting is not prevalent relative to the Group average, with more households being homeowners or private renters. As throughout the Group, flats are prevalent, although less so than in other Subgroups. Unemployment is also slightly lower than the Group average, but nevertheless high relative to the UK average. Those in employment tend to hold managerial and professional positions."
        },
        {
          id: "3b2",
          parent: "3b",
          name: "Young Ethnic Minority Families",
          colour: "#bb6666",
          visible: true,
          description:
            "This Subgroup includes many young adults who are also very likely to have dependent children. Older generations are more prevalent than elsewhere in the Group. Many individuals identify with a Bangladeshi ethnicity, and neighbourhoods are characterised by a high level of Black ethnic group representation. Affiliation to Christian religions is low, and levels of non-English languages as the main language are the highest in the Supergroup. Members of this Subgroup are predominantly social renters, typically living in overcrowded flats. Part-time employment is also common, but rarely in managerial or professional occupations."
        },
        {
          id: "3c1",
          parent: "3c",
          name: "Centrally Located Professionals",
          colour: "#880000",
          visible: true,
          description:
            "Living in central urban locations, many individuals in this Subgroup fall into the 25 to 44-year age bracket. Residents are typically educated to degree level. Individuals identifying as of Indian or Chinese ethnic groups are all in evidence. Privately rented flats are the norm, and multiple car ownership is low. Standardised Disability Ratios and provision of unpaid care are both low. Part-time work and employment in non-managerial positions are rare."
        },
        {
          id: "3c2",
          parent: "3c",
          name: "Career Progression",
          colour: "#660000",
          visible: true,
          description:
            "A significant portion of adults hold a degree, but a notable number have also pursued apprenticeships in the past. Employment is predominantly concentrated in caring, leisure and other service occupations, or in sales and customer services. Individuals in this Subgroup are slightly more likely to be UK-born, compared with the Group average. The population displays a fairly balanced age distribution, apart from the smaller proportion of elderly retirees. The neighbourhoods show limited ethnic diversity, and the housing landscape primarily consists of terraced homes, with a mix of detached and semi-detached properties as well. It is not uncommon for residents to own multiple vehicles."
        },
        {
          id: "4a1",
          parent: "4a",
          name: "Semi-Detached, Service Workers & Students",
          colour: "#f781bf",
          visible: true,
          description:
            "These neighbourhoods house many families with dependent children, and children are more prevalent than elsewhere in this Group. Residents born in mainland Europe are not present in large numbers, and the ethnic group diversity of this Subgroup is lower than for the rest of the Group. Residents are typically accommodated in socially rented housing, and semi-detached properties are common. Few residents are educated to degree level or work in managerial or professional occupations."
        },
        {
          id: "4a2",
          parent: "4a",
          name: "City Service Workers",
          colour: "#d791bf",
          visible: true,
          description:
            "Residents born outside the UK are more common than elsewhere in this Group. While the ethnic group composition of neighbourhoods is diverse, persons identifying with a Pakistani ethnicity are much lower than the Group average. Although private renting is not the most common housing tenure, it is more prevalent here than elsewhere in the Group. Residents in this Subgroup are on average educated to higher levels than other Subgroups. Managerial and professional occupations are uncommon, with occurrences of other skilled occupations around the UK average."
        },
        {
          id: "4a3",
          parent: "4a",
          name: "Multi-Child Young Families",
          colour: "#b7a1bf",
          visible: true,
          description:
            "Many of the families in this Subgroup have more than one dependent child, and the number of children is above the Group average. Ethnic group diversity is higher than the Group average, and English is frequently not a person’s main language. Most families live in socially rented flats that are often overcrowded. Students are also present, as are part-time workers."
        },
        {
          id: "4b1",
          parent: "4b",
          name: "Multi-Generational Migrants",
          colour: "#f761af",
          visible: true,
          description:
            "These neighbourhoods are characterised by the highest Group proportions of people born outside the UK. Limited English language skills are common in this Group. Identification with an Indian ethnicity is common. Members of this Subgroup are more likely to be of normal retirement age or above than those in the rest of the Group. Many households nevertheless have dependent children, indicating that these households may be multi-generational."
        },
        {
          id: "4b2",
          parent: "4b",
          name: "European Skilled Workforce",
          colour: "#e771af",
          visible: true,
          description:
            "Members of this Subgroup have a higher probability than other Subgroups of being born in the EU, and of holding degree level qualifications. Neighbourhood populations are relatively young, with low proportions above normal retirement age, and have the lowest levels of long-term disability in the Group."
        },
        {
          id: "4b3",
          parent: "4b",
          name: "Inner Suburb Ethnic Group Mix",
          colour: "#d781af",
          visible: true,
          description:
            "These neighbourhoods occur in the inner suburbs of many provincial towns and cities. They have more children aged 5 to 14 but fewer adults aged 25 to 44 than is the case elsewhere in this Group. UK born individuals are more common in this Subgroup than the Group average. Few residents live in flats and private renting is less common than elsewhere in the Group. More household residents speak English very well or well than elsewhere in the Group. A relatively small number of residents have Mixed or Multiple ethnicities. Provision of unpaid care is higher than the Group average. Employment structure is not concentrated in any particular sector."
        },
        {
          id: "4b4",
          parent: "4b",
          name: "Ethnic Minority Routine Service Workers",
          colour: "#c791af",
          visible: true,
          description:
            "Ethnic minority members are less dominant in this Subgroup, and are also more likely to be UK-born than elsewhere in the Group. Of the ethnic minorities only those identifying as Pakistani are more prevalent than the Group average. Residents tend to be slightly older than the Group average, and adults of normal retirement age or older are particularly in evidence. The Subgroup has levels of English language proficiency above the Group average (but below the UK average). Overcrowding and terraced housing are less common than elsewhere in the Group, and social housing remains the most common form of housing. The overall employment structure is diverse, but is concentrated in caring, leisure and other service occupations."
        },
        {
          id: "4c1",
          parent: "4c",
          name: "African & Asian Influences",
          colour: "#f7419f",
          visible: true,
          description:
            "The Subgroup includes many individuals born in Africa, and those identifying with the Indian ethnic group are more prevalent than elsewhere in the Group. There are fewer families with dependent children than elsewhere in the Group. Rates of Christian religious affiliation are the lowest in the Group. Members of this Subgroup are slightly more likely to be private renters. The occupation structure is very similar to the Group average, though levels of educational qualification are slightly higher."
        },
        {
          id: "4c2",
          parent: "4c",
          name: "European & Asian Heritage",
          colour: "#d7619f",
          visible: true,
          description:
            "Many residents of this Subgroup are born in EU countries, but UK-born members are also more common than elsewhere in the Group. Many residents identify with the Pakistani ethnic group. Residents are more likely than members of other Subgroups to reside in properties that are not flats, to declare no religious affiliation and to identify as being of Mixed or Multiple ethnicities. The members of the Subgroup are educated to lower levels than the Group average."
        },
        {
          id: "5a1",
          parent: "5a",
          name: "Outer Suburb Asian Mix",
          colour: "#ff7f00",
          visible: true,
          description:
            "Many neighbourhood residents belong to an older generation, and many were born outside the UK. Residents identify with diverse, but principally Asian ethnic groups. For some residents, English is not their main language, and affiliation with Christian religions is below the Group average. Accommodation is frequently in overcrowded, privately rented flats, although terraced houses are also common and social renting exists too. Contrary to the overall Group profile, residents are more likely to be employed as managers, directors or senior officials, but are typically educated only to intermediate levels."
        },
        {
          id: "5a2",
          parent: "5a",
          name: "Suburban Empty Nesters",
          colour: "#dd5f00",
          visible: true,
          description:
            "Marriage, Christian religious affiliation and UK birthplaces are each more common than elsewhere in the Group. Residents also tend to be aged 45 years or above, and numbers of dependent children are not high - indicating that they may have left home when becoming young adults. The proportion of residents living in communal establishments such as care homes is the highest in the Group. Ethnic diversity is substantially lower than elsewhere in the Group, with those identifying as of Black ethnicities being notably absent. The dominant housing type is detached houses, and the share of flats is below the Group average. Many properties are also under-occupied and fewer workers are employed as managers, directors and senior officials."
        },
        {
          id: "5a3",
          parent: "5a",
          name: "Young Suburban Families",
          colour: "#bb3f00",
          visible: true,
          description:
            "Subgroup members tend to be younger than the rest of the Group. Correspondingly fewer residents live in communal establishments, and families with dependent children are less common. Fewer residents identify as belonging to an ethnic minority, although those of Mixed or Multiple ethnicities are more prevalent. Incidences of disability and provision of unpaid care are below average. Members of the Subgroup are much more likely to reside in terraced houses and are less likely to work part-time."
        },
        {
          id: "5b1",
          parent: "5b",
          name: "Families in Multi-Ethnic Terraces",
          colour: "#ffaf00",
          visible: true,
          description:
            "Cohabiting couples with young families are common in these neighbourhoods. There are few individuals beyond retirement age and few students or non-dependent children. The multi-ethnic character of neighbourhoods arises primarily from individuals identifying with a range of Black or White ethnicities. Individuals tend to take a more secular view of religion than the rest of the Group. Terraces are prevalent, alongside some semi-detached houses. Social renting and non-managerial employment (particularly machine operatives, skilled trades and service occupations) are also common."
        },
        {
          id: "5b2",
          parent: "5b",
          name: "Established Multi-Ethnic Suburbs",
          colour: "#ffbf77",
          visible: true,
          description:
            "Many adults are married or in civil partnerships, and the population is older than the Group mean, with more adults beyond retirement age. Individuals identifying as Indian or Pakistani ethnic groups are much in evidence. Christian religious affiliation is also uncommon. Flats and terraced houses are rare, and homeownership is the common tenure. Education levels are above the Group mean and employment is concentrated in managerial and professional occupations."
        },
        {
          id: "6a1",
          parent: "6a",
          name: "Suburban Housing Starters",
          colour: "#ffcb1f",
          visible: true,
          description:
            "The Subgroup comprises younger adults, only rarely living alone. Neighbourhoods are ethnically diverse, with many individuals identifying with the Black ethnic group. Housing is principally semi-detached or terraced. While residence in the social rented sector is common, this is slightly less so than in the Group as a whole. Elementary occupations are slightly more common than at the Group level."
        },
        {
          id: "6a2",
          parent: "6a",
          name: "Semi Detached Strivers",
          colour: "#ffdb1f",
          visible: true,
          description:
            "These typically non-UK born, married couples tend to be older and comprise a slightly more ethnically diverse mix than the Group as a whole – including more individuals identifying with Indian and Pakistani ethnicities than in the rest of the Group. Affiliation to Christian religions is particularly uncommon. Housing is predominantly semi-detached and detached, with much lower incidence of terraces than elsewhere in the Group. Homeownership is slightly more common within this Subgroup. Households are likely to own multiple cars and to work in managerial or professional occupations."
        },
        {
          id: "6a3",
          parent: "6a",
          name: "Younger Ethnic Minority Families in Flats",
          colour: "#ffeb1f",
          visible: true,
          description:
            "Neighbourhood residents are relatively young, and many were born in Africa. The Subgroup is characterised by lower-than-Group-average rates of marriage and childless families. Ethnic diversity is moderate, though with higher representation of Black or Mixed or Multiple background ethnicities. Residents are more likely to live in overcrowded socially rented flats and are less likely to speak English very well or well. Households with multiple cars are uncommon. Attainment of higher-level qualifications is below the Group average, and employment is skewed towards elementary occupations."
        },
        {
          id: "6b1",
          parent: "6b",
          name: "Retired Seniors",
          colour: "#cc9b1f",
          visible: true,
          description:
            "These neighbourhoods are characterised by high proportions of elderly people, with many living in communal establishments. Incidence of individuals born in the EU are lower than the Group average. Ethnic diversity is low, although Group levels of identification with Mixed or Multiple ethnicities are around the Group average. The housing stock is mixed but terraced accommodation is the most common. The social rented sector is the predominant housing tenure, although owner-occupation is also present. Managerial, professional and technical occupations provide the main employment for those in employment, and there are few students."
        },
        {
          id: "6b2",
          parent: "6b",
          name: "Traditional Terraces",
          colour: "#ccab1f",
          visible: true,
          description:
            "Young married childless couples born in the UK are common. Neighbourhoods are typically densely populated with low ethnic diversity, although some individuals identify with Pakistani or Bangladeshi ethnicities. Households typically live in terraced houses that are privately rented or owner occupied. Those in employment work principally in intermediate professions, including secretarial and service occupations."
        },
        {
          id: "6b3",
          parent: "6b",
          name: "EU Singles",
          colour: "#ccbb1f",
          visible: true,
          description:
            "Residents in this Subgroup are typically single or divorced EU-born migrants who live alone. Many are aged 25 to 44 and are employed in sales and customer service occupations or are process, plant and machine operatives. A higher than Group average proportion identify with the White ethnic group or Other Asian ethnicities, and many do not speak English very well or well. Housing is principally in flats in the social or private rented sector, with households typically overcrowded, and home ownership rare."
        },
        {
          id: "6c1",
          parent: "6c",
          name: "Transient Communities",
          colour: "#996b1f",
          visible: true,
          description:
            "These are high-density neighbourhoods with young adults, typically born in the UK and unmarried. There are few children and quite a low proportion of older generation adults. The level of residential turnover is exceptionally high. Ethnic diversity is below the Group average and those identifying as White predominate, some individuals identify as Chinese. Individuals tend to eschew religious affiliations and incidence of disabilities is low. Housing is primarily in privately rented terraced houses. The Subgroup is dominated by full-time students, and graduates employed in professional and managerial occupations."
        },
        {
          id: "6c2",
          parent: "6c",
          name: "Semi-Detached Family Renters",
          colour: "#aa9b1f",
          visible: true,
          description:
            "Contrary to the rest of the Group, these neighbourhoods commonly have many detached or semi-detached houses, let through the social rented sector. Adult members of this Subgroup tend to be younger than the Group average, but are more likely to be married, sometimes living with dependent children. Residents are slightly more likely to be divorced. The neighbourhoods appear to be very ethnically diverse, particularly regarding those identifying with Black ethnicities. Students and university graduates are slightly less common than the Group average, while those with GCSE and apprenticeship qualifications are more common. Employment is often as machine operatives, skilled traders."
        },
        {
          id: "7a1",
          parent: "7a",
          name: "Ageing Established Urban Communities",
          colour: "#377eff",
          visible: true,
          description:
            "These neighbourhoods have high levels of married couples amongst their predominantly UK-born residents. Neighbourhoods are densely populated with residents of retirement-age or older affiliated to Christianity. Despite the age structure, provision of unpaid care is less common than the Group average. Residents have frequently received only a rudimentary education and rent their terraced houses in the social rented sector. Unemployment is above the Group average, and working individuals typically hold administrative and secretarial positions."
        },
        {
          id: "7a2",
          parent: "7a",
          name: "Industry Associations",
          colour: "#578eff",
          visible: true,
          description:
            "These neighbourhoods include younger than Group average individuals, frequently born in the EU. Many residents are of Mixed or Multiple ethnicities. Many are unmarried or divorced. Many reside in privately rented detached houses or flats, some of which are underoccupied. Individuals are more likely than the Group average to be educated to A Level/Highers or degree level, and to work in managerial occupations. Unemployment rates are lower than the Group average. Many of these neighbourhoods are found close to industrial estates."
        },
        {
          id: "7b1",
          parent: "7b",
          name: "Terraces in Transitional Towns",
          colour: "#77aeff",
          visible: true,
          description:
            "Levels of marriage are below the Group average but there are slightly higher numbers of dependent children. Adults tend to be younger than elsewhere in the Group and most residents are housed at higher densities. A greater share of adults are EU-born, ethnic diversity is greater, and higher proportions of residents identify as of Mixed or Multiple ethnicities. English is slightly less likely to be the main language used. More individuals do not profess any religion and, amongst those that do, there is lower affiliation to Christian religions. More people are housed in flats, although privately rented terraced houses remain the dominant housing type. The employment profile generally mirrors that of the Group, although managerial and elementary occupations are each slightly more common."
        },
        {
          id: "7b2",
          parent: "7b",
          name: "Families & Later Life",
          colour: "#97beff",
          visible: true,
          description:
            "Members of this Subgroup are more likely to have been born in the UK and to be part of households with no resident children. Adults are substantially older than those in other Subgroups, with substantial representation of old and very old residents who are above retirement age. Individuals identifying as White, or affiliating with Christian religions are common. Individuals are more likely to live in low-density neighbourhoods of detached and semi-detached houses: some do nevertheless live in terraced houses. There are fewer students, graduates and adults educated to A Level/Higher standard. The employment patterns generally mirror the Group average, albeit with marginally higher rates of employment in administrative and skill trade occupations."
        },
        {
          id: "8a1",
          parent: "8a",
          name: "Retirement Residences",
          colour: "#984ea3",
          visible: true,
          description:
            "Many individuals are well beyond normal retirement age, with other age groups being almost absent. Divorced and single-person households are very common, and most residents identify as members of the White ethnic group. Levels of long-term disability are above the Group average, but provision of unpaid care is broadly similar. Housing and tenure is also highly homogenous, being dominated by flats in the social rented sector. Unemployment is below the Group average and some of those in employment work in managerial or professional occupations."
        },
        {
          id: "8a2",
          parent: "8a",
          name: "Flats & Routine Occupations",
          colour: "#a16fa8",
          visible: true,
          description:
            "These neighbourhoods are mainly comprised of flats, interspersed with occasional detached, semi-detached or terraced houses. There are many families with children, and marriage and civil partnership is less common than in the Group as a whole. Rates of use of English as the main language are slightly lower than Group average and residents are less likely to be affiliated with any religion. There are more students and there is slightly higher unemployment than elsewhere in the Group. Employment is heavily skewed towards the provision of routine services."
        },
        {
          id: "8b1",
          parent: "8b",
          name: "Challenged Families",
          colour: "#bd99c3",
          visible: true,
          description:
            "These UK-born families have dependent children, typically in the 5 to 14 age range. Members of this Subgroup are relatively young but lack qualifications from higher education. The very old are much less in evidence. As with the rest of the Group, flats are the most common housing type, although some residents live in terraced or semi-detached houses. Unemployment rates are the highest in the Group, and those in employment primarily hold non-managerial or professional positions."
        },
        {
          id: "8b2",
          parent: "8b",
          name: "Retirement Pockets",
          colour: "#cbaed0",
          visible: true,
          description:
            "Residence in communal establishments is relatively common in these neighbourhoods. Residents are typically of retirement age or older, and live as single person households. The Subgroup appears to be marginally more ethnically diverse than the rest of the Group, principally because the Subgroup appears to be marginally more ethnically diverse than the rest of the Group. This is reflected by a higher representation of individuals identifying as being of Mixed or Multiple ethnic groups. A higher proportion of adults were born elsewhere in Europe and more individuals live in detached or semi-detached houses than elsewhere in the Group. There are very few students and little unemployment. Those in work are often employed in managerial and professional occupations."
        },
        {
          id: "8b3",
          parent: "8b",
          name: "Young Families & Neighbourhood Turnover",
          colour: "#dac3dd",
          visible: true,
          description:
            "Young families comprising adults aged 25 to 44 with children aged 5 to 14 are common in these neighbourhoods, although adults are less likely to be married or in a civil partnership than elsewhere in the Group. Neighbourhoods are characterised by high residential turnover. Individuals identifying as Indian, Pakistani, Bangladeshi, Chinese or any other Asian background are slightly more common that the Group average. Residents almost exclusively rent in the private sector or own their homes – which are typically flats. Levels of education are higher than elsewhere in the Group, as is the proportion of individuals employed as managers or administrators. There is a correspondingly lower share of workers in sales and customer service roles."
        }
      ]
    }
  ]
};
