import colorbrewer from "colorbrewer";

const colsPrefix = "the_geom_webmercator, lad21cd, lad21nm, ";

const ramp = colorbrewer.RdPu[9];

const legendStops = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp[0], name: "None" },
      { value: 0.00001, colour: ramp[1], name: "0-1%" },
      { value: 0.01, colour: ramp[2], name: "1-2%" },
      { value: 0.02, colour: ramp[3], name: "2-3%" },
      { value: 0.03, colour: ramp[4], name: "3-5%" },
      { value: 0.05, colour: ramp[5], name: "5-10%" },
      { value: 0.1, colour: ramp[6], name: "10-15%" },
      { value: 0.15, colour: ramp[7], name: "15-20%" },
      { value: 0.2, colour: ramp[8], name: "Over 20%" }
    ]
  }
];

const legendStopsWBR = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp[0], name: "Under 40%" },
      { value: 0.4, colour: ramp[1], name: "40-50%" },
      { value: 0.5, colour: ramp[2], name: "50-60%" },
      { value: 0.6, colour: ramp[3], name: "60-70%" },
      { value: 0.7, colour: ramp[4], name: "70-80%" },
      { value: 0.8, colour: ramp[5], name: "80-90%" },
      { value: 0.9, colour: ramp[6], name: "90-95%" },
      { value: 0.95, colour: ramp[7], name: "95-98%" },
      { value: 0.98, colour: ramp[8], name: "Over 98%" }
    ]
  }
];

export const MEP = {
  title: "Ethnicity Estimator",
  description:
    "Forename and surname-based declared ethnicity proportions combined with linked consumer registers to create modelled populations, aggregated by local authority and converted to proportions.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/cdrc-modelled-ethnicity-proportions-la-geography",
  storyLinks: [
    {
      link:
        "https://data.cdrc.ac.uk/story/changing-distributions-londons-ethnic-minority-communities",
      title: "Changing distributions of London’s ethnic minority communities"
    }
  ],
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + getCols() + " FROM la21_uk",
  buildingSql: "SELECT " + getCols() + " FROM la21_uk_bua22",
  detailUrbanSql: "SELECT " + getCols() + " FROM la21_uk_bua22",
  urbanSql: "SELECT " + getCols() + " FROM la21_uk_bua22",
  defaultLayers: "1111000",
  extent: 2,
  popup: false,
  idField: "lad21cd",
  geogNameField: "lad21nm",
  ladCodeField: "lad21cd",
  hoverFieldMetricMode: "Some",
  metrics: getMetricList()
};

function getCols() {
  return (
    colsPrefix +
    getMetricList()
      .map(function(a) {
        return a.field;
      })
      .join(", ")
  );
}

function getMetricList() {
  //const minYear = 1997;
  //const maxYear = 2020;
  let list = [];
  let i = 0;
  let ic = 0;

  const cats = {
    abd: "Asian Bangladeshi",
    acn: "Asian Chinese",
    ain: "Asian Indian",
    apk: "Asian Pakistani",
    aao: "Asian Other",
    baf: "Black African",
    bca: "Black Caribbean",
    wbr: "White British",
    wir: "White Irish",
    wao: "White Other"
  };

  const years = ["1997", "2006", "2016", "2023"];

  Object.keys(cats).forEach(function(key) {
    //for (let j = minYear; j <= maxYear; j++)
    for (const year in years) {
      const j = years[year];
      list.push({
        id: i,
        name: cats[key] + " " + j,
        field: "mepla" + key + j.substring(2),
        display: "Percentage",
        decimals: 0,
        legendStops: key == "wbr" ? legendStopsWBR : legendStops,
        legendOptions: [],
        additionalMetrics: Array(years.length)
          .fill()
          .map((v, k) => k + ic)
        //additionalMetrics: Array(maxYear-minYear+1).fill().map((v,k)=>k+ic)
      });
      i++;
    }
    ic = i;
  });
  return list;
}
