<template>
  <div
    class="map-container"
    :class="{ 'map-container-embed': uiSettings.embed > 0 }"
  >
    <MapBoxMap
      :lat="this.mapSettings.lat"
      :long="this.mapSettings.long"
      :zoom="this.mapSettings.zoom"
      :sources="sources"
      :layers="layers"
      :mode="clickMode"
      :searchResult="searchResult"
      :embed="this.uiSettings.embed"
      v-on:hover="updateHoverFeature"
      v-on:click="showPopup"
      v-on:move="updateMapSettings"
      v-on:clickModeChange="setClickMode"
      v-on:featureDrawn="featureDrawn"
    />
    <MapSearch v-if="extent.name != 'USA'" v-on:selected="setSearchResult" />
    <MapSidebar
      :title="metadata.title"
      :description="metadata.description"
      :downloadLink="metadata.downloadLink"
      :storyLinks="metadata.storyLinks"
      :relatedMaps="metadata.relatedMaps"
      :embed="uiSettings.embed"
    >
      <PdfReport
        v-if="uiSettings.embed == 0"
        ref="pdf"
        :selectedStop="selectedStop"
        :metadata="metadata"
      />
      <div>
        <b-tabs content-class="mt-2" small>
          <b-tab title="Legend" active>
            <b-form-select
              v-model="selectedHierarchy"
              v-on:change="changeField"
              v-if="hierarchyOptions.length > 1"
            >
              <b-form-select-option
                v-for="ho in hierarchyOptions"
                :key="ho.value"
                :value="ho.value"
              >
                <span v-html="ho.text"> </span>
              </b-form-select-option>
            </b-form-select>
            <b-form-select
              v-model="selectedMetric"
              v-on:change="changeMetric"
              v-if="metricOptions.length > 1"
            >
              <b-form-select-option
                v-for="mo in metricOptions"
                :key="mo.value"
                :value="mo.value"
                :disabled="uiSettings.embed == 2"
              >
                <span v-html="mo.text"> </span>
              </b-form-select-option>
            </b-form-select>
            <b-form-select
              v-model="selectedStop"
              :options="stopOptions"
              v-on:change="changeStop"
              v-if="stopOptions.length > 1 && uiSettings.embed < 2"
              class="mt-1"
            ></b-form-select>

            <MapGroupLegend
              v-if="selectedHierarchy != null"
              :selectedHierarchy="selectedHierarchy"
              :hierarchy="hierarchy"
            />
            <MapMetricLegend
              v-if="selectedMetric != null"
              :stops="metrics[selectedMetric].legendStops[selectedStop].stops"
              :metrictitle="metrics[selectedMetric].name"
              :metricdescription="metrics[selectedMetric].description"
            />
          </b-tab>
          <b-tab title="Filter" v-if="uiSettings.embed < 2">
            <MapFilter
              :hierarchy="hierarchy"
              :metrics="metrics"
              :ladField="metadata.ladCodeField"
              :london="extent.name == 'London'"
              :usa="extent.name == 'USA'"
              v-on:change="changeFilter"
          /></b-tab>
        </b-tabs>
      </div>
      <MapHoverFeature v-if="uiSettings.embed == 0" :data="hoverFeatureData" />
    </MapSidebar>
    <MapLayerControl
      :type="extent.name"
      :landVisibility="land"
      :detailsVisibility="visibilityOfLayer('water')"
      :labelsVisibility="visibilityOfLayer('place')"
      :regionVisibility="visibilityOfLayer('region')"
      :combinedVisibility="visibilityOfLayer('combined')"
      :pconVisibility="visibilityOfLayer('pcon')"
      :ladVisibility="visibilityOfLayer('lad')"
      :retailVisibility="visibilityOfLayer('retail')"
      :embed="uiSettings.embed"
      v-on:change="changeAdditionalLayerVisibility"
    >
      <div class="map-draw" v-if="this.metadata.popup">
        <hr class="my-2" />
        <strong
          v-b-tooltip.hover
          title="Click polygon button to enter drawing mode, when done click the button again to return to select mode"
          >Draw <span class="badge badge-pill badge-primary">?</span>
        </strong>
        <b-button
          squared
          :pressed="clickMode == 'select'"
          v-on:click="setClickMode('select')"
          class="ml-1"
          ><font-awesome-icon :icon="['fas', 'mouse-pointer']"
        /></b-button>
        <b-button squared :pressed="clickMode == 'draw'" v-on:click="toggleDraw"
          ><font-awesome-icon :icon="['fas', 'draw-polygon']"
        /></b-button>
        <b-button squared v-on:click="setClickMode('delete')"
          ><font-awesome-icon :icon="['fas', 'trash']"
        /></b-button>
      </div>
    </MapLayerControl>
    <b-modal
      id="map-classification-popup"
      hide-header
      scrollable
      centered
      size="lg"
      footer-class="p-1"
    >
      <MapClassificationPopupContents
        :title="metadata.title"
        :data="popupClassificationData"
        :type="extent.name"
        :hierarchy="hierarchy"
      />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="hidePopup">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="map-custom-popup"
      hide-header
      scrollable
      centered
      size="lg"
      footer-class="p-1"
    >
      <MapCustomPopupContents
        :title="metadata.title"
        :data="popupCustomData"
        :type="extent.name"
      />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="hidePopup">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <img
      v-if="uiSettings.embed == 0"
      class="carto-logo"
      alt="CARTO"
      src="@/assets/carto-logo-negative.svg"
    />
    <b-button
      v-if="uiSettings.embed > 0"
      :href="'/#' + this.$route.fullPath + '&embed=0'"
      target="_new"
      variant="light"
      class="carto-logo"
      alt="View full map"
    >
      View full map
    </b-button>

    <b-dropdown text="Attributions" variant="light" class="attributions">
      <b-dropdown-item href=""
        ><i>To reference CDRC Mapmaker, use the following text:</i><br />An
        output of the Consumer Data Research Centre,<br />an ESRC Data
        Investment, ES/L011840/1; ES/L011891/1”</b-dropdown-item
      >
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item href=""
        >Powered by and hosted on CARTO Platform.</b-dropdown-item
      >
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item href=""
        >Contains OS data © Crown copyright and database right
        2022</b-dropdown-item
      >
      <b-dropdown-item href=""
        >Contains Royal Mail data © Royal Mail copyright and Database right
        2022</b-dropdown-item
      >
      <b-dropdown-item href=""
        >Contains GeoPlace data © Local Government Information House Limited<br />copyright
        and database right 2022</b-dropdown-item
      >
      <b-dropdown-item href=""
        >Source: Office for National Statistics licensed under<br />
        the Open Government Licence v.3.0</b-dropdown-item
      >
      <b-dropdown-item href=""
        >© OpenStreetMap contributors, licenced under the Open Database
        Licence.</b-dropdown-item
      >
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item href=""
        >Check the descriptions on individual maps, and their associated data<br />download
        link pages, for additional attributions and copyright
        statements.</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
import MapBoxMap from "@/components/MapBoxMap.vue";
import MapLayerControl from "@/components/MapLayerControl.vue";
import MapSidebar from "@/components/MapSidebar.vue";
import MapSearch from "@/components/MapSearch.vue";
import MapGroupLegend from "@/components/MapGroupLegend.vue";
import MapMetricLegend from "@/components/MapMetricLegend.vue";
import MapHoverFeature from "@/components/MapHoverFeature.vue";
import MapFilter from "@/components/MapFilter.vue";
import MapClassificationPopupContents from "@/components/MapClassificationPopupContents.vue";
import MapCustomPopupContents from "@/components/MapCustomPopupContents.vue";
import PdfReport from "@/components/PdfReport.vue";
import "whatwg-fetch";
import { DATASOURCES } from "@/config/DataSources";
import { DATAGROUPS } from "@/config/DataGroups";
import { ADDITIONALLAYERS } from "@/config/AdditionalLayers";
import { EXTENTS } from "@/config/Extents";
import { MAPS } from "@/config/Maps";

const NO_DATA_MAP_COLOUR = "#787168";
const NO_DATA_KEY_COLOUR = "#9a9591";
const NO_DATA_VALUE = -999999;

export default {
  name: "MapContainer",
  props: {
    metadata: Object
  },
  data() {
    return {
      pdfKey: location.hash,
      searchResult: null,
      land: this.setInitialLand(),
      selectedHierarchy: this.setInitialHierarchy(),
      selectedMetric: this.setInitialMetric(),
      selectedStop: 0,
      hierarchyOptions: [...this.makeHierachyConfig()],
      metricOptions: [...this.makeMetricConfig()],
      stopOptions: [...this.makeStopConfig(this.setInitialMetric())],
      metrics: [...(this.metadata.metrics || [])],
      hierarchy: [...(this.metadata.hierarchy || [])],
      clickMode: "select",
      extent: EXTENTS.find(x => x.id == this.metadata.extent),
      filters: this.makeFilters(),
      sources: this.makeSources(),
      layers: this.makeLayerConfig(
        this.setInitialHierarchy(),
        this.setInitialMetric()
      ),
      rawHoverFeatureData: null,
      hoverFeatureData: {
        id: null,
        name: null,
        hierarchy: [],
        metrics: [],
        additionalFields: []
      },
      popupClassificationData: {
        id: null,
        name: null,
        hierarchy: [],
        region: { id: null, name: null, data: [] },
        lad: { id: null, name: null, data: [] },
        combined: { id: null, name: null, data: [] },
        pcon: { id: null, name: null, data: [] },
        retail: { id: null, name: null, data: [] },
        adhoc: { id: null, name: null, data: [] }
      },
      popupCustomData: {
        featureConfig: null,
        geographyConfig: null,
        feature: { id: null, name: null, data: [] },
        region: { id: null, name: null, data: [] },
        lad: { id: null, name: null, data: [] },
        combined: { id: null, name: null, data: [] },
        pcon: { id: null, name: null, data: [] },
        retail: { id: null, name: null, data: [] }
      },
      mapSettings: this.setInitialMapSettings(),
      title: this.setTitle(this.setInitialMetric(true)),
      uiSettings: this.setInitialUISettings(),
      isUnlistedMap: MAPS.find(x => x.id == this.$route.name).hidden
    };
  },
  components: {
    MapBoxMap,
    MapLayerControl,
    MapSidebar,
    MapGroupLegend,
    MapMetricLegend,
    MapHoverFeature,
    MapFilter,
    MapClassificationPopupContents,
    MapCustomPopupContents,
    MapSearch,
    PdfReport
  },
  methods: {
    setSearchResult: function(data) {
      this.searchResult = data;
    },
    toggleDraw: function() {
      if (this.clickMode == "select") {
        this.setClickMode("draw");
      } else if (this.clickMode == "draw") {
        this.setClickMode("select");
      }
    },
    setClickMode: function(newValue) {
      this.clickMode = newValue;
    },
    setInitialHierarchy: function() {
      let initialValue = null;

      if (Object.prototype.hasOwnProperty.call(this.metadata, "hierarchy")) {
        initialValue = 0; //this.metadata.hierarchy.length - 1;

        if (this.metadata.initialValue) {
          initialValue = this.metadata.initialValue;
        }

        const params = this.$route.query;
        if (
          Object.prototype.hasOwnProperty.call(params, "h") &&
          !isNaN(params.h) &&
          Number(params.h) < this.metadata.hierarchy.length
        ) {
          initialValue = Number(params.h);
        }
      }

      return initialValue;
    },
    setInitialLand: function() {
      let initialValue = this.metadata.defaultLayers.slice(0, 1);
      const params = this.$route.query;
      if (
        Object.prototype.hasOwnProperty.call(params, "d") &&
        !isNaN(params.d) &&
        params.d.length == DATAGROUPS.length + 1
      ) {
        initialValue = params.d.slice(0, 1);
      }
      return initialValue;
    },
    setInitialMetric: function(warnMissing = false) {
      let initialValue = null;

      if (Object.prototype.hasOwnProperty.call(this.metadata, "metrics")) {
        initialValue = this.metadata.metrics[0].id;

        if (this.metadata.initialValue) {
          initialValue = this.metadata.initialValue;
        }

        const params = this.$route.query;
        if (Object.prototype.hasOwnProperty.call(params, "m")) {
          if (this.metadata.metrics.find(x => x.field == params.m) == null) {
            if (warnMissing) {
              this.$bvModal.msgBoxOk(
                'The config for the specified metric "' +
                  params.m +
                  '" is missing. The metric and its corresponding map may have been retired or replaced. ' +
                  'The default metric "' +
                  this.metadata.metrics[0].field +
                  '" will be mapped instead.',
                {
                  title: "Unable to display requested map",
                  headerBgVariant: "warning"
                }
              );
            }
          } else {
            initialValue = this.metadata.metrics.find(x => x.field == params.m)
              .id;
          }
        }
      }
      return initialValue;
    },
    setInitialMapSettings: function() {
      const extent = EXTENTS.find(x => x.id == this.metadata.extent);
      let initialSettings = {
        lat: extent.lat,
        long: extent.long,
        zoom: extent.zoom
      };
      const params = this.$route.query;
      if (
        Object.prototype.hasOwnProperty.call(params, "lat") &&
        !isNaN(params.lat)
      ) {
        initialSettings.lat = Number(params.lat);
      }
      if (
        Object.prototype.hasOwnProperty.call(params, "lon") &&
        !isNaN(params.lon)
      ) {
        initialSettings.long = Number(params.lon);
      }
      if (
        Object.prototype.hasOwnProperty.call(params, "zoom") &&
        !isNaN(params.zoom)
      ) {
        initialSettings.zoom = Number(params.zoom);
      }

      if (
        initialSettings.lat > extent.maxLat ||
        initialSettings.lat < extent.minLat ||
        initialSettings.long > extent.maxLong ||
        initialSettings.long < extent.minLong
      ) {
        initialSettings = {
          lat: extent.lat,
          long: extent.long,
          zoom: extent.zoom
        };
      }

      return initialSettings;
    },
    setInitialUISettings: function() {
      let initialUISettings = {
        embed: 0
      };
      const params = this.$route.query;

      if (
        Object.prototype.hasOwnProperty.call(params, "embed") &&
        !isNaN(params.embed)
      ) {
        initialUISettings.embed = Number(params.embed);
      }
      return initialUISettings;
    },
    updateUrl: function() {
      let query = {};

      let data = this.land;

      DATAGROUPS.forEach(element => {
        data +=
          this.layers.find(x => x.group == element).layout.visibility ==
          "visible"
            ? 1
            : 0;
      });

      if (data != this.metadata.defaultLayers) {
        query.d = data;
      }

      if (
        Object.prototype.hasOwnProperty.call(this.metadata, "hierarchy") &&
        this.metadata.hierarchy.length > 1
      ) {
        query.h = this.selectedHierarchy;
      }

      if (
        Object.prototype.hasOwnProperty.call(this.metadata, "metrics") &&
        this.metadata.metrics.length > 1
      ) {
        query.m = this.metadata.metrics.find(
          x => x.id == this.selectedMetric
        ).field;
      }

      if (this.filters) {
        if (this.filters.lad.selected) {
          query["f_lad"] = this.filters.lad.selected;
        }
        this.filters.hierarchy.forEach((element, index) => {
          if (element.selected && element.selected.length > 0) {
            query["f_h" + index + ""] = element.selected.join("~");
          }
        });
        this.filters.metrics.forEach(element => {
          query["f_m" + element.id + ""] =
            element.field + "~" + element.type + "~" + element.value;
        });
      }

      query["lon"] = Math.round(this.mapSettings.long * 10000) / 10000;
      query["lat"] = Math.round(this.mapSettings.lat * 10000) / 10000;
      query["zoom"] = Math.round(this.mapSettings.zoom * 100) / 100;
      if (this.uiSettings.embed != 0) {
        query["embed"] = this.uiSettings.embed;
        this.$route.query.embed = query.embed;
      }

      this.$route.query.lon = query.lon;
      this.$route.query.lat = query.lat;
      this.$route.query.zoom = query.zoom;

      //update
      let url = window.location.href;
      if (url.indexOf("?") > 0) {
        url = url.substring(0, url.indexOf("?"));
      }
      url =
        url +
        "?" +
        Object.keys(query)
          .map(key => {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(query[key])
            );
          })
          .join("&");

      history.replaceState({}, null, url);
      this.$nextTick(() => {
        if (this.$refs.pdf) {
          this.$refs.pdf.update();
        }
      });
    },
    updateMapSettings: function(data) {
      this.mapSettings = data;
      this.updateUrl();
    },
    hidePopup: function() {
      this.$bvModal.hide("map-classification-popup");
      this.$bvModal.hide("map-custom-popup");
      this.setClickMode("select");
    },
    featureDrawn: function(data) {
      this.showPopup([{ name: "adhoc", feature: data }]);
    },
    showPopup: function(features) {
      if (this.metadata.popupType == "custom") {
        this.showCustomPopup(features);
      } else {
        this.showClassificationPopup(features);
      }
    },
    showCustomPopup: function(features) {
      if (
        Object.prototype.hasOwnProperty.call(this.metadata, "popup") &&
        this.metadata.popup
      ) {
        const vm = this;
        var newData = {
          featureConfig: this.metadata.popupFeatureConfig,
          geographyConfig: this.metadata.popupGeographyConfig,
          feature: { id: null, name: null, data: [] },
          region: { id: null, name: null, data: [] },
          lad: { id: null, name: null, data: [] },
          combined: { id: null, name: null, data: [] },
          pcon: { id: null, name: null, data: [] },
          retail: { id: null, name: null, data: [] },
          adhoc: { id: null, name: null, data: [] }
        };

        const group = features.find(e => e.name == "group");
        let resultCount = 0;
        if (group) {
          resultCount += 1;
          newData.feature.id = group.feature.properties[this.metadata.idField];
          newData.feature.name =
            (this.metadata.geogNamePrefix ? this.metadata.geogNamePrefix : "") +
            group.feature.properties[this.metadata.geogNameField];
          newData.feature.data = group.feature.properties;
        }
        const adhoc = features.find(e => e.name == "adhoc");
        if (adhoc) {
          resultCount += 1;
          newData.adhoc.id = "adhoc";
          newData.adhoc.name = "adhoc";
          if (this.metadata.makePolygonSQL) {
            const formData = new FormData();
            formData.append(
              "q",
              this.metadata.makePolygonSQL(
                "SELECT ST_SetSRID(ST_GeomFromGeoJSON('" +
                  JSON.stringify(adhoc.feature.geometry) +
                  "'), 4326) as the_geom"
              )
            );
            fetch(this.metadata.queryUrl, {
              method: "POST",
              body: formData
            })
              .then(e => e.json())
              .then(results => {
                let data = { ...vm.popupCustomData };
                data.adhoc.data = results.rows[0];
                this.popupCustomData = data;
              });
          }
        }
        ADDITIONALLAYERS.forEach(element => {
          const layer = features.find(e => e.name == element.name);
          if (element.popup && layer) {
            resultCount += 1;
            const formData = new FormData();

            if (element.makePolygonSQL && this.metadata.makePolygonSQL) {
              formData.append(
                "q",
                this.metadata.makePolygonSQL(
                  element.makePolygonSQL(
                    layer.feature.properties[element.idField],
                    element.table[this.metadata.extent],
                    element.idColumn[this.metadata.extent]
                  )
                )
              );
              fetch(this.metadata.queryUrl, {
                method: "POST",
                body: formData
              })
                .then(e => e.json())
                .then(results => {
                  if (
                    this.popupCustomData[element.name].id ==
                    layer.feature.properties[element.idField]
                  ) {
                    let data = { ...vm.popupCustomData };
                    data[element.name].data = results.rows[0];
                    this.popupCustomData = data;
                  }
                });
            }
            newData[element.name].id =
              layer.feature.properties[element.idField];
            newData[element.name].name =
              layer.feature.properties[element.nameField];
          }
        });
        this.popupCustomData = newData;
        if (resultCount > 0) {
          this.$bvModal.show("map-custom-popup");
        }
      }
    },

    showClassificationPopup: function(features) {
      if (
        Object.prototype.hasOwnProperty.call(this.metadata, "popup") &&
        this.metadata.popup
      ) {
        const vm = this;
        if (features && features.length > 0) {
          let newData = {
            id: null,
            name: null,
            hierarchy: [],
            region: { id: null, name: null, data: {} },
            lad: { id: null, name: null, data: {} },
            combined: { id: null, name: null, data: {} },
            pcon: { id: null, name: null, data: {} },
            retail: { id: null, name: null, data: {} },
            adhoc: { id: null, name: null, data: {} }
          };
          let resultCount = 0;
          const group = features.find(e => e.name == "group");
          if (group) {
            resultCount += 1;
            newData.id = group.feature.properties[this.metadata.idField];
            newData.name =
              (this.metadata.geogNamePrefix
                ? this.metadata.geogNamePrefix
                : "") + group.feature.properties[this.metadata.geogNameField];

            this.metadata.hierarchy.forEach(element => {
              var groupData = element.groups.find(
                x =>
                  x[element.lookup] == group.feature.properties[element.field]
              );

              if (groupData == null) {
                groupData = {
                  id: "",
                  name: "Unclassified",
                  colour: NO_DATA_KEY_COLOUR
                };
              }

              newData.hierarchy.push({
                id: element.id,
                name: element.name,
                groupID: groupData.id,
                groupName: groupData.name,
                groupColour: groupData.colour,
                lightText: groupData.lightText,
                groupDescription: groupData.description
              });
            });
          }
          const adhoc = features.find(e => e.name == "adhoc");
          if (adhoc) {
            resultCount += 1;
            newData.adhoc.id = "adhoc";
            newData.adhoc.name = "adhoc";
            if (this.metadata.makePolygonSQL) {
              const formData = new FormData();
              formData.append(
                "q",
                this.metadata.makePolygonSQL(
                  "SELECT ST_SetSRID(ST_GeomFromGeoJSON('" +
                    JSON.stringify(adhoc.feature.geometry) +
                    "'), 4326) as the_geom"
                )
              );
              fetch(this.metadata.queryUrl, {
                method: "POST",
                body: formData
              })
                .then(e => e.json())
                .then(results => {
                  let data = { ...vm.popupClassificationData };
                  data.adhoc.data = results.rows[0];
                  this.popupClassificationData = data;
                });
            }
          }

          ADDITIONALLAYERS.forEach(element => {
            const layer = features.find(e => e.name == element.name);
            if (element.popup && layer) {
              resultCount += 1;
              const formData = new FormData();
              if (element.makePolygonSQL && this.metadata.makePolygonSQL) {
                formData.append(
                  "q",
                  this.metadata.makePolygonSQL(
                    element.makePolygonSQL(
                      layer.feature.properties[element.idField],
                      element.table[this.metadata.extent],
                      element.idColumn[this.metadata.extent]
                    )
                  )
                );
                fetch(this.metadata.queryUrl, {
                  method: "POST",
                  body: formData
                })
                  .then(e => e.json())
                  .then(results => {
                    if (
                      this.popupClassificationData[element.name].id ==
                      layer.feature.properties[element.idField]
                    ) {
                      let data = { ...vm.popupClassificationData };
                      data[element.name].data = results.rows[0];
                      this.popupClassificationData = data;
                    }
                  });
              }
              newData[element.name].id =
                layer.feature.properties[element.idField];
              newData[element.name].name =
                layer.feature.properties[element.nameField];
            }
          });
          this.popupClassificationData = newData;
          if (resultCount > 0) {
            this.$bvModal.show("map-classification-popup");
          }
        }
      }
    },
    updateHoverFeature(features) {
      this.rawHoverFeatureData = features;
      let newdata = {
        id: null,
        name: null,
        hierarchy: [],
        metrics: [],
        additionalFields: []
      };

      function ordinal_suffix_of(i) {
        var j = i % 10,
          k = i % 100;
        if (j == 1 && k != 11) {
          return i + "st";
        }
        if (j == 2 && k != 12) {
          return i + "nd";
        }
        if (j == 3 && k != 13) {
          return i + "rd";
        }
        return i + "th";
      }

      if (features && features.length > 0) {
        const individual = features.find(e => e.name == "group-individual");
        const agggroup = features.find(e => e.name == "group");

        var group = agggroup;
        if (group || individual) {
          if (individual) {
            newdata.id =
              individual.feature.properties[this.metadata.individualIdField];
            newdata.name = "";
            if (group) {
              newdata.name = this.metadata.geogNamePrefix
                ? group.feature.properties[this.metadata.geogNameField]
                : "";
            }
            group = individual;
          } else {
            newdata.id = group.feature.properties[this.metadata.idField];
            newdata.name =
              (this.metadata.geogNamePrefix
                ? this.metadata.geogNamePrefix
                : "") + group.feature.properties[this.metadata.geogNameField];
          }
          if (Object.prototype.hasOwnProperty.call(this.metadata, "metrics")) {
            let metricsToAdd = this.metadata.metrics;

            if (
              !this.metadata.hoverFieldMetricMode ||
              this.metadata.hoverFieldMetricMode != "All"
            ) {
              metricsToAdd = this.metadata.metrics.filter(
                x => x.id == this.selectedMetric
              );
              let thisMetric = metricsToAdd[0];
              if (thisMetric.additionalMetrics) {
                if (this.metadata.hoverFieldMetricMode != "MetricOnly") {
                  metricsToAdd = [];
                }
                metricsToAdd = [
                  ...metricsToAdd,
                  ...this.metadata.metrics.filter(x =>
                    thisMetric.additionalMetrics.includes(x.id)
                  )
                ];
              }
            }
            metricsToAdd.forEach(metric => {
              var metricField = metric.field;
              if (metric.compField !== undefined) {
                metricField = metric.field.split(".")[0];
              }
              let value = group.feature.properties[metricField];
              let displayValue = value;
              if (metric.display == "Percentage") {
                if (value === undefined) {
                  displayValue = "";
                } else {
                  if (metric.compField !== undefined) {
                    value = value - group.feature.properties[metric.compField];
                  }

                  if (metric.rankCount !== undefined) {
                    value = value / metric.rankCount;
                  }

                  if (metric.denomField !== undefined) {
                    value = value / group.feature.properties[metric.denomField];
                  }

                  value = value * 100;

                  let decimalPlaces = 1;

                  if (metric.decimals !== undefined) {
                    decimalPlaces = metric.decimals;
                  }

                  value =
                    Math.round(value * Math.pow(10, decimalPlaces)) /
                    Math.pow(10, decimalPlaces);
                  displayValue = value + "%";
                }
              } else if (metric.display == "Decile") {
                if (value === undefined) {
                  displayValue = "";
                } else {
                  let decile = Math.ceil((10 * value) / metric.rankCount);
                  displayValue =
                    ordinal_suffix_of(decile) +
                    " (" +
                    value +
                    " of " +
                    metric.rankCount +
                    ")";
                }
              } else if (metric.display == "Colour") {
                //NOOP
              } else if (value !== undefined) {
                if (metric.decimals !== undefined) {
                  value =
                    Math.round(value * Math.pow(10, metric.decimals)) /
                    Math.pow(10, metric.decimals);
                  displayValue = value;
                }
                if (metric.display !== undefined) {
                  if (metric.display == "£") {
                    displayValue = "£" + value.toLocaleString();
                  } else {
                    displayValue = value + "" + metric.display;
                  }
                }
              }

              let stopName = "";
              let stopColour = "";
              if (metric.legendStops) {
                var stops = metric.legendStops[0].stops;
                for (var i in stops) {
                  if (stops[i].value <= value) {
                    stopName = stops[i].name;
                    stopColour = stops[i].colour;
                  }
                }
              }

              newdata.metrics.push({
                id: metric.id,
                name: metric.name,
                value: displayValue,
                display: metric.display,
                stopName: stopName,
                stopColour: stopColour
              });
            });
          }

          if (
            Object.prototype.hasOwnProperty.call(this.metadata, "hierarchy")
          ) {
            this.metadata.hierarchy.forEach(element => {
              var groupData = element.groups.find(
                x =>
                  x[element.lookup] == group.feature.properties[element.field]
              );

              if (groupData == null) {
                groupData = {
                  id: "",
                  name: "Unclassified",
                  colour: NO_DATA_KEY_COLOUR
                };
              }

              newdata.hierarchy.push({
                id: element.id,
                name: element.name,
                groupID: groupData.id,
                groupName: groupData.name,
                groupColour: groupData.colour,
                lightText: groupData.lightText,
                groupDescription: groupData.description
              });
            });
          }
        }

        ADDITIONALLAYERS.forEach(element => {
          const layer = features.find(e => e.name == element.name);
          if (
            layer &&
            element.popup &&
            !newdata.additionalFields.find(x => x.field == element.description)
          ) {
            newdata.additionalFields.push({
              field: element.description,
              value: layer.feature.properties[element.nameField]
            });
          }
        });
      }
      this.hoverFeatureData = newdata;
    },
    makeHierachyConfig() {
      let data = [];
      if (Object.prototype.hasOwnProperty.call(this.metadata, "hierarchy")) {
        data = this.metadata.hierarchy.map(x => ({
          value: x.id,
          text: x.name
        }));
      }
      return data;
    },
    makeMetricConfig() {
      let data = [];
      if (Object.prototype.hasOwnProperty.call(this.metadata, "metrics")) {
        var filteredMetrics = this.metadata.metrics.reduce(
          (filteredMetrics, metric) => {
            if (metric.visible !== false) {
              filteredMetrics.push(metric);
            }
            return filteredMetrics;
          },
          []
        );
        data = filteredMetrics.map(x => ({ value: x.id, text: x.name }));
      }
      return data;
    },
    makeStopConfig(metricID) {
      let data = [];
      if (Object.prototype.hasOwnProperty.call(this.metadata, "metrics")) {
        let metric = this.metadata.metrics.find(x => x.id == metricID);
        data = metric.legendOptions.map(x => ({ value: x.id, text: x.name }));
      }
      return data;
    },
    getMetricPaint(metricID, stopID, border = false) {
      let metric = this.metadata.metrics.find(x => x.id == metricID);

      let paint = "";
      if (metric.compField) {
        var metricField = metric.field.split(".")[0];
        if (metric.denomField) {
          paint = {
            "fill-color": [
              "step",
              [
                "/",
                [
                  "-",
                  ["coalesce", ["get", metricField], NO_DATA_VALUE],
                  ["coalesce", ["get", metric.compField], 0]
                ],
                ["coalesce", ["get", metric.denomField], 10]
              ],
              NO_DATA_MAP_COLOUR
            ]
          };
        } else {
          paint = {
            "fill-color": [
              "step",
              [
                "-",
                ["coalesce", ["get", metricField], NO_DATA_VALUE],
                ["coalesce", ["get", metric.compField], 0]
              ],
              NO_DATA_MAP_COLOUR
            ]
          };
        }
      } else {
        paint = {
          "fill-color": [
            "step",
            ["coalesce", ["get", metric.field], NO_DATA_VALUE],
            NO_DATA_MAP_COLOUR
          ]
        };
      }

      metric.legendStops[stopID].stops.forEach(stop => {
        paint["fill-color"].push(stop.value);
        paint["fill-color"].push(stop.colour);
      });

      if (metric.display == "Colour") {
        paint = "";
        paint = {
          "fill-color": ["coalesce", ["get", metric.field], NO_DATA_MAP_COLOUR]
        };
      }
      if (border) {
        let borderPaint = {};
        borderPaint["line-color"] = [...paint["fill-color"]];
        borderPaint["line-width"] = 2;
        return borderPaint;
      }
      return paint;
    },
    getMetricPointPaint(metricID, stopID) {
      const urbanToBuildingTransition = 12;

      let metric = this.metadata.metrics.find(x => x.id == metricID);
      let paint = "";
      paint = {
        "circle-radius": [
          "interpolate",
          ["linear"],
          ["zoom"],
          6,
          1,
          11,
          4,
          urbanToBuildingTransition - 0.0001,
          7,
          urbanToBuildingTransition,
          1.5,
          16,
          6
        ],
        "circle-opacity": 1,
        /* "marker-allow-overlap": true, */
        "circle-stroke-width": 0,
        "circle-stroke-color": "#FFFFFF",
        "circle-stroke-opacity": 1,
        "circle-color": [
          "step",
          ["coalesce", ["get", metric.field], NO_DATA_VALUE],
          NO_DATA_MAP_COLOUR
        ]
      };

      metric.legendStops[stopID].stops.forEach(stop => {
        paint["circle-color"].push(stop.value);
        paint["circle-color"].push(stop.colour);
      });

      return paint;
    },
    getGroupPaint(hierarchyID, border = false) {
      const vm = this;

      let hierarchy = vm.metadata.hierarchy.find(function(element) {
        return element.id == hierarchyID;
      });
      let paint = {
        "fill-color": ["match", ["get", hierarchy.field]]
      };
      hierarchy.groups.forEach(group => {
        paint["fill-color"].push(group[hierarchy.lookup]);
        paint["fill-color"].push(group.colour);
      });
      paint["fill-color"].push(NO_DATA_MAP_COLOUR);
      if (border) {
        let borderPaint = {};
        borderPaint["line-color"] = [...paint["fill-color"]];
        borderPaint["line-width"] = 2;
        return borderPaint;
      }
      return paint;
    },
    getGroupPointPaint(hierarchyID) {
      const urbanToBuildingTransition = 12;
      const vm = this;

      let hierarchy = vm.metadata.hierarchy.find(function(element) {
        return element.id == hierarchyID;
      });
      let paint = "";
      paint = {
        "circle-radius": [
          "interpolate",
          ["linear"],
          ["zoom"],
          7,
          1,
          11,
          3,
          urbanToBuildingTransition - 0.0001,
          8,
          urbanToBuildingTransition,
          3,
          16,
          15
        ],
        "circle-opacity": 1,
        /* "marker-allow-overlap": true, */
        "circle-stroke-width": 0,
        "circle-stroke-color": "#FFFFFF",
        "circle-stroke-opacity": 1,
        "circle-color": ["match", ["get", hierarchy.field]]
      };

      hierarchy.groups.forEach(group => {
        paint["circle-color"].push(group[hierarchy.lookup]);
        paint["circle-color"].push(group.colour);
      });
      paint["circle-color"].push(NO_DATA_MAP_COLOUR);
      return paint;
    },
    changeAdditionalLayerVisibility(data) {
      if (data.layer == "land") {
        this.land = data.visibility;
        this.changeLand();
      } else {
        let change = [...this.layers];
        change
          .filter(x => x.group == data.layer)
          .forEach(x => {
            x.layout.visibility = data.visibility;
          });

        if (data.layer == "name") {
          // names toggled - toggle labels to match
          change
            .filter(x => x.label)
            .forEach(x => {
              const groupVisibility = change.find(y => y.group == x.group)
                .layout.visibility;

              if (groupVisibility == "visible") {
                x.layout.visibility = data.visibility;
              }
            });
        } else if (data.visibility == "visible") {
          // data toggled, if visible set labels to match names
          change
            .filter(x => x.group == data.layer && x.label)
            .forEach(x => {
              x.layout.visibility = change.find(
                x => x.group == "name"
              ).layout.visibility;
            });
        }

        this.layers = change;
      }
      this.updateUrl();
    },
    changeFilter(filters) {
      const vm = this;
      vm.filters = filters;
      //create a new object and then apply back
      let change = [...vm.layers];
      //start filter by all - will be the only filter if everything else is blank
      let filter = ["all"];
      // set the group features - where the group field is in the values
      filters.hierarchy.forEach((element, index) => {
        if (element.selected.length > 0) {
          let filterTerm = ["in", element.field];
          element.selected.forEach(selected => {
            let group = this.hierarchy[index].groups.find(
              x => x[this.hierarchy[index].lookup] == selected
            );
            filterTerm.push(group[this.hierarchy[index].lookup]);
          });
          filter.push(filterTerm);
        }
      });
      //set the lad filter - just where row equals the name
      if (filters.lad.selected && this.metadata.ladCodeField) {
        filter.push(["in", this.metadata.ladCodeField, filters.lad.selected]);
      }
      //set the metric filter.
      filters.metrics.forEach(element => {
        filter.push([element.type, element.field, parseFloat(element.value)]);
      });
      //set the data to the new values
      change.find(x => x.name == "group").filter = filter;
      change.find(x => x.name == "group-detail").filter = filter;
      change.find(x => x.name == "group-urban").filter = filter;
      change.find(x => x.name == "group-building").filter = filter;

      const individualLayer = change.find(x => x.name == "group-individual");
      if (individualLayer) {
        change.find(x => x.name == "group-individual").filter = filter;
      }

      //set other layesr LAD filters
      ADDITIONALLAYERS.forEach(element => {
        if (element.ladCodeField) {
          let newfilter = element.filter ? element.filter : ["all"];
          if (filters.lad.selected) {
            newfilter.push(["in", element.ladCodeField, filters.lad.selected]);
          }
          change.find(x => x.name == element.name).filter = newfilter;
        }
      });

      this.layers = change;
      vm.updateUrl();
    },
    changeLand() {
      let change = [...this.layers];
      const groupLayer = change.find(x => x.name == "group");
      const buildingLayer = change.find(x => x.name == "group-building");
      const individualLayer = change.find(x => x.name == "group-individual");
      const detailLayer = change.find(x => x.name == "group-detail");
      const urbanLayer = change.find(x => x.name == "group-urban");

      if (this.land == "1") {
        if (buildingLayer) {
          buildingLayer.layout["visibility"] = "visible";
        }
        if (!individualLayer) {
          if (groupLayer) {
            groupLayer.paint["fill-opacity"] = 0;
          }
          if (detailLayer) {
            detailLayer.layout["visibility"] = "visible";
          }
          if (urbanLayer) {
            urbanLayer.layout["visibility"] = "visible";
          }
        }
      } else {
        if (buildingLayer) {
          buildingLayer.layout["visibility"] = "none";
        }
        if (!individualLayer) {
          if (groupLayer) {
            groupLayer.paint["fill-opacity"] = 1;
          }
          if (detailLayer) {
            detailLayer.layout["visibility"] = "none";
          }
          if (urbanLayer) {
            urbanLayer.layout["visibility"] = "none";
          }
        }
      }

      this.layers = change;
    },
    changeField() {
      //update display
      let change = [...this.layers];
      change.find(x => x.name == "group").paint = this.getGroupPaint(
        this.selectedHierarchy
      );
      change.find(x => x.name == "group-border").paint = this.getGroupPaint(
        this.selectedHierarchy,
        true
      );
      change.find(x => x.name == "group-detail").paint = this.getGroupPaint(
        this.selectedHierarchy
      );

      change.find(x => x.name == "group-urban").paint = this.getGroupPaint(
        this.selectedHierarchy
      );

      change.find(x => x.name == "group-building").paint = this.getGroupPaint(
        this.selectedHierarchy
      );

      this.layers = change;
      this.updateUrl();
    },
    changeMetric() {
      this.stopOptions = [...this.makeStopConfig(this.selectedMetric)];
      if (this.stopOptions.length > this.selectedStop) {
        this.selectedStop = 0;
      }
      this.changeStop();
      this.setTitle(this.selectedMetric);
    },
    changeStop() {
      //update display
      let change = [...this.layers];
      const individualLayer = change.find(x => x.name == "group-individual");

      if (individualLayer) {
        change.find(x => x.name == "group").paint = this.getMetricPointPaint(
          this.selectedMetric,
          this.selectedStop
        );
        change.find(
          x => x.name == "group-detail"
        ).paint = this.getMetricPointPaint(
          this.selectedMetric,
          this.selectedStop
        );
        change.find(
          x => x.name == "group-urban"
        ).paint = this.getMetricPointPaint(
          this.selectedMetric,
          this.selectedStop
        );
        change.find(x => x.name == "group-building").paint = {
          "fill-color": NO_DATA_MAP_COLOUR
        };
        change.find(
          x => x.name == "group-individual"
        ).paint = this.getMetricPointPaint(
          this.selectedMetric,
          this.selectedStop
        );
      } else {
        change.find(x => x.name == "group").paint = this.getMetricPaint(
          this.selectedMetric,
          this.selectedStop
        );
        change.find(x => x.name == "group-border").paint = this.getMetricPaint(
          this.selectedMetric,
          this.selectedStop,
          true
        );
        change.find(x => x.name == "group-detail").paint = this.getMetricPaint(
          this.selectedMetric,
          this.selectedStop
        );
        change.find(x => x.name == "group-urban").paint = this.getMetricPaint(
          this.selectedMetric,
          this.selectedStop
        );
        change.find(
          x => x.name == "group-building"
        ).paint = this.getMetricPaint(this.selectedMetric, this.selectedStop);
      }

      this.layers = change;
      this.updateHoverFeature(this.rawHoverFeatureData);
      this.updateUrl();
    },
    visibilityOfLayer(name) {
      return this.layers.find(x => x.name == name).layout.visibility;
    },
    setTitle(metricID) {
      if (metricID != null) {
        this.$route.query.m = this.metadata.metrics[metricID].field;
      }

      var route = this.$router.currentRoute;
      var nicepath = route.path;
      var queries = route.query;

      var out = [];
      for (var key in queries) {
        if (!["zoom", "lat", "lon", "d", "h"].includes(key)) {
          out.push(key + "=" + queries[key]);
        }
      }
      if (out.length > 0) {
        nicepath = nicepath + "?" + out.join("&");
      }

      if (metricID != null) {
        document.title =
          route.meta.title + " (" + this.metadata.metrics[metricID].name + ")";
      } else {
        document.title = route.meta.title;
      }

      if (process.env.NODE_ENV == "development") {
        console.log("Sending to GA4: " + nicepath + ", " + document.title);
      }
      this.$gtag.pageview({
        page_path: nicepath,
        page_title: document.title,
        page_location: window.location.href
      });
    },
    makeSources() {
      const sources = [];

      DATASOURCES.forEach(element => {
        sources.push({
          name: element.name,
          sql: element.sql[this.metadata.extent],
          url: element.url
        });
      });

      if (this.metadata.individualSql) {
        sources.push({
          name: "group-individual",
          sql: this.metadata.individualSql,
          url: this.metadata.url
        });
      }

      return [
        ...sources,
        { name: "group", sql: this.metadata.sql, url: this.metadata.url },
        {
          name: "group-building",
          sql: this.metadata.buildingSql,
          url: this.metadata.url
        },
        {
          name: "group-urban",
          sql: this.metadata.urbanSql,
          url: this.metadata.url
        },
        {
          name: "group-detail",
          sql: this.metadata.detailUrbanSql,
          url: this.metadata.url
        }
      ];
    },
    makeFilters() {
      let filters = {
        hierarchy: [],
        metrics: [],
        lad: { selected: null }
      };
      const params = this.$route.query;
      if (Object.prototype.hasOwnProperty.call(params, "f_lad")) {
        filters.lad.selected = params["f_lad"];
      }

      if (this.metadata.hierarchy) {
        this.metadata.hierarchy.forEach(h => {
          let hfilter = {
            selected: [],
            field: h.field
          };
          if (Object.prototype.hasOwnProperty.call(params, "f_h" + h.id + "")) {
            hfilter.selected = params["f_h" + h.id + ""].split("~");
          }
          filters.hierarchy.push(hfilter);
        });
      }
      if (this.metadata.metrics) {
        for (let i = 0; i < 10; i++) {
          if (Object.prototype.hasOwnProperty.call(params, "f_m" + i + "")) {
            const data = params["f_m" + i + ""].split("~");
            if (data.length == 3) {
              filters.metrics.push({
                id: i,
                field: data[0],
                type: data[1],
                value: data[2]
              });
            }
          }
        }
      }
      return filters;
    },
    makeLayerConfig(hierarchyID, metricID) {
      let dataVisibility = this.metadata.defaultLayers;
      const params = this.$route.query;
      if (
        Object.prototype.hasOwnProperty.call(params, "d") &&
        params.d.length == DATAGROUPS.length + 1
      ) {
        dataVisibility = params.d;
      }

      const filters = this.makeFilters();

      //replace land bit with true to always show a colour for land.  This bit is variable for buildings or mask etc
      const land = dataVisibility.slice(0, 1);
      dataVisibility = dataVisibility.substr(1);
      let groupPaint =
        hierarchyID != null
          ? this.getGroupPaint(hierarchyID)
          : metricID != null
          ? this.getMetricPaint(metricID, 0)
          : { "fill-color": NO_DATA_MAP_COLOUR };
      // make main layer transparent if land is set.
      groupPaint["fill-opacity"] = land == "1" ? 0 : 1;

      let groupBorderPaint =
        hierarchyID != null
          ? this.getGroupPaint(hierarchyID, true)
          : metricID != null
          ? this.getMetricPaint(metricID, 0, true)
          : { "line-color": NO_DATA_MAP_COLOUR };

      let filter = ["all"];
      filters.hierarchy.forEach((element, index) => {
        if (element.selected.length > 0) {
          let filterTerm = ["in", element.field];
          element.selected.forEach(selected => {
            let group = this.metadata.hierarchy[index].groups.find(
              x => x[this.metadata.hierarchy[index].lookup] == selected
            );
            filterTerm.push(group[this.metadata.hierarchy[index].lookup]);
          });
          filter.push(filterTerm);
        }
      });
      //set the lad filter - just where row equals the name
      if (filters.lad.selected && this.metadata.ladCodeField) {
        filter.push(["in", this.metadata.ladCodeField, filters.lad.selected]);
      }
      //set the metric filter.
      filters.metrics.forEach(element => {
        filter.push([element.type, element.field, parseFloat(element.value)]);
      });

      const urbanToBuildingTransition = 12;
      const urbanDetailTransition = 9;

      let layers = [];
      if (this.metadata.individualSql) {
        let opacity = ["step", ["zoom"], 1, urbanToBuildingTransition, 0];
        let groupPointPaint = "";
        if (metricID != null) {
          groupPointPaint = this.getMetricPointPaint(metricID, 0);
        } else {
          groupPointPaint = this.getGroupPointPaint(hierarchyID);
        }
        groupPointPaint["circle-opacity"] = land == "1" ? 0 : opacity;
        layers.push({
          name: "group",
          source: "group",
          group: "group",
          layout: { visibility: "visible" },
          paint: { ...groupPointPaint },
          type: "circle",
          filter: filter,
          layerBelow: "adhoc"
        });
      } else {
        layers.push({
          name: "group",
          source: "group",
          group: "group",
          layout: { visibility: "visible" },
          paint: { ...groupPaint },
          type: "fill",
          filter: filter,
          layerBelow: "adhoc"
        });
        layers.push({
          name: "group-border",
          source: "group",
          group: "group",
          layout: {
            visibility: this.metadata.border != null ? "visible" : "none"
          },
          paint: { ...groupBorderPaint },
          type: "line",
          filter: filter,
          layerBelow: "adhoc"
        });
      }
      /*
      layers.push({
        name: "groupoutline",
        source: "group",
        group: "group",
        layout: { visibility: "visible" },
        paint: { ...groupPaintOutline },
        type: "line",
        filter: filter,
        layerBelow: "adhoc"
      }); */

      //We "preload" layers for someone zooming in, at one zoom level above.
      if (this.metadata.buildingSql) {
        let opacity = ["step", ["zoom"], 0, urbanToBuildingTransition, 1];
        groupPaint["fill-opacity"] = opacity;
        if (this.metadata.individualSql) {
          layers.push({
            name: "group-building",
            source: "group-building",
            group: "group",
            layout: { visibility: land == "1" ? "visible" : "none" },
            paint: {
              "fill-opacity": opacity,
              "fill-color": NO_DATA_MAP_COLOUR
            },
            type: "fill",
            filter: filter,
            minzoom: urbanToBuildingTransition - 2,
            layerBelow: "adhoc"
          });
        } else {
          layers.push({
            name: "group-building",
            source: "group-building",
            group: "group",
            layout: { visibility: land == "1" ? "visible" : "none" },
            paint: { ...groupPaint },
            type: "fill",
            filter: filter,
            minzoom: urbanToBuildingTransition - 2,
            layerBelow: "adhoc"
          });
        }
      }
      if (this.metadata.detailUrbanSql) {
        let opacity = [
          "step",
          ["zoom"],
          0,
          urbanDetailTransition,
          1,
          urbanToBuildingTransition,
          0
        ];
        if (this.metadata.individualSql) {
          let groupPointPaint = "";
          if (metricID != null) {
            groupPointPaint = this.getMetricPointPaint(metricID, 0);
          } else {
            groupPointPaint = this.getGroupPointPaint(hierarchyID);
          }
          groupPointPaint["circle-opacity"] = opacity;
          layers.push({
            name: "group-detail",
            source: "group-detail",
            group: "group",
            layout: { visibility: land == "1" ? "visible" : "none" },
            paint: { ...groupPointPaint },
            type: "circle",
            filter: filter,
            minzoom: urbanDetailTransition - 1,
            maxzoom: urbanToBuildingTransition + 1,
            layerBelow: "adhoc"
          });
        } else {
          groupPaint["fill-opacity"] = opacity;
          layers.push({
            name: "group-detail",
            source: "group-detail",
            group: "group",
            layout: { visibility: land == "1" ? "visible" : "none" },
            paint: { ...groupPaint },
            type: "fill",
            filter: filter,
            minzoom: urbanDetailTransition - 1,
            maxzoom: urbanToBuildingTransition + 1,
            layerBelow: "adhoc"
          });
        }
      }
      if (this.metadata.urbanSql) {
        let opacity = ["step", ["zoom"], 1, urbanDetailTransition, 0];
        if (this.metadata.individualSql) {
          let groupPointPaint = "";
          if (metricID != null) {
            groupPointPaint = this.getMetricPointPaint(metricID, 0);
          } else {
            groupPointPaint = this.getGroupPointPaint(hierarchyID);
          }
          groupPointPaint["circle-opacity"] = opacity;
          layers.push({
            name: "group-urban",
            source: "group-urban",
            group: "group",
            layout: { visibility: land == "1" ? "visible" : "none" },
            paint: { ...groupPointPaint },
            type: "circle",
            filter: filter,
            minzoom: 4,
            maxzoom: urbanDetailTransition + 1,
            layerBelow: "adhoc"
          });
        } else {
          groupPaint["fill-opacity"] = opacity;
          layers.push({
            name: "group-urban",
            source: "group-urban",
            group: "group",
            layout: { visibility: land == "1" ? "visible" : "none" },
            paint: { ...groupPaint },
            type: "fill",
            filter: filter,
            minzoom: 4,
            maxzoom: urbanDetailTransition + 1,
            layerBelow: "adhoc"
          });
        }
      }

      //We don't preload this, because it is very computationally intensive.
      if (this.metadata.individualSql) {
        let groupPointPaint = "";
        if (metricID != null) {
          groupPointPaint = this.getMetricPointPaint(metricID, 0);
        } else {
          groupPointPaint = this.getGroupPointPaint(hierarchyID);
        }
        groupPointPaint["circle-opacity"] = [
          "step",
          ["zoom"],
          0,
          urbanToBuildingTransition,
          1
        ];
        layers.push({
          name: "group-individual",
          source: "group-individual",
          group: "group",
          layout: { visibility: land == "1" ? "visible" : "none" },
          paint: { ...groupPointPaint },
          type: "circle",
          filter: filter,
          minzoom: urbanToBuildingTransition,
          layerBelow: "adhoc"
        });
      }
      const labels = dataVisibility[DATAGROUPS.indexOf("name")] == "1";

      ADDITIONALLAYERS.forEach(element => {
        let visibility = "visible";

        if (DATAGROUPS.includes(element.group)) {
          visibility =
            dataVisibility[DATAGROUPS.indexOf(element.group)] == "1"
              ? "visible"
              : "none";
          if (element.label && !labels) {
            visibility = "none";
          }
        }

        if (element.ladCodeField) {
          filter = element.filter ? element.filter : ["all"];
          if (filters.lad.selected) {
            filter.push(["in", element.ladCodeField, filters.lad.selected]);
          }
        }

        layers.push({
          name: element.name,
          paint: element.paint,
          source: element.source,
          minzoom: element.minzoom,
          maxzoom: element.maxzoom,
          group: element.group,
          type: element.type,
          filter: element.filter,
          label: element.label,
          layout: {
            ...element.layout,
            visibility: visibility
          },
          layerBelow: element.base ? "group" : "adhoc"
        });
      });

      return layers;
    }
  }
};
</script>

<style scoped>
.map-container {
  position: absolute;
  bottom: 0;
  top: 60px;
  width: 100%;
}

.map-container-embed {
  top: 0;
}

.carto-logo {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.attributions {
  position: relative;
  top: 50px;
  left: 50px;
  z-index: 2;
}
</style>
