import colorbrewer from "colorbrewer";

const cols =
  "the_geom_webmercator, lsoa11_cd, lsoa11_nm, lad_code, " +
  "eneleav20, enelemd20, engasav20, engasmd20, enepcmo22n, elpvfitc";

var ramp = colorbrewer.RdYlBu[7];

const legendStopsElectricity = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: ramp[6], name: "Under 2000 kWh/yr" },
      { value: 2000, colour: ramp[5], name: "2000-2400 kWh/yr" },
      { value: 2400, colour: ramp[4], name: "2400-2800 kWh/yr" },
      { value: 2800, colour: ramp[3], name: "2800-3200 kWh/yr" },
      { value: 3200, colour: ramp[2], name: "3200-3600 kWh/yr" },
      { value: 3600, colour: ramp[1], name: "3600-4000 kWh/yr" },
      { value: 4000, colour: ramp[0], name: "Over 4000 kWh/yr" }
    ]
  }
];

const legendStopsGas = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: ramp[6], name: "Under 10000 kWh/yr" },
      { value: 10000, colour: ramp[5], name: "10-12000 kWh/yr" },
      { value: 12000, colour: ramp[4], name: "12-14000 kWh/yr" },
      { value: 14000, colour: ramp[3], name: "14-16000 kWh/yr" },
      { value: 16000, colour: ramp[2], name: "16-18000 kWh/yr" },
      { value: 18000, colour: ramp[1], name: "18-20000 kWh/yr" },
      { value: 20000, colour: ramp[0], name: "Over 20000 kWh/yr" }
    ]
  }
];

ramp = colorbrewer.YlOrRd[7];

const legendStopsSolar = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#ffffff", name: "No installations" },
      { value: 0, colour: ramp[0], name: "Under 50 kW" },
      { value: 50, colour: ramp[1], name: "50-100 kW" },
      { value: 100, colour: ramp[2], name: "100-150 kW" },
      { value: 150, colour: ramp[3], name: "150-200 kW" },
      { value: 200, colour: ramp[4], name: "200-250 kW" },
      { value: 250, colour: ramp[5], name: "250-300 kW" },
      { value: 300, colour: ramp[6], name: "Over 300 kW" }
    ]
  }
];

const rampEPC = colorbrewer.PiYG[9];

const legendStopsEPC = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 1, colour: rampEPC[8], name: "A" },
      { value: 2, colour: rampEPC[7], name: "B" },
      { value: 3, colour: rampEPC[6], name: "C" },
      { value: 4, colour: rampEPC[4], name: "D" },
      { value: 5, colour: rampEPC[1], name: "E" },
      { value: 6, colour: rampEPC[0], name: "F" },
      { value: 7, colour: rampEPC[0], name: "G" }
    ]
  }
];

export const ENERGY = {
  title: "Energy Usage and Performance",
  description:
    "Domestic energy meter average usage/generation and modal domestic dwelling EPC rating",
  downloadLink: "",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM lsoa11_uk",
  buildingSql: "SELECT " + cols + " FROM lsoa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM lsoa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM lsoa11_uk_urban",
  defaultLayers: "1111000",
  extent: 0,
  popup: false,
  idField: "lsoa11_cd",
  geogNameField: "lsoa11_nm",
  ladCodeField: "lad_code",
  hoverFieldMetricMode: "Some",
  metrics: [
    {
      id: 0,
      name: "Median Electric Usage per Meter 2020",
      field: "enelemd20",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsElectricity,
      additionalMetrics: [0, 1]
    },
    {
      id: 1,
      name: "Median Gas Usage per Meter 2020",
      field: "engasmd20",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsGas,
      additionalMetrics: [0, 1]
    },
    {
      id: 2,
      name: "Solar Panel Power (Domestic PV FIT kW)",
      field: "elpvfitc",
      decimals: 0,
      extent: 3,
      legendOptions: [{ id: 1, name: "Name" }],
      legendStops: legendStopsSolar,
      additionalMetrics: [2]
    },
    {
      id: 3,
      name: "Modal EPC Rating (to 2022)",
      field: "enepcmo22n",
      decimals: 0,
      extent: 3,
      legendOptions: [{ id: 1, name: "Name" }],
      legendStops: legendStopsEPC,
      additionalMetrics: []
    }
  ]
};
