import colorbrewer from "colorbrewer";

const cols =
  "the_geom_webmercator, lsoa11_cd, lsoa11_nm, lad_code, " +
  "dwe_modbp1, dwe_p45pc, dwe_p16pc, dwe_mdargb, hpmd202003, hpmd202103";

const legendStops = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: "#333333", name: "Less than 50%" },
      { value: 0.5, colour: "#A10100", name: "50-75%" },
      { value: 0.75, colour: "#DA1F05", name: "75%-90%" },
      { value: 0.9, colour: "#FFF75D", name: "90% or more" }
    ]
  }
];

const legendStops2 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: "#333333", name: "Less than 10%" },
      { value: 0.1, colour: "#A10100", name: "10-30%" },
      { value: 0.3, colour: "#FFF75D", name: "30% or more" }
    ]
  }
];

const legendStops3 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: "rgb(0,0,255)", name: "All Pre-1900", visible: true },
      {
        value: 1,
        colour: "rgb(0,64,255)",
        name: "All 1900-1918",
        visible: true
      },
      {
        value: 2,
        colour: "rgb(0,128,255)",
        name: "All 1919-1929",
        visible: true
      },
      {
        value: 3,
        colour: "rgb(0,192,255)",
        name: "All 1930-1939",
        visible: true
      },
      {
        value: 4,
        colour: "rgb(0,255,192)",
        name: "All 1945-1954",
        visible: true
      },
      {
        value: 5,
        colour: "rgb(0,255,0)",
        name: "All 1955-1964",
        visible: true
      },
      {
        value: 6,
        colour: "rgb(192,255,0)",
        name: "All 1965-1972",
        visible: true
      },
      {
        value: 7,
        colour: "rgb(255,255,0)",
        name: "All 1973-1982",
        visible: true
      },
      {
        value: 8,
        colour: "rgb(255,192,0)",
        name: "All 1983-1992",
        visible: true
      },
      {
        value: 9,
        colour: "rgb(255,128,0)",
        name: "All 1993-1999",
        visible: true
      },
      {
        value: 10,
        colour: "rgb(255,64,0)",
        name: "All 2000-2008",
        visible: true
      },
      {
        value: 11,
        colour: "rgb(255,0,0)",
        name: "All Post-2009",
        visible: true
      },
      {
        value: 12,
        colour: "rgb(255,255,255)",
        name: "All Unknown",
        visible: true
      }
    ]
  }
];

const rampHP = colorbrewer.YlOrRd[9];

const legendStopsHP = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data" },
      { value: 0, colour: rampHP[0], name: "Under £75k" },
      { value: 75000, colour: rampHP[1], name: "£75k-£150k" },
      { value: 150000, colour: rampHP[2], name: "£150k-£225k" },
      { value: 225000, colour: rampHP[3], name: "£225k-£300k" },
      { value: 300000, colour: rampHP[4], name: "£300k-£400k" },
      { value: 400000, colour: rampHP[5], name: "£400k-£500k" },
      { value: 500000, colour: rampHP[6], name: "£500k-£600k" },
      { value: 600000, colour: "#cd1126", name: "£600k-£700k" },
      { value: 700000, colour: "#9c0026", name: "£700k-£800k" },
      { value: 800000, colour: "#6c0020", name: "£800k-£900k" },
      { value: 900000, colour: "#3c0016", name: "£900k-£1m" },
      { value: 1000000, colour: "#000000", name: "£1m-£3m" },
      { value: 3000000, colour: "#ff00ff", name: "£3m and over" }
    ]
  }
];

const groups = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#888888", name: "No data", visible: true },
      { value: 65, colour: "#333333", name: "Pre-1900", visible: true },
      { value: 66, colour: "#313695", name: "1900-1918", visible: true },
      { value: 67, colour: "#4575b4", name: "1919-1929", visible: true },
      { value: 68, colour: "#74add1", name: "1930-1939", visible: true },
      { value: 69, colour: "#abd9e9", name: "1945-1954", visible: true },
      { value: 70, colour: "#e0f3f8", name: "1955-1964", visible: true },
      { value: 71, colour: "#ffffbf", name: "1965-1972", visible: true },
      { value: 72, colour: "#fee090", name: "1973-1982", visible: true },
      { value: 73, colour: "#fdae61", name: "1983-1992", visible: true },
      { value: 74, colour: "#f46d43", name: "1993-1999", visible: true },
      { value: 75, colour: "#d73027", name: "2000-2008", visible: true },
      { value: 76, colour: "#a50026", name: "Post-2009", visible: true },
      { value: 77, colour: "rgb(255,255,255)", name: "Unknown", visible: true } //,
      //{ id: "X", colour: "rgb(255,255,255)", name: "Various", visible: true }
    ]
  }
];

/*
const rampHPChange = colorbrewer.RdYlBu[9]

const legendStopsHPChange = [
 {
    id: 0,
    stops: [
      { value: -999999, colour: '#aaaaaa', name: "N/A" },
      { value: -999998, colour: rampHPChange[0], name: "40%+ fall or now N/A" },
      { value: -0.4, colour: rampHPChange[1], name: "30-40% fall" },
      { value: -0.3, colour: rampHPChange[2], name: "20-30% fall" },
      { value: -0.2, colour: rampHPChange[3], name: "10-20% fall" },
      { value: -0.1, colour: rampHPChange[4], name: "<10% change" },
      { value: 0.1, colour: rampHPChange[5], name: "10-20% rise" },
      { value: 0.2, colour: rampHPChange[6], name: "20-30% rise" },
      { value: 0.3, colour: rampHPChange[7], name: "30-40% rise" },
      { value: 0.4, colour: rampHPChange[8], name: "40%+ rise or was N/A" }
    ]
  }
];
*/

export const DWELLINGAGESPRICES = {
  title: "Dwelling Ages and Prices",
  description:
    "Proportion of properties built after key eras, colour indexed build period average and median house prices.",
  downloadLink: "https://data.cdrc.ac.uk/dataset/dwelling-ages-and-prices",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM lsoa11_uk",
  buildingSql: "SELECT " + cols + " FROM lsoa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM lsoa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM lsoa11_uk_urban",
  defaultLayers: "1111000",
  extent: 3,
  popup: false,
  idField: "lsoa11_cd",
  geogNameField: "lsoa11_nm",
  ladCodeField: "lad_code",
  hoverFieldMetricMode: "All",
  metrics: [
    {
      id: 0,
      name: "Post 1945",
      field: "dwe_p45pc",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops
    },
    {
      id: 1,
      name: "Post 2016",
      field: "dwe_p16pc",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops2
    },
    {
      id: 2,
      name: "Colour Weighted Average",
      field: "dwe_mdargb",
      display: "Colour",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStops3
    },
    {
      id: 3,
      name: "Modal Age Band",
      field: "dwe_modbp1",
      display: "Stop Name",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: groups
    },
    {
      id: 4,
      name: "Median House Price 1YE Mar-21",
      field: "hpmd202103",
      display: "£",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsHP
    },
    {
      id: 5,
      name: "Median House Price 1YE Mar-20",
      field: "hpmd202003",
      display: "£",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsHP
    } /*
    {
      id: 5,
      name: "Median House Price Change 2020-21",
      field: "hpmd202103",
	  compField: "hpmd202003",
      denomField: "hpmd202003",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsHPChange
    }, */
  ]
};
