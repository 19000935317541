<template>
  <div id="app">
    <map-nav />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import MapNav from "@/components/MapNav.vue";

export default {
  components: {
    MapNav
  }
};
</script>

<style lang="scss">
.maplibregl-ctrl-scale {
  color: white;
  border-color: white;
  background-color: rgba(0, 0, 0, 0);
  font-family: "Lexend Deca", sans-serif;
  font-weight: bold;
}

@font-face {
  font-family: "Museo";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: local("Museo"), local("Museo500-Regular"),
    url("assets/fonts/Museo500-Regular.woff") format("woff");
}

@font-face {
  font-family: "font_icon_select_1";
  src: url("assets/fonts/dkan-topics.eot");
  src: url("assets/fonts/dkan-topics.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/dkan-topics.woff") format("woff"),
    url("assets/fonts/dkan-topics.ttf") format("truetype"),
    url("assets/fonts/dkan-topics.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.font-icon-select-1 {
  font-family: "font_icon_select_1";
}

.font-icon-select-1-e965:before {
  content: "\e965";
}

.font-icon-select-1-e91f:before {
  content: "\e91f";
}

.font-icon-select-1-e977:before {
  content: "\e977";
}

.font-icon-select-1-e908:before {
  content: "\e908";
}

.font-icon-select-1-e92a:before {
  content: "\e92a";
}
</style>
