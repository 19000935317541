import colorbrewer from "colorbrewer";

const cols =
  "the_geom_webmercator, lsoa11_cd, lsoa11_nm, lad_code, " +
  "c1, c2, c3, c4, c5, c6, ep2008, ep2009, ep2010, ep2011, ep2012, ep2013, ep2014, " +
  "ep2015, ep2016, n_60pl2010, n_60pl2011, n_60pl2012, n_60pl2013, n_60pl2014, " +
  "n_60pl2015, n_60pl2016, n_disa2010, n_disa2011, n_disa2012, n_disa2013, n_disa2014, " +
  "n_disa2015, n_disa2016";

const ramp1 = colorbrewer.Greens[9];

const legendStopsC1 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp1[0], name: "Under 40%" },
      { value: 0.4, colour: ramp1[1], name: "40-45%" },
      { value: 0.45, colour: ramp1[2], name: "45-50%" },
      { value: 0.5, colour: ramp1[3], name: "50-55%" },
      { value: 0.55, colour: ramp1[4], name: "55-60%" },
      { value: 0.6, colour: ramp1[5], name: "60-65%" },
      { value: 0.65, colour: ramp1[6], name: "65-70%" },
      { value: 0.7, colour: ramp1[7], name: "70-75%" },
      { value: 0.75, colour: ramp1[8], name: "Over 75%" }
    ]
  }
];

const ramp2 = colorbrewer.BuPu[9];

const legendStopsC2 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp2[0], name: "None" },
      { value: 0.0001, colour: ramp2[1], name: "Under 5%" },
      { value: 0.05, colour: ramp2[2], name: "5-10%" },
      { value: 0.1, colour: ramp2[3], name: "10-15%" },
      { value: 0.15, colour: ramp2[4], name: "15-20%" },
      { value: 0.2, colour: ramp2[5], name: "20-25%" },
      { value: 0.25, colour: ramp2[6], name: "25-30%" },
      { value: 0.3, colour: ramp2[7], name: "30-35%" },
      { value: 0.35, colour: ramp2[8], name: "Over 35%" }
    ]
  }
];

const ramp3 = colorbrewer.OrRd[9];

const legendStopsC3 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp3[0], name: "None" },
      { value: 0.0001, colour: ramp3[1], name: "Under 2.5%" },
      { value: 0.025, colour: ramp3[2], name: "2.5-5%" },
      { value: 0.05, colour: ramp3[3], name: "5-7.5%" },
      { value: 0.075, colour: ramp3[4], name: "7.5-10%" },
      { value: 0.1, colour: ramp3[5], name: "10-12.5%" },
      { value: 0.125, colour: ramp3[6], name: "12.5-15%" },
      { value: 0.15, colour: ramp3[7], name: "15-17.5%" },
      { value: 0.175, colour: ramp3[8], name: "Over 17.5%" }
    ]
  }
];

const ramp4 = colorbrewer.PuRd[9];

const legendStopsC4 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp4[0], name: "None" },
      { value: 0.0001, colour: ramp4[1], name: "Under 2.5%" },
      { value: 0.025, colour: ramp4[2], name: "2.5-5%" },
      { value: 0.05, colour: ramp4[3], name: "5-7.5%" },
      { value: 0.075, colour: ramp4[4], name: "7.5-10%" },
      { value: 0.1, colour: ramp4[5], name: "10-12.5%" },
      { value: 0.125, colour: ramp4[6], name: "12.5-15%" },
      { value: 0.15, colour: ramp4[7], name: "15-17.5%" },
      { value: 0.175, colour: ramp4[8], name: "Over 17.5%" }
    ]
  }
];

const ramp5 = colorbrewer.Reds[9];

const legendStopsC5 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp5[0], name: "None" },
      { value: 0.0001, colour: ramp5[1], name: "Under 2%" },
      { value: 0.02, colour: ramp5[2], name: "2-4%" },
      { value: 0.04, colour: ramp5[3], name: "4-6%" },
      { value: 0.06, colour: ramp5[4], name: "6-8%" },
      { value: 0.08, colour: ramp5[5], name: "8-10%" },
      { value: 0.1, colour: ramp5[6], name: "10-12%" },
      { value: 0.12, colour: ramp5[7], name: "12-14%" },
      { value: 0.14, colour: ramp5[8], name: "Over 14%" }
    ]
  }
];

const ramp6 = colorbrewer.Blues[9];

const legendStopsC6 = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp6[0], name: "None" },
      { value: 0.0001, colour: ramp6[1], name: "Under 5%" },
      { value: 0.05, colour: ramp6[2], name: "5-10%" },
      { value: 0.1, colour: ramp6[3], name: "10-15%" },
      { value: 0.15, colour: ramp6[4], name: "15-20%" },
      { value: 0.2, colour: ramp6[5], name: "20-25%" },
      { value: 0.25, colour: ramp6[6], name: "25-30%" },
      { value: 0.3, colour: ramp6[7], name: "30-35%" },
      { value: 0.35, colour: ramp6[8], name: "Over 35%" }
    ]
  }
];

const rampEle = colorbrewer.Blues[9];

const legendStopsEle = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: rampEle[0], name: "Less than 50" },
      { value: 50, colour: rampEle[1], name: "50-100" },
      { value: 100, colour: rampEle[2], name: "100-150" },
      { value: 150, colour: rampEle[3], name: "150-200" },
      { value: 200, colour: rampEle[4], name: "200-250" },
      { value: 250, colour: rampEle[5], name: "250-300" },
      { value: 300, colour: rampEle[6], name: "300-350" },
      { value: 350, colour: rampEle[7], name: "350-400" },
      { value: 400, colour: rampEle[8], name: "Over 400" }
    ]
  }
];

const rampEleChg = colorbrewer.RdBu[9];

const legendStopsEleChg = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: rampEleChg[0], name: "30%+ fall" },
      { value: -0.3, colour: rampEleChg[1], name: "20-30% fall" },
      { value: -0.2, colour: rampEleChg[2], name: "10-20% fall" },
      { value: -0.1, colour: rampEleChg[3], name: "3-1% fall" },
      { value: -0.03, colour: rampEleChg[4], name: "<3% change" },
      { value: 0.03, colour: rampEleChg[5], name: "3-10% rise" },
      { value: 0.1, colour: rampEleChg[6], name: "10-20% rise" },
      { value: 0.2, colour: rampEleChg[7], name: "20-30% rise" },
      { value: 0.3, colour: rampEleChg[8], name: "30%+ rise" }
    ]
  }
];

const ramp60p = colorbrewer.Greens[9];

const legendStops60p = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: ramp60p[0], name: "Less than 500" },
      { value: 500, colour: ramp60p[1], name: "500-1000" },
      { value: 1000, colour: ramp60p[2], name: "1000-1500" },
      { value: 1500, colour: ramp60p[3], name: "1500-2000" },
      { value: 2000, colour: ramp60p[4], name: "2000-2500" },
      { value: 2500, colour: ramp60p[5], name: "2500-3000" },
      { value: 3000, colour: ramp60p[6], name: "3000-3500" },
      { value: 3500, colour: ramp60p[7], name: "3500-4000" },
      { value: 4000, colour: ramp60p[8], name: "Over 4000" }
    ]
  }
];

const ramp60pChg = colorbrewer.PiYG[9];

const legendStops60pChg = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: -999998, colour: ramp60pChg[0], name: "40%+ fall" },
      { value: -0.4, colour: ramp60pChg[1], name: "30-40% fall" },
      { value: -0.3, colour: ramp60pChg[2], name: "20-30% fall" },
      { value: -0.2, colour: ramp60pChg[3], name: "10-20% fall" },
      { value: -0.1, colour: ramp60pChg[4], name: "<10% change" },
      { value: 0.1, colour: ramp60pChg[5], name: "10-20% rise" },
      { value: 0.2, colour: ramp60pChg[6], name: "20-30% rise" },
      { value: 0.3, colour: ramp60pChg[7], name: "30-40% rise" },
      { value: 0.4, colour: ramp60pChg[8], name: "40%+ rise" }
    ]
  }
];

const rampDis = colorbrewer.Purples[9];

const legendStopsDis = [
  {
    id: 0,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      { value: 0, colour: rampDis[0], name: "Less than 100" },
      { value: 100, colour: rampDis[1], name: "100-200" },
      { value: 200, colour: rampDis[2], name: "200-300" },
      { value: 300, colour: rampDis[3], name: "300-400" },
      { value: 400, colour: rampDis[4], name: "400-500" },
      { value: 500, colour: rampDis[5], name: "500-600" },
      { value: 600, colour: rampDis[6], name: "600-700" },
      { value: 700, colour: rampDis[7], name: "700-800" },
      { value: 800, colour: rampDis[8], name: "Over 800" }
    ]
  }
];

const rampDisChg = colorbrewer.PuOr[9];

const legendStopsDisChg = [
  {
    id: 1,
    stops: [
      { value: -999999, colour: "#aaaaaa", name: "No data" },
      {
        value: -999998,
        colour: rampDisChg[0],
        name: "40%+ fall or now No data"
      },
      { value: -0.4, colour: rampDisChg[1], name: "30-40% fall" },
      { value: -0.3, colour: rampDisChg[2], name: "20-30% fall" },
      { value: -0.2, colour: rampDisChg[3], name: "10-20% fall" },
      { value: -0.1, colour: rampDisChg[4], name: "<10% change" },
      { value: 0.1, colour: rampDisChg[5], name: "10-20% rise" },
      { value: 0.2, colour: rampDisChg[6], name: "20-30% rise" },
      { value: 0.3, colour: rampDisChg[7], name: "30-40% rise" },
      { value: 0.4, colour: rampDisChg[8], name: "40%+ rise or was No data" }
    ]
  }
];

export const WMMOBILITY = {
  title: "West Midlands Mobility and Travel Behaviour",
  description:
    "Boarding counts, and proportion of eligible ENCTS registered population assigned to each of 6 travel behaviour clusters, in each LSOA.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/west-midlands-accessibility-and-travel-passes",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM lsoa11_wm",
  buildingSql: "SELECT " + cols + " FROM lsoa11_wm_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM lsoa11_wm_detail_urban",
  urbanSql: "SELECT " + cols + " FROM lsoa11_wm_urban",
  defaultLayers: "1111000",
  extent: 5,
  popup: false,
  idField: "lsoa11_cd",
  geogNameField: "lsoa11_nm",
  ladCodeField: "lad_code",
  hoverFieldMetricMode: "MetricOnly",
  // prettier-ignore
  metrics: [

    {
      id: 0,
      name: "C1: Rarely seen",
      field: "c1",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsC1,
      additionalMetrics: [1, 2, 3, 4, 5]
    },
    {
      id: 1,
      name: "C2: Irregular",
      field: "c2",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsC2,
      additionalMetrics: [0, 2, 3, 4, 5]
    },
    {
      id: 2,
      name: "C3: Regularly seen",
      field: "c3",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsC3,
      additionalMetrics: [0, 1, 3, 4, 5]
    },
    {
      id: 3,
      name: "C4: Withdrawing",
      field: "c4",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsC4,
      additionalMetrics: [0, 1, 2, 4, 5]
    },
    {
      id: 4,
      name: "C5: Withdrawing (post 2014)",
      field: "c5",
      display: "Percentage",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsC5,
      additionalMetrics: [0, 1, 2, 3, 5]
    },
    {
      id: 5,
      name: "C6: Daily",
      field: "c6",
      display: "%",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Value" }],
      legendStops: legendStopsC6,
      additionalMetrics: [0, 1, 2, 3, 4]
    },
    { id: 6, name: "ENCTS Eligib. 2008", field: "ep2008", decimals: 0, legendStops: legendStopsEle, legendOptions: [], additionalMetrics: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
    { id: 7, name: "ENCTS Eligib. 2009", field: "ep2009", decimals: 0, legendStops: legendStopsEle, legendOptions: [], additionalMetrics: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
    { id: 8, name: "ENCTS Eligib. 2010", field: "ep2010", decimals: 0, legendStops: legendStopsEle, legendOptions: [], additionalMetrics: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
    { id: 9, name: "ENCTS Eligib. 2011", field: "ep2011", decimals: 0, legendStops: legendStopsEle, legendOptions: [], additionalMetrics: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
    { id: 10, name: "ENCTS Eligib. 2012", field: "ep2012", decimals: 0, legendStops: legendStopsEle, legendOptions: [], additionalMetrics: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
    { id: 11, name: "ENCTS Eligib. 2013", field: "ep2013", decimals: 0, legendStops: legendStopsEle, legendOptions: [], additionalMetrics: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
    { id: 12, name: "ENCTS Eligib. 2014", field: "ep2014", decimals: 0, legendStops: legendStopsEle, legendOptions: [], additionalMetrics: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
    { id: 13, name: "ENCTS Eligib. 2015", field: "ep2015", decimals: 0, legendStops: legendStopsEle, legendOptions: [], additionalMetrics: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
    { id: 14, name: "ENCTS Eligib. 2016", field: "ep2016", decimals: 0, legendStops: legendStopsEle, legendOptions: [], additionalMetrics: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
    { 
		id: 15, 
		name: "ENCTS Eligib. Change 2008-16", 
		field: "ep2016.chg", 
		compField: "ep2008", 
		display: "Percentage",
		denomField: "ep2008",
 		decimals: 0, 
		legendStops: legendStopsEleChg, 
		legendOptions: [], 
		additionalMetrics: [6, 7, 8, 9, 10, 11, 12, 13, 14] 
	},
    { id: 16, name: "60+ Board # 2010", field: "n_60pl2010", decimals: 0, legendStops: legendStops60p, legendOptions: [], additionalMetrics: [16, 17, 18, 19, 20, 21, 22, 23] },
    { id: 17, name: "60+ Board # 2011", field: "n_60pl2011", decimals: 0, legendStops: legendStops60p, legendOptions: [], additionalMetrics: [16, 17, 18, 19, 20, 21, 22, 23] },
    { id: 18, name: "60+ Board # 2012", field: "n_60pl2012", decimals: 0, legendStops: legendStops60p, legendOptions: [], additionalMetrics: [16, 17, 18, 19, 20, 21, 22, 23] },
    { id: 19, name: "60+ Board # 2013", field: "n_60pl2013", decimals: 0, legendStops: legendStops60p, legendOptions: [], additionalMetrics: [16, 17, 18, 19, 20, 21, 22, 23] },
    { id: 20, name: "60+ Board # 2014", field: "n_60pl2014", decimals: 0, legendStops: legendStops60p, legendOptions: [], additionalMetrics: [16, 17, 18, 19, 20, 21, 22, 23] },
    { id: 21, name: "60+ Board # 2015", field: "n_60pl2015", decimals: 0, legendStops: legendStops60p, legendOptions: [], additionalMetrics: [16, 17, 18, 19, 20, 21, 22, 23] },
    { id: 22, name: "60+ Board # 2016", field: "n_60pl2016", decimals: 0, legendStops: legendStops60p, legendOptions: [], additionalMetrics: [16, 17, 18, 19, 20, 21, 22, 23] },
    { 
		id: 23, 
		name: "60+ Board # Change 2010-16", 
		field: "n_60pl2016.chg", 
		compField: "n_60pl2010", 
		display: "Percentage",
		denomField: "n_60pl2010",
 		decimals: 0, 
		legendStops: legendStops60pChg, 
		legendOptions: [], 
		additionalMetrics: [16, 17, 18, 19, 20, 21, 22] 
	},
    { id: 24, name: "Disb Board # 2010", field: "n_disa2010", decimals: 0, legendStops: legendStopsDis, legendOptions: [], additionalMetrics: [24, 25, 26, 27, 28, 29, 30, 31] },
    { id: 25, name: "Disb Board # 2011", field: "n_disa2011", decimals: 0, legendStops: legendStopsDis, legendOptions: [], additionalMetrics: [24, 25, 26, 27, 28, 29, 30, 31] },
    { id: 36, name: "Disb Board # 2012", field: "n_disa2012", decimals: 0, legendStops: legendStopsDis, legendOptions: [], additionalMetrics: [24, 25, 26, 27, 28, 29, 30, 31] },
    { id: 27, name: "Disb Board # 2013", field: "n_disa2013", decimals: 0, legendStops: legendStopsDis, legendOptions: [], additionalMetrics: [24, 25, 26, 27, 28, 29, 30, 31] },
    { id: 28, name: "Disb Board # 2014", field: "n_disa2014", decimals: 0, legendStops: legendStopsDis, legendOptions: [], additionalMetrics: [24, 25, 26, 27, 28, 29, 30, 31] },
    { id: 29, name: "Disb Board # 2015", field: "n_disa2015", decimals: 0, legendStops: legendStopsDis, legendOptions: [], additionalMetrics: [24, 25, 26, 27, 28, 29, 30, 31] },
    { id: 30, name: "Disb Board # 2016", field: "n_disa2016", decimals: 0, legendStops: legendStopsDis, legendOptions: [], additionalMetrics: [24, 25, 26, 27, 28, 29, 30, 31] },
    { 
		id: 31, 
		name: "Disb Board # Change 2010-16", 
		field: "n_disa2016.chg", 
		compField: "n_disa2010", 
		display: "Percentage",
		denomField: "n_disa2010",
 		decimals: 0, 
		legendStops: legendStopsDisChg, 
		legendOptions: [], 
		additionalMetrics: [24, 25, 26, 27, 28, 29, 30] 
	},
  ]
};
