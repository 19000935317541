const cols = "the_geom_webmercator, gridcode, nt_id";

export const NEIGHBOURHOODTRAJECTORIES = {
  title: "Neighbourhood Trajectories",
  description:
    "A classification of neighbourhood trajectories - a sequence of transitions of each neighbourhood (1km grid) between neighbourhood type at each census year as defined by Patias, Rowe and Cavazzi (2018). Based on temporally-and geographically-consistent 1km gridded sets of population counts, encompassing demographic, socioeconomic and housing attributes, from the Censuses Britain conducted in 1971, 1981, 1991, 2001 and 2011.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/neighbourhood-trajectories-great-britain-1971-2011",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",

  sql: "SELECT " + cols + " FROM popgrid where nt_id is not null",
  buildingSql: "SELECT " + cols + " FROM popgrid where nt_id is not null",
  detailUrbanSql: "SELECT " + cols + " FROM popgrid where nt_id is not null",
  urbanSql: "SELECT " + cols + " FROM popgrid where nt_id is not null",
  defaultLayers: "1111000",
  extent: 0,
  popup: true,
  popupType: "classification",
  idField: "gridcode",
  geogNameField: "gridcode",
  geogNamePrefix: "OpenPopGrid ID ",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.nt_id = '1' then 1 else 0 end) as count_1,
      sum(case when d.nt_id = '2' then 1 else 0 end) as count_2,
      sum(case when d.nt_id = '3' then 1 else 0 end) as count_3,
      sum(case when d.nt_id = '4' then 1 else 0 end) as count_4,
      sum(case when d.nt_id = '5' then 1 else 0 end) as count_5,
      sum(case when d.nt_id = '6' then 1 else 0 end) as count_6,
      sum(case when d.nt_id = '7' then 1 else 0 end) as count_7
  FROM oliverobrien.popgrid d 
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Category",
      field: "nt_id",
      lookup: "id",
      groups: [
        {
          id: "1",
          name: "Upwarding thriving",
          colour: "#9fd65c",
          visible: true,
          description:
            "Areas transitioning from an older striving to, or remaining in, a thriving suburban type"
        },
        {
          id: "2",
          name: "Increasing socioeconomic diversity",
          colour: "#cb6fb9",
          visible: true,
          description:
            "Areas transitioning from a struggling or blue collar families to a mixed workers suburban type"
        },
        {
          id: "3",
          name: "Increasing struggling home-owners",
          colour: "#f71924",
          visible: true,
          description:
            "Areas transitioning from a families in council rent to a struggling type"
        },
        {
          id: "4",
          name: "Rejuvenating",
          colour: "#65d2cd",
          visible: true,
          description:
            "Areas transitioning from an older striving to a mixed workers suburban type"
        },
        {
          id: "5",
          name: "Stable affluent",
          colour: "#3b8222",
          visible: true,
          description:
            "Areas remaining persistently affluent over 1971 and 2011."
        },
        {
          id: "6",
          name: "Ageing manual labour",
          colour: "#2c2ce6",
          visible: true,
          description:
            "Areas transitioning from being dominated by blue collar families to an older striving neighbourhood type"
        },
        {
          id: "7",
          name: "Stable multicultural urban",
          colour: "#d68c3c",
          visible: true,
          description: "Areas remaining multicultural in urban locations"
        }
      ]
    }
  ]
};
