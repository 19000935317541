const cols =
  "the_geom_webmercator, msoa11_cd, msoa11_nm, lic2002, lic2007, lic2012";

import colorbrewer from "colorbrewer";

const ramp = colorbrewer.PiYG[11];

const legendStops = [
  {
    id: 0,
    stops: [
      { value: 0, colour: ramp[0], name: "Under 5 km" },
      { value: 5, colour: ramp[1], name: "5-10 km" },
      { value: 10, colour: ramp[2], name: "10-20 km" },
      { value: 20, colour: ramp[3], name: "20-30 km" },
      { value: 30, colour: ramp[4], name: "30-40 km" },
      { value: 40, colour: ramp[5], name: "40-50 km" },
      { value: 50, colour: ramp[6], name: "50-70 km" },
      { value: 70, colour: ramp[7], name: "70-100 km" },
      { value: 100, colour: ramp[8], name: "100-200 km" },
      { value: 200, colour: ramp[9], name: "200-500 km" },
      { value: 500, colour: ramp[10], name: "500 km+" }
    ]
  }
];

export const LIC = {
  title: "Local Internet Content",
  description:
    "Radius of gyration (RG) of local internet content. Lower RG areas tend to host websites mainly focused on local interest. Higher RG areas have more nationally-focused websites.",
  downloadLink: "https://data.cdrc.ac.uk/dataset/local-internet-content",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM msoa11_gb",
  buildingSql: "SELECT " + cols + " FROM msoa11_gb_detail_urban",
  detailUrbanSql: "SELECT " + cols + " FROM msoa11_gb_detail_urban",
  urbanSql: "SELECT " + cols + " FROM msoa11_gb_urban",
  defaultLayers: "1111000",
  extent: 0,
  popup: false,
  idField: "msoa11_cd",
  geogNameField: "msoa11_nm",
  hoverFieldMetricMode: "All",
  metrics: [
    {
      id: 0,
      name: "2002",
      field: "lic2002",
      display: " km",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Distance" }],
      legendStops: legendStops
    },
    {
      id: 1,
      name: "2007",
      field: "lic2007",
      display: " km",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Distance" }],
      legendStops: legendStops
    },
    {
      id: 2,
      name: "2012",
      field: "lic2012",
      display: " km",
      decimals: 0,
      legendOptions: [{ id: 0, name: "Distance" }],
      legendStops: legendStops
    }
  ]
};
