export const TOPICS = [
  /*{
    id: 0,
    name: "All",
    display: "Filter Maps by Topic",
    iconClass: ""
  }, */
  {
    id: 1,
    name: "Population & Mobility",
    display: "Population",
    iconClass: "font-icon-select-1-e965"
  },
  {
    id: 2,
    name: "Retail Futures",
    display: "Retail",
    iconClass: "font-icon-select-1-e91f"
  },
  {
    id: 3,
    name: "Finance & Economy",
    display: "Financial",
    iconClass: "font-icon-select-1-e92a"
  },
  {
    id: 4,
    name: "Digital",
    display: "Digital",
    iconClass: "font-icon-select-1-e908"
  },
  {
    id: 5,
    name: "Transport & Movement",
    display: "Transport",
    iconClass: "font-icon-select-1-e977"
  }
];
