const cols =
  "the_geom_webmercator, lsoa11_cd, lsoa11_nm, lad_code, aip_spr, aip_grp";

export const AIPC = {
  title: "Ageing in Place Classification",
  description:
    "AiPC classifies the Over 50s population in England, based on a number of high quality data sources such as the 2011 Census, British Population Survey (BPS), Access to Healthy Assets & Hazards (AHAH), NHS English Prescribing Data (EPS) and Registered Patient (RP) data.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/ageing-place-classification-aipc",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM lsoa11_uk",
  buildingSql: "SELECT " + cols + " FROM lsoa11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM lsoa11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM lsoa11_uk_urban",
  defaultLayers: "1111000",
  extent: 3,
  popup: true,
  popupType: "classification",
  idField: "lsoa11_cd",
  geogNameField: "lsoa11_nm",
  ladCodeField: "lad_code",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.aip_spr = '1' then 1 else 0 end) as count_1,
      sum(case when d.aip_spr = '2' then 1 else 0 end) as count_2,
      sum(case when d.aip_spr = '3' then 1 else 0 end) as count_3,
      sum(case when d.aip_spr = '4' then 1 else 0 end) as count_4,
      sum(case when d.aip_spr = '5' then 1 else 0 end) as count_5,
      sum(case when d.aip_grp = '1.1' then 1 else 0 end) as count_1_1,
      sum(case when d.aip_grp = '1.2' then 1 else 0 end) as count_1_2,
      sum(case when d.aip_grp = '1.3' then 1 else 0 end) as count_1_3,
      sum(case when d.aip_grp = '2.1' then 1 else 0 end) as count_2_1,
      sum(case when d.aip_grp = '2.2' then 1 else 0 end) as count_2_2,
      sum(case when d.aip_grp = '3.1' then 1 else 0 end) as count_3_1,
      sum(case when d.aip_grp = '3.2' then 1 else 0 end) as count_3_2,
      sum(case when d.aip_grp = '4.1' then 1 else 0 end) as count_4_1,
      sum(case when d.aip_grp = '4.2' then 1 else 0 end) as count_4_2,
      sum(case when d.aip_grp = '4.3' then 1 else 0 end) as count_4_3,
      sum(case when d.aip_grp = '5.1' then 1 else 0 end) as count_5_1,  
      sum(case when d.aip_grp = '5.2' then 1 else 0 end) as count_5_2,
      sum(case when d.aip_grp = '5.3' then 1 else 0 end) as count_5_3
  FROM oliverobrien.lsoa11_uk d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Supergroup",
      field: "aip_spr",
      lookup: "id",
      groups: [
        {
          id: "1",
          name: "Struggling, More Vulnerable Urbanites",
          colour: "#EA6E70",
          visible: true,
          description:
            "The population of this supergroup tend to live in urban and semi-urban areas, predominantly concentrated around major cities of the Midlands, Yorkshire and the Humber, North West and North East. Residents tend to be female, living in single-person households, and to live in terraced housing or flats, with above-average representation in socially rented accommodation. They are more likely to live in income deprived households and experience fuel poverty. Residents are characterised by the lowest levels of educational attainment and internet engagement, provide high levels of unpaid care, suffer from poor health, and see the highest rates of prescribing dementia medications for more advanced conditions. The areas are characterised by the lowest median house prices, and crime rates tend to be higher."
        },
        {
          id: "2",
          name: "Multicultural Central Urban Living",
          colour: "#F9D102",
          visible: true,
          description:
            "The population of this supergroup tend to live in city centres, with concentrations in major cities.  This is the youngest and most ethnically diverse supergroup, with higher-than-average proportions of residents born overseas, and of Asian, and Black, Mixed and Other ethnicities. There are also notably lower levels of English language proficiency. Residents are more likely to live in rented accommodation, particularly flats, and unlikely to have any spare rooms. The proportion of households without central heating is above the national average, and households in this supergroup are the most likely to experience income deprivation and fuel poverty. Nevertheless, median house prices are relatively high – typical of their central location. This supergroup has the lowest proportion of retirees, likely reflecting the younger age structure with a high proportion aged 50-64. However, employment rates are below average (though rates of self-employment are similar), and this supergroup has the highest rates of unemployment. The proportion of single-person households and living with children is higher than the national average, vehicle ownership is low, and residents tend to have relatively low levels of education. Though less likely to provide unpaid care, the likelihood of poor health and disability are also relatively high compared to the national average. Proximity to the city centre means distances to amenities and health services are amongst the shortest while the density of civic assets is the highest."
        },
        {
          id: "3",
          name: "Rurban Comfortable Ageing",
          colour: "#9DD2A8",
          visible: true,
          description:
            "The population of this supergroup predominantly live in rural, or rural-urban fringe areas. This is the largest and oldest supergroup: it has the highest ratio of older people to younger people, and the highest median age reflects the concentration of older people in more rural areas. Residents are the most likely to be married and/or living as a couple. There is a high proportion of White British residents, with lower-than-average representation of ethnic minorities. This supergroup is most likely to own their properties outright and tend to live in detached/semi-detached housing or bungalows, and with spare rooms. This supergroup is the least likely to experience fuel poverty or to live in income deprived households. They tend to be in better health than the other supergroups and are most likely to provide between 0-19 hours of unpaid care a week. They are relatively more likely to be either in self- or part-time employment and tend to have medium or higher levels of educational attainment. This is the most digitally engaged supergroup of older people. Their geography means that though they benefit from better air quality and lower crime rates, distance to services and amenities are amongst the highest. Accordingly, this supergroup is the most likely to have access to a vehicle."
        },
        {
          id: "4",
          name: "Retired Fringe and Residential Stability",
          colour: "#1E6FD2",
          visible: true,
          description:
            "The population of this supergroup are concentrated in rural suburbs of smaller cities and towns, and coastal areas, particularly to the East. Residents of these areas are more likely to be between 65 and 84, with the highest proportion of retirees found across all clusters. They are predominantly UK-born White British, are most likely to own their property outright and are likely to have spare rooms. This group represents a very stable population, with the lowest levels of residential mobility indicated across all clusters. However, the remaining characteristics in each domain are otherwise very close to the national average."
        },
        {
          id: "5",
          name: "Cosmopolitan Comfort Ageing",
          colour: "#7833A6",
          visible: true,
          lightText: true,
          description:
            "The population of this supergroup are concentrated in the periphery of major cities, or in the suburbs of towns, particularly around London and the South East. These areas are characterised by the highest median house price (mean value of £362,158.90). Residents are highly educated and likely to be either in full-time employment or self-employed. They do not provide many hours of unpaid care, and are likely to live in property with a mortgage or shared ownership. There is also a higher proportion of people living in privately rented accommodation. Housing type tends towards terraced houses or flats, and there is an above-average rate of living in a crowded property. Though members of this group tend to have better health outcomes overall, there is a higher prescribing rate of dementia medications. People have access to high-speed broadband and like to engage with the internet, especially for shopping, banking and social use. These communities have the highest level of residential churn, and it is notable that there is also a relatively low ratio of older people to younger people in the local populations."
        }
      ]
    },
    {
      id: 1,
      name: "Group",
      field: "aip_grp",
      lookup: "id",
      // prettier-ignore
      groups: [
		{ id: "1.1", parent: "1", name: "Disadvantaged Single Households", colour: "#F2B28E", visible: true, description: "Shares a similar age-structure to parent supergroup, with the highest proportion of people aged 85+ despite the lowest median age at 34.68. A relatively more diverse population than the parent supergroup but with similarly high proportions in single-person households and socially rented accommodation. Households are less likely to have spare rooms and most likely to experience income deprivation and fuel poverty.  This group are the least educated and least likely to engage digitally, but with the highest capacity for civic engagement." },
		{ id: "1.2", parent: "1", name: "Struggling White British", colour: "#FA6B4B", visible: true, description: "Median age are slightly higher than parent supergroup (37.75) and with a slightly higher proportion aged 65-74. A higher proportion of White British than the parent supergroup, and relatively more likely to identify with a religion. Though below the national average, they are more likely to live in detached/semi-detached houses or bungalows than parent supergroup and, overall, more likely to provide unpaid care. As this group tend to locate at semi-urban (urban fringe) areas, travel distances to local amenities and health services tend to be greater than the other two groups." },
		{ id: "1.3", parent: "1", name: "Terraced Mix, Relative Stability", colour: "#CA1A1E", visible: true, description: "This group is slightly younger than the parent supergroup, with a slightly higher proportion aged 50-64. They are characterised by higher levels of educational attainment, employment and digital engagement than the parent supergroup, and are more likely to own their own home, enjoy more financial security and are less likely to be in poor health. Though living in strained circumstances compared to the national average, this group are relatively better off than the two sister groups." },
		{ id: "2.1", parent: "2", name: "Inner City Diverse Living", colour: "#FECC5F", visible: true, description: "This group tend to concentrate within inner-city areas, with higher median house prices than the parent supergroup. Residents are far more likely to live in socially rented accommodation, concentrating in flats and less likely to have spare rooms. It is an ethnically diverse cluster, but as compared to the parent supergroup with relatively lower proportions of Asian ethnicities and higher proportions of Black, Mixed and Other ethnicities.  Though residents are more likely to live in income deprived households, they have a lower risk of fuel poverty. Given the proximity to the city centre, the low levels of vehicle ownership and short distances to services and amenities is unsurprising." },
		{ id: "2.2", parent: "2", name: "Peripheral Constrained Diverse Living", colour: "#FE8D3B", visible: true, description: "This group concentrates on the periphery of town and city centres, with notably lower median house prices than the parent group. Residents are more likely to be of Asian ethnicity and to identify with a religion than in any other cluster reported, and residents have the lowest levels of English language proficiency. They are more likely to be living as a couple or married than the parent supergroup, residing in terraces, detached/semi-detached housing, or bungalows, either mortgaged or owned outright. However, housing quality is poorer than the parent supergroup, and households are at greater risk of fuel poverty. Rates of self-employment are lower while there are a higher proportion of retirees." },
		{ id: "3.1", parent: "3", name: "Rural Ageing", colour: "#B9E5B5", visible: true, description: "This group concentrate in rural areas, with the highest travel distances to healthcare facilities, services and amenities. This group have less financial security than the parent supergroup, are more likely to live in privately or socially rented accommodation and less likely to have a mortgage.  Though this group tend to have central heating, the housing quality is relatively poor. Of those working, more are in self-employment than the parent supergroup. Digital engagement is slightly lower than in the parent supergroup, with notably lower proportions of people with broadband access at home and a lower broadband speed available." },
		{ id: "3.2", parent: "3", name: "Ageing in the Affluent Fringe", colour: "#77C476", visible: true, description: "Members in this group tend to reside in more affluent areas on the urban-rural fringe and are less likely to experience financial hardship than the parent supergroup. Housing tends to be of good quality, with detached/semi-detached housing and bungalows dominating the dwelling types. Higher proportions are married, living as a couple, and/or with children. This group has the highest level of digital engagement across different online activities of all clusters, and experiences relatively better health than the parent supergroup." },
		{ id: "4.1", parent: "4", name: "Retired Country and Coastal Living", colour: "#BED6E9", visible: true, description: "Relative to the parent supergroup, there are more people aged 75-84 and 85 and over in this group, and residents have a slightly higher tendency to higher levels of educational attainment and being self-employed. Residents also have a higher probability of living in communal establishments (rather than a household) and are less likely to identify with a religion. Despite notably lower broadband speeds, levels of digital engagement are relatively high. As many areas in this group are in coastal areas and further from larger urban areas, members of this group benefit from significantly better air quality, but also have high levels of vehicle access and longer distance commutes." },
		{ id: "4.2", parent: "4", name: "Comfortable Rural/Suburban Ageing Workers and Retirees", colour: "#6AAED6", visible: true, description: "A slightly younger age-structure relative to the parent supergroup, with a higher proportion aged 50-64 and 65-74. Residents are likely to be married or living as a couple, with a low proportion of single-person households. They tend to own their property which is more likely to be detached /semi-detached housing or a bungalow, and less likely to be overcrowded. Of those working, there are slightly more people in part-time employment. These areas are very stable, experiencing the lowest level of residential churn within this group." },
		{ id: "4.3", parent: "4", name: "Constrained Semi-Rural Ageing and Retirement", colour: "#1E71B4", visible: true, description: "This group may be living in relatively more constrained circumstances than the parent supergroup: they have relatively lower levels of educational attainment, are more likely to be unemployed, provide more than 20 hours of unpaid care a week, and tend to have poorer health outcomes. This group are much less likely to engage with the internet and more likely to be affected by household income deprivation and fuel poverty. The areas experience higher crime rates, and the median house price is lower." },
		{ id: "5.1", parent: "5", name: "Cosmopolitan Family Ageing", colour: "#CBAADE", visible: true, description: "This group has a slightly higher proportion of ages 50-64 than the parent supergroup, with people more likely to live in mortgaged properties, particularly detached/semi-detached housing. A higher proportion of this group have lower levels of educational attainment relative to the parent group, and there is also a greater representation of people born overseas and from Asian ethnicities. Single-person households are relatively less common, and residents are more likely to be living with children, married and/or living as a couple. This group is most prevalent in London and across the South East." },
		{ id: "5.2", parent: "5", name: "Coastal Later Aged Retirees", colour: "#9169BE", visible: true, description: "Compared to the parent supergroup, there are notably higher proportions of White British residents in these areas, with a much lower representation of ethnic minorities or residents born overseas. Residents are also notably older, both than the average national population for ages 85 and over, and the parent supergroup for 75 and over.  They are likely to be female, living in single-person households and retired. However, there is also a higher proportion living in communal establishments than many other areas. These areas tend to have relatively high levels of residential mobility, are much less prevalent in London and more often found in towns on the Southern coast." },
		{ id: "5.3", parent: "5", name: "Cosmopolitan Ageing", colour: "#621263", visible: true, lightText: true, description: "Residents are highly educated, less likely to be retired, and slightly younger than the parent supergroup. Ethnic minorities, particularly Black, Mixed and Other ethnicities and those born overseas are more highly represented in this group. Residents are more likely to live in crowded households, particularly flats. These areas concentrate in London, but are also found in other affluent suburbs of major cities. The median house price in these areas is very high, but the centrality means residents benefit from good proximity to a range of amenities and civic assets." }
      ]
    }
  ]
};
