<template>
  <div class="map-filter">
    <p>Choose filters to only show matching features on the map</p>
    <div class="mt-2">
      <label> <strong>Local Authority</strong> is</label>
      <b-form-select
        v-model="localAuthorityFilter.selected"
        :options="localAuthorityFilter.options"
        :disabled="!ladField"
        v-on:change="updateFilter"
      >
        <template v-slot:first> </template
      ></b-form-select>
    </div>
    <div v-for="(h, i) in hierarchyFilters" v-bind:key="h.id" class="mt-2">
      <label>
        and <strong>{{ h.label }} </strong> is
      </label>
      <b-form-select
        v-model="h.selected"
        multiple
        :select-size="4"
        v-on:change="updateFilter"
      >
        <b-form-select-option
          v-for="opt in h.options.filter(x => hierachyFilter(x, i))"
          :key="opt.value"
          :value="opt.value"
          :style="{ backgroundColor: opt.colour }"
        >
          <span v-html="opt.text"> </span>
        </b-form-select-option>
      </b-form-select>
    </div>
    <div v-if="metrics.length > 0">
      <div
        v-for="(metric, index) in metricFilters"
        v-bind:key="index"
        class="mt-2"
      >
        <b-button
          class="float-right"
          style="padding:0 5px"
          v-on:click="removeMetric(index)"
          variant="outline-danger"
          size="sm"
          v-if="index > 0"
          >X</b-button
        >
        <label> and <strong>Data Field</strong> </label>
        <b-form-select v-model="metric.field" v-on:change="updateFilter">
          <b-form-select-option
            v-for="mo in metricOptions.fields"
            :key="mo.value"
            :value="mo.value"
          >
            <span v-html="mo.text"> </span>
          </b-form-select-option>
        </b-form-select>
        <b-form-select
          v-model="metric.type"
          :options="metricOptions.types"
          class="mt-1"
          v-on:change="updateFilter"
        >
        </b-form-select>
        <b-form-input
          :id="`metric-${index}`"
          v-model="metric.value"
          placeholder="Enter a number"
          :state="!metric.value ? null : !isNaN(metric.value)"
          :aria-describedby="`feedback-metric-${index}`"
          class="mt-1"
          v-on:change="updateFilter"
        ></b-form-input>
        <b-form-invalid-feedback :id="`feedback-metric-${index}`">
          Enter a number
        </b-form-invalid-feedback>
      </div>
      <b-button
        class="mt-2 float-right"
        v-on:click="addMetric"
        variant="outline-secondary"
        size="sm"
        >Add another</b-button
      >
    </div>
    <b-button class="mt-2" v-on:click="clearFilter" variant="primary"
      >Clear all</b-button
    >
  </div>
</template>

<script>
import { LAD } from "@/config/LAD";

export default {
  name: "MapFilter",
  props: {
    hierarchy: Array,
    metrics: Array,
    ladField: String,
    london: Boolean
  },
  components: {},
  methods: {
    hierachyFilter: function(hierachy, index) {
      let filter = true;

      if (
        index > 0 &&
        hierachy.parent &&
        this.hierarchyFilters[index - 1].selected.length > 0
      ) {
        filter = this.hierarchyFilters[index - 1].selected.includes(
          hierachy.parent
        );
      }
      return filter;
    },
    updateFilter: function() {
      this.hierarchyFilters = [...this.hierarchyFilters];
      const metrics = this.metricFilters.filter(
        opt =>
          opt.field != null &&
          opt.type &&
          opt.value != null &&
          !isNaN(opt.value)
      );
      this.$emit("change", {
        hierarchy: this.hierarchyFilters,
        metrics: metrics,
        lad: this.localAuthorityFilter
      });
    },
    clearFilter: function() {
      this.hierarchyFilters.forEach(element => {
        element.selected = [];
      });
      this.localAuthorityFilter.selected = null;
      this.metricFilters = [{ id: 0, field: null, type: ">", value: null }];
      this.updateFilter();
    },
    makeMetricOptions: function() {
      let config = {
        fields: this.metrics.map(x => ({
          value: x.field,
          text: x.name
        })),
        types: [
          { value: ">", text: "greater than" },
          { value: "<", text: "less than" }
        ]
      };

      return config;
    },
    addMetric: function() {
      const maxid = this.metricFilters.reduce((acc, metric) => {
        return Math.max(acc, metric.id);
      }, 0);

      this.metricFilters.push({
        id: maxid + 1,
        field: null,
        type: ">",
        value: null
      });
    },
    removeMetric: function(index) {
      this.metricFilters.splice(index, 1);
    },
    makeHierarchyFilters: function() {
      let config = [];
      const params = this.$route.query;
      this.hierarchy.forEach(element => {
        let selected = [];
        if (
          Object.prototype.hasOwnProperty.call(params, "f_h" + element.id + "")
        ) {
          selected = params["f_h" + element.id + ""].split("~");
        }
        let data = {
          label: element.name,
          selected: selected,
          field: element.field,
          options: []
        };
        element.groups.forEach(group => {
          data.options.push({
            value: group[element.lookup],
            text: group.id.toString() + " - " + group.name,
            colour: group.colour,
            parent: group.parent
          });
        });
        config.push(data);
      });
      return config;
    },
    makeLADFilter: function() {
      let selected = null;
      const params = this.$route.query;
      if (Object.prototype.hasOwnProperty.call(params, "f_lad")) {
        selected = params["f_lad"];
      }

      return {
        selected: selected,
        options: [
          ...[{ value: null, text: "All Local Authorities" }],
          ...LAD.filter(x =>
            this.london ? x.london == this.london : true
          ).map(x => ({ value: x.id, text: x.name }))
        ]
      };
    },
    makeMetricFilters: function() {
      let filters = [];

      const params = this.$route.query;
      for (let i = 0; i < 10; i++) {
        if (Object.prototype.hasOwnProperty.call(params, "f_m" + i + "")) {
          const data = params["f_m" + i + ""].split("~");
          if (data.length == 3) {
            filters.push({
              id: i,
              field: data[0],
              type: data[1],
              value: data[2]
            });
          }
        }
      }
      if (filters.length == 0) {
        filters.push({ id: 0, field: null, type: ">", value: null });
      }
      return filters;
    }
  },

  data() {
    return {
      hierarchyFilters: this.makeHierarchyFilters(),
      metricOptions: this.makeMetricOptions(),
      metricFilters: this.makeMetricFilters(),
      localAuthorityFilter: this.makeLADFilter()
    };
  }
};
</script>

<style scoped></style>
