const cols = "the_geom_webmercator, pcds, classif";

export const POSTCODECONTEXT = {
  title: "Postcode Context Classification",
  description:
    "Urban context measures captured from multispectral satellite imagery.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/postcode-context-classification",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  individualSql: "SELECT " + cols + " FROM pc20_gb_pcc",
  sql: "SELECT " + cols + " FROM pc20_gb_pcc",
  buildingSql: "SELECT " + cols + " FROM pc20_gb_pcc",
  detailUrbanSql:
    "SELECT " +
    cols +
    " FROM pc20_gb_pcc where right(pcds, 1) in ('A', 'B', 'C', 'D')",
  urbanSql: "SELECT " + cols + " FROM pc20_gb_pcc where right(pcds, 1) = 'A'",
  defaultLayers: "1111000",
  extent: 0,
  popup: true,
  popupType: "classification",
  idField: "pcds",
  individualIdField: "pcds",
  geogNameField: "pcds",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.classif = 'A' then 1 else 0 end) as count_A,
      sum(case when d.classif = 'B' then 1 else 0 end) as count_B,
      sum(case when d.classif = 'C' then 1 else 0 end) as count_C,
      sum(case when d.classif = 'D' then 1 else 0 end) as count_D,
      sum(case when d.classif = 'E' then 1 else 0 end) as count_E,
      sum(case when d.classif = 'F' then 1 else 0 end) as count_F,
      sum(case when d.classif = 'G' then 1 else 0 end) as count_G
  FROM oliverobrien.pc20_gb_pcc d 
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Group",
      field: "classif",
      lookup: "id",
      groups: [
        {
          id: "A",
          name: "Inner City and Town Centres",
          colour: "#ea4d78",
          visible: true,
          description:
            "Predominantly found covering many central areas of cities and towns; this Group characterises many highstreets and small commercial premises supporting large working day populations. Coalescing with commercial land uses, residential accommodation is mostly flats. This Group is also observed in some less central commercial parks / zones; and dense terraced residential houses proximal to these areas with very limited greenspace."
        },
        {
          id: "B",
          name: "Spacious Residential Properties",
          colour: "#f36d5a",
          visible: true,
          description:
            "Areas characterised by this Group are predominantly residential and often contain properties at a low density, with gardens, wide streets and often surrounded by greenspace. Typically, this Group is found within larger developments on suburban fringes and in villages, and mostly containing property that have been built since the 1940s, although less prevalently since the 2000s."
        },
        {
          id: "C",
          name: "High Density Urban Core",
          colour: "#e4a5d0",
          visible: true,
          description:
            "This cluster tends to be found within the built-up urban core of many large towns and cities but also characterises areas of out-of-town business, retail or industrial zones. Coalescing with structures supporting a large workday population, residential accommodation is predominantly in flats, but also includes areas of dense traditional terraced houses."
        },
        {
          id: "D",
          name: "Rural Residences",
          colour: "#a5cf7c",
          visible: true,
          description:
            "Properties within this group tend to be residential and surrounded by greenspace. These areas are characterised by clusters of larger adjoining or traditional cottages, with ages dating from before 1900."
        },
        {
          id: "E",
          name: "Rural Properties and Farms",
          colour: "#d2d1ab",
          visible: true,
          description:
            "This Group are mostly found within rural or more isolated locations and are typically characterised by larger residential properties or farms. As might be expected, properties tend to be surrounded by greenspace; however, many plots also encompass large paved agricultural yards."
        },
        {
          id: "F",
          name: "Inner Suburban Townhouses and Large Terraces",
          colour: "#79cdcd",
          visible: true,
          description:
            "Properties within these inner suburban locations tend to comprise semi-detached properties or larger terraces; with some properties sub divided into flats. Many of the property within these areas are pre-World War 2, some of which have gardens / outdoor space."
        },
        {
          id: "G",
          name: "Outer Suburbs",
          colour: "#ffd39b",
          visible: true,
          description:
            "This Group is mostly found towards the periphery of urban areas, consisting of mature residential neighbourhoods of mainly semi-detached properties. Many of the properties were built after World War 2 and until the 1970s and 80s."
        }
      ]
    }
  ]
};
