const cols = "the_geom_webmercator, oa11_cd, la17_cd, la17_nm, mdm_clascd";

export const MODUM = {
  title:
    "Classification of Multidimensional Open Data of Urban Morphology (MODUM)",
  description:
    "Typology of neighbourhoods based on a number of built environment and urban morphology attributes, such as street & railway network, green spaces, retail access and historic buildings.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/classification-multidimensional-open-data-urban-morphology-modum",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM oa11_uk where mdm_clascd is not null",
  buildingSql:
    "SELECT " + cols + " FROM oa11_uk_buildings where mdm_clascd is not null",
  detailUrbanSql:
    "SELECT " +
    cols +
    " FROM oa11_uk_detail_urban where mdm_clascd is not null",
  urbanSql:
    "SELECT " + cols + " FROM oa11_uk_urban where mdm_clascd is not null",
  defaultLayers: "1111000",
  extent: 3,
  popup: true,
  popupType: "classification",
  idField: "oa11_cd",
  geogNameField: "la17_nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la17_cd",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.mdm_clascd = '1' then 1 else 0 end) as count_1,
      sum(case when d.mdm_clascd = '2' then 1 else 0 end) as count_2,
      sum(case when d.mdm_clascd = '3' then 1 else 0 end) as count_3,
      sum(case when d.mdm_clascd = '4' then 1 else 0 end) as count_4,
      sum(case when d.mdm_clascd = '5' then 1 else 0 end) as count_5,
      sum(case when d.mdm_clascd = '6' then 1 else 0 end) as count_6,
      sum(case when d.mdm_clascd = '7' then 1 else 0 end) as count_7,
      sum(case when d.mdm_clascd = '8' then 1 else 0 end) as count_8
  FROM oliverobrien.oa11_uk d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Cluster",
      field: "mdm_clascd",
      lookup: "id",
      groups: [
        {
          id: "1",
          name: "Suburban Landscapes",
          colour: "#96a17a",
          visible: true,
          description:
            "These areas are typically of semi-detached houses, with good access to parks. They tend to be quite distant from town centres. They are primarily residential areas and close to schools. Cul-de-sacs are relatively common, probably because of organized developments and gated communities."
        },
        {
          id: "2",
          name: "Railway Buzz",
          colour: "#8a8bad",
          visible: true,
          description:
            "These areas are dominated by railway tracks and railway stations. They have no other major distinguishing attributes which may suggest that they are actually rather heterogeneous in physical structure."
        },
        {
          id: "3",
          name: "The Old Town",
          colour: "#d5787b",
          visible: true,
          description:
            "The traditional town centre, usually close by the main high street. It is strongly defined by the amount of registered buildings. Typically, a lot of recreational facilities can be found there, like pubs and restaurants, along with many administrative buildings and some historical major roads. Although it does have a considerable amount of flats, densities remain low, potentially due to refurbishments and change of usage."
        },
        {
          id: "4",
          name: "Victorian Terraces",
          colour: "#cebc7f",
          visible: true,
          description:
            "These are typical neighbourhoods with terraced housing, average densities and some access to amenities. It is one of the few morphologies that can be found anywhere."
        },
        {
          id: "5",
          name: "Waterside Settings",
          colour: "#a5c7df",
          visible: true,
          description:
            "The principal defining attribute of these neighbourhoods is their proximity to surface water such as rivers, canals or sea. Some of these areas are ports, industrial or post-industrial sites. Distinctive infrastructure is arterial roads, i.e. roads wide enough to be used by lorries for the distribution of goods."
        },
        {
          id: "6",
          name: "Countryside Sceneries",
          colour: "#8dc298",
          visible: true,
          description:
            "These areas are dotted with detached houses, and are located either near or within open countryside. Most rural villages fall into this category, along with some city fringe developments that lie beyond the classic suburbs."
        },
        {
          id: "7",
          name: "High Street and Promenades",
          colour: "#f5c39a",
          visible: true,
          description:
            "These clearly depicted areas represent the main retail centres of urban regions located along the main commercial streets. This cluster also includes areas with significant pedestrianised street network, especially along seafronts, where a lot of recreational and leisure venues can be found."
        },
        {
          id: "8",
          name: "Central Business District",
          colour: "#bc8fb6",
          visible: true,
          description:
            "The area often called city centre. Typically, high-rise buildings with a lot of commercial and office spaces, hence the relatively low net population density. These areas have proximity to the majority of public amenities, and have plenty of access vial major roads and railways. For moderate-size cities the title holds true, but in areas such as London they tend to be too expansive to be labelled as central."
        }
      ]
    }
  ]
};
