const cols = "the_geom_webmercator, wz11_cd, la_nm, la11_cd, cow_ewsg, cow_ewg";

export const COWZEW = {
  title: "Classification of Workplace Zones (England/Wales)",
  description:
    "An interim geodemographic classification for England/Wales based on the 2011 UK Census.",
  downloadLink:
    "https://data.cdrc.ac.uk/dataset/classification-workplace-zones-cowz",
  url: "https://oliverobrien.carto.com/api/v1/map?api_key=default_public",
  sql: "SELECT " + cols + " FROM wz11_uk",
  buildingSql: "SELECT " + cols + " FROM wz11_uk_buildings",
  detailUrbanSql: "SELECT " + cols + " FROM wz11_uk_detail_urban",
  urbanSql: "SELECT " + cols + " FROM wz11_uk_urban",
  defaultLayers: "1111000",
  extent: 3,
  popup: true,
  popupType: "classification",
  idField: "wz11_cd",
  geogNameField: "la_nm",
  geogNamePrefix: "Part of ",
  ladCodeField: "la11_cd",
  queryUrl: "https://oliverobrien.carto.com/api/v1/sql?api_key=default_public",
  makePolygonSQL: function(polygonSubquery) {
    return `SELECT
    count(*) as count_total,
    sum(case when d.cow_ewsg = 'A' then 1 else 0 end) as count_a,
      sum(case when d.cow_ewsg = 'B' then 1 else 0 end) as count_b,
      sum(case when d.cow_ewsg = 'C' then 1 else 0 end) as count_c,
      sum(case when d.cow_ewsg = 'D' then 1 else 0 end) as count_d,
      sum(case when d.cow_ewsg = 'E' then 1 else 0 end) as count_e,
      sum(case when d.cow_ewsg = 'F' then 1 else 0 end) as count_f,
      sum(case when d.cow_ewsg = 'G' then 1 else 0 end) as count_g,
      sum(case when d.cow_ewg = 'A1' then 1 else 0 end) as count_a1,
      sum(case when d.cow_ewg = 'A2' then 1 else 0 end) as count_a2,
      sum(case when d.cow_ewg = 'A3' then 1 else 0 end) as count_a3,
      sum(case when d.cow_ewg = 'A4' then 1 else 0 end) as count_a4,
      sum(case when d.cow_ewg = 'A5' then 1 else 0 end) as count_a5,
      sum(case when d.cow_ewg = 'A6' then 1 else 0 end) as count_a6,
      sum(case when d.cow_ewg = 'B1' then 1 else 0 end) as count_b1,
      sum(case when d.cow_ewg = 'B2' then 1 else 0 end) as count_b2,
      sum(case when d.cow_ewg = 'B3' then 1 else 0 end) as count_b3,
      sum(case when d.cow_ewg = 'B4' then 1 else 0 end) as count_b4,
      sum(case when d.cow_ewg = 'B5' then 1 else 0 end) as count_b5,  
      sum(case when d.cow_ewg = 'C1' then 1 else 0 end) as count_c1,
      sum(case when d.cow_ewg = 'C2' then 1 else 0 end) as count_c2,
      sum(case when d.cow_ewg = 'C3' then 1 else 0 end) as count_c3,
      sum(case when d.cow_ewg = 'C4' then 1 else 0 end) as count_c4,
      sum(case when d.cow_ewg = 'C5' then 1 else 0 end) as count_c5,
      sum(case when d.cow_ewg = 'D1' then 1 else 0 end) as count_d1,
      sum(case when d.cow_ewg = 'D2' then 1 else 0 end) as count_d2,
      sum(case when d.cow_ewg = 'E1' then 1 else 0 end) as count_e1,
      sum(case when d.cow_ewg = 'E2' then 1 else 0 end) as count_e2,
      sum(case when d.cow_ewg = 'E3' then 1 else 0 end) as count_e3,
      sum(case when d.cow_ewg = 'E4' then 1 else 0 end) as count_e4,
      sum(case when d.cow_ewg = 'F1' then 1 else 0 end) as count_f1,
      sum(case when d.cow_ewg = 'F2' then 1 else 0 end) as count_f2,
      sum(case when d.cow_ewg = 'F3' then 1 else 0 end) as count_f3,
      sum(case when d.cow_ewg = 'F4' then 1 else 0 end) as count_f4,
      sum(case when d.cow_ewg = 'G1' then 1 else 0 end) as count_g1,
      sum(case when d.cow_ewg = 'G2' then 1 else 0 end) as count_g2,
      sum(case when d.cow_ewg = 'G3' then 1 else 0 end) as count_g3
  FROM oliverobrien.wz11_uk d
  inner join  (${polygonSubquery}) p
  on st_intersects(d.the_geom, p.the_geom)`;
  },
  hierarchy: [
    {
      id: 0,
      name: "Supergroup",
      field: "cow_ewsg",
      lookup: "id",
      groups: [
        {
          id: "1",
          name: "Retail",
          colour: "#FEB93D",
          visible: true,
          description:
            "The retail supergroup is characterised by high workplace population density, higher than average female, young and student workers, working part-time, in semi-routine occupations in the retail, motor repair, real estate, food and accommodation services, and finance and insurance sectors. They tend to travel very short distances to work by public transport, bike or on foot."
        },
        {
          id: "2",
          name: "Top jobs",
          colour: "#3D76EA",
          visible: true,
          description:
            "This supergroup is characterised by high density and a low OA:WZ ratio (splits), higher than average percentage of young females, high Black, Asian + European ethnicities, high percentages of Level 4 qualifications, high employees, high on ICT, Finance, Prof/Sci/Tech, high on Higher managerial and Lower managerial, low on routine occupations, high on travelling more than 20km to work, low on working from home/no fixed place, low on part time working. This group is mainly found in large numbers in the major metropolitan centres. There are small numbers of isolated WZs in this group in other locations e.g. Science Parks etc."
        },
        {
          id: "3",
          name: "Metro suburbs",
          colour: "#9731B5",
          visible: true,
          description:
            "This supergroup is characterised by a higher than average percentage of non-white workers, self-employed workers and workers working from home or with no fixed place of work, together with a higher than average percentage of people using public transport to get to work. Geographically, this supergroup is mostly in large metropolitan areas, particularly those with a significant ethnic population and public transport system. It is particularly obvious in large cities such as London, Manchester and Birmingham and other cities with multicultural populations. In the very large cities where the top jobs supergroup is present, the metro suburbs supergroup is generally found in a ring outside of these top jobs, but is located inside supergroup four (suburban services). In areas which do not have a core of top jobs, the metro suburbs are often found just outside the retail centre of the city, but still inside the suburban service."
        },
        {
          id: "4",
          name: "Suburban services",
          colour: "#838383",
          visible: true,
          description:
            "This supergroup is characterised by slightly higher than average white ethnicity, high percentages working from home/no fixed place of work, slightly higher than average Education, Health/social work, Construction and utilities and a low proportion of people travelling more than 20km. It is fairly low density, being characterised by a higher than average proportion of OA of mergers. It is the largest supergroup by number of WZs and is mostly found outside of the metro suburbs and across lower-density urban areas such as the South Wales valleys."
        },
        {
          id: "5",
          name: "Manufacturing and distribution",
          colour: "#8A6F3D",
          visible: true,
          description:
            "The manufacturing, transport and distribution supergroup is characterised by a slightly lower than average OA:WZ ratio, low percentage of female workers, low percentage high qualifications, high percentage low qualifications, high employees, low part-time, high manufacturing, Energy/utilities, transport and storage, lower status working, low on working from home and no fixed place of work, slightly high on >20km."
        },
        {
          id: "6",
          name: "Rural",
          colour: "#32BE55",
          visible: true,
          description:
            "The Rural supergroup is characterised by a higher than average percentages of older, white, workers who are self-employed (both with and without employees), who work from home or have no fixed place of work. Workplace population density is low. Workers are primarily engaged in Agric/forest/fish, and, to a lesser extent, other sectors such as Mining/quarrying, Manufac, Utilities, Construction and Education. This supergroup is the largest in terms of number of WZs and is found in rural and outer suburban areas throughout England and Wales."
        },
        {
          id: "7",
          name: "Servants of society",
          colour: "#FB333D",
          visible: true,
          description:
            "The Servants of Society cluster is characterised by higher than average density, females and young females. It is just below average on non-White and European groups and just above for White. Level 4 qualifications are high, as are employees, while self-employment of any type is low. Both extremes of working hours are low, with part time 16-30 just on the average. The only industrial sectors above average are public administration and defence, education and health. Higher managerial jobs are a little above average, but lower managerial and intermediate status more so. Both extremes of travel distance are above average but working from home and without a fixed place of work are well below. In summary, these tend to be higher status, well-qualified employees in large public service organizations with good female representation in the workforce. They include hospitals, schools and colleges, prisons and government offices and are widely spread nationally, although somewhat clustered in the major service centres."
        }
      ]
    },
    {
      id: 1,
      name: "Group",
      field: "cow_ewg",
      lookup: "id",
      // prettier-ignore
      groups: [
		{ id: "1a", parent: "1", name: "Low density retail and wholesale", colour: "#ffebb0", visible: true, description: "A workforce characterised by above-average levels of White British ethnicity, employees and students, with particularly high levels of part-time working and employment in wholesale and retail trades, semi-routine occupations and short travel to work distances. This group is widely dispersed nationally but is found primarily on urban fringes." },
		{ id: "1b", parent: "1", name: "Market squares", colour: "#f7d98b", visible: true, description: "This group has a mainly White British workforce with high levels of female participation, self-employment, both with and without employees, students, part-time working. The presence of retail and wholesale is equalled by accommodation and food services and exceeded by financial and insurance activities. Travel to work distances are short and percentages travelling on foot or by bicycle are high. There are very low levels of working from home or at no fixed location. The group is very widely dispersed and found primarily in smaller towns." },
		{ id: "1c", parent: "1", name: "Multicultural urban high streets", colour: "#f0c465", visible: true, description: "This group has high levels of female participaton in a generally younger workforce with high percentages of Black and Asian ethnicities, students and part- time working. Accommodation and food services, financial and insurance activities occur with retail and wholesale as the dominant industries. Distances travelled are low and there is high use of public transport as well as travelling on foot or bicycle. These retail centres are generally found in larger cities and in outer centres of the major conurbations." },
		{ id: "1d", parent: "1", name: "Traditional high streets", colour: "#e8ae41", visible: true, description: "Very high female participation in workforce, which is generally young and White. High percentages of students, part-time working and with accommodation and food services, financial and insurance activities occuring alongside retail and wholesale. Widely dispersed nationally." },
		{ id: "1e", parent: "1", name: "Shop until you drop", colour: "#e0961f", visible: true, description: "High female participation in the workforce, which is young and with above average levels of Black and Asian ethnicities. Very high representation of students and part-time working. Retail and wholesale exceeds all other activities. Travel to work distances are short and percentages travelling on foot or by bicycle are high. This group includes major national and regional retail centres, including large purpose-built out of town and in-town developments." },
		{ id: "1f", parent: "1", name: "Eat, drink, shop and be merry", colour: "#db8400", visible: true, description: "Accommodation and food services, arts entertainment and recreation industries exceed retail and wholesale as a proportion of the workforce. European workers are present to an above-average level and there are many students and high levels of part-time working. Travel to work distances are short and percentages travelling on foot or by bicycle are high. There are very low levels of working from home or at no fixed location. The smallest group in terms of WZ numbers, but widespread geographically and covering a wide range of locations including coastal locations and other tourist destinations." },
		{ id: "2a", parent: "2", name: "Global business", colour: "#bfe9ff", visible: true, description: "This group is characterised by concentration in ICT, finance and insurance, professional, scientific and technical activities. The workforce has above average levels of Black, Asian and European ethnicities and high percentages of Level 4 qualifications. It is dominated by the highest status managerial, administrative and professional occupations, with very high percentages travelling more than 20km to work by public transport. It occurs almost exclusively in London, where it is widespread in the City of London and Docklands, with only a very few WZs in the centres of other metropolitan cities." },
		{ id: "2b", parent: "2", name: "Administrative centres", colour: "#7db4db", visible: true, description: "While the demographic, occupational status and travel to work characteristics of this group are very similar to the high status city commuters, these areas are marked by a very different industrial structure, with public administration and defence being the largest activity. This group is found only in the centres of large cities, but is less concentrated in London than the high status city commuters." },
		{ id: "2c", parent: "2", name: "Big city life", colour: "#467fb8", visible: true, description: "The workforce has a high proportion of non-British workers, with above-average levels of female participation. Activity is spread across a range of industries with accommodation and food services in the largest proportion but also ICT, financial and insurance activities and other professional services. Many travel more than 20km to work by public transport. This group is found exlusively close to the centres of the largest metropolitan areas and includes much of London's entertainment districts." },
		{ id: "2d", parent: "2", name: "Regional business centres", colour: "#1c4e94", visible: true, description: "This group is dominated by financial and insurance activities, together with other professional services and high status occupations dominate. The workforce includes many females and is moderately multicultural. There is a high percentage with Level 4 qualifications and most workers are employees. Travel to work distances are high, as is public transport use. This group is remarkably absent from Central London and is rather found in the business quarters of many regional cities." },
		{ id: "2e", parent: "2", name: "Science and business parks", colour: "#002673", visible: true, description: "This group comprises a number of individual sites with a highly qualified multiethnic workforce concentrated particularly in ICT and professional, scientific and technical activities. The geographical distribution is wide, although it is clustered in and around urban Britain and generally close to major transportation corridors. Percentages travelling more than 20km are very high and public transport use is above average." },
		{ id: "3a", parent: "3", name: "Metro surburban distribution", colour: "#e9bfff", visible: true, description: "Above average Black and Asian ethnicities and post-2001 EU accession countries in a workforce which is average in very many dimensions but in which transport and storage is the most prominent industry, wholesale and retail, accommodation and food services are also above average. Scattered across outer suburban areas of major metropolitan centres." },
		{ id: "3b", parent: "3", name: "Cosmopolitan metro suburban mix", colour: "#aa67cf", visible: true, description: "Above average Black and Asian ethnicities and post-2001 EU accession countries in a workforce in which construction, transport and storage, ICT are all above average as is self-employment without employees. Strongly marked by working from home or at no fixed place. Occurring in quite dense bands around the outer edges of multicultural urban areas and forming distinctive concentric rings around cities such as London, Birmingham and Leicester." },
		{ id: "3c", parent: "3", name: "Independent professional metro services", colour: "#7628a1", visible: true, description: "A muliticultural workforce in which Black, Asian and European groups are all above average, as is self-employment. ICT, financial and insurance activitie and professional services are all above the average, as are higher status occupations. Working from home or no fixed place and travel by public transport are all high. A very concentrated geographical distribution limited to espeically inner suburban areas of London and a few major cities but entirely absent elsewhere." },
		{ id: "3d", parent: "3", name: "Suburban metro infrastructure", colour: "#4d0073", visible: true, description: "A multicultural workforce with above average levels of employment in education, health and social work activities and with lower managerial, adminstrative and technical occupations slightly above average. Widely dispersed in the suburban areas of major multicultural cities but entirely absent elsewhere." },
		{ id: "4a", parent: "4", name: "Non-metropolitan suburban areas", colour: "#e0e0e0", visible: true, description: "Mostly White suburban areas with employment across a range of industries but with local services such as education, health and public administration all above average. Above average female participation in the workforce, mainly lowest status occupations, low long-distance commuting and high percentages travelling under 5km, working from home or no fixed place. Widespread suburban distribution, but mostly away from the larger metropolitan centres." },
		{ id: "4b", parent: "4", name: "Primarily residential suburbs", colour: "#4f4f4f", visible: true, description: "Mostly white suburban areas with above average levels of self-employment without employees, ranging across many industries with energy, utilities, construction, ICT and education all above average. Very little commuting over 20km but very high levels of working from home or no fixed place. Widely dispersed geographical distribution, but mostly on the edges of larger urban areas." },
		{ id: "5a", parent: "5", name: "Mining and quarrying facilities", colour: "#d6c19c", visible: true, description: "A workforce with low female participation, high percentage of White British and employees, greatly above average in mining and quarrying, manufacturing and energy and utilities, transport and storage. Lower supervisory, technical and routine occupations are all above average. Working from home or no fixed place are well below average. Widely dispersed geographically but mainly away from urban centres, some concentration around ports." },
		{ id: "5b", parent: "5", name: "Industrial units", colour: "#b59359", visible: true, description: "A multiethnic workforce with low female participation, with high proportions of no qualfications, employees. Industrial structure dominated by manufacturing and transport and storage and lower status, especially routine occupations. Found in industrial areas on the outer edges of larger cities." },
		{ id: "5c", parent: "5", name: "Business parks", colour: "#946d25", visible: true, description: "Workforce comprising mostly employees with below average females and part-time working, dominated by manufacturing and energy industries but ICT also above average. Covering broad spectrum of occupational statuses from higher managerial to routine. Greatly above average travelling more than 20km to work but low working from home or no fixed place. Widely dispersed nationally." },
		{ id: "5d", parent: "5", name: "Manufacturing, energy and utilities", colour: "#734d00", visible: true, description: "Below average female participation, above average post-2001 EU workforce, employees. Industrial structure dominated by manufacturing and energy with transport and storage. Above average lower status occupations with both high over 20km and under 5km travel to work. Extremely widely dispersed and often away from major urban areas." },
		{ id: "6a", parent: "6", name: "Rural with core services", colour: "#d3ffbf", visible: true, description: "Low female participation in a workforce which is older, mostly White and dominated by agriculture, forestry and fishing activities but where education and health are also above average, as is working from home. These are very widespread but tend to be found in rural service centres rather than in the most remote areas." },
		{ id: "6b", parent: "6", name: "Rural with non-local workers", colour: "#8acf67", visible: true, description: "Low female participation in a workforce which is older, mostly White and dominated by agriculture, forestry and fishing activities but with manufacturing and energy and utilities industries also present. Above average working from home. Widespread but not usually in the most remote rural areas." },
		{ id: "6c", parent: "6", name: "Rural with mining or quarrying", colour: "#50a128", visible: true, description: "Low female participation in a workforce which is older, mostly White and dominated by agriculture, forestry and fishing activities but in combination with mining and quarrying activities as well as energy and construction. Above average working from home or no fixed place. Widespread and low density, often in remote areas." },
		{ id: "6d", parent: "6", name: "Traditional countryside", colour: "#267300", visible: true, description: "In many way the most extreme rural profile, with above average White, elderly workforce, self-employment and working from home. Industrial structure dominated by agriculture, forestry and fishing, with some energy and construction. Geographically extensive and widely dispersed, covering many of the most remote rural areas." },
		{ id: "7a", parent: "7", name: "Large scale education", colour: "#ffbfbf", visible: true, description: "This group has a distinct profile with high female participation in a highly qualified workforce which is massively dominated by education. Lower managerial, administrative and professional occupations are pre-eminent. The geographical distribution is very widespread, reflecting not only the presence of universities but also of large schools and colleges within communities." },
		{ id: "7b", parent: "7", name: "Public administration", colour: "#cf6767", visible: true, description: "This group comprises a very White British workforce with high levels of female participation and most workers being employees. The industrial structure is dominated by public administration and defence, compulsory social security with intermediate occupations most in evidence. Commutes of both under 5km and over 20km are above average but, unsurprisingly, there is very little working from home. This group covers a range of large public sector employers among which prisons and various types of government offices are evident. It is widely dispersed nationally." },
		{ id: "7c", parent: "7", name: "Major hospitals", colour: "#a12828", visible: true, description: "The proportion of females in this workforce is far above average, as are Black and Asian ethnicities. High percentages of Level 4 qualifications and employees as a proportion of the workforce, with above average part-time working. The industrial structure is dominated by human health and social work, with a secondary peak in public administration. A range of higher to intermediate status occupations are above average, with both long and short commuting but very low working from home. This group is concentrated on major hospitals and is widely spread geographically." },
		{ id: "7d", parent: "7", name: "Highly qualified workforces and professional services", colour: "#730000", visible: true, description: "This group is characterised by a mixture of financial and insurance activities, professional services and public administration. The workforce has above- average female participation, is highly qualified and dominated by employees. A range of higher to intermediate status occupations are above average, with both long and short commuting but very low working from home. The geographical spread is nationwide, but concentrated in large service centres." }
  	  ]
    }
  ]
};
